import React from 'react'
import { useHistory } from 'react-router-dom'

import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import UsersGroupListItem from './ListItem'
import ScrollTransition from '../UI/animations/transition/ScrollTransition'

const UsersGroupList = React.memo(({ groups = [], onDelete, style }) => {
  const history = useHistory()
  const redirectToGroupPage = id => history.push(`groups/${id}`)
  if (!groups.length) {
    return <EmptyPlaceholder text='Список групп пуст' />
  }
  return (
    <ScrollTransition loading>
      <div className='card_list' style={style}>
        {groups?.map((group, index) => (
          <UsersGroupListItem
            group={group}
            key={group.id}
            index={index}
            hideDeleteIcon={group.pre_defined}
            onClick={() => redirectToGroupPage(group.id)}
            onDelete={() => onDelete({ id: group.id, title: group.title })}
          />
        ))}
      </div>
    </ScrollTransition>
  )
})

export default UsersGroupList
