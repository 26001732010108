import factsScheme from './factsScheme'

const formatValue = (value, format) => {
  const valuesByFormat = {
    percent: value => `${value}%`,
    count: value => value,
  }

  return valuesByFormat[format](value)
}

const prepareBadgeValue = (badgeScheme, data) => {
  const { type, format } = badgeScheme
  const value = data[type]

  return formatValue(value, format)
}

export default data => factsScheme.map(scheme => {
  const { type, title, format, badge: badgeScheme } = scheme
  const value = data[type.toLowerCase()]

  return {
    title,
    value: formatValue(value, format),
    badgeValue: badgeScheme ? prepareBadgeValue(badgeScheme, data) : null,
  }
})
