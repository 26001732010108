import {
  CHECK_ALL_ROOMS,
  CHECK_ALL_TABLES,
  CHECK_ROOM,
  CHECK_TABLE,
  CLEAR_FLOORS_SUCCEED,
  CLEAR_OFFICE,
  CLEAR_ROOMS_SUCCEED,
  CLEAR_TABLES_SUCCEED,
  DELETE_ALL_MARKERS_ON_FLOOR,
  DELETE_FLOOR,
  DELETE_MAP,
  DELETE_OFFICE,
  DELETE_OFFICE_TAG,
  DELETE_ROOM,
  DELETE_ROOM_MARKER,
  DELETE_ROOM_TYPE,
  DELETE_SELECTED_ROOMS_AND_TABLES,
  DELETE_TABLE,
  DELETE_TABLE_MARKER,
  DELETE_ZONE,
  GET_FILTERED_ROOMS,
  GET_FLOORS,
  GET_OFFICE,
  GET_OFFICE_TAGS,
  GET_OFFICES,
  GET_OFFICES_LICENSES,
  GET_ROOM_TYPES,
  GET_ROOMS,
  GET_TABLES,
  GET_ZONES,
  POST_FLOORS,
  POST_MAP_TO_FLOOR,
  POST_OFFICE,
  POST_OFFICE_IMAGES,
  POST_OFFICE_TAGS,
  POST_ROOM,
  POST_ROOM_MARKER,
  POST_ROOM_TYPE_ICON,
  POST_ROOM_TYPES,
  POST_TABLE,
  POST_TABLE_MARKER,
  POST_TAG_ICON,
  POST_ZONES,
  PUT_FLOOR,
  PUT_OFFICE,
  PUT_OFFICE_TAG,
  PUT_ROOM,
  PUT_ROOM_MARKER,
  PUT_ROOM_TYPE,
  PUT_TABLE,
  PUT_TABLE_MARKER,
  PUT_ZONE,
  RECALCULATE_SELECTED_COUNTS,
  UPDATE_MAP_TO_FLOOR,
} from '../offices'

// eslint-disable-next-line import/prefer-default-export
export const officesAC = {
  /* ------------ Offices ------------ */
  /* =========================================================================================== */
  getOffices: ({ delaySec, searchString, filter }) => ({
    type: GET_OFFICES, payload: { delaySec, searchString, filter },
  }),
  getOffice: ({ officeId }) =>
    ({ type: GET_OFFICE, payload: { officeId } }),
  postOffice: ({ officeData, callback }) => ({
    type: POST_OFFICE, payload: { officeData, callback },
  }),
  putOffice: ({ officeData, officeId }) => ({
    type: PUT_OFFICE, payload: { officeData, officeId },
  }),
  deleteOffice: ({ officeId, title, callback }) => ({
    type: DELETE_OFFICE, payload: { officeId, title, callback },
  }),
  postOfficeImages: ({ images, callback }) => ({
    type: POST_OFFICE_IMAGES, payload: { images, callback },
  }),
  // сбрасывает все поля в officesReducer кроме списка офисов
  clearOffice: () => ({ type: CLEAR_OFFICE }),
  /* =========================================================================================== */

  /* ------------ Licenses ------------ */
  /* =========================================================================================== */
  getLicenses: () => ({ type: GET_OFFICES_LICENSES }),
  /* =========================================================================================== */

  /* ------------ Floors ------------ */
  /* =========================================================================================== */
  getFloors: ({ roomType, officeId }) => ({
    type: GET_FLOORS, payload: { roomType, officeId },
  }),
  postFloors: ({ titles, office, callback }) => ({
    type: POST_FLOORS, payload: { titles, office, callback },
  }),
  putFloor: ({ floor, office, callback }) => ({
    type: PUT_FLOOR, payload: { floor, office, callback },
  }),
  deleteFloor: ({ floorId, callback }) => ({
    type: DELETE_FLOOR, payload: { floorId, callback },
  }),
  clearFloors: () => ({
    type: CLEAR_FLOORS_SUCCEED,
  }),
  /* =========================================================================================== */

  /* ------------ Zones ------------ */
  /* =========================================================================================== */
  getZones: ({ officeId }) => ({
    type: GET_ZONES, payload: { officeId },
  }),
  postZones: ({ titles, officeId, groupWhitelist, callback }) => ({
    type: POST_ZONES, payload: { titles, officeId, groupWhitelist, callback },
  }),
  putZone: ({ zoneId, zone, office, callback }) => ({
    type: PUT_ZONE, payload: { zoneId, zone, office, callback },
  }),
  deleteZone: ({ zoneId }) => ({ type: DELETE_ZONE, payload: { zoneId } }),
  /* =========================================================================================== */

  /* ------------ Tags ------------ */
  /* =========================================================================================== */
  getTags: ({ officeId }) =>
    ({ type: GET_OFFICE_TAGS, payload: { officeId } }),
  postOfficeTags: ({ titles, officeId, callback }) =>
    ({ type: POST_OFFICE_TAGS, payload: { titles, officeId, callback } }),
  putOfficeTag: ({ tagId, tagData, callback }) =>
    ({ type: PUT_OFFICE_TAG, payload: { tagId, tagData, callback } }),
  deleteOfficeTag: ({ tagId }) =>
    ({ type: DELETE_OFFICE_TAG, payload: { tagId } }),
  postTagIcon: ({ tagId, icon }) =>
    ({ type: POST_TAG_ICON, payload: { tagId, icon } }),
  /* =========================================================================================== */

  /* ------------ RoomTypes ------------ */
  /* =========================================================================================== */
  getRoomTypes: ({ officeId, isBookable }) =>
    ({ type: GET_ROOM_TYPES, payload: { officeId, isBookable } }),
  postRoomTypes: ({ titles, officeId, roomTypeSettings, callback }) =>
    ({ type: POST_ROOM_TYPES, payload: { titles, officeId, roomTypeSettings, callback } }),
  putRoomType: ({ roomTypeId, roomTypeData, callback }) =>
    ({ type: PUT_ROOM_TYPE, payload: { roomTypeId, roomTypeData, callback } }),
  deleteRoomType: ({ roomTypeId }) =>
    ({ type: DELETE_ROOM_TYPE, payload: { roomTypeId } }),
  uploadRoomTypeIcon: ({ roomTypeData, icon }) =>
    ({ type: POST_ROOM_TYPE_ICON, payload: { roomTypeData, icon } }),
  /* =========================================================================================== */

  /* ------------ Rooms ------------ */
  /* =========================================================================================== */
  getRooms: ({ officeId, floorId, roomType, unified, isDownloadingWithTables }) => ({
    type: GET_ROOMS,
    payload: { officeId, floorId, roomType, unified, isDownloadingWithTables },
  }),
  getFilteredRooms: ({ filters, officeId, recalculateSelectedCounts }) => ({
    type: GET_FILTERED_ROOMS, payload: { filters, officeId, recalculateSelectedCounts },
  }),
  postRoom: ({ room, closeRoomModal }) => ({
    type: POST_ROOM,
    payload: { room, closeRoomModal },
  }),
  putRoom: ({ room, roomId, closeRoomModal, recalculateSelectedCounts }) => ({
    type: PUT_ROOM,
    payload: { room, roomId, closeRoomModal, recalculateSelectedCounts },
  }),
  deleteRoom: ({ roomId, recalculateSelectedCounts }) => ({
    type: DELETE_ROOM,
    payload: { roomId, recalculateSelectedCounts },
  }),
  clearRooms: () => ({
    type: CLEAR_ROOMS_SUCCEED,
  }),
  /* =========================================================================================== */

  /* ------------ Tables ------------ */
  /* =========================================================================================== */
  getTables: ({ roomId, dateFrom, dateTo, tableId }) => ({
    type: GET_TABLES,
    payload: { roomId, dateFrom, dateTo, tableId },
  }),
  postTable: ({ table, closeTableModal, recalculateSelectedCounts }) => ({
    type: POST_TABLE,
    payload: { table, closeTableModal, recalculateSelectedCounts },
  }),
  putTable: ({ table, oldRoomId, closeTableModal, recalculateSelectedCounts }) => ({
    type: PUT_TABLE,
    payload: { table, oldRoomId, closeTableModal, recalculateSelectedCounts },
  }),
  deleteTable: ({ tableId, recalculateSelectedCounts, deleteTableMarkerFromMap }) => ({
    type: DELETE_TABLE,
    payload: { tableId, recalculateSelectedCounts, deleteTableMarkerFromMap },
  }),
  clearTables: () => ({
    type: CLEAR_TABLES_SUCCEED,
  }),
  /* =========================================================================================== */

  /* ------------ Rooms and Tables ------------ */
  /* =========================================================================================== */
  deleteSelectedRoomsAndTables: ({
    roomIds, tableIds, setIsDeletionModalVisible, recalculateSelectedCounts,
  }) => ({
    type: DELETE_SELECTED_ROOMS_AND_TABLES,
    payload: { roomIds, tableIds, setIsDeletionModalVisible, recalculateSelectedCounts },
  }),
  /* =========================================================================================== */

  /* ------------ Maps & Markers ------------ */
  /* =========================================================================================== */
  updateMapToFloor: ({ floorId, map, sizes, isUpdate, activeFloorMapId }) => ({
    type: UPDATE_MAP_TO_FLOOR, payload: { floorId, map, sizes, isUpdate, activeFloorMapId },
  }),
  postMapToFloor: ({ floorId, map, sizes, isUpdate, activeFloorMapId }) => ({
    type: POST_MAP_TO_FLOOR, payload: { floorId, map, sizes, isUpdate, activeFloorMapId },
  }),
  deleteMap: ({ floorMapId, activeFloor }) => ({
    type: DELETE_MAP, payload: { floorMapId, floorId: activeFloor },
  }),

  postRoomMarker: ({ marker, callback }) => ({
    type: POST_ROOM_MARKER, payload: { marker, callback },
  }),
  putRoomMarker: ({ marker }) => ({
    type: PUT_ROOM_MARKER, payload: { marker },
  }),
  deleteRoomMarker: ({ markerId, roomId, deleteAllTableMarkers }) => ({
    type: DELETE_ROOM_MARKER,
    payload: { markerId, roomId, deleteAllTableMarkers },
  }),

  postTableMarker: ({ tableMarker, setTableMarkerOnMap }) => ({
    type: POST_TABLE_MARKER,
    payload: { tableMarker, setTableMarkerOnMap },
  }),
  putTableMarker: ({ tableMarker, setTableMarkerOnMap }) => ({
    type: PUT_TABLE_MARKER,
    payload: { tableMarker, setTableMarkerOnMap },
  }),
  deleteTableMarker: ({ tableMarker, deleteTableMarkerFromMap }) => ({
    type: DELETE_TABLE_MARKER,
    payload: { tableMarker, deleteTableMarkerFromMap },
  }),

  deleteAllMarkersOnFloor: ({ floorId, callback }) => ({
    type: DELETE_ALL_MARKERS_ON_FLOOR,
    payload: { floorId, callback },
  }),
  /* =========================================================================================== */

  /* ------------ Для чекбоксов в DetailInfo ------------ */
  /* =========================================================================================== */
  checkRoom: ({ roomId }) => ({ type: CHECK_ROOM, payload: { roomId } }),
  checkAllRooms: ({ checked }) => ({ type: CHECK_ALL_ROOMS, payload: { checked } }),
  checkTable: ({ tableId, roomId }) => ({ type: CHECK_TABLE, payload: { tableId, roomId } }),
  checkAllTables: ({ roomId, checked }) => ({
    type: CHECK_ALL_TABLES,
    payload: { roomId, checked },
  }),
  recalculateSelectedCounts: () => ({ type: RECALCULATE_SELECTED_COUNTS }),
  /* =========================================================================================== */
}
