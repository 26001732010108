import React, { useEffect, useState } from 'react'
import RecipientsList from './RecipientsList'
import SearchFilter from '../UserGroups/SearchFilter'
import LargeSpin from '../UI/Spin/LargeSpin'

const getFilteredItems = (items, filterValue) => items?.filter(item => {
  const normalizedTitle = item?.title ? item.title.toLowerCase() : item.phone_number.toLowerCase()
  const normalizedFilterValue = filterValue.toLowerCase().trim()
  return normalizedTitle.includes(normalizedFilterValue)
})

const CheckbleListNoRequest = React.memo(({
  renderListItem,
  renderListHeader,
  rowHeight = 54,
  title,
  searchPlaceholder,
  list = [],
  type,
  setDisable,
  loading,
}) => {
  const [filterValue, setFilterValue] = useState('')
  const filteredElements = getFilteredItems(list, filterValue)

  useEffect(() => {
    filteredElements.length < list.length ? setDisable(true) : setDisable(false)
  }, [filteredElements.length])
  return (
    <div className='notifications__list-container'>
      <h3>{title}</h3>
      <SearchFilter
        style={{ maxWidth: '100%' }}
        placeholder={searchPlaceholder}
        value={filterValue}
        onChange={setFilterValue}
        type={type}
        disable={loading}
      />
      {loading ? <LargeSpin />
        : (
          <RecipientsList
            rowHeight={rowHeight}
            renderListItem={renderListItem}
            header={renderListHeader}
            list={filteredElements}
          />
        )}

    </div>
  )
})

export default CheckbleListNoRequest
