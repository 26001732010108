import React, { useRef } from 'react'
import { AutoSizer, List } from 'react-virtualized'

import ScrollTransition from '../UI/animations/transition/ScrollTransition'

const RecipientsList = React.memo(({ header, list, rowHeight, renderListItem }) => {
  const listRef = useRef()

  const handleScroll = ({ scrollTop }) => listRef.current.scrollToPosition(scrollTop)

  const rowRenderer = ({ key, index, style }) => renderListItem(list[index], key, style)
  return (
    <>
      <div className='checkbox-wrapper'>{header}</div>
      <div style={{ marginTop: '10px', flex: 1 }}>
        <AutoSizer>
          {({ height, width }) => (
            <ScrollTransition style={{ width, height }} onLiveScroll={handleScroll} loading>
              <List
                height={height}
                width={width}
                rowHeight={rowHeight}
                rowCount={list.length}
                rowRenderer={rowRenderer}
                overscanRowCount={30}
                ref={listRef}
              />
            </ScrollTransition>
          )}
        </AutoSizer>
      </div>
    </>
  )
})

export default RecipientsList
