/* eslint-disable max-len */
import React from 'react'
import { Circle } from 'react-konva'

import {
  SET_CURSOR,
  SET_MARKER_ON_CLICK_ATTR,
  SET_TABLE_MARKERS,
  SET_MARKER_ON_HOVER_ATTR,
} from './helpers/useReducerForMapComponent'

const TableMarker = props => {
  const {
    point,
    x = 0,
    y = 0,
    currentRadius,
    isMouseOverMap,
    dispatchWithoutRedux,
    stateWithoutRedux,
    putTableMarker,
  } = props

  const { id, isDragging } = point
  const {
    // isBucketHover,
    // deletingMarkerRoomId,
    tableMarkers,
    mapState,
  } = stateWithoutRedux

  const hideToolTip = () => dispatchWithoutRedux({
    type: SET_MARKER_ON_HOVER_ATTR,
    payload: {
      hover: false,
      title: point.title,
      isTableMarker: false,
    },
  })

  const onHover = evt => {
    const { id } = evt.target.attrs
    const point = tableMarkers.find(tableMarker => tableMarker.id === id)
    if (point.isDragging) return null
    dispatchWithoutRedux({ type: SET_CURSOR, payload: 'move' })
    dispatchWithoutRedux({
      type: SET_MARKER_ON_HOVER_ATTR,
      payload: {
        hover: true,
        id: id,
        x: point.x,
        y: point.y,
        title: point.title,
        zoneTitle: null,
        description: null,
        isTableMarker: true,
      },
    })
  }

  const onMouseLeaveTableMarker = evt => {
    const { id } = evt.target.attrs
    const point = tableMarkers.find(tableMarker => tableMarker.id === id)
    if (point.isDragging) return null
    dispatchWithoutRedux({ type: SET_CURSOR, payload: null })
    hideToolTip()
  }

  const getShadowSettings = () => {
    if (isDragging) {
      return {
        shadowBlur: 10,
        shadowOpacity: 0.6,
        shadowOffsetX: 15,
        shadowOffsetY: 15,
      }
    }
    return {
      shadowBlur: 10,
      shadowOpacity: 0.35,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
    }
  }

  const handleTableMarkerDragStart = e => {
    hideToolTip()
    dispatchWithoutRedux({ type: SET_CURSOR, payload: 'grabbing' })
    const id = e.target.id()
    const tableMarkersCopy = tableMarkers.slice()
    const tableMarker = tableMarkersCopy.find(tm => tm.id === id)
    const idx = tableMarkersCopy.indexOf(tableMarker)
    tableMarkersCopy.splice(idx, 1)
    tableMarkersCopy.push({ ...tableMarker, isDragging: true })

    dispatchWithoutRedux({ type: SET_TABLE_MARKERS, payload: tableMarkersCopy })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const handleTableMarkerDragEnd = e => {
    dispatchWithoutRedux({ type: SET_CURSOR, payload: null })
    // TODO удаление тейбл маркеров через корзину
    // if (isDeletingMarker) {
    //   dispatchWithoutRedux({ type: SET_IS_BUCKET_HOVER, payload: false })
    //   dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: false })
    //   setTimeout(() => {
    //     dispatchWithoutRedux({ type: DELETING_MARKER, payload: false })
    //     dispatchWithoutRedux({ type: SET_DELETING_MARKER_ROOM_ID, payload: null })
    //   }, 100)
    //   return null
    // }
    // dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: false })
    const id = e.target?.attrs?.id
    const newX = (e.target.attrs.x * 100) / mapState.image.width
    const newY = (e.target.attrs.y * 100) / mapState.image.height
    const tableMarker = tableMarkers.find(tb => tb.id === id)
    tableMarker.x = newX.toFixed(2)
    tableMarker.y = newY.toFixed(2)
    tableMarker.isDragging = false
    // const roomId = tableMarker?.roomId
    // const icon = marker?.icon
    // dispatchWithoutRedux({ type: SET_MARKER_ON_HOVER_ATTR, payload: { hover: true, x: newX, y: newY, isCursorMove: true, isTableMarker: true } })
    // dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false, x: newX, y: newY } })
    const newTableMarkers = tableMarkers.map(tb => {
      if (tb.id === tableMarker.id) return tableMarker
      return ({ ...tb, isDragging: false })
    })

    dispatchWithoutRedux({ type: SET_TABLE_MARKERS, payload: newTableMarkers })
    putTableMarker({
      id: tableMarker.id,
      table: tableMarker.table,
      title: point.title,
      room: tableMarker.room,
      x: tableMarker.x,
      y: tableMarker.y,
    })
  }

  // TODO сделать корзину для тейбл маркеров
  // const radiusWhenDragging = (isBucketHover || deletingMarkerRoomId === id)
  //   ? 0
  //   : currentRadius * 1.25
  const radiusWhenDragging = currentRadius * 1.25

  return (
    <Circle
      draggable={isMouseOverMap}
      x={x}
      y={y}
      onDragStart={handleTableMarkerDragStart}
      onDragEnd={handleTableMarkerDragEnd}
      stroke='#fff'
      shadowForStrokeEnabled={false}
      strokeWidth={currentRadius / 6}
      radius={isDragging ? radiusWhenDragging : currentRadius}
      fill='#0079C1'
      shadowColor='black'
      shadowBlur={getShadowSettings().shadowBlur}
      shadowOpacity={getShadowSettings().shadowOpacity}
      shadowOffsetX={getShadowSettings().shadowOffsetX}
      shadowOffsetY={getShadowSettings().shadowOffsetY}
      id={id}
      onMouseenter={onHover}
      onMouseleave={onMouseLeaveTableMarker}
    />
  )
}

export default TableMarker
