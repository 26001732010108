import React, { useState } from 'react'

import UserPickItem from './UserPickItem'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import ExpandingSearch from '../../UI/ExpandingSearch'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import LargeSpin from '../../UI/Spin/LargeSpin'
import ListLoadingSpin from '../../UI/Spin/ListLoadingSpin'
import { USERS_ON_PAGE } from '../../../utils/data/constants'

const UsersList = React.memo(({
  list,
  loading,
  name,
  onScroll,
  onSearchClose,
  search,
  searchString,
  setSearchString,
  title,
  getItems,
  addDeleteHandler,
  replaceUser,
  countInStore,
  paginationLoading,
  length,
  loadWithoutScroll,
}) => {
  const [isSearchFieldExpanded, setIsSearchFieldExpanded] = useState(false)
  const loadWithOutScroll = USERS_ON_PAGE >= length
  const userRender = () => {
    if (!list?.length) return <EmptyPlaceholder text='Список пуст' />
    return (
      <>
        {list.map(item => (
          <UserPickItem
            user={item}
            key={item.id}
            onClick={() => {
              replaceUser(item)
              addDeleteHandler(item.id)
              loadWithOutScroll && loadWithoutScroll()
            }}
            containerType={name}
          />
        ))}
      </>
    )
  }
  return (
    <div className='card_list__small'>
      <div className='card_list__small_container__title'>{`${title} ${list?.length || 0}/${countInStore || 0}`}</div>
      {search && (
        <div className='container__users_group_search'>
          <ExpandingSearch
            setIsExpanded={val => setIsSearchFieldExpanded(val)}
            setSearchString={setSearchString}
            searchString={searchString}
            isExpanded={isSearchFieldExpanded}
            onClose={onSearchClose}
            defaultExpanded
            large
            placeholder='Начните вводить имя пользователя'
            getItems={getItems}
          />
        </div>
      )}
      <ScrollTransition loading={!isSearchFieldExpanded} onScroll={onScroll && onScroll}>
        <div className='card_list full_height' style={{ flex: 1 }}>
          {loading
            ? <LargeSpin styles={{ flex: 1 }} /> : userRender()}
        </div>
        {paginationLoading && <ListLoadingSpin />}
      </ScrollTransition>

    </div>
  )
})
export default UsersList
