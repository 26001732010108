import React from 'react'
import DisabledInputBlock from '../../UI/dataBlock/DisabledInputBlock'
import FadeTransition from '../../UI/animations/transition/FadeTransition'

const TableInfo = React.memo(({ floor, room, table, rating, tags, description }) => {
  const tableHeader = 'flex_container__row space_between full_width flex_container__no_wrap'
  return (
    <FadeTransition loading>
      <div>
        <div className={tableHeader} style={{ paddingTop: 0 }}>
          <DisabledInputBlock title='Этаж:'>
            <div className='main_text__subtitle'>{floor}</div>
          </DisabledInputBlock>
          <DisabledInputBlock title='Помещение:'>
            <div className='main_text__subtitle'>{room}</div>
          </DisabledInputBlock>
          <DisabledInputBlock title='Стол:'>
            <div className='main_text__subtitle'>{table}</div>
          </DisabledInputBlock>
          <DisabledInputBlock title='Рейтинг:'>
            <div className='flex_container__icon_text'>
              <div className='main_text__subtitle margin_right'>{String(rating)}</div>
              <div className='icon__big_star' />
            </div>
          </DisabledInputBlock>
        </div>

        {tags?.length > 0 && (
          <DisabledInputBlock title='Теги' style={{ paddingBottom: '5px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {tags.map(tag => (
                <div key={tag.id} className='tag'>
                  {tag.title}
                </div>
              ))}
            </div>
          </DisabledInputBlock>
        )}

        {description && (
          <div className='flex_container__row'>
            <DisabledInputBlock
              title='Описание'
              style={{ paddingBottom: '5px' }}
              innerText={description}
            />
          </div>
        )}
      </div>
    </FadeTransition>
  )
})

export default TableInfo
