import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'
import messageInfo from 'antd/lib/message'

import getResponse from './getResponse'
import { POST, GET } from '../utils/methods'
import { content, auth } from '../utils/data/requestHeaders'
import setTokenToStorage from '../utils/manageTokens'
import isNoMessage from '../utils/isNoMessage'
import {
  GET_ACCOUNT,
  GET_ACCOUNT_SUCCEED,
  LOG_IN_USER,
  SET_TOKEN,
  CHANGE_PASSWORD,
} from '../actions/account'


export function* getAccount() {
  try {
    const token = localStorage.getItem('access_token')
    if (!token || token === 'null') return null

    const response = yield call(() => getResponse({ method: GET, path: '/admin/user/me', headers: auth }))
    yield put({ type: GET_ACCOUNT_SUCCEED, account: response })
  } catch (e) { console.log(e.message) }
}

export function* logInUser({ credentials, callback }) {
  try {
    const response = yield call(() =>
      getResponse({
        method: POST,
        path: '/admin/auth',
        headers: {
          'Content-Type': 'application/json',
          'X-Workspace': process.env.REACT_APP_WORKSPACE,
        },
        body: credentials,
      }))

    if (response && isNoMessage(response)) {
      yield setTokenToStorage(response)
      yield put({ type: SET_TOKEN, token: response })
      yield callback && callback()
      yield call(getAccount)
    }
  } catch (e) { console.log(e.message) }
}

function* changePassword({ passwords, callback }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      headers: content,
      path: '/admin/user/pass_change',
      body: passwords,
    }))

    const { message, ...token } = response
    if (message === 'OK') {
      messageInfo.success('Пароль изменен')
      yield setTokenToStorage(token)
      yield put({ type: SET_TOKEN, token })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) }
}

export default function* accountSagas() {
  yield takeLatest(LOG_IN_USER, logInUser)
  yield takeLatest(GET_ACCOUNT, getAccount)
  yield takeEvery(CHANGE_PASSWORD, changePassword)
}
