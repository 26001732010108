/* eslint-disable no-console */
import { call, put, takeEvery } from 'redux-saga/effects'
import message from 'antd/lib/message'

import {
  POST_FILE,
  POST_PHOTO,
  POST_PHOTO_SUCCEED,
  CHANGE_USER_PHOTO_SUCCEED,
  SET_PHOTO_LOADING,
} from '../actions/files'
import { POST } from '../utils/methods'
import getResponse from './getResponse'
import { auth } from '../utils/data/requestHeaders'
import isNoMessage from '../utils/isNoMessage'

function* postFile({ file, callback }) {
  try {
    const postedFile = yield call(() => getResponse({
      method: POST,
      path: '/admin/file',
      headers: auth,
      body: file,
      isFile: true,
    }))

    callback(isNoMessage(postedFile) ? postedFile : null)
  } catch (e) {
    message.error('Внутренняя ошибка сервера')
    console.log(e.message)
  }
}

function* postPhoto({ file, isNew, userId, callback }) {
  try {
    yield put({ type: SET_PHOTO_LOADING })
    const postedFile = yield call(() => getResponse({
      method: POST,
      path: '/admin/file',
      headers: auth,
      body: file,
      isFile: true,
    }))

    if (isNew) yield put({ type: POST_PHOTO_SUCCEED, photo: postedFile })
    else yield put({ type: CHANGE_USER_PHOTO_SUCCEED, photo: postedFile, userId })

    yield callback && callback()
  } catch (e) {
    message.error('Внутренняя ошибка сервера')
    console.log(e.message)
  }
}

export default function* filesSagas() {
  yield takeEvery(POST_FILE, postFile)
  yield takeEvery(POST_PHOTO, postPhoto)
}
