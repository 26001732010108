import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import CardListItem from '../../../helpers/lists/CardListItem'
import IconInfo from '../../../UI/icons/Info'
import LicenseInfoModal from '../LicenseInfoModal/LicenseInfoModal'
import ListPopUp from '../../../ListPopUp'
import OfficeCard from '../OfficeCard/OfficeCard'
import ConfirmationModal from '../../../UI/modals/ConfirmationModal'

import { officesAC } from '../../../../actions/actionCreator/offices'

const OfficeListItem = React.memo(props => {
  const {
    office,
    index,
    flexValues,
  } = props

  const history = useHistory()
  const [isLicenseInfoVisible, setIsLicenseInfoVisible] = useState(false)
  const [isPopUpMenuOpen, setIsPopUpMenuOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const dispatch = useDispatch()

  const getLicenseHighLightColor = () => {
    const { license } = office
    switch (true) {
      case license?.forever: return 'white'
      case license?.expiry_status === 'expired': return '#CD4141'
      default: return license?.expiry_status === 'expires_soon' ? '#F7A833' : '#10CF5C'
    }
  }

  const getIconHighLightColor = () => {
    const { license } = office
    if (!license) return null
    const tablesStatus = license?.tables_status
    const expiryStatus = license?.expiry_status

    switch (true) {
      case tablesStatus === 'expired' || expiryStatus === 'expired': return 'red'
      case tablesStatus === 'expires_soon' || expiryStatus === 'expires_soon': return 'orange'
      default: return 'green'
    }
  }

  const getTablesHighLightColor = () => {
    const { license } = office

    switch (true) {
      case license?.tables_infinite: return 'white'
      case license?.tables_status === 'expired': return '#CD4141'
      default: return license?.tables_status === 'expires_soon' ? '#F7A833' : '#10CF5C'
    }
  }

  const handleInfoButtonOnClick = e => {
    e.stopPropagation()
    setIsLicenseInfoVisible(true)
  }

  const getInfoButton = () => (
    <div className='icon__wrapper__hover_enabled full_height' onClick={handleInfoButtonOnClick}>
      <IconInfo extraClassName={getIconHighLightColor()} />
    </div>
  )

  const togglePopUp = () => setIsPopUpMenuOpen(val => !val)

  const redirectToOfficeEditing = () => history.push(`/offices/${office.id}/edit-main-info`)

  const redirectToOfficeDetailInfo = () => history.push(`/offices/${office.id}/edit-detail-info`)

  const redirectToMapEditing = () => history.push(`/offices/${office.id}/map`)

  const handleOnConfirm = () => dispatch(
    officesAC.deleteOffice({
      officeId: office.id, title: office.title, callback: () => setIsDeleteModalOpen(false),
    }),
  )

  const popUpOptions = [
    { title: 'Общая информация', icon: 'icon__edit', onClick: redirectToOfficeEditing },
    {
      title: 'Управление картой',
      icon: 'icon__map_marker',
      style: { width: '14px' },
      onClick: redirectToMapEditing,
    },
    { title: 'Удалить', icon: 'icon__trash', onClick: () => setIsDeleteModalOpen(true) },
  ]

  return (
    <>
      <CardListItem
        confirm
        extraClassName='list__offices'
        onCardClick={() => redirectToOfficeDetailInfo(office?.id)}
      >
        <OfficeCard
          office={office}
          index={index}
          flexValues={flexValues}
          infoButton={getInfoButton()}
          onMoreIconClick={togglePopUp}
          isMoreBtnActive={isPopUpMenuOpen}
        />
        {isPopUpMenuOpen && <ListPopUp options={popUpOptions} closePopUp={togglePopUp} />}
      </CardListItem>
      <LicenseInfoModal
        licenseTextColor={getLicenseHighLightColor()}
        supportTextColor='white'
        tablesTextColor={getTablesHighLightColor()}
        officeName={office?.title}
        license={office?.license}
        start={isLicenseInfoVisible}
        onClose={() => setIsLicenseInfoVisible(false)}
      />
      <ConfirmationModal
        id={office.id}
        start={isDeleteModalOpen}
        text={`Удалить БЦ "${office.title}" ?`}
        onConfirm={handleOnConfirm}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </>
  )
})

export default OfficeListItem
