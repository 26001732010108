import React, { useState } from 'react'
import { ErrorMessage, withFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import Gallery from 'react-dynamic-image-gallery'
import * as Yup from 'yup'

import Modal from '../../../../UI/modals/Modal'
import ValidationMsg from '../../../../UI/ValidationMsg'
import SimpleInput from '../../../../UI/input/SimpleInput'
import SimpleTextArea from '../../../../UI/input/SimpleTextArea'
import SimpleSelect from '../../../../UI/input/SimpleSelect'
import ConfirmationModal from '../../../../UI/modals/ConfirmationModal'
import SimpleInputNumber from '../../../../UI/input/SimpleInputNumber'
import { onImageUpload } from '../../uploadImage'
import ScrollTransition from '../../../../UI/animations/transition/ScrollTransition'
import { EDIT_ROOM } from '../../../../Map/helpers/useReducerForMapComponent'

import { officesAC } from '../../../../../actions/actionCreator/offices'
import { translateRoomTypes } from '../../../../Map/helpers/roomsTypes'
import {message} from "antd";

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Обязательное поле')
    .max(140, 'Название не более 140 символов'),
  zone: Yup.string()
    .required('Обязательное поле'),
  floor: Yup.string()
    .required('Обязательное поле'),
  description: Yup.string()
    .max(150, 'Максимальная длина - 150 символов'),
})

const InnerForm = React.memo(({
  start,
  onClose,
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  floors,
  zones,
  isNew,
  room,
  roomTypes,
  dispatchWithoutRedux,
}) => {
  const rooms = useSelector(({ offices }) => offices.rooms)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false)

  const dispatch = useDispatch()

  const getErrorCondition = field => errors[field] && touched[field]
  const getRoomTypeId = title => roomTypes?.find(type => type.title === title).id
  const getRoomTypeById = id => roomTypes.find(roomType => roomType.id === id)

  const recalculateSelectedCounts = () => dispatch(officesAC.recalculateSelectedCounts())

  const putRoom = payload => {
    dispatch(officesAC.putRoom({
      room: payload,
      roomId: room.id,
      closeRoomModal: onClose,
      recalculateSelectedCounts,
    }))

    dispatchWithoutRedux && dispatchWithoutRedux({ type: EDIT_ROOM, payload })
  }

  const getPayload = () => {
    const basePayload = {
      title: values.title.trim().replace(/ +(?= )/g, ''),
      description: values.description,
      floor: values.floor,
      type: isNew && values.type === 'Рабочее место' ? getRoomTypeId('Рабочее место') : values.type,
      zone: values.zone,
      images: values.images.map(img => img.id),
      seats_amount: values.type === 'Переговорная' ? values.seats_amount : undefined,
    }

    // для изменения иконки у рум маркера
    if (!isNew && room?.marker) {
      const newRoomType = getRoomTypeById(values.type)

      return {
        ...basePayload,
        icon: translateRoomTypes(newRoomType.title),
      }
    }

    return basePayload
  }

  const postRoom = payload => {
    dispatch(officesAC.postRoom({ room: payload, closeRoomModal: onClose }))
  }

  const editRoom = payload => {
    const nextRoomType = getRoomTypeById(payload.type)
    const nextRoomTypeUnified = nextRoomType.unified
    const nextRoomTypeIsBookable = nextRoomType.bookable
    const previuosRoomTypeUnified = room.room_type_unified

    const isPreviuosTablesShouldDelete = !previuosRoomTypeUnified
      && room.tables.length
      && (nextRoomTypeUnified || !nextRoomTypeIsBookable)

    if (isPreviuosTablesShouldDelete) {
      setIsConfirmationModalVisible(true)
    } else {
      putRoom(payload)
    }
  }

  const isRoomUniqueOnFloor = payload => !rooms.some(roomToCheck => {
    const { id, floor_id, title } = roomToCheck

    return payload.title === title && floor_id === payload.floor && id !== room?.id
  })

  const handleSubmit = () => {
    setIsSubmitDisabled(true)

    const payload = getPayload()

    if (!isRoomUniqueOnFloor(payload)) {
      message.error('На выбранном этаже помещение с таким названием уже существует')

      return setIsSubmitDisabled(false)
    }

    isNew ? postRoom(payload) : editRoom(payload)
    setIsSubmitDisabled(false)
  }

  const isFormValid = ValidationSchema.isValidSync(values)

  const setImages = images => {
    const fulFilledImages = images.map(img => img.status === 'fulfilled' && img.value)

    const nextImagesArr = values.images.length
      ? [...values.images, ...fulFilledImages]
      : fulFilledImages

    setFieldValue('images', nextImagesArr)
  }

  const handleImageUpload = e => onImageUpload(e, setImages)

  const onImageDelete = deletedImage =>
    setFieldValue('images', values.images.filter(img => img.id !== deletedImage.id))

  const getFormTitle = () => isNew ? 'ДОБАВИТЬ КАБИНЕТ' : ' РЕДАКТИРОВАТЬ КАБИНЕТ'

  return (
    <Modal extraClassName='room-form__modal' start={start} onClose={onClose}>
      <h1 className='room-form__modal-title'>
        {getFormTitle()}
      </h1>

      <ScrollTransition style={{ height: '100%' }} loading>
        <div style={{ height: '100%', padding: '0 10px 0 2px' }}>
          <SimpleInput
            name='title'
            label='Название*'
            value={values.title}
            placeholder='Введите название кабинета'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('title')}
          >
            <ErrorMessage component={ValidationMsg} name='title' />
          </SimpleInput>
          <SimpleSelect
            name='floor'
            label='Этаж*'
            value={values.floor}
            placeholder='Выберите этаж'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            errorComponent={() => <ErrorMessage component={ValidationMsg} name='floor' />}
          >
            {floors?.map(floor =>
              <Select.Option key={floor.id} value={floor.id}>{floor.title}</Select.Option>)}
          </SimpleSelect>
          <SimpleSelect
            name='zone'
            label='Зона*'
            value={values.zone}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            placeholder='Зона бизнес-центра'
            error={getErrorCondition('zone')}
            errorComponent={() => <ErrorMessage component={ValidationMsg} name='zone' />}
          >
            {zones?.length > 0 && zones.map(zone => (
              <Select.Option value={zone.id} key={zone.id}>{zone.title}</Select.Option>))}
          </SimpleSelect>
          <div className='flex_container'>
            <div style={{ marginRight: '20px' }} className='flex_container__flex_3'>
              <SimpleSelect
                name='type'
                label='Тип'
                value={values.type}
                placeholder='Выберите тип кабинета'
                onChange={setFieldValue}
                setFieldTouched={setFieldTouched}
              >
                {roomTypes?.map(type =>
                  <Select.Option key={type.id} value={type.id}>{type.title}</Select.Option>)}
              </SimpleSelect>
            </div>
            <div className='flex_container__flex_1'>
              <SimpleInputNumber
                min={1}
                disabled={values.type !== 'Переговорная'}
                name='seats_amount'
                label='К-во мест'
                style={{ width: '100%' }}
                value={values.seats_amount}
                onChange={setFieldValue}
                setFieldTouched={setFieldTouched}
                error={getErrorCondition('seats_amount')}
              >
                <ErrorMessage component={ValidationMsg} name='seats_amount' />
              </SimpleInputNumber>
            </div>
          </div>
          <SimpleTextArea
            name='description'
            label='Описание'
            value={values.description}
            placeholder='Введите описание'
            style={{ height: '70px' }}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('description')}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </SimpleTextArea>
          <span className='secondary_text__white' style={{ margin: '6px 0 12px' }}>
            Добавьте фото кабинета
          </span>

          <Gallery
            onUpload={handleImageUpload}
            source={values.images}
            itemsToShow={values.images.length}
            itemsToShowInPreview={5}
            uploadEnabled
            isItemDeleteEnable
            onItemDelete={onImageDelete}
          />
        </div>
      </ScrollTransition>

      <div
        className='button_container__content_right'
        style={{ margin: '20px 10px 0 0', maxHeight: '32px' }}
      >
        <Button type='text' onClick={onClose}>Отмена</Button>
        <Button
          disabled={isSubmitDisabled || !isFormValid}
          type='primary'
          onClick={handleSubmit}
        >
          {isNew ? 'Сохранить и добавить' : 'Сохранить изменения'}
        </Button>
      </div>
      <ConfirmationModal
        start={isConfirmationModalVisible}
        textStyle={{ wordBreak: 'normal' }}
        text='При сохранении изменений с выбранным типом помещения, созданные столы будут удалены.'
        onConfirm={() => {
          putRoom(getPayload())
          setIsConfirmationModalVisible(false)
        }}
        onClose={() => setIsConfirmationModalVisible(false)}
      />
    </Modal>
  )
})

const RoomFormModal = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ isNew, roomTypes = [], room = {}, activeFloor, floors }) => {
    const defaultFloor = floors?.find(f => f.id === activeFloor)

    return ({
      title: room.title || '',
      floor: room.floor_id || defaultFloor?.id || undefined,
      zone: room.zone_id || undefined,
      type: isNew ? 'Рабочее место' : roomTypes.find(type => type.title === room.type)?.id,
      description: room.description || '',
      seats_amount: room.seats_amount || 1,
      images: room.images || [],
    })
  },
  validationSchema: ValidationSchema,
})(InnerForm)

export default RoomFormModal
