/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable curly */
import React, { useEffect, useState } from 'react'
import { Circle, Group, Image } from 'react-konva'
import { SET_MARKER_ON_HOVER_ATTR, ON_CLICK_MARKER } from './helpers/useReducerForMapComponent'
import { geiIconSrc } from './helpers/roomsTypes'

const RoomMarker = React.memo(props => {
  const {
    point,
    x = 0,
    y = 0,
    handlePointDragStart,
    handlePointDragEnd,
    points,
    currentRadius,
    isMouseOverMap,
    dispatchWithoutRedux,
    stateWithoutRedux,
  } = props
  const { id, isDragging, room_type_color: fill = '#0079C1' } = point
  const [icon, setIcon] = useState(null)

  const updateIcon = iconName => {
    const localIcon = new window.Image()
    if (iconName?.slice(0, 4) === 'http') {
      localIcon.src = iconName
    } else {
      localIcon.src = geiIconSrc(iconName)
    }
    localIcon.onload = () => {
      setIcon(localIcon)
    }
  }

  useEffect(() => {
    updateIcon(point.room_type_icon || point.icon)
  }, [point])

  const getShadowSettings = () => {
    if (isDragging) return {
      shadowBlur: 10,
      shadowOpacity: 0.6,
      shadowOffsetX: 15,
      shadowOffsetY: 15,
    }
    return {
      shadowBlur: 10,
      shadowOpacity: 0.35,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
    }
  }

  const onHoverMarker = evt => {
    const id = evt.target?.parent?.attrs?.id
    const point = points.find(p => p.id === id)
    if (point.isDragging) {
      return null
    }
    dispatchWithoutRedux({
      type: SET_MARKER_ON_HOVER_ATTR,
      payload: {
        hover: true,
        isCursorMove: true,
        id,
        x: point?.x,
        y: point?.y,
        title: point?.title,
        zoneTitle: point?.zoneTitle,
        description: point?.description,
        isTableMarker: false,
      },
    })
  }

  const onMouseLeaveMarker = evt => {
    const id = evt.target?.parent?.attrs?.id
    const point = points.find(p => p.id === id)
    dispatchWithoutRedux(
      {
        type: SET_MARKER_ON_HOVER_ATTR,
        payload: {
          hover: false,
          id,
          x: point?.x,
          y: point?.y,
          isCursorMove: false,
          isTableMarker: false,
        },
      },
    )
  }

  const onClickMarker = evt => {
    const id = evt.target?.parent?.attrs?.id
    const point = points.find(p => p.id === id)
    if (point.isDragging) {
      return null
    }
    dispatchWithoutRedux({
      type: ON_CLICK_MARKER,
      payload: {
        ...point,
        id,
        x: point?.x,
        y: point?.y,
      },
    })
  }

  const radiusWhenDragging = (stateWithoutRedux.isBucketHover || stateWithoutRedux.deletingMarkerRoomId === id)
    ? 0
    : currentRadius * 1.25

  const isHideImage = (isDragging && stateWithoutRedux.isBucketHover) || stateWithoutRedux.deletingMarkerRoomId === id
  return (
    <Group
      draggable={isMouseOverMap}
      x={x}
      y={y}
      onClick={onClickMarker}
      onMouseleave={onMouseLeaveMarker}
      onMouseenter={onHoverMarker}
      onDragStart={handlePointDragStart}
      onDragEnd={handlePointDragEnd}
      id={id}
    >
      <Circle
        stroke='#fff'
        shadowForStrokeEnabled={false}
        strokeWidth={currentRadius / 6}
        radius={isDragging ? radiusWhenDragging : currentRadius}
        fill={fill}
        shadowColor='black'
        shadowBlur={getShadowSettings().shadowBlur}
        shadowOpacity={getShadowSettings().shadowOpacity}
        shadowOffsetX={getShadowSettings().shadowOffsetX}
        shadowOffsetY={getShadowSettings().shadowOffsetY}
        id={id}
      />
      {isHideImage
        ? null
        : (
          <Image
            image={icon}
            x={-currentRadius * 1.143 / 2} // 1.143 - поправочный коэффициент из дизайна. Радиус 21 - длина стороны картинки 24
            y={-currentRadius * 1.143 / 2}
            width={currentRadius * 1.143}
            height={currentRadius * 1.143}
          />
        )}
    </Group>
  )
})

export default RoomMarker
