import React from 'react'

const LidBucket = ({ style = {}, className = 'icon__lid-bucket-on-map', fill = 'white', height = '6', width = '20' }) => (
  <div className={className} style={{ ...style, marginBottom: '-2px' }}>
    <svg width={width} height={height} viewBox={`0 0 ${width * 2} ${height * 2}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M39.043 7.32422C39.043 6.51455 38.3878 5.85938 37.5781 5.85938H27.3242V1.46484C27.3242 0.655176 26.669 0 25.8594 0H14.1406C13.331 0 12.6758 0.655176 12.6758 1.46484V5.85938H2.42188C1.61221 5.85938 0.957031 6.51455 0.957031 7.32422V11.7188H39.043V7.32422ZM15.6055 2.92969H24.3945V5.85938H15.6055V2.92969Z' fill={fill} />
    </svg>
  </div>
)

export default LidBucket
