import { useEffect, useState } from 'react'

export default (filtersBlockRef, filterBtnRef) => {
  const [filtersVisible, setFiltersVisible] = useState(false)

  useEffect(() => {
    const onClickListener = e => {
      const path = e.path || (e.composedPath && e.composedPath())

      // проверяет сделан ли клик вне копмонента с фильтрами
      const isOutsideClick = !path.includes(filtersBlockRef.current)

      // проверяет сделан ли клик по кнопке вызова фильтров
      const isToggleBtnClick = path.includes(filterBtnRef.current)

      isToggleBtnClick && setFiltersVisible(!filtersVisible)
      isOutsideClick && filtersVisible && setFiltersVisible(false)
    }

    document.addEventListener('click', onClickListener)

    return () => document.removeEventListener('click', onClickListener)
  }, [filtersBlockRef, filterBtnRef, filtersVisible])

  return [filtersVisible, setFiltersVisible]
}
