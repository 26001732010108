export const isAllItemsChecked = source => source.every(item => item.checked)

export const isSomeItemsChecked = source => source.some(item => item.checked)
  && source.some(item => !item.checked)

export const getSelectedIds = source => source.filter(item => item.checked).map(item => item.id)

export const setCheckedToValue = (source, value = false) =>
  source.map(item => ({ ...item, checked: value }))

export const getUpdatedList = (prevList, checkedItem) => {
  const newList = [...prevList]
  const targetItemIndex = prevList.findIndex(item => item.id === checkedItem.id)
  const oldItem = { ...newList[targetItemIndex] }
  newList[targetItemIndex] = { ...oldItem, checked: !checkedItem.checked }
  // Для правильного отрабатывания чекбосов только в группах
  if (checkedItem.title) {
    const updatedList = newList.map(el => {
      // если в группе 1 юзер и он входит в юзеров выбранной группы ставим checked
      return (((el.id !== checkedItem.id) && (el.users.length === 1)
        && checkedItem.users.includes(el.users[0]))
        ? {
          ...el,
          checked: !el.checked,
        }
        : el)
    })
    return updatedList
  }
  return newList
}
