import React from 'react'

import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import '../../../styles/animations/userForm.scss'
import KioskCard from './KioskCard'
import UserCard from './UserCard'

const KioskList = React.memo(({ list, onDelete }) => {
  if (!list?.length) {
    return <EmptyPlaceholder text='Список панелей пуст' />
  }
  return (
    <div className='container__list '>
      {list?.map((k, index) =>
        (
          <KioskCard
            key={k.id}
            kiosk={k}
            index={index}
            onDelete={onDelete}
          />
        ))}
    </div>
  )
})
export default KioskList
