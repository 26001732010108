export const POST_FILE = 'POST_FILE'

export const POST_PHOTO = 'POST_PHOTO'
export const POST_PHOTO_SUCCEED = 'POST_PHOTO_SUCCEED'

export const COPY_PHOTO_TO_CURRENT_FILES = 'COPY_PHOTO_TO_CURRENT_FILES'
export const CHANGE_USER_PHOTO_SUCCEED = 'CHANGE_USER_PHOTO_SUCCEED'

export const CLEAR_FILES = 'CLEAR_FILES'

export const DELETE_PHOTO = 'DELETE_PHOTO'

export const SET_PHOTO_LOADING = 'SET_PHOTO_LOADING'
export const SET_STOP_PHOTO_LOADING = 'SET_STOP_PHOTO_LOADING'

export const DELETE_PHOTO_FROM_NEW_OFFICE = 'DELETE_PHOTO_FROM_NEW_OFFICE'

export const DELETE_PHOTO_FROM_NEW_ROOM = 'DELETE_PHOTO_FROM_NEW_ROOM'
