import { GET_NOTIFICATION_GROUPS_SUCCEED, GET_NOTIFICATION_USERS_SUCCEED } from '../actions/notifications'

const initialState = {
  groups: [],
  users: [],

}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATION_GROUPS_SUCCEED: {
      return {
        ...state,
        groups: action.payload,
      }
    }
    case GET_NOTIFICATION_USERS_SUCCEED: {
      return {
        ...state,
        users: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export default notificationReducer
