import React from 'react'

const Expand = React.memo(({ isExpanded, color = '#0079C1' }) => (
  <div
    className='icon__expand_svg'
    style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
  >
    <svg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1 1L4 4L7 1' stroke={color} strokeLinecap='round' />
    </svg>
  </div>
))

export default Expand
