import React from 'react'

import Selectors from './Selectors/Selectors'
import Facts from './Facts/Facts'

const Statistics = React.memo(() => (
  <div className='main' id='main' style={{ paddingTop: '40px' }}>
    <div className='event_form' style={{ paddingLeft: '2px' }}>
      <Selectors />
      <Facts />
    </div>
  </div>
))

export default Statistics
