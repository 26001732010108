import { put, takeLatest } from 'redux-saga/effects'
import message from 'antd/lib/message'

import getResponse from './getResponse'
import { DELETE, GET, POST } from '../utils/methods'
import { auth, content } from '../utils/data/requestHeaders'
import { dateToShortString } from '../utils/dates/formatDate'
import isNoMessage from '../utils/isNoMessage'
import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCEED,
  POST_BOOKING,
  POST_BOOKING_SUCCEED,
  SET_BOOKING_LOADING,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCEED,
  POST_FAST_BOOKING,
  POST_FAST_BOOKING_SUCCEED,
  GET_USER_BOOKING_HISTORY,
  GET_USER_BOOKING_HISTORY_SUCCEED,
} from '../actions/booking'
import nextPaginationStart from '../utils/nextPaginationStart'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import formPath from '../utils/formPath'
import { appAC } from '../actions/actionCreator/appAC'

function* getBookings({ payload }) {
  const { tableId, callback } = payload
  yield put(appAC.setBookingLoader(true))
  try {
    yield put(appAC.setAppIsLoading(true))
    const booking = yield getResponse({
      method: GET,
      path: `/admin/booking?table=${tableId}`,
      headers: auth,
    })
    yield put({ type: GET_BOOKINGS_SUCCEED, payload: booking.results })
    callback && callback()
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setBookingLoader(false))
  }
}

function* getUserBookingsAdmin({ userId, page, callback }) {
  try {
    yield put({ type: SET_BOOKING_LOADING })

    const path = formPath(`/admin/booking?user=${userId}`, [
      { start: nextPaginationStart(page) },
      { limit: ITEMS_ON_PAGE },
      { user: userId }])
    const booking = yield getResponse({ method: GET, path: path, headers: auth })

    yield put({ type: GET_USER_BOOKING_HISTORY_SUCCEED, booking, page })
    callback && callback()
  } catch (e) { console.log(e.message) }
}

function* postBookingAdmin({ payload }) {
  const { bookings, onCancel } = payload
  yield put(appAC.setBookingLoader(true))
  try {
    const newBooking = yield getResponse({
      method: POST,
      path: '/admin/booking',
      headers: content,
      body: bookings,
    })

    if (newBooking.message === 'Date range is being overflowed by existing booking') {
      const dateFrom = dateToShortString(newBooking.overflows[0].date_from)
      const dateTo = dateToShortString(newBooking.overflows[0].date_to)
      message.error(`Стол занят с ${dateFrom} до ${dateTo}`)
      return null
    }
    if (!isNoMessage(newBooking)) return null

    yield put({ type: POST_BOOKING_SUCCEED, newBooking })
    onCancel && onCancel()
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setBookingLoader(false))
  }
}

function* postFastBookingAdmin({ payload }) {
  const { bookings, history, addToHistory, onCancel } = payload
  yield put(appAC.setBookingLoader(true))
  try {
    const newBooking = yield getResponse({
      method: POST,
      path: '/admin/booking',
      headers: content,
      body: bookings,
    })

    if (newBooking[0] === 'No table found for fast booking') {
      message.error('В выбранном бизнес-центре на это время нет доступных мест для бронирования')
      return
    }

    if (!newBooking || !isNoMessage(newBooking)) return null

    yield put({ type: addToHistory ? POST_BOOKING_SUCCEED : POST_FAST_BOOKING_SUCCEED, newBooking })
    const type = bookings.type === 'Переговорная' ? 'room' : 'table'

    history && history.push(`/reservation/${type}/${newBooking.table.id}`)
    onCancel && onCancel()
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setBookingLoader(false))
  }
}

function* deleteBookingAdmin({ bookId }) {
  try {
    yield put(appAC.setDisable(true))
    yield getResponse({ method: DELETE, path: `/admin/booking/${bookId}`, headers: auth })
    yield put({ type: DELETE_BOOKING_SUCCEED, bookId })
    message.success('Бронирование удалено')
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setDisable(false))
  }
}

export default function* pollSagas() {
  yield takeLatest(GET_USER_BOOKING_HISTORY, getUserBookingsAdmin)
  yield takeLatest(GET_BOOKINGS, getBookings)
  yield takeLatest(POST_BOOKING, postBookingAdmin)
  yield takeLatest(DELETE_BOOKING, deleteBookingAdmin)
  yield takeLatest(POST_FAST_BOOKING, postFastBookingAdmin)
}
