import React, { useEffect, useState } from 'react'
import Select from 'antd/lib/select'
import Input from 'antd/lib/input'
import message from 'antd/lib/message'
import IconSave from '../../../../../UI/icons/Save'
import IconEdit from '../../../../../UI/icons/Edit'
import IconDelete from '../../../../../UI/icons/Delete'
import CardListItem from '../../../../../helpers/lists/CardListItem'
import ConfirmationModal from '../../../../../UI/modals/ConfirmationModal'

const getDefaultWhitelist = (groups = []) => groups.map(({ id }) => id) || []

export default ({ zone = {}, isNew, onSave, onDelete, groups, onCancel }) => {
  const [zoneTitle, setZoneTitle] = useState(zone.title)
  const [isEdit, setIsEdit] = useState(isNew)
  const [error, setError] = useState(false)
  const [groupWhitelist, setGroupWhitelist] = useState(getDefaultWhitelist(zone.groups))
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const isZoneTitleValid = (title = '') => {
    const titleLength = title.length
    if (titleLength >= 3 && titleLength <= 140) return true

    message.error(`Введите название зоны (от 3 до 140 символов). Введено: ${titleLength} символов`)
    return false
  }

  const handleSave = () => {
    const formattedTitle = zoneTitle?.trim()

    if (formattedTitle && isZoneTitleValid(formattedTitle)) {
      onSave({ title: formattedTitle, groupWhitelist },
        () => setIsEdit(val => !val))
      return
    }

    !error && setError(true)
  }

  const handleInputChange = ({ target }) => {
    const inputValue = target.value
    setZoneTitle(inputValue)
    setError(!inputValue)
  }

  const renderWhitelist = () => {
    const whitelist = zone.groups || []
    return whitelist.length
      ? whitelist.map(group => group.title).join(', ')
      : <span className='secondary_text__inactive'>Доступно только для Администраторов</span>
  }

  const renderInput = () => (
    <Input
      autoFocus
      className='zones-list__item-input'
      id={zone.id}
      name='title'
      value={zoneTitle}
      placeholder='Название зоны'
      onChange={handleInputChange}
      style={{ border: error && '1px solid #CD4141', width: '100%' }}
    />
  )

  const clickOutsideNewItemHandler = ({ target }) => {
    if (!zoneTitle) {
      const isClickOnBtn = target.closest('.btn-group__item')
      const isClickOnSidePanel = target.closest('.side_panel')
      if (isClickOnBtn || isClickOnSidePanel) return

      const isClickOutsideItem = !target.closest('.zones-list__item')
      if (target.nodeName === 'LI') return
      if (isClickOutsideItem) onCancel()
    }
  }

  const handleConfirm = () => {
    onDelete(zone.id)
    setIsDeleteModalVisible(false)
  }
  const filterGroups = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  const getIconClassName = (() =>
    `zones-list__item-action${zone.pre_defined ? '_disabled' : ''}`)()
  useEffect(() => {
    if (isNew) {
      document.addEventListener('mousedown', clickOutsideNewItemHandler)
      return () => document.removeEventListener('mousedown', clickOutsideNewItemHandler)
    }
  }, [zoneTitle])

  return (
    <>
      <CardListItem
        extraClassName='zones-list__item'
        style={{ height: isEdit && 'auto', alignItems: isEdit && 'flex-start' }}
      >
        <div
          className={`zones-list__item-title ${isEdit ? '' : 'text__wrapper'}`}
          style={{ paddingRight: isEdit && '10px' }}
        >
          {isEdit ? renderInput() : zone.title}
        </div>

        <div
          className='zones-list__item-whitelist'
          style={{ marginRight: zone.pre_defined ? '60px' : '30px', padding: isEdit && '2px' }}
        >
          {isEdit
            ? (
              <Select
                listHeight={32}
                mode='multiple'
                value={groupWhitelist}
                placeholder='Выберите группы пользователей'
                notFoundContent='Не найдено'
                onChange={setGroupWhitelist}
                filterOption={filterGroups}
                style={{ width: '100%', border: error && '1px solid #CD4141' }}
                source={groups}
              >
                {groups?.map(group =>
                  <Select.Option key={group.id}>{group.title}</Select.Option>)}
              </Select>
            )
            : renderWhitelist()}
        </div>

        {isEdit
          ? (
            <IconSave
              style={{ height: '41px', display: 'flex', alignItems: 'center' }}
              onClick={handleSave}
              color='#0079C1'
            />
          )
          : (
            <>
              {!zone.pre_defined && (
                <IconDelete
                  onClick={() => setIsDeleteModalVisible(true)}
                  className='zones-list__item-action'
                />
              )}
              <IconEdit
                width='18'
                height='18'
                className={getIconClassName}
                onClick={() => !zone.pre_defined && setIsEdit(true)}
              />
            </>
          )}
      </CardListItem>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={`Удалить зону '${zone.title}'?`}
        onConfirm={handleConfirm}
        onClose={() => setIsDeleteModalVisible(false)}
      />
    </>
  )
}
