/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import React from 'react'
import Table from './Table'

const renderTables = ({ room, defaultProps }) => room.tables.map(table => {
  table.room = room.id
  return (
    <Table
      key={table.id}
      table={table}
      room={room}
      defaultProps={defaultProps}
    />
  )
})

export default renderTables
