import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import FloorsInfo from './forms/Floors/FloorsInfo'
import GeneralInfo from './forms/GeneralInfo'
import RoomTypes from './forms/RoomTypes/RoomTypes'
import SideNavigationPanel from './SideNavigationPanel'
import TagsInfo from './forms/Tags/TagsInfo'
import ZonesInfo from './forms/Zones/ZonesInfo'
import AdvancedSettings from './forms/AdvancedSettings/AdvancedSettings'

import { officesAC } from '../../../../actions/actionCreator/offices'
import { groupAC } from '../../../../actions/actionCreator/groups'

const SIDE_PANEL_BUTTONS = [
  { title: 'Информация о БЦ', name: 'general' },
  { title: 'Этажи', name: 'floors' },
  { title: 'Зоны', name: 'zones' },
  { title: 'Теги', name: 'tags' },
  { title: 'Типы помещений', name: 'room-types' },
  // Фича на будущее
  // { title: 'Доп настройки', name: 'advanced-settings' },
]

const renderActiveForm = ({ activeForm, isNew, office }) => {
  const mappingByActiveForm = {
    general: () => <GeneralInfo isNew={isNew} office={office} />,
    floors: () => <FloorsInfo />,
    zones: () => <ZonesInfo />,
    tags: () => <TagsInfo />,
    'room-types': () => <RoomTypes />,
    // Фича на будущее
    // 'advanced-settings': () => <AdvancedSettings />,
  }

  return mappingByActiveForm[activeForm]()
}

const OfficeMainInfoEdit = React.memo(props => {
  const { isNew } = props

  const params = useParams()
  const history = useHistory()
  const [activeForm, setActiveForm] = useState('general')
  const dispatch = useDispatch()

  const office = useSelector(({ offices }) => offices)
  const goBack = useCallback(() =>
    history.location.state
      ? history.push(history.location.state.from)
      : history.goBack(), [history.location.pathname])

  useEffect(() => {
    const officeId = params.id
    if (!isNew) {
      dispatch(officesAC.getOffice({ officeId }))
      dispatch(officesAC.getFloors({ officeId }))
      dispatch(officesAC.getZones({ officeId }))
      dispatch(officesAC.getTags({ officeId }))
      dispatch(officesAC.getRoomTypes({ officeId }))
    } else {
      dispatch(officesAC.getLicenses())
    }
    dispatch(groupAC.getGroupsList())
    return () => dispatch(officesAC.clearOffice())
  }, [])
  return (
    <>
      <SideNavigationPanel
        navItems={SIDE_PANEL_BUTTONS}
        backBtnTitle='Назад'
        onBackBtnClick={goBack}
        activeItem={activeForm}
        isSaved={!isNew}
        setActiveItem={setActiveForm}
      />
      <div className='office_main_info__card'>
        {renderActiveForm({ activeForm, isNew, office })}
      </div>
    </>
  )
})

export default OfficeMainInfoEdit
