import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Form as FormikForm, withFormik } from 'formik'

import ConfirmationModal from '../UI/modals/ConfirmationModal'
import { ErrorMsg } from '../UI/ValidationMsg'
import PasswordInput from '../UI/input/PasswordInput'
import getBasicInputProps from '../../utils/getBasicInputProps'
import { changeUserPassword } from '../../actions/actionCreator/actionCreator'

const ValidationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('Обязательное поле'),
  newPassword: Yup.string()
    .min(8, 'Минимальная длина пароля 8 символов')
    .required('Обязательное поле'),
  newPasswordRepeat: Yup.mixed()
    .oneOf([Yup.ref('newPassword')], 'Подтверждение не совпадает с паролем')
    .required('Обязательное поле'),
})

const FIELDS = [
  { label: 'Старый пароль', name: 'oldPassword' },
  { label: 'Новый пароль', name: 'newPassword' },
  { label: 'Повторите новый пароль', name: 'newPasswordRepeat' }]

const InnerForm = React.memo(({
  start,
  onClose,
  setFieldValue,
  setFieldTouched,
  values,
  setTouched,
  changeUserPassword,
  resetForm,
  touched,
  errors,
}) => {
  const packPasswords = () => ({
    old_password: values.oldPassword,
    new_password: values.newPassword,
  })

  const closeModal = () => {
    onClose()
    resetForm()
  }

  const changePassword = () => {
    setTouched({ oldPassword: true, newPassword: true, newPasswordRepeat: true })

    if (!ValidationSchema.isValidSync(values)) return null
    const payload = packPasswords()
    changeUserPassword(payload, closeModal)
  }

  const getInputProps = getBasicInputProps({
    values,
    errors,
    touched,
    setFieldTouched,
    onChange: setFieldValue,
  })

  const fieldStyle = { width: '100%', border: '1px solid transparent' }

  const renderFields = () => FIELDS.map(({ label, name }) => (
    <PasswordInput
      key={`password-input-${label}`}
      label={label}
      onEnter={changePassword}
      style={fieldStyle}
      {...getInputProps(name)}
    >
      <ErrorMsg field={name} />
    </PasswordInput>
  ))

  return (
    <ConfirmationModal
      start={start}
      onClose={closeModal}
      confirmBtnText='Сохранить'
      title='СМЕНА ПАРОЛЯ'
      onConfirm={changePassword}
    >
      <FormikForm style={{ paddingTop: '10px', marginBottom: '50px' }}>
        {renderFields()}
      </FormikForm>
    </ConfirmationModal>
  )
})

const PasswordChangeModal = withFormik({
  mapPropsToValues: () => ({ oldPassword: '', newPassword: '', newPasswordRepeat: '' }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapDispatchToProps = { changeUserPassword }

export default connect(null, mapDispatchToProps)(PasswordChangeModal)
