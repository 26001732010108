import React from 'react'

import Icon from '../../../UI/Icon'

const OfficeCard = ({
  office,
  index,
  flexValues = [],
  infoButton,
  onMoreIconClick,
  isMoreBtnActive,
}) => {
  const { title, description, floors_number, capacity, occupied } = office

  return (
    <>
      <span className='card_list__item_id' style={{ flex: flexValues[0] }}>{`${index + 1}.`}</span>
      <span className='card_list__item_office_name' style={{ flex: flexValues[1] }}>{title}</span>
      <span className='card_list__item_address' style={{ flex: flexValues[2] }}>
        {description
          ? (
            <>
              <Icon name='icon__location' />
              <div className='secondary_text'>{description}</div>
            </>
          )
          : <div className='secondary_text__inactive'>Не указан</div>}
      </span>
      <span style={{ flex: flexValues[3] }} className='text__wrapper secondary_text__white'>
        {floors_number}
      </span>
      <span style={{ flex: flexValues[4] }} className='text__wrapper secondary_text__white'>
        {capacity}
      </span>
      <span style={{ flex: flexValues[5] }} className='secondary_text__green text__wrapper'>
        {(capacity - occupied) || '-'}
      </span>
      {infoButton}
      <div
        className={`icon__more ${isMoreBtnActive ? 'icon__active' : ''}`}
        onClick={e => {
          e.stopPropagation()
          onMoreIconClick(e)
        }}
      />
    </>
  )
}

export default OfficeCard
