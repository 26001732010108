import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import CardListItem from '../../../helpers/lists/CardListItem'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'
import ScrollTransition from '../../../UI/animations/transition/ScrollTransition'
import UnderlinedTitle from '../../../UI/UnderlinedTitle'
import UserBookingCardListItem from './UserBookingCardListItem'
import { bookingAC } from '../../../../actions/actionCreator/bookingAC'
import { getHistory } from '../../../../reducers/booking-selectors'
import ListLoadingSpin from '../../../UI/Spin/ListLoadingSpin'
import { getIsBookingLoading } from '../../../../reducers/app-selectors'

const UserBookingHistory = React.memo(() => {
  const dispatch = useDispatch()
  const bookHistory = useSelector(getHistory)
  const loading = useSelector(getIsBookingLoading)

  return (
    <div className='container_form__right' style={{ maxWidth: 'none', flex: 1.8 }}>
      <UnderlinedTitle title='ИСТОРИЯ БРОНИ' />
      <ScrollTransition loading>
        <div className='card_list'>
          {loading && <ListLoadingSpin />}
          {bookHistory?.length > 0
            ? bookHistory.map(item => (
              <CardListItem
                key={item.id}
                onDelete={() => dispatch(bookingAC.deleteBooking(item.id))}
                extraClassName='card_list__item__no_hover'
              >
                <UserBookingCardListItem booking={item} />
              </CardListItem>
            ))
            : <EmptyPlaceholder text='Список бронирований пуст' />}
        </div>
      </ScrollTransition>
    </div>
  )
})
export default UserBookingHistory
