import React from 'react'
import Icon from 'antd/lib/icon'
import { Link, useParams } from 'react-router-dom'
import st from '../../styles/BigCardTitle.module.scss'
import LocalIcon from './Icon'
import { useSelector } from 'react-redux'

const BigCardTitle = React.memo(({ icon, title, subtitle, isOffice }) => {
  const floors = useSelector(({ offices }) => offices.floors)
  const office = useParams()
  return (
    <div className='card_title'>
      <LocalIcon name={icon} />
      <div className='flex_container'>
        {[title, subtitle].map((text, i) =>
          <span
            key={`key-title-${i}`}
            className='large_btn__title'
          >{text?.toUpperCase()}</span>)}
      </div>
      {isOffice
      && (
        <div className={st.header}>
          <Link
            to={`/offices/${office.id}/edit-main-info`}
            className={st.header__link}
          >
            <Icon
              type='edit'
              className={st.header__icon}
            />
            Общая информация
          </Link>
          <Link
            to={`/offices/${office.id}/map`}
            style={floors.length ? null : { pointerEvents: 'none', color: 'grey' }}
          >
            <Icon
              type='environment'
              className={st.header__icon}
              style={floors.length ? null : { color: 'grey' }}
            />
            Управление картой
          </Link>
        </div>
      )}
    </div>
  )
})
export default BigCardTitle
