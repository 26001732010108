/* eslint-disable curly */
/* eslint-disable max-len */
import React from 'react'
import './menuContent.scss'
import {
  SET_STATE_WITHOUT_REDUX,
  CLEAR_OPEN_ROOM,
  DELETE_TABLE_MARKER,
} from './useReducerForMapComponent'

const MenuContent = ({ room, hasMarkerOnMap, hasTableMarkerOnMap, setIsVisibleMenu, defaultProps, deleteTableMarker, table }) => {
  const {
    dispatchWithoutRedux,
    deleteMarker,
    deleteRoom,
    setConfirmModal,
    editTable,
    deleteTable,
  } = defaultProps

  const edit = () => {
    setIsVisibleMenu(false)
    if (table) {
      return editTable(table, room.id)
    }
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { editableRoom: room, isRoomModalVisible: true } })
  }

  const deleteMarkerHandler = () => {
    setIsVisibleMenu(false)
    if (table?.id && hasTableMarkerOnMap?.id) {
      return deleteTableMarker()
    }
    deleteMarker({ markerId: hasMarkerOnMap.id, roomId: room.id, dispatchWithoutRedux })
  }

  const deleteHandler = () => {
    setIsVisibleMenu(false)

    if (table) {
      const deleteTableCallback = () => {
        const isLatestTable = room.tables.length === 1

        const deleteTableMarkerFromMap = () => {
          dispatchWithoutRedux({ type: DELETE_TABLE_MARKER, payload: { tableMarker: hasTableMarkerOnMap } })
          isLatestTable && dispatchWithoutRedux({ type: CLEAR_OPEN_ROOM })
        }

        deleteTable(table.id, deleteTableMarkerFromMap)
      }

      return setConfirmModal(prev => ({ ...prev, isVisible: true, type: 'removeTable', title: table.title, callback: deleteTableCallback }))
    }

    const deleteRoomCallback = () => deleteRoom({ roomId: room.id, dispatchWithoutRedux })

    setConfirmModal(prev => ({ ...prev, isVisible: true, type: 'removeRoom', title: room.title, callback: deleteRoomCallback }))
  }

  return (
    <div className='menu-content-wrap'>
      <div className='default-functioun-list-wrap'>
        <div className='default-functioun-wrap' onClick={edit}>
          <div className='map-screen__menu-edit-icon' />
          <span className='default-function'>Редактировать</span>
        </div>
        {(hasMarkerOnMap?.id || hasTableMarkerOnMap?.id) && (
          <div className='default-functioun-wrap' onClick={deleteMarkerHandler}>
            <div className='map-screen__menu-map-icon' />
            <span className='default-function'>Убрать метку</span>
          </div>
        )}
      </div>
      <div className='dangerous-functioun-wrap' onClick={deleteHandler}>
        <div className='map-screen__menu-bucket-icon' />
        <span className='dangerous-function'>Удалить</span>
      </div>
    </div>
  )
}

export default MenuContent
