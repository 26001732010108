import React from 'react'
import Tooltip from 'antd/lib/tooltip'

import { dateToStringDayOfWeek } from '../../../../utils/dates/formatDate'
import DoubleLineColonBlock from '../../../UI/dataBlock/DoubleLineColonBlock'

const UserBookingCardListItem = React.memo(({ booking }) => {
  const markerStyle = { height: '20px', maxWidth: '12px', padding: '0 2px', margin: '0 5px' }

  const durationStyle = { borderRight: '0.5px solid #2B3647', flex: 1, maxWidth: '150px' }

  const placeContainerStyle = { overflow: 'auto', marginRight: '10px', minWidth: '70px', flex: 1 }

  const officeContainerStyle = {
    overflow: 'auto',
    marginRight: '10px',
    flex: 1,
    borderRight: '0.5px solid #2E343E',
  }
  const getMarkerContent = (() => booking.is_over
    ? { title: 'Завершено', style: 'blue_round_marker' }
    : { title: 'Не завершено', style: 'green_round_marker' }
  )()

  return (
    <>
      <Tooltip title={getMarkerContent.title}>
        <div className='card_list__item_marker' style={markerStyle}>
          <div className={getMarkerContent.style} />
        </div>
      </Tooltip>

      <DoubleLineColonBlock
        first={{ title: 'с', text: dateToStringDayOfWeek(booking.date_from) }}
        second={{ title: 'по', text: dateToStringDayOfWeek(booking.date_to) }}
        containerClass='card_list__item_duration'
        containerStyle={durationStyle}
      />

      <DoubleLineColonBlock
        first={{ title: 'БЦ', text: booking.office_title }}
        second={{ title: 'Эт.', text: booking.floor_title }}
        containerStyle={officeContainerStyle}
      />

      <DoubleLineColonBlock
        first={{ title: 'Пом.', text: booking.room_title }}
        second={{ title: 'Стол', text: booking.table_title }}
        containerStyle={placeContainerStyle}
      />
    </>
  )
})

export default UserBookingCardListItem
