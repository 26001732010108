import React from 'react'
import Select from 'antd/lib/select'
import { ErrorMessage } from 'formik'

import SimpleSelect from '../../../UI/input/SimpleSelect'
import FadeTransition from '../../../UI/animations/transition/FadeTransition'
import ValidationMsg from '../../../UI/ValidationMsg'

const BookingDetailsBlock = React.memo(({
  values,
  floors,
  rooms,
  tables,
  onFloorSelect,
  onRoomSelect,
  setFieldTouched,
  setFieldValue,
  getErrorCondition,
}) => (
  <FadeTransition loading={values.isDetailBooking}>
    <div className='flex_container flex_container__space_between'>
      <div
        className='container__booking__floor_field'
      >
        <SimpleSelect
          name='floor'
          label='Этаж'
          placeholder='Этаж'
          value={values.floor}
          onChange={onFloorSelect}
          setFieldTouched={setFieldTouched}
          error={getErrorCondition('floor')}
          errorComponent={() => <ErrorMessage component={ValidationMsg} name='floor' />}
        >
          {floors && floors.length > 0 && floors?.map((f => (
            <Select.Option key={`floor-${f.id}`} value={f.id}>{f.title}</Select.Option>
          )))}
        </SimpleSelect>
      </div>
      <div
        className='container__booking__room_field_margin'
      >
        <SimpleSelect
          name='room'
          label='Помещение'
          placeholder='Выберите кабинет'
          onChange={onRoomSelect}
          setFieldTouched={setFieldTouched}
          disabled={!values.floor}
          value={values.room}
          error={getErrorCondition('room')}
          errorComponent={() => <ErrorMessage component={ValidationMsg} name='room' />}
        >
          {/*проскакивала невоспроизводимая ошибка*/}
          {rooms && rooms?.map(({ id, title }) => (
            <Select.Option key={`room-${id}`} value={id}>{title}</Select.Option>
          ))}
        </SimpleSelect>
      </div>
      <div className='container__booking__table_field'>
        <SimpleSelect
          name='table'
          label='Стол'
          value={values.table}
          placeholder='Выберите место'
          onChange={setFieldValue}
          setFieldTouched={setFieldTouched}
          disabled={!values.room || !values.floor}
          error={getErrorCondition('table')}
          errorComponent={() => <ErrorMessage component={ValidationMsg} name='table' />}
        >
          {/*проскакивала невоспроизводимая ошибка*/}
          {tables && tables?.map(t => (
            <Select.Option key={`table-${t.id}`} value={t.id}>{t.title}</Select.Option>
          ))}
        </SimpleSelect>
      </div>
    </div>
  </FadeTransition>
))

export default BookingDetailsBlock
