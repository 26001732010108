import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAvatarStyle } from '../../utils/getStyle/getImageStyle'
import UserPopUpInfo from './UserPopUpInfo'
import PasswordChangeModal from './PasswordChangeModal'
import useOutsideClick from '../../utils/hooks/useOutsideClick'
import {
  getUserDescription,
  getUserPhoneNumber,
  getUserPhoto,
} from '../../reducers/account-selectors'

const UserInfo = React.memo(() => {
  const photo = useSelector(getUserPhoto)
  const phoneNumber = useSelector(getUserPhoneNumber)
  const description = useSelector(getUserDescription)
  const popupRef = useRef()
  const popupOpenButtonRef = useRef()

  const [
    isUserPopupExpanded, setIsUserPopupExpanded,
  ] = useOutsideClick(popupRef, popupOpenButtonRef)
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
  const logoClassNames = 'flex_container flex_container__center user_info__inner'
  const getArrowStyle = () => ({ transform: isUserPopupExpanded ? 'rotate(180deg)' : 'rotate(0deg)' })

  return (
    <div className='user_info' id='userInfoWrap'>
      <div className={logoClassNames} id='userLogoWrap' ref={popupOpenButtonRef}>
        {phoneNumber
          ? <span id='userInfo' className='user_name'>{phoneNumber}</span>
          : <div className='placeholder__on_load' />}
        <span className='card_list__item_avatar'>
          <div className='card_list__avatar' style={getAvatarStyle(photo)} />
        </span>
        <div className='expand_arrow' style={getArrowStyle()} />
      </div>
      {isUserPopupExpanded && (
        <UserPopUpInfo
          popupRef={popupRef}
          phone={phoneNumber}
          description={description}
          closePopUp={() => setIsUserPopupExpanded(false)}
          openPasswordModal={() => setIsPasswordModalVisible(true)}
        />
      )}
      {isPasswordModalVisible && (
        <PasswordChangeModal
          start={isPasswordModalVisible}
          onClose={() => setIsPasswordModalVisible(false)}
        />
      )}
    </div>
  )
})

export default UserInfo
