/* eslint-disable curly */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable import/extensions */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Image, Layer, Stage } from 'react-konva'
import { useDrop } from 'react-dnd'
import message from 'antd/lib/message'
import RoomMarker from './RoomMarker'
import TableMarker from './TableMarker'
import { translateRoomTypes } from './helpers/roomsTypes'

import MarkerHover from './MarkerHover'
import MarkerClick from './MarkerClick'
import BucketKonva from './BucketKonva'
import ModalOnMap from './ModalOnMap'
import { equationStraightLineGenerate } from './utils'
import {
  ADD_NEW_TABLE_MARKER,
  DEFAULT_MARKER_ON_CLICK_ATTR,
  DELETING_MARKER,
  PUT_TABLE_MARKER_ON_MAP,
  SET_BUCKET_POSITION,
  SET_DEFAULT_MAP_SCALE,
  SET_DELETING_MARKER_ROOM_ID,
  SET_IS_BUCKET_HOVER,
  SET_IS_VISIBLE_BUCKET,
  SET_MAP_STATE,
  SET_MARKER_ON_CLICK_ATTR,
  SET_MARKER_ON_HOVER_ATTR,
  SET_MARKERS,
  SET_ROOM_MARKER_ON_MAP,
  SET_ROOM_ON_HOVER_ATTR,
  SET_STATE_WITHOUT_REDUX,
  SET_VIEWPORT_DIMENSIONS,
} from './helpers/useReducerForMapComponent.js'
import { officesAC } from '../../actions/actionCreator/offices'

const MapKonva = React.memo(props => {
  const {
    map,
    floorMap,
    activeFloor,
    setConfirmModal,
    stateWithoutRedux,
    dispatchWithoutRedux,
    deleteMarker,
    deleteRoom,
  } = props

  const {
    viewportDimensions,
    markerOnHoverAttr,
    markerOnClickAttr,
    mapState,
    markers,
    isBucketHover,
    isDeletingMarker,
    isVisibleBucket,
    bucketPosition,
    openRoom,
    // TODO удалить из проекта isActiveAddMarkerMode (эта функциональность ушла)
    isActiveAddMarkerMode,
    tableMarkers,
    cursor,
  } = stateWithoutRedux
  const dispatch = useDispatch()
  const mapWrap = useRef()
  const stageRef = useRef()
  const [isMouseOverMap, setIsMouseOverMap] = useState(null)

  const updateImage = () => {
    const mapImage = new window.Image()
    mapImage.src = map
    mapImage.onload = () => {
      dispatchWithoutRedux({ type: SET_MAP_STATE, payload: { image: mapImage, loading: false } })
    }
  }

  const getNewMapState = ({ width, height }) => {
    const widthCoefficient = 1 / (floorMap.width / width) // коеф. уменьшения длины
    const heightCoefficient = 1 / (floorMap.height / height) // коеф. уменьшения высоты
    const newScale = heightCoefficient > widthCoefficient ? heightCoefficient : widthCoefficient
    const newMinScale = newScale / 2
    const newMaxScale = newScale * 4

    return {
      scale: newScale > 1 ? 1 : newScale,
      minScale: newMinScale,
      maxScale: newMaxScale,
      x: 0,
      y: 0,
      newScale,
    }
  }

  useEffect(() => {
    updateImage()
  }, [map])

  useEffect(() => {
    const width = mapWrap.current.offsetWidth
    const height = mapWrap.current.offsetHeight
    const { x: mapOffsetX, y: mapOffsetY } = mapWrap.current.getBoundingClientRect()
    dispatchWithoutRedux({
      type: SET_VIEWPORT_DIMENSIONS,
      payload: { width, height, mapOffsetX, mapOffsetY },
    })
    dispatchWithoutRedux({ type: SET_BUCKET_POSITION, payload: { top: height * 0.85 } })
    const newMapState = getNewMapState({ width, height })
    const { newScale } = newMapState
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    dispatchWithoutRedux({ type: SET_DEFAULT_MAP_SCALE, payload: newScale > 1 ? 1 : newScale })
  }, [])

  useEffect(() => {
    const width = mapWrap.current.offsetWidth
    const height = mapWrap.current.offsetHeight
    dispatchWithoutRedux({ type: SET_VIEWPORT_DIMENSIONS, payload: { width, height } })
    const newMapState = getNewMapState({ width, height })
    const { newScale } = newMapState
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    dispatchWithoutRedux({ type: SET_DEFAULT_MAP_SCALE, payload: newScale > 1 ? 1 : newScale })
  }, [activeFloor])

  const postRoomMarker = ({ marker, callback }) => {
    dispatch(officesAC.postRoomMarker({
      marker,
      callback,
    }))
  }

  const putRoomMarker = ({ marker, callback }) => {
    dispatch(officesAC.putRoomMarker({
      marker,
      callback,
    }))
  }

  const postTableMarker = ({ tableMarker, setTableMarkerOnMap }) => {
    dispatch(officesAC.postTableMarker({
      tableMarker,
      setTableMarkerOnMap,
    }))
  }

  const putTableMarker = tableMarker => {
    const setTableMarkerOnMap = newTableMarker => dispatchWithoutRedux({
      type: PUT_TABLE_MARKER_ON_MAP,
      payload: { ...newTableMarker, title: tableMarker.title },
    })

    dispatch(officesAC.putTableMarker({ tableMarker, setTableMarkerOnMap }))
  }

  const getNewCoordinates = delta => {
    const correctX = delta.x - viewportDimensions.mapOffsetX
    const correctY = delta.y - viewportDimensions.mapOffsetY
    const xWithoutMapOffset = correctX - mapState.x
    const yWithoutMapOffset = correctY - mapState.y
    /*
    карта может быть смещена относительно вьюпорта.
    Вычисления выше убирают смещение карты относительно вьюпорта
    */
    const newX = xWithoutMapOffset * 100 / (floorMap.width * mapState.scale)
    const newY = yWithoutMapOffset * 100 / (floorMap.height * mapState.scale)
    return { newX, newY }
  }
  // eslint-disable-next-line no-unused-vars
  const [nothing, drop] = useDrop({
    accept: 'room',
    drop: (item, monitor) => {
      const { room } = item
      const delta = monitor.getClientOffset()
      const { newX, newY } = getNewCoordinates(delta)
      const marker = markers.find(p => p.roomId === room.id)
      const type = marker?.type
      const icon = translateRoomTypes(type)
      const callback = openRoom.id
        // eslint-disable-next-line max-len
        ? marker => dispatchWithoutRedux({ type: SET_ROOM_MARKER_ON_MAP, payload: { room: { ...room, marker } } })
        : null
      const payload = {
        marker: { x: newX.toFixed(2), y: newY.toFixed(2), room: room.id, type, icon },
        callback,
      }

      postRoomMarker(payload)
      dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { isActiveAddMarkerMode: false } })
    },
  })

  // eslint-disable-next-line no-unused-vars
  const [nothingTable, tableDrop] = useDrop({
    accept: 'table',
    drop: (item, monitor) => {
      const { table, room } = item
      if (room.marker) {
        const delta = monitor.getClientOffset()
        const { newX, newY } = getNewCoordinates(delta)
        const tableMarker = { x: newX.toFixed(2), y: newY.toFixed(2), table: table.id }

        const setTableMarkerOnMap = tableMarker => {
          const newTableMarker = { ...tableMarker, title: table.title }

          dispatchWithoutRedux({ type: ADD_NEW_TABLE_MARKER, payload: { newTableMarker } })
        }

        postTableMarker({ tableMarker, setTableMarkerOnMap })
        dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { isActiveAddMarkerMode: false } })
      } else {
        message.error(`Добавьте помещение ${room.title} на карту`)
      }
    },
  })

  const handleWheel = e => {
    e.evt.preventDefault()
    const scaleByCoef = 1.05 // TODO - определить скорость увеличения
    const stage = e.target.getStage()
    const oldScale = stage.scaleX()
    const { maxScale, minScale } = mapState
    const newLargerScale = (oldScale * scaleByCoef) > maxScale ? maxScale : oldScale * scaleByCoef
    const newSmallerScale = (oldScale / scaleByCoef) < minScale ? minScale : oldScale / scaleByCoef

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    }
    const newScale = e.evt.deltaY < 0 ? newLargerScale : newSmallerScale
    stage.scale({ x: newScale, y: newScale })
    const newMapState = {
      scale: newScale,
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    }
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    if (markerOnHoverAttr.hover)
      dispatchWithoutRedux({
        type: SET_MARKER_ON_HOVER_ATTR,
        payload: { hover: false, isCursorMove: false, isTableMarker: false },
      })
    if (markerOnClickAttr.isActive)
      dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const handlePointDragStart = e => {
    const id = e.target.id()
    dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: true })
    dispatchWithoutRedux({
      type: SET_MARKER_ON_HOVER_ATTR,
      payload: { hover: false, isCursorMove: true, isTableMarker: false },
    })
    const markersCopy = markers.slice()
    const marker = markersCopy.find(m => m.id === id)
    const idx = markersCopy.indexOf(marker)
    markersCopy.splice(idx, 1)
    markersCopy.push({ ...marker, isDragging: true })

    dispatchWithoutRedux({ type: SET_MARKERS, payload: markersCopy })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const handlePointDragEnd = e => {
    if (isDeletingMarker) {
      dispatchWithoutRedux({ type: SET_IS_BUCKET_HOVER, payload: false })
      dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: false })
      setTimeout(() => {
        dispatchWithoutRedux({ type: DELETING_MARKER, payload: false })
        dispatchWithoutRedux({ type: SET_DELETING_MARKER_ROOM_ID, payload: null })
      }, 100)
      return null
    }
    dispatchWithoutRedux({ type: SET_IS_VISIBLE_BUCKET, payload: false })
    const id = e.target?.attrs?.id
    const newX = (e.target.attrs.x * 100) / floorMap.width
    const newY = (e.target.attrs.y * 100) / floorMap.height
    const marker = markers.find(p => p.id === id)
    const roomId = marker?.roomId
    const icon = marker?.icon
    dispatchWithoutRedux({
      type: SET_MARKER_ON_HOVER_ATTR,
      payload: { hover: true, x: newX, y: newY, isCursorMove: true, isTableMarker: false },
    })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false, x: newX, y: newY } })
    const newMarkers = markers.map(point => ({ ...point, isDragging: false }))
    dispatchWithoutRedux({ type: SET_MARKERS, payload: newMarkers })

    putRoomMarker({
      floorId: activeFloor,
      marker: {
        id: marker?.id,
        x: newX.toFixed(2),
        y: newY.toFixed(2),
        room: roomId,
        icon,
      },
    })
  }

  const handleStageDragStart = e => {
    const id = e.target?.attrs?.id
    if (id === 'Stage') {
      dispatchWithoutRedux({ type: SET_MAP_STATE, payload: { isDragging: true } })
      dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    }
  }

  const handleStageDragEnd = e => {
    if (e.target?.attrs?.width === viewportDimensions.width) {
      // если двигалась именно карта - изменяется x и y
      const newMapState = { x: e.target.attrs.x, y: e.target.attrs.y, isDragging: false }
      dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    }
  }

  const handleStageClick = e => {
    const id = e.target?.parent?.attrs?.id
    const isClickOnMarker = markers.some(marker => marker.id && marker.id === id)

    if (!isClickOnMarker)
      dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: DEFAULT_MARKER_ON_CLICK_ATTR })
  }

  const handleStageWrapClick = e => {
    e.preventDefault()
    if (isActiveAddMarkerMode)
      dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { isActiveAddMarkerMode: false } })
  }

  const handleHoverBucket = () => {
    dispatchWithoutRedux({ type: SET_IS_BUCKET_HOVER, payload: true })
  }

  const handleDropBucket = () => {
    const activeMarker = markers.find(m => m.isDragging)

    dispatchWithoutRedux({ type: SET_DELETING_MARKER_ROOM_ID, payload: activeMarker?.roomId })
    dispatch(officesAC.deleteRoomMarker({
      markerId: activeMarker.id, roomId: activeMarker.room,
    }))
    dispatchWithoutRedux({ type: SET_ROOM_ON_HOVER_ATTR, payload: { isHover: false } })
    dispatchWithoutRedux({ type: DELETING_MARKER, payload: true })
  }

  const handleMouseLevaeFromBucket = () => {
    dispatchWithoutRedux({ type: SET_IS_BUCKET_HOVER, payload: false })
    dispatchWithoutRedux({ type: DELETING_MARKER, payload: false })
  }

  const handleClickOnScaleButton = type => {
    const scaleByCoef = 1.045 // TODO - определить скорость увеличения
    const { mapState: prevMapState } = stateWithoutRedux
    const { scale: prevScale } = prevMapState
    const newScale = type === '+' ? prevScale * scaleByCoef : prevScale / scaleByCoef
    const newMapState = { ...prevMapState, scale: newScale }
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: newMapState })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }

  const getVisibleMarkers = () => {
    if (openRoom.id) {
      return markers.filter(p => !p.isBookable && p.x && p.y)
    }

    return markers.filter(p => p.isVisible)
  }

  const visiblePoints = getVisibleMarkers()

  const currentRadius = (() => {
    const maxValue = (mapState?.image?.width * 0.04 / 2).toFixed(2) // для ф-ии equationStraightLineGenerate
    const minValue = (mapState?.image?.width * 0.005).toFixed(2) // для ф-ии equationStraightLineGenerate
    const maxScale = mapState?.maxScale.toFixed(2) // для ф-ии equationStraightLineGenerate
    const minScale = mapState?.minScale.toFixed(2) // для ф-ии equationStraightLineGenerate
    const easyScale = mapState.scale.toFixed(2) // для ф-ии equationStraightLineGenerate

    const newCurrentRadius = equationStraightLineGenerate(minScale / 20, maxValue, maxScale, minValue)(easyScale)

    const maxRadius = mapState?.image?.width * 0.04 / 2
    const minRadius = viewportDimensions.width * 0.015 / 2
    if (newCurrentRadius >= maxRadius) return maxRadius
    if (newCurrentRadius <= minRadius) return minRadius
    return newCurrentRadius
  })()

  const getCursorClassName = () => {
    // TODO отрефачить поведение курсора
    const isClicked = markerOnClickAttr.isActive
    const isHover = markerOnHoverAttr.isCursorMove
    const isMapDragged = mapState.isDragging

    switch (true) {
      case isMapDragged:
        return 'dragging'
      case isActiveAddMarkerMode:
        return 'cell'
      case isClicked:
        return 'click'
      case isHover:
        return 'hover'
      default:
        return ''
    }
  }

  // if (openRoom.id && openRoom.marker && !tableMarkers.length) {
  //   message.info('Перенесите места для бронирования на карту')
  // }

  return (
    <div
      ref={mapWrap}
      style={{ width: '100%', height: '100%', cursor }}
      onClick={handleStageWrapClick}
      onMouseLeave={() => setIsMouseOverMap(false)}
      onMouseOver={() => setIsMouseOverMap(true)}
      className={`canvas-map-wrap ${getCursorClassName()}`}
    >
      <div ref={drop}>
        <div ref={tableDrop}>
          {markerOnClickAttr.isActive && (
            <MarkerClick
              mapState={mapState}
              currentRadius={currentRadius}
              pointer={markerOnClickAttr}
              setConfirmModal={setConfirmModal}
              dispatchWithoutRedux={dispatchWithoutRedux}
              deleteMarker={deleteMarker}
              deleteRoom={deleteRoom}
            />
          )}
          {markerOnHoverAttr.hover && (
            <MarkerHover
              mapState={mapState}
              currentRadius={currentRadius}
              markerOnHoverAttr={markerOnHoverAttr}
            />
          )}
          {isVisibleBucket && (
            <BucketKonva
              style={{ top: bucketPosition * 0.90 }}
              isBucketHover={isBucketHover}
              handleHoverBucket={handleHoverBucket}
              handleDropBucket={handleDropBucket}
              handleMouseLevaeFromBucket={handleMouseLevaeFromBucket}
            />
          )}

          <div style={{ overflow: 'hidden', height: '100%' }}>
            <Stage
              draggable
              ref={stageRef}
              width={viewportDimensions.width}
              height={viewportDimensions.height}
              onWheel={handleWheel}
              onDragStart={handleStageDragStart}
              onDragEnd={handleStageDragEnd}
              onClick={handleStageClick}
              scaleX={mapState.scale}
              scaleY={mapState.scale}
              x={mapState.x}
              y={mapState.y}
              id='Stage'
            >
              <Layer style={{ width: '100%' }}>
                {!mapState.loading && <Image image={mapState.image} name='map' id='map' />}
              </Layer>
              <Layer>
                {tableMarkers.map(tableMarker => (
                  <TableMarker
                    key={tableMarker.id}
                    point={tableMarker}
                    x={tableMarker.x / 100 * floorMap.width}
                    y={tableMarker.y / 100 * floorMap.height}
                    currentRadius={currentRadius * 0.7} // радиус стола составляет 70% от радиуса рума
                    isMouseOverMap={isMouseOverMap}
                    dispatchWithoutRedux={dispatchWithoutRedux}
                    stateWithoutRedux={stateWithoutRedux}
                    putTableMarker={putTableMarker}
                  />
                ))}
              </Layer>
              <Layer>
                {!mapState.loading && visiblePoints.map(point => (
                  <RoomMarker
                    key={point.id}
                    point={point}
                    x={point.x / 100 * floorMap.width}
                    y={point.y / 100 * floorMap.height}
                    handlePointDragStart={handlePointDragStart}
                    handlePointDragEnd={handlePointDragEnd}
                    viewportDimensions={viewportDimensions}
                    points={visiblePoints}
                    currentRadius={currentRadius}
                    isMouseOverMap={isMouseOverMap}
                    dispatchWithoutRedux={dispatchWithoutRedux}
                    stateWithoutRedux={stateWithoutRedux}
                  />
                ))}
              </Layer>
              {openRoom.id && openRoom.marker && !tableMarkers.length && (
                <ModalOnMap
                  openRoom={openRoom}
                  mapState={mapState}
                  floorMap={floorMap}
                />
              )}
            </Stage>
          </div>
          <div className='map-buttons-wrap'>
            <button
              className='map__button'
              type='button'
              onClick={() => handleClickOnScaleButton('+')}
            >
              +
            </button>
            <button
              className='map__button'
              type='button'
              onClick={() => handleClickOnScaleButton('-')}
            >
              -
            </button>
          </div>
        </div>
      </div>
    </div>
  )
})

export default MapKonva
