import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import KioskCardListItem from './KioskCardListItem'
import KioskForm from '../forms/Kiosk/index'
import AnimatedHeight from '../../UI/animations/AnimatedHeight'
import { DELETE_KIOSK } from '../../../actions/users'
import ConfirmationModal from '../../UI/modals/ConfirmationModal'

const KioskCard = React.memo(({ kiosk, index }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const dispatch = useDispatch()

  const deleteKiosk = useCallback(() => {
    dispatch({
      type: DELETE_KIOSK,
      userId: kiosk.id,
    })
    setIsDeleteModalVisible(false)
  }, [isDeleteModalVisible])

  const getItemActivityClass = useCallback(() => {
    const defaultClass = 'card_list__item'
    return isOpen ? `${defaultClass}__active` : defaultClass
  }, [isOpen])

  return (
    <React.Fragment key={kiosk.id}>
      <KioskCardListItem
        kiosk={kiosk}
        index={index}
        onDelete={() => setIsDeleteModalVisible(true)}
        onItemClick={() => setIsOpen(!isOpen)}
        getItemActivityClass={getItemActivityClass}
      />
      <AnimatedHeight condition={isOpen}>
        <KioskForm
          isEditMode
          kiosk={kiosk}
          onCancel={() => setIsOpen(false)}
        />
      </AnimatedHeight>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={`Удалить киоск '${kiosk.first_name}'?`}
        onConfirm={deleteKiosk}
        onClose={() => setIsDeleteModalVisible(false)}
      />
    </React.Fragment>
  )
})

export default KioskCard
