export const SET_APP_IS_LOADING = 'SET_APP_IS_LOADING'
export const SET_OFFICE_IS_LOADING = 'SET_OFFICE_IS_LOADING'
export const SET_LICENSES_IS_LOADING = 'SET_LICENSES_IS_LOADING'
export const SET_FLOORS_IS_LOADING = 'SET_FLOORS_IS_LOADING'
export const SET_ROOMS_IS_LOADING = 'SET_ROOMS_IS_LOADING'
export const SET_ZONES_IS_LOADING = 'SET_ZONES_IS_LOADING'
export const SET_TAGS_IS_LOADING = 'SET_TAGS_IS_LOADING'
export const SET_ROOM_TYPES_IS_LOADING = 'SET_ROOM_TYPES_IS_LOADING'
export const SET_APP_USERS_IS_LOADING = 'SET_APP_USERS_IS_LOADING'
export const SET_APP_PAGINATION_IN_GROUP = 'SET_APP_PAGINATION_IN_GROUP'
export const SET_APP_PAGINATION_NOT_IN_GROUP = 'SET_APP_PAGINATION_NOT_IN_GROUP'
export const SET_APP_IMPORT_LOADER = 'SET_APP_IMPORT_LOADER'
export const SET_BOOKING_IS_LOADING = 'SET_BOOKING_IS_LOADING'
export const SET_TABLE_IS_LOADING = 'SET_TABLE_IS_LOADING'
export const SET_DISABLE_IS_LOADING = 'SET_DISABLE_IS_LOADING'
export const SET_GROUP_IS_LOADING = 'SET_GROUP_IS_LOADING'
