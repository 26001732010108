import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import message from 'antd/lib/message'

import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import LargeSpin from '../../../../../UI/Spin/LargeSpin'
import Button from '../../../../../UI/Button'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import TableHeader from '../../../../../UI/TableHeader'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import ZonesListItem from './ZoneListItem'
import TextModal from '../../../../../UI/modals/TextModal'
import GroupsInfoModal from './GroupsInfoModal'

import { officesAC } from '../../../../../../actions/actionCreator/offices'

const LIST_COLUMNS_NAMES = ['Название зоны', 'Доступна для групп']
const COLUMNS_FLEX_VALUES = [1, 1.5]

const ZonesInfo = React.memo(() => {
  const params = useParams()
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isAddSingleZoneInputVisible, setIsAddSingleZoneInputVisible] = useState(false)
  const [isTextModalOpen, setIsTextModalOpen] = useState(false)
  const [isGroupsInfoModalOpen, setIsGroupsInfoModalOpen] = useState(false)

  const dispatch = useDispatch()

  const { current: office } = useRef(params.id)

  const isZonesLoading = useSelector(({ app }) => app.isZonesLoading)
  // TODO Подписать на реальный лодер isGroupsLoading
  const isGroupsLoading = false
  const zones = useSelector(({ offices }) => offices.zones)
  const groups = useSelector(({ users }) => users.groupsList)

  const toggleInputVisibility = () => setIsAddSingleZoneInputVisible(val => !val)

  const toggleAddMultipleZonesModal = () => setIsTextModalOpen(val => !val)

  const handleZonePost = zone => {
    if (zones?.some(({ title }) => zone.title === title)) {
      message.error('Зона с таким названием уже существует')
      return
    }
    dispatch(officesAC.postZones({
      titles: [zone.title],
      officeId: office,
      groupWhitelist: zone.groupWhitelist,
      callback: () => setIsAddSingleZoneInputVisible(false),
    }))
  }

  const handlePutZone = ({ zoneId, updatedZone, callback }) => dispatch(officesAC.putZone({
    zoneId,
    zone: updatedZone,
    office,
    callback,
  }))

  const isTitlesNotValid = titles => titles.some(title => title.length < 3 || title.length > 140)

  const handlePackageUpload = (titlesArray, callback) => {
    if (!isTitlesNotValid(titlesArray)) {
      dispatch(officesAC.postZones({
        titles: titlesArray,
        officeId: office,
        callback,
      }))
      return
    }
    message.error('Некорректные значения. Длина названия зоны – от 3 до 140 символов')
  }

  const handleDeleteZone = zoneId => dispatch(officesAC.deleteZone({ zoneId }))

  const renderZonesList = () => zones.length || isAddSingleZoneInputVisible
    ? (
      <ScrollTransition loading>
        <div className='zones-list'>
          {isAddSingleZoneInputVisible && (
            <ZonesListItem
              isNew
              groups={groups}
              onSave={handleZonePost}
              onCancel={toggleInputVisibility}
            />
          )}
          {zones.map(zone => (
            <ZonesListItem
              zone={zone}
              key={zone.id}
              groups={groups}
              onDelete={handleDeleteZone}
              onSave={(updatedZone, callback) => handlePutZone({
                zoneId: zone.id, updatedZone, callback,
              })}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список зон пуст' />

  if (isZonesLoading || isGroupsLoading) {
    return <LargeSpin />
  }

  return (
    <>
      <div className='flex_container__column' style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ЗОНЫ' />
        <Button
          style={{ position: 'absolute', top: 28, right: 0 }}
          styles='simple-btn'
          title='Информация о допущенных группах'
          onClick={() => setIsGroupsInfoModalOpen(true)}
        />
        <SubtitleWithActions
          style={{ width: 'fit-content' }}
          subtitle={`Всего зон: ${zones.length || 0}`}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={toggleInputVisibility}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleZoneInputVisible ? 'Отменить добавление зоны' : 'Добавить 1 зону'}
          />
          <Button
            onClick={toggleAddMultipleZonesModal}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>

      <TableHeader
        columns={LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={34}
      />
      {renderZonesList()}

      <ImportFromCsvModal
        form='zones'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />

      <TextModal
        tags={zones}
        isOpen={isTextModalOpen}
        setIsOpen={setIsTextModalOpen}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ЗОН'
        subtitle='Введите названия зон через запятую'
        hint='Пример: Коворкинг, Зона для сотрудников, Парковка'
        submitTitle='Добавить зоны'
      />

      <GroupsInfoModal
        groups={zones.reduce((acc, z) => z.groups?.length ? [...acc, ...z.groups] : acc, [])}
        start={isGroupsInfoModalOpen}
        onClose={() => setIsGroupsInfoModalOpen(false)}
      />
    </>
  )
})

export default ZonesInfo
