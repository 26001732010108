/* eslint-disable no-mixed-operators */
import React from 'react'

const MarkerHover = React.memo(props => {
  const {
    markerOnHoverAttr,
    mapState,
    currentRadius,
  } = props

  const {
    description,
    title,
    zoneTitle,
    x: xPercent,
    y: yPercent,
    isTableMarker,
  } = markerOnHoverAttr

  const realMapSize = {
    width: mapState.image.width * mapState.scale,
    height: mapState.image.height * mapState.scale,
  }

  const topOffset = realMapSize.height * yPercent / 100 + mapState.y
  const left = realMapSize.width * xPercent / 100 + mapState.x
  const top = topOffset + currentRadius * 1.8 * mapState.scale * (isTableMarker ? 0.7 : 1)

  return (
    <div
      className='marker__tooltip marker-hover'
      style={{
        top,
        left: left,
        minWidth: '20%',
      }}
    >
      <span className='title'>{title}</span>
      {description && <span className='subtitle'>{description}</span>}
      <span className='subtitle'>{zoneTitle}</span>
    </div>
  )
})

export default MarkerHover
