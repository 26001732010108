/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable curly */
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Menu from 'antd/lib/menu'
import message from 'antd/lib/message'

import './newRoomType.scss'
import { Scrollbar } from 'react-scrollbars-custom'

import LargeSpin from '../../UI/Spin/LargeSpin'
import RoomGroup, { getUniqueValue, menuKeyByRoomType } from '../helpers/RoomGroup'
import { SHOW_TABLE_MARKERS, CLEAR_OPEN_ROOM, SET_OPEN_ROOM } from '../helpers/useReducerForMapComponent'

const NewRoomsList = React.memo(props => {
  const {
    rooms,
    deleteMarker,
    deleteRoom,
    handleAddTable,
    editTable,
    deleteTable,
    deleteTableMarker,
    // mode,
    // TODO: удалить mode и isMapSet отовсюду. они не используются (скорее всего)
    addNewRoomHandler,
    refreshRoomsLoading,
    setConfirmModal,
    dispatchWithoutRedux,
  } = props

  const [openKeys, setOpenKeys] = useState([])

  const onOpenChangeHandler = keys => {
    // TODO отрефачить логику внизу
    const latestOpenKey = getUniqueValue(keys, openKeys)
    const isRoom = latestOpenKey?.includes('room')
    if (isRoom) {
      const hasRoomInKeys = openKeys.includes(latestOpenKey)
      const roomId = latestOpenKey.slice(7)
      const room = rooms.find(r => r.id === roomId)
      const hasTables = !!room.tables.length

      hasTables && !room.room_type_unified && dispatchWithoutRedux({ type: SET_OPEN_ROOM, payload: { room } })

      if (hasRoomInKeys && hasTables) {
        // нажата комната, которая была открыта раньше
        setOpenKeys([menuKeyByRoomType(room.type)])
      } else if (hasTables) {
        const newKeys = [menuKeyByRoomType(room.type), latestOpenKey]
        // нажата комната, в которой есть столы
        setOpenKeys(newKeys)
        dispatchWithoutRedux({ type: SHOW_TABLE_MARKERS, payload: { room } })
      } else {
        room.is_bookable && !room.room_type_unified && message.info('Добавьте места для бронирования')
      }
    } else {
      const hasRoomTypeInKeys = openKeys.includes(latestOpenKey)
      if (hasRoomTypeInKeys) {
        // нажатие по рум тайп груп (рабочее место, переговорная или прочее), которая была раньше открыта'
        dispatchWithoutRedux({ type: CLEAR_OPEN_ROOM })
        setOpenKeys([])
      } else {
        // нажатие на рум тайп груп
        dispatchWithoutRedux({ type: CLEAR_OPEN_ROOM })
        setOpenKeys(keys.length ? [latestOpenKey] : [])
      }
    }
  }

  const workingPlaces = rooms.filter(r => r.type === 'Рабочее место')
  const meetingRooms = rooms.filter(r => r.type === 'Переговорная')
  const anotherRoomTypes = rooms.filter(r => r.type !== 'Рабочее место' && r.type !== 'Переговорная')

  const defaultProps = {
    openKeys,
    dispatchWithoutRedux,
    deleteMarker,
    deleteRoom,
    setConfirmModal,
    handleAddTable,
    editTable,
    deleteTable,
    deleteTableMarker,
  }

  return (
    <div className='container__room__map_screen'>
      <Scrollbar>
        <div className='room-map-screen__menu-wrap'>
          {refreshRoomsLoading
            ? (<div style={{ paddingTop: '2rem ' }}><LargeSpin /></div>)
            : (
              <Menu
                mode='inline'
                openKeys={openKeys}
                onOpenChange={onOpenChangeHandler}
                style={{ width: '100%' }}
                theme='dark'
              >
                {workingPlaces.length && (
                  <RoomGroup
                    rooms={workingPlaces}
                    title='Рабочие места'
                    defaultProps={defaultProps}
                  />
                )}
                {meetingRooms.length && (
                  <RoomGroup
                    rooms={meetingRooms}
                    title='Переговорные'
                    defaultProps={defaultProps}
                  />
                )}
                {anotherRoomTypes.length && (
                  <RoomGroup
                    rooms={anotherRoomTypes}
                    title='Прочее'
                    defaultProps={defaultProps}
                  />
                )}
              </Menu>
            )}
        </div>
      </Scrollbar>
      <div className='map-screen__button-wrap' onClick={addNewRoomHandler}>
        <div className='simple-btn_with-background'><span>Создать помещение</span></div>
      </div>
    </div>
  )
})

const mapStateToProps = ({ offices = {} }, { activeFloor }) => {
  const roomsOnFloor = offices.rooms?.filter(room => room.floor_id === activeFloor)
  const { refreshRoomsLoading } = offices

  return ({ rooms: roomsOnFloor || [], refreshRoomsLoading })
}

export default connect(mapStateToProps)(NewRoomsList)
