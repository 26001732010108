import React from 'react'
import Button from '../Button'
import Modal from './Modal'

const isEqual = (prevProps, nextProps) => {
  if (nextProps.id !== prevProps.id) return false
  if (nextProps.id === null) return true
}

const ConfirmationModal = props => {
  const {
    start,
    onClose,
    onConfirm,
    text,
    children,
    style,
    textStyle,
  } = props
  return (
    <>
      {start && (
        <Modal extraClassName='modal_confirmation' start={start} onClose={onClose} style={style}>
          <span className='large_btn__title__center border_bottom'>ПОДТВЕРЖДЕНИЕ</span>
          {text && (
            <span
              className='main_text margin_top'
              style={{ marginBottom: '50px', wordBreak: 'break-all', ...textStyle }}
            >
              {text}
            </span>
          )}
          {children}
          <div className='modal__button_wrapper'>
            <Button
              styles='simple-btn'
              style={{ marginRight: '20px' }}
              title='Отмена'
              onClick={onClose}
            />
            <Button
              styles='bordered_btn__save_lecture'
              title='Ок'
              onClick={onConfirm}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default React.memo(ConfirmationModal, isEqual)
