import React from 'react'
import { Field as FormikField } from 'formik'
import Form from 'antd/lib/form'
import AutoComplete from 'antd/lib/auto-complete'

const { Option } = AutoComplete

const initialState = { searchString: '', filteredList: [] }

class SelectWithSearch extends React.Component {
  state = initialState

  debounce = null

  static getDerivedStateFromProps(nextProps) {
    return { filteredList: nextProps.source }
  }

  setNoSelectedOption = () => {
    const { name, onChange, idField, targetField } = this.props
    onChange(name, { [idField]: null, [targetField]: '' })
  }

  handleChange = val => {
    this.setState({ searchString: val })
    if (val === '') this.setNoSelectedOption()
  }

  handleSelect = val => {
    const { name, onChange, toShownString, targetField, idField } = this.props

    const selectedObj = this.state.filteredList.find(item => String(item[idField]) === val)
    onChange(name, { [idField]: selectedObj[idField], [targetField]: toShownString(selectedObj) })
    this.setState({ searchString: '' })
  }

  delayedSearch = val => {
    if (this.debounce) clearTimeout(this.debounce)
    this.debounce = setTimeout(() => this.props.onSearch(val, 1), 300)
  }

  render() {
    const {
      label,
      name,
      style,
      placeholder,
      value,
      toShownString,
      children,
      icon,
      targetField,
      idField,
      error,
      disabled,
    } = this.props
    const { searchString, filteredList } = this.state

    const renderPlaceholder = () => {
      const content = value[targetField] && disabled ? '' : placeholder
      const style = { color: disabled ? '#323B49' : '#4F5762' }
      return <span style={style}>{content}</span>
    }

    return (
      <Form.Item label={label}>
        <div className='validation_block'>
          <FormikField
            render={() => (
              <>
                <AutoComplete
                  name={name}
                  value={searchString || value[targetField]}
                  disabled={disabled}
                  placeholder={renderPlaceholder()}
                  onSearch={val => this.delayedSearch(val)}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  style={{ ...style, border: error && '1px solid #CD4141' }}
                >
                  {filteredList?.length > 0 && filteredList.map(item => (
                    <Option key={item[idField]}>{toShownString(item)}</Option>))}
                </AutoComplete>
              </>
            )}
          />
          {children && <div className='validation_block margin_top__small'>{children}</div>}
        </div>
        {icon && icon()}
      </Form.Item>
    )
  }
}

export default SelectWithSearch
