export default [
  {
    type: 'TABLES_AVAILABLE_FOR_BOOKING',
    title: 'Рабочие места, доступные для бронирования',
    format: 'count',
    badge: {
      type: 'percentage_of_tables_available_for_booking',
      format: 'percent',
    },
  },
  {
    type: 'ACTIVE_USERS',
    title: 'Количество зарегистрированных пользователей',
    format: 'count',
  },
  {
    type: 'PERCENT_OF_TABLES_BOOKED_AT_LEAST_ONCE',
    title: 'Доля рабочих мест, которые были хотя бы раз забронированы',
    format: 'percent',
  },
  {
    type: 'PERCENTAGE_OF_REGISTERED_TABLES',
    title: 'Доля зарегистрированных рабочих мест',
    format: 'percent',
  },
  {
    type: 'NUMBER_OF_BOOKINGS',
    title: 'Количество бронирований',
    format: 'count',
  },
  {
    type: 'SHARE_OF_CONFIRMED_BOOKINGS',
    title: 'Доля подтвержденных бронирований',
    format: 'percent',
  },
  {
    type: 'RECYCLING_PERCENTAGE_FOR_ALL_WORKPLACES',
    title: 'Средний процент утилизации по всем рабочим местам',
    format: 'percent',
  },
]
