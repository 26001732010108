import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../UI/Button'
import PopUpTransition from '../UI/animations/transition/PopUptransition'
import LabeledDataBlock from '../UI/dataBlock/LabeledDataBlock'
import { removeTokenFromStorage } from '../../utils/manageTokens'

const UserPopUpInfo = React.memo(props => {
  const history = useHistory()
  const {
    closePopUp,
    openPasswordModal,
    phone,
    popupRef,
    description,
  } = props
  const handleKeyDown = useCallback(({ keyCode }) => keyCode === 27 && closePopUp(), [closePopUp])
  const showChangeConfirmPassword = () => {
    closePopUp()
    openPasswordModal()
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => { document.removeEventListener('keydown', handleKeyDown) }
  }, [handleKeyDown])

  const logOut = () => removeTokenFromStorage(() => history.push('/auth'))
  return (
    <>
      <PopUpTransition loading>
        <div className='user_info_pop_up' ref={popupRef} onKeyDown={handleKeyDown}>
          <div className='user_info_pop_up__triangle' />
          <LabeledDataBlock title='Телефон' data={phone} />
          <LabeledDataBlock title='Должность' data={description} />
          <div className='flex_container flex_container__center'>
            <Button
              styles='simple-btn'
              title='Сменить пароль'
              onClick={showChangeConfirmPassword}
            />
          </div>
          <Button
            title='Выйти'
            icon='icon__logout'
            styles='bordered_btn'
            style={{ margin: '0 auto', marginTop: '10px' }}
            onClick={logOut}
          />
        </div>
      </PopUpTransition>
    </>
  )
})

export default UserPopUpInfo
