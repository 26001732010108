import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { withFormik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Button from 'antd/lib/button'
import Select from 'antd/lib/select'
import Gallery from 'react-dynamic-image-gallery'

import Modal from '../../../../../UI/modals/Modal'
import SimpleInput from '../../../../../UI/input/SimpleInput'
import ValidationMsg from '../../../../../UI/ValidationMsg'
import SimpleTextArea from '../../../../../UI/input/SimpleTextArea'
import TagsSelector from './TagsSelector'
import { onImageUpload } from '../../../uploadImage'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import SimpleSelect from '../../../../../UI/input/SimpleSelect'

import { officesAC } from '../../../../../../actions/actionCreator/offices'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Обязательное поле')
    .max(140, 'Название не более 140 символов'),
  description: Yup.string()
    .max(150, 'Описание не более 150 символов'),
  room: Yup.string()
    .nullable(),
})

const InnerForm = React.memo(({
  start,
  onClose,
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  isNew,
  roomId,
  table,
  tags,
  isRoomUnified,
}) => {
  const rooms = useSelector(({ offices }) => offices.rooms)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)

  const dispatch = useDispatch()

  const onlyBookableRooms = rooms.length
    ? rooms?.filter(r => r.is_bookable && !r.room_type_unified)
    : null

  const getErrorCondition = field => errors[field] && touched[field]

  const isFormValid = ValidationSchema.isValidSync(values)

  const recalculateSelectedCounts = () => dispatch(officesAC.recalculateSelectedCounts())

  const handleSubmit = () => {
    setIsSubmitDisabled(true)

    const newTable = {
      title: values.title.trim().replace(/ +(?= )/g, ''),
      description: values.description,
      tags: values.tags.length ? values.tags.map(tag => tag.id) : [],
      room: isNew ? roomId : values.room,
      images: values.images.map(img => img.id),
    }

    isNew
      ? dispatch(officesAC.postTable({
        table: newTable,
        closeTableModal: onClose,
        recalculateSelectedCounts,
      }))
      : dispatch(officesAC.putTable({
        table: { ...newTable, id: table.id },
        oldRoomId: roomId,
        closeTableModal: onClose,
        recalculateSelectedCounts,
      }))

    setIsSubmitDisabled(false)
  }

  const setImages = images => {
    const fulFilledImages = images.map(({ status, value }) => status === 'fulfilled' && value)

    const nextImagesArr = values.images.length
      ? [...values.images, ...fulFilledImages]
      : fulFilledImages

    setFieldValue('images', nextImagesArr)
  }

  const handleImageUpload = e => onImageUpload(e, setImages)

  const onImageDelete = deletedImage =>
    setFieldValue('images', values.images.filter(img => img.id !== deletedImage.id))

  const getFormTitle = () => isNew ? 'ДОБАВИТЬ РАБОЧЕЕ МЕСТО' : 'РЕДАКТИРОВАТЬ РАБОЧЕЕ МЕСТО'

  return (
    <Modal extraClassName='table-form-modal' start={start} onClose={onClose}>
      <h3 className='table-form-modal__title'>{getFormTitle()}</h3>
      <ScrollTransition style={{ height: '100%' }} loading>
        <div className='table-form-modal__form-wrapper'>
          <span className='table-form-modal__form-title'>
            Заполните данные о рабочем месте
          </span>
          <SimpleInput
            name='title'
            label='Номер стола'
            value={values.title}
            placeholder='Введите номер стола'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('title')}
          >
            <ErrorMessage component={ValidationMsg} name='title' />
          </SimpleInput>
          <SimpleTextArea
            name='description'
            label='Описание'
            value={values.description}
            placeholder='Введите описание'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('description')}
            rows={3}
          >
            <ErrorMessage component={ValidationMsg} name='description' />
          </SimpleTextArea>
          {!(isNew || isRoomUnified) && (
            <SimpleSelect
              setFieldTouched={setFieldTouched}
              name='room'
              label='Помещение'
              placeholder='Выберите Помещени'
              onChange={setFieldValue}
              disabled={onlyBookableRooms === null}
              value={onlyBookableRooms?.length ? values.room : 'Загрузка...'}
            >
              {rooms?.map(room => (
                <Select.Option
                  disabled={!room.is_bookable || room.room_type_unified}
                  key={room.id}
                  value={room.id}
                >
                  {room.title}
                </Select.Option>
              ))}
            </SimpleSelect>
          )}
          <TagsSelector
            inputProps={{ setFieldTouched }}
            name='tags'
            selectedTags={values.tags}
            setFieldValue={setFieldValue}
            tags={tags}
          />
          <span className='table-form-modal__form-title'>
            Добавьте фото рабочего места
          </span>
          <Gallery
            source={values.images}
            itemsToShow={values.images.length}
            itemsToShowInPreview={5}
            uploadEnabled
            isItemDeleteEnable
            onUpload={handleImageUpload}
            onItemDelete={onImageDelete}
          />
        </div>
      </ScrollTransition>
      <div className='table-form-modal-actions'>
        <Button type='text' onClick={onClose}>Отмена</Button>
        <Button
          disabled={isSubmitDisabled || !isFormValid}
          type='primary'
          onClick={handleSubmit}
        >
          {isNew ? 'Сохранить и добавить' : 'Сохранить изменения'}
        </Button>
      </div>
    </Modal>
  )
})

const mapPropsToValues = ({ roomId, isNew, table = {} }) => ({
  title: table.title || '',
  description: table.description || '',
  tags: table.tags || [],
  images: table.images || [],
  room: isNew ? roomId : table.room,
})

const TableFormModal = withFormik({
  mapPropsToValues,
  validationSchema: ValidationSchema,
})(InnerForm)

export default withRouter(TableFormModal)
