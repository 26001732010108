import React from 'react'

const UserPickItem = React.memo(props => {
  const { user, containerType, onClick } = props
  const getIcon = () => containerType === 'selected' ? 'icon__delete' : 'icon__plus__green'

  return (
    <div className='card_list__item__user__hover cursor_pointer' key={user?.id} onClick={onClick}>
      <span className='card_list__item__group_title' style={{ marginLeft: '10px' }}>
        {user?.phone_number || <span className='secondary_text__inactive'>Не указано</span>}
      </span>
      <span className='card_list__item_description' style={{ flex: 1.3 }}>
        {user?.description
          ? <span className='secondary_text'>{user?.description}</span>
          : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
      </span>
      <div className='icon__wrapper__hover_enabled'><div className={getIcon()} /></div>
    </div>
  )
})

export default UserPickItem
