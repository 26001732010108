import React from 'react'
import * as Yup from 'yup'
import Gallery from 'react-dynamic-image-gallery'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { ErrorMessage, Form as FormikForm, withFormik } from 'formik'
import Select from 'antd/lib/select'
import { useHistory } from 'react-router-dom'

import SimpleInput from '../../../../UI/input/SimpleInput'
import SimpleSelect from '../../../../UI/input/SimpleSelect'
import TimeRangePicker from '../../../../UI/input/TimeRangePicker'
import UnderlinedTitle from '../../../../UI/UnderlinedTitle'
import ValidationMsg from '../../../../UI/ValidationMsg'
import LargeSpin from '../../../../UI/Spin/LargeSpin'
import { formFormData } from '../../../../Map/helpers/loadMap'
import { filterFilesBySizeInBits } from '../../../../../utils/filesValidator'
import { officesAC } from '../../../../../actions/actionCreator/offices'
import { emailRegExp } from '../../../../../utils/data/regExp'

const DEFAULT_FORM_VALUES = {
  title: '',
  description: '',
  service_email: '',
  working_hours: '07:00-21:00',
}

const ValidationSchema = Yup.object().shape({
  license: Yup.string()
    .nullable()
    .required('Обязательное поле'),
  title: Yup.string()
    .required('Обязательное поле')
    .min(6, 'Название не менее 6 символов')
    .max(120, 'Название не более 120 символов'),
  service_email: Yup.string()
    .nullable()
    .matches(emailRegExp, 'Введите адрес сервисной электронной почты')
    .max(120, 'Максимум - 120 символов')
    .required('Обязательное поле'),
  working_hours: Yup.string()
    .required('Обязательное поле'),
  description: Yup.string()
    .min(3, 'Адрес не менее 3 символов')
    .max(150, 'Максимум - 150 символов')
    .required('Обязательное поле'),
})

const getLicenseStringToShow = (license = {}) => {
  const { forever, expires_at, tables_infinite, tables_available, id } = license
  const expireAt = forever ? 'Постоянная' : `до ${moment(expires_at).format('DD.MM.YY')}`
  const capacity = tables_infinite ? 'Без ограничений' : `${tables_available} мест`

  return `${expireAt} / ${capacity} / № ${id}`
}

const InnerForm = React.memo(props => {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    office,
    isNew,
    isSubmitting,
    setSubmitting,
    setTouched,
  } = props

  const history = useHistory()
  const dispatch = useDispatch()

  const isOfficeLoading = useSelector(({ app }) => app.isOfficeLoading)
  const isLicensesLoading = useSelector(({ app }) => app.isLicensesLoading)
  const licenses = useSelector(({ offices }) => offices.licenses)

  const getErrorCondition = field => errors[field] && touched[field]

  const redirectToOfficeScreen = id => history.push(`/offices/${id}/edit-main-info`, { from: '/offices' })

  const packOfficeData = () => {
    const updatedOffice = {
      ...values,
      license: values.license?.id,
      images: values.images?.map(image => image.id) || undefined,
    }

    return updatedOffice
  }

  const handleSave = e => {
    e.preventDefault()
    setTouched({
      license: true,
      title: true,
      description: true,
      service_email: true,
      working_hours: true,
    })

    if (isSubmitting) return
    if (!ValidationSchema.isValidSync(values)) {
      setSubmitting(false)
      return
    }

    setSubmitting(true)

    const officeData = packOfficeData()

    isNew
      ? dispatch(officesAC.postOffice({ officeData, callback: redirectToOfficeScreen }))
      : dispatch(officesAC.putOffice({ officeData, officeId: office.id }))

    setSubmitting(false)
  }

  const onSucceedImageUploadCallback = images => {
    const newImagesValue = values.images?.length ? [...images, ...values.images] : images
    setFieldValue('images', newImagesValue)
  }

  const onImageUpload = ({ target = {} }) => {
    const files = Array.from(target.files)

    if (!files.length) return
    const filesUnderMaxSize = files.filter(filterFilesBySizeInBits)

    const formattedImages = filesUnderMaxSize.map(formFormData)

    dispatch(officesAC.postOfficeImages({
      images: formattedImages,
      callback: images => isNew && onSucceedImageUploadCallback(images),
    }))
  }

  const onImageDelete = deletedImage => {
    setFieldValue('images', values.images.filter(img => img.id !== deletedImage.id))
  }

  if (isOfficeLoading || isLicensesLoading) {
    return <LargeSpin />
  }

  return (
    <div className='flex_container__column' style={{ paddingRight: '10px', flex: 1 }}>
      <UnderlinedTitle title='ИНФОРМАЦИЯ О БЦ' />
      <span style={{ margin: '4px 0 10px' }}>Добавьте общую информацию о бизнес центре</span>
      <FormikForm
        style={{ flex: 1 }}
        className='flex_container__column'
        onSubmit={handleSave}
      >
        <SimpleSelect
          name='license'
          label='Лицензия'
          placeholder='Выберите номер лицензии'
          onChange={setFieldValue}
          value={values.license ? getLicenseStringToShow(values.license) : undefined}
          disabled={!isNew}
          setFieldTouched={setFieldTouched}
          error={getErrorCondition('license')}
          errorComponent={() => <ErrorMessage component={ValidationMsg} name='license' />}
        >
          {licenses.length && licenses.map(l =>
            <Select.Option value={l} key={l.id}>{getLicenseStringToShow(l)}</Select.Option>)}
        </SimpleSelect>
        <SimpleInput
          name='title'
          label='Название'
          value={values.title}
          placeholder='Введите название бизнес центра'
          onChange={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={getErrorCondition('title')}
        >
          <ErrorMessage component={ValidationMsg} name='title' />
        </SimpleInput>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
          <div style={{ marginRight: '20px', flex: 1.4 }}>
            <SimpleInput
              name='description'
              label='Адрес'
              value={values.description}
              placeholder='Введите адрес'
              onChange={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getErrorCondition('description')}
            >
              <ErrorMessage component={ValidationMsg} name='description' />
            </SimpleInput>
          </div>
          <div style={{ flex: 1 }}>
            <SimpleInput
              name='service_email'
              label='Сервисный email'
              value={values.service_email}
              style={{ flex: 1 }}
              placeholder='Введите адрес сервисной электронной почты'
              onChange={setFieldValue}
              setFieldTouched={setFieldTouched}
              error={getErrorCondition('service_email')}
            >
              <ErrorMessage component={ValidationMsg} name='service_email' />
            </SimpleInput>
          </div>
        </div>
        <TimeRangePicker
          label='Часы работы'
          value={values.working_hours || '00:00 - 00:00'}
          onChange={setFieldValue}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          name='working_hours'
        />
        <span className='secondary_text__white' style={{ margin: '6px 0 12px' }}>
          Добавьте фото бизнес-центра
        </span>
        <Gallery
          source={values.images || []}
          itemsToShow={17}
          itemsToShowInPreview={5}
          onUpload={onImageUpload}
          uploadEnabled
          isItemDeleteEnable
          onItemDelete={onImageDelete}
        />
        <div className='button_container__content_right' style={{ alignSelf: 'flex-end' }}>
          <button
            type='submit'
            className='rounded-btn_blue'
            onClick={handleSave}
          >
            Сохранить
          </button>
        </div>
      </FormikForm>
    </div>
  )
})

const GeneralInfo = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ isNew, office }) => {
    if (isNew) return DEFAULT_FORM_VALUES

    const { license, title, description, service_email, working_hours, images } = office

    return { license, title, description, service_email, working_hours, images }
  },
  validationSchema: ValidationSchema,
})(InnerForm)

export default GeneralInfo
