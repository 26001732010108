import { GET_ACCOUNT_SUCCEED, SET_TOKEN, SET_TOKEN_LOADING } from '../actions/account'
import setTokenToStorage from '../utils/manageTokens'

const initialAccount = {
  token: { access_token: localStorage.getItem('access_token') },
  user: {
    firstName: 'Не указано',
    middleName: 'Не указано',
    lastName: 'Не указано',
    email: 'Не указан',
    description: 'Не указано',
    phoneNumber: 'Не указан',
    photo: {
      id: '',
      path: '',
    },
  },

}

export const accountReducer = (state = initialAccount, action) => {
  switch (action.type) {
    case SET_TOKEN_LOADING:
      return { ...state, loading: true }

    case SET_TOKEN: {
      setTokenToStorage(action.token)
      return { ...state, token: action.token, loading: false }
    }
    case GET_ACCOUNT_SUCCEED: {
      const { account } = action
      const { firstname, middlename, lastname, email, description, phone_number, photo } = account

      return {
        ...state,
        account: account,
        user: {
          ...state.user,
          firstName: firstname,
          middleName: middlename,
          lastName: lastname,
          email: email,
          description: description,
          phoneNumber: phone_number,
          photo: photo,
        },
      }
    }

    default:
      return state
  }
}

export default accountReducer
