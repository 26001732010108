import React, { useRef } from 'react'
import Button from './Button'

const UploadButton = React.memo(({ handleChange, btnIcon, btnClassName, btnTitle, acceptFiles }) => {
  const upload = useRef()
  return (
    <>
      <input
        accept={acceptFiles}
        type='file'
        ref={upload}
        className='input__file_uploader'
        onChange={handleChange}
        onClick={e => e.target.value = null}
      />
      <Button
        icon={btnIcon}
        styles={btnClassName}
        title={btnTitle}
        onClick={() => upload.current.click()}
      />
    </>
  )
})

export default UploadButton
