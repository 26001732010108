import React from 'react'
import Menu from 'antd/lib/menu'
import Icon from 'antd/lib/icon'
import Room from './Room'

const { SubMenu } = Menu

export const menuKeyByRoomType = roomType => {
  switch (true) {
    case roomType === 'Рабочее место': return 'Рабочее место'
    case roomType === 'Переговорная': return 'Переговорная'
    default: return 'Прочее'
  }
}

export const getUniqueValue = (arr1, arr2) => {
  const smallerArr = arr1.length <= arr2.length ? arr1 : arr2
  const biggerArr = arr1.length >= arr2.length ? arr1 : arr2
  return biggerArr.filter(el => !smallerArr.includes(el))[0]
}

const RoomGroup = React.memo(({ rooms, title, defaultProps, ...other }) => {
  const { openKeys } = defaultProps
  const isExtanded = openKeys.includes(menuKeyByRoomType(rooms[0]?.type))

  const renderRoomTypeTitle = () => (
    <div className='room-type-group-title-wrap'>
      <span className='room-type-group-title'>{title}</span>
      {isExtanded
        ? <Icon type='caret-up' />
        : <Icon type='caret-down' />}
    </div>
  )

  const newOther = { ...other, eventKey: menuKeyByRoomType(rooms[0]?.type) }
  return (
    <SubMenu {...newOther} title={renderRoomTypeTitle()}>
      {rooms.map(room => (
        <Room
          key={`roomId_${room.id}`}
          room={room}
          defaultProps={defaultProps}
        />
      ))}
    </SubMenu>
  )
})

export default RoomGroup
