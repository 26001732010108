import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ListLoadingSpin from '../../UI/Spin/ListLoadingSpin'
import SelectorsInnerComponent from './SelectorsInnerComponent/SelectorsInnerComponent'
import { getOfficesList } from '../../../reducers/offices-selectors'
import { getAppLoading } from '../../../reducers/app-selectors'
import { officesAC } from '../../../actions/actionCreator/offices'

const Selectors = () => {
  const offices = useSelector(getOfficesList)
  const loading = useSelector(getAppLoading)
  const dispatch = useDispatch()

  const isOfficesEmpty = !offices.length

  useEffect(() => {
    isOfficesEmpty && dispatch(officesAC.getOffices({}))
  }, [])

  if (loading || isOfficesEmpty) return <ListLoadingSpin />

  return <SelectorsInnerComponent offices={offices} />
}

export default Selectors
