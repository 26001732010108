import React from 'react'
import { useHistory } from 'react-router-dom'

const ErrorPage = React.memo(({ error }) => {
  const history = useHistory()
  return (
    <div className='flex_container__center'>
      <div className='h1__error_page'>{error.title}</div>
      <div className='main_text'>{error.text}</div>
      <div onClick={() => history.push('/')} className='main_text__link'>На главную</div>
    </div>
  )
})

export default ErrorPage
