/* eslint-disable no-console */
import { call, takeEvery } from 'redux-saga/effects'
import notification from 'antd/lib/notification'

import getResponse from './getResponse'
import isNoMessage from '../utils/isNoMessage'
import { POST_PUSH_NOTIFICATION } from '../actions/notifications'
import { POST } from '../utils/methods'
import notificationBasicSetups from '../utils/data/notificationConfig'
import { content } from '../utils/data/requestHeaders'

const generateDescription = accountsCount => {
  const strings = [
    `Уведомление получит ${accountsCount} пользователь`,
    `Уведомления получит ${accountsCount} пользователь`,
    `Уведомления получат ${accountsCount} пользователя`,
    `Уведомления получат ${accountsCount} пользователей`,
  ]

  if (accountsCount === 1) return strings[0]

  const num1 = Math.abs(accountsCount) % 100
  const num2 = num1 % 10

  if (num1 > 10 && num1 < 20) return strings[3]
  if (num2 > 1 && num2 < 5) return strings[2]
  if (num2 === 1) return strings[1]

  return strings[3]
}

const generateMessage = accountsCount => {
  const strings = [
    'Уведомление отправлено',
    'Уведомления отправлены',
  ]

  return accountsCount > 1 ? strings[1] : strings[0]
}

function* postNotification({ payload, callback, errorCallback }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/admin/push/send_broadcast',
      headers: content,
      body: payload,
    }))
    if (isNoMessage(response)) {
      notification.success({
        ...notificationBasicSetups,
        className: 'notification_antd___succeed',
        message: generateMessage(payload.accounts.length),
        description: generateDescription(payload.accounts.length),
      })
      yield callback && callback()
    }
  } catch (e) {
    console.log(e.message)
    errorCallback()
    notification.error({
      ...notificationBasicSetups,
      className: 'notification_antd',
      message: 'Ошибка отправки',
      description: 'Не удалось отправить PUSH-уведомление',
    })
  }
}

export default function* notificationsSagas() {
  yield takeEvery(POST_PUSH_NOTIFICATION, postNotification)
}
