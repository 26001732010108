import React from 'react'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import FadeTransition from '../../UI/animations/transition/FadeTransition'
import DisabledInputBlock from '../../UI/dataBlock/DisabledInputBlock'

const UserInfo = React.memo(({ isFormShow, user, FormBlock }) => (
  <div className='container_form__left' style={{ border: 0, paddingRight: '20px', flex: 1, minWidth: 'auto' }}>
    <ScrollTransition loading>
      <FadeTransition loading style={{ flex: 1 }}>
        <div className='container__booking__user_info'>
          <DisabledInputBlock
            title='Описание:'
            style={{ padding: '6px 0', marginBottom: '30px' }}
          >
            <div className='main_text__subtitle'>{user.description || 'Не указано'}</div>
          </DisabledInputBlock>
          {/* Test version  */}
          {/* <DisabledInputBlock title='E-mail:' style={{ padding: '6px 0 30px 0' }}> */}
          {/*  <div className='main_text__subtitle'>{user.email || 'Не указано'}</div> */}
          {/* </DisabledInputBlock> */}
          {isFormShow && FormBlock()}
        </div>
      </FadeTransition>
    </ScrollTransition>
  </div>
))

export default UserInfo
