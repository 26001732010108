import React from 'react'

const importFromCsvSource = [
  {
    form: 'tags',
    title: 'Импортировать из файла',
    description: 'Пример форматирования файла',
    example: <>Example 1<br />Example 2<br />Example 3<br />Example 4<br /></>,
    onSubmit: data => console.log(data),
  },
  {
    form: 'floors',
    title: 'Импортировать из файла',
    description: 'Только этажи',
    example: <>Example 1<br />Example 2<br />Example 3<br />Example 4<br /></>,
    onSubmit: data => console.log(data),
  },
  {
    form: 'zones',
    title: 'Импортировать из файла',
    description: 'Пример форматирования файла',
    example: <>Example 1<br />Example 2<br />Example 3<br />Example 4<br /></>,
    onSubmit: data => console.log(data),
  },
  {
    form: 'room-types',
    title: 'Импортировать из файла',
    description: 'Только типы помещений',
    example: <>Example 1<br />Example 2<br />Example 3<br />Example 4<br /></>,
    onSubmit: data => console.log(data),
  },
]

export default importFromCsvSource
