import React from 'react'
import { connect } from 'react-redux'
import { Field as FormikField } from 'formik'
import Form from 'antd/lib/form'
import { getAvatarStyle } from '../../../utils/getStyle/getImageStyle'
import defaultAvatar from '../../../assets/images/default_avatar.jpg'
import { POST_PHOTO, DELETE_PHOTO, CHANGE_USER_PHOTO_SUCCEED } from '../../../actions/files'

const DEFAULT_PHOTO = { id: null, path: defaultAvatar }

class SimpleImageUploader extends React.Component {
  state = { isFileLoading: false }

  setFileLoading = val => this.setState({ isFileLoading: val })

  formFileObj = file => {
    const data = new FormData()
    data.append('file', file)
    data.append('title', file.name)
    data.append('max_width', '500')
    data.append('max_height', '500')
    return data
  }

  onUploadChange = e => {
    const { postPhoto, isNew, userId } = this.props
    const file = e.target.files[0]
    if (!file) return

    this.setFileLoading(true)
    const data = this.formFileObj(file)

    postPhoto(data, isNew, userId, () => this.setFileLoading(false))
  }

  render() {
    const {
      deletePhoto,
      userPhoto,
      isNew,
      label,
      updatePhoto,
      userId,
      disable,
      wrapperStyle,
    } = this.props

    const { isFileLoading } = this.state
    const uploaderStyle = `input__upload_container ${isFileLoading ? 'loading_animation' : ''}`

    return (
      <Form.Item label={label} style={wrapperStyle}>
        <FormikField
          render={() => (
            <>
              <input
                type='file'
                ref={ref => this.upload = ref}
                accept='.png, .jpg, .jpeg .svg'
                className='input__file_uploader'
                onChange={this.onUploadChange}
                onClick={e => e.target.value = null}
              />
              <div
                className={uploaderStyle}
                style={{ minWidth: '100%', opacity: disable && 0.5 }}
              >
                <span className='input__upload_placeholder'>
                  Загрузить фото
                </span>
                <div
                  className={`input__upload_button${disable ? '_disabled' : ''}`}
                  onClick={() => !disable && this.upload.click()}
                >
                  Выбрать
                </div>
              </div>
              {userPhoto?.id && (
                <div className='photo_preview' style={getAvatarStyle(userPhoto)}>
                  <div
                    className='icon__delete'
                    onClick={() => isNew ? deletePhoto(isNew) : updatePhoto(userId)}
                  />
                </div>
              )}
            </>
          )}
        />
      </Form.Item>
    )
  }
}

const mapStateToProps = ({ files }, { isNew, photo, userId }) => {
  const getUserPhoto = () => {
    if (isNew) return files?.photo
    const fileInCurrentFiles = files?.currentFilesList?.find(file => file.userId === userId)
    return fileInCurrentFiles || photo
  }

  return ({ userPhoto: getUserPhoto(), loading: files?.loading || false })
}

const mapDispatchToProps = dispatch => ({
  deletePhoto: (isNew, userId) => dispatch({ type: DELETE_PHOTO, isNew, userId }),
  updatePhoto: userId =>
    dispatch({ type: CHANGE_USER_PHOTO_SUCCEED, photo: DEFAULT_PHOTO, userId }),
  postPhoto: (file, isNew, userId, callback) =>
    dispatch({ type: POST_PHOTO, file, isNew, userId, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SimpleImageUploader)
