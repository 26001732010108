import React from 'react'
import Tooltip from 'antd/lib/tooltip'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import { getAvatarStyle } from '../../../utils/getStyle/getImageStyle'
import stopPropagation from '../../../utils/stopPropagation'
import IconEmail from '../../UI/icons/Email'
import IconBooking from '../../UI/icons/Booking'
import { usersAC } from '../../../actions/actionCreator/usersAC'

const UserCardListItem = React.memo(({
  userPhoto,
  user,
  index,
  onDelete,
  onItemClick,
  getItemActivityClass,
  onSendEmailClick,
  openGroupsInfoModal,
}) => {
  const { id, name, description, is_active: isActive } = user
  const history = useHistory()
  const getTooltipTitle = () => isActive
    ? 'Доступ к приложению открыт'
    : 'Доступ к приложению закрыт'

  const redirectToBookingPage = e => {
    e.stopPropagation()
    history.push(`/users/${user.id}/booking`)
  }
  const dispatch = useDispatch()
  return (
    <div
      className={getItemActivityClass()}
      onClick={e => stopPropagation(() => onItemClick(user), e)}
    >
      <Tooltip title={getTooltipTitle()}>
        <div className={`icon-circle-status_${isActive ? 'active' : 'inactive'}`} />
      </Tooltip>
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item_avatar'>
        <div className='card_list__avatar' style={getAvatarStyle(userPhoto)} />
      </span>
      <span className='card_list__item_user_name'>
        {user?.phone_number || <div className='main_text__inactive text__wrapper'>Не указано</div>}
      </span>
      <span className='card_list__item__user_description' style={{ border: 0 }}>
        {description
          ? <span className='secondary_text'>{description}</span>
          : <div className='secondary_text__inactive text__wrapper'>Без описания</div>}
      </span>
      <Tooltip title='Группы доступа' mouseEnterDelay={0.6}>
        <div
          className='icon__wrapper__hover_enabled full_height'
          onClick={e => {
            stopPropagation(openGroupsInfoModal, e)
            dispatch(usersAC.getUserAccessGroups({ id: user.id }))
          }}
        >
          <div className='icon__groups' style={{ marginRight: 0, backgroundSize: 'contain' }} />
        </div>
      </Tooltip>
      {onSendEmailClick && user.email && (
        <Tooltip title='Отправить сообщение' mouseEnterDelay={0.6}>
          <div
            className='icon__wrapper__hover_enabled full_height'
            onClick={e => stopPropagation(() => onSendEmailClick({ id, name }), e)}
          >
            <IconEmail />
          </div>
        </Tooltip>
      )}
      <Tooltip title='Бронирования' mouseEnterDelay={0.6}>
        <div
          className='icon__wrapper__hover_enabled full_height'
          onClick={redirectToBookingPage}
        >
          <IconBooking />
        </div>
      </Tooltip>
      {onDelete && (
        <Tooltip title='Удалить' mouseEnterDelay={0.6}>
          <div
            className='icon__wrapper__hover_enabled full_height'
            onClick={e => stopPropagation(() => onDelete({ id, name }), e)}
          >
            <div className='icon__delete' />
          </div>
        </Tooltip>
      )}
    </div>
  )
})
export default UserCardListItem
