/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
import React, { useState, useContext } from 'react'
import Tooltip from 'antd/lib/tooltip'

import InfoIcon from '../UI/icons/InfoBig'
import EditIcon from '../UI/icons/Edit'
import closeIcon from '../../assets/images/icons/big-close-icon.svg'
import DeleteIcon from '../UI/icons/Delete'
import ToolbarInfo from './ToolbarInfo'
import EditRoomModalContext from './editRoomModalContext'
import { SET_MARKER_ON_CLICK_ATTR } from './helpers/useReducerForMapComponent'

const MarkerClick = React.memo(props => {
  const {
    pointer,
    mapState,
    currentRadius,
    setConfirmModal,
    dispatchWithoutRedux,
    deleteMarker,
    deleteRoom,
  } = props
  const [infoTabVisibility, setInfoTabVisibility] = useState(false)

  const roomEditModalManipulation = useContext(EditRoomModalContext)

  const { x: xPercent, y: yPercent } = pointer

  const realMapSize = {
    width: mapState.image.width * mapState.scale,
    height: mapState.image.height * mapState.scale,
  }
  const top = realMapSize.height * yPercent / 100 + mapState.y
  const left = realMapSize.width * xPercent / 100 + mapState.x

  const deleteRoomHandler = () => deleteRoom({ roomId: pointer.roomId, dispatchWithoutRedux })

  const deleteMarkerHandler = () => {
    deleteMarker({ markerId: pointer.id, roomId: pointer.roomId, dispatchWithoutRedux })
  }

  const onPressDelete = () => {
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    setConfirmModal(prev => ({ ...prev, isVisible: true, type: 'removeRoom', title: pointer.title, callback: deleteRoomHandler }))
  }

  return (
    <>
      {infoTabVisibility
        ? (
          <ToolbarInfo
            marker={pointer}
            roomEditModalManipulation={roomEditModalManipulation}
            setInfoTabVisibility={setInfoTabVisibility}
            top={top}
            left={left}
            currentRadius={currentRadius}
            mapState={mapState}
          />
        )
        : (
          <div
            className={`marker__toolbar marker-click ${infoTabVisibility ? 'wide' : ''}`}
            style={{ top: top - currentRadius * mapState.scale, left: left, zIndex: 50 }}
          >
            <InfoIcon
              onClick={() => setInfoTabVisibility(true)}
              className='tooltip'
              tooltipText='Показать информацию'
            />
            <EditIcon
              className='tooltip'
              tooltipText='Редактировать информацию'
              width='20'
              height='20'
              color='#0079C1'
              onClick={() => {
                roomEditModalManipulation().set(pointer.roomId)
                dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
              }}
            />
            <Tooltip title='Убрать с карты'>
              <div className='marker-click__closeicon-wrap' onClick={deleteMarkerHandler}>
                <img src={closeIcon} alt='Убрать с карты' />
              </div>
            </Tooltip>
            <DeleteIcon
              className='tooltip'
              tooltipText='Удалить помещение'
              width='18'
              height='18'
              onClick={onPressDelete}
            />
          </div>
        )}
    </>
  )
})

export default MarkerClick
