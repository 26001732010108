import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import UserCardListItem from './UserCardListItem'
import EditUserForm from '../forms/User/EditUserForm'
import AnimatedHeight from '../../UI/animations/AnimatedHeight'
import SendEmailModal from '../modals/SendEmailModal'
import ConfirmationModal from '../../UI/modals/ConfirmationModal'
import { DELETE_USER } from '../../../actions/users'
import GroupsInfoModal from './GroupsInfoModal/GroupsInfoModal'

const UserCard = React.memo(({ user, index, onEmailConfirm }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false)
  const [isGroupsInfoModalVisible, setIsGroupsInfoModalVisible] = useState(false)

  const dispatch = useDispatch()

  const deleteUser = useCallback(() => {
    dispatch({
      type: DELETE_USER,
      userId: user.id,
    })
    setIsDeleteModalVisible(false)
  }, [isDeleteModalVisible])

  const getItemActivityClass = useCallback(() => {
    const defaultClass = 'card_list__item'
    return isOpen ? `${defaultClass}__active` : defaultClass
  }, [isOpen])
  return (
    <>
      <UserCardListItem
        user={user}
        userPhoto={user.photo}
        index={index}
        onDelete={() => setIsDeleteModalVisible(true)}
        onItemClick={() => setIsOpen(!isOpen)}
        onSendEmailClick={() => setIsEmailModalVisible(true)}
        getItemActivityClass={getItemActivityClass}
        openGroupsInfoModal={() => setIsGroupsInfoModalVisible(true)}
      />
      <AnimatedHeight condition={isOpen}>
        <EditUserForm
          user={user}
          onCancel={() => setIsOpen(false)}
          onEmailConfirm={onEmailConfirm}
        />
      </AnimatedHeight>

      <SendEmailModal
        user={user}
        start={isEmailModalVisible}
        onClose={() => setIsEmailModalVisible(false)}
      />
      <GroupsInfoModal
        start={isGroupsInfoModalVisible}
        onClose={() => setIsGroupsInfoModalVisible(false)}
      />
      <ConfirmationModal
        start={isDeleteModalVisible}
        text='Удалить пользователя?'
        onConfirm={deleteUser}
        onClose={() => setIsDeleteModalVisible(false)}
      />
    </>
  )
})

export default UserCard
