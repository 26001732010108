/* ------------ USERS ------------ */
/* ========================================================================================== */
export const GET_USERS = 'GET_USERS'
export const GET_USERS_THROTTLE = 'GET_USERS_THROTTLE'
export const GET_USERS_SUCCEED = 'GET_USERS_SUCCEED'


export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_SUCCEED = 'CREATE_USER_SUCCEED'

export const PUT_USER = 'PUT_USER'
export const PUT_USER_SUCCEED = 'PUT_USER_SUCCEED'

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_SUCCEED = 'DELETE_USER_SUCCEED'

export const DELETE_USERS_FROM_NEW_GROUP_SUCCEED = 'DELETE_USERS_FROM_NEW_GROUP_SUCCEED'
export const SET_USERS_TO_CREATE_GROUP_SUCCEED = 'SET_USERS_TO_CREATE_GROUP_SUCCEED'

export const CLEAN_USERS_SUCCEED = 'CLEAN_USERS_SUCCEED'
export const CLEAN_UP_FILTERED_USERS_SUCCEED = 'CLEAN_UP_FILTERED_USERS_SUCCEED'

export const CREATE_OPERATOR = 'CREATE_OPERATOR'
export const CREATE_OPERATOR_SUCCEED = 'CREATE_OPERATOR_SUCCEED'
export const RESET_PASSWORD = 'RESET_PASSWORD'

export const PROMOTE_OPERATOR = 'PROMOTE_OPERATOR'
export const PROMOTE_OPERATOR_SUCCEED = 'PROMOTE_OPERATOR_SUCCEED'
/* ------------ GROUPS ------------ */
/* ========================================================================================== */
export const GET_ACCESS_GROUPS = 'GET_ACCESS_GROUPS'
export const GET_ACCESS_GROUPS_SUCCEED = 'GET_ACCESS_GROUPS_SUCCEED'

export const GET_ACCESS_GROUP = 'GET_ACCESS_GROUP'
export const GET_GROUP_SUCCEED = 'GET_GROUP_SUCCEED'

export const GET_GROUP_ACCESS = 'GET_GROUP_ACCESS'
export const GET_GROUP_ACCESS_SUCCEED = 'GET_GROUP_ACCESS_SUCCEED'

export const GET_USER_ACCESS_GROUPS = 'GET_USER_ACCESS_GROUPS'
export const GET_USER_ACCESS_GROUPS_SUCCEED_ADMIN = 'GET_USER_ACCESS_GROUPS_SUCCEED_ADMIN'

export const CREATE_NEW_GROUP = 'CREATE_NEW_GROUP'

export const UPDATE_GROUP_DELETE_SUCCEED = 'UPDATE_GROUP_DELETE_SUCCEED'
export const UPDATE_GROUP_ADD_SUCCEED = 'UPDATE_GROUP_ADD_SUCCEED'
export const UPDATE_GROUP = 'UPDATE_GROUP'

export const DELETE_GROUP = 'DELETE_GROUP'
export const DELETE_GROUP_SUCCEED = 'DELETE_GROUP_SUCCEED'

export const CLEAR_GROUP_SUCCEED = 'CLEAR_GROUP_SUCCEED'
export const SET_IS_NEW_FLAG_SUCCEED = 'SET_IS_NEW_FLAG_SUCCEED'
/* ------------ GROUPS ------------ */
/* ========================================================================================== */
export const GET_KIOSKS = 'GET_KIOSKS'
export const GET_KIOSKS_SUCCEED = 'GET_KIOSKS_SUCCEED'

export const CREATE_KIOSK = 'CREATE_KIOSK'
export const CREATE_KIOSK_SUCCEED = 'CREATE_KIOSK_SUCCEED'

export const PUT_KIOSK = 'PUT_KIOSK'
export const PUT_KIOSK_SUCCEED = 'PUT_KIOSK_SUCCEED'

export const DELETE_KIOSK = 'DELETE_KIOSK'
export const DELETE_KIOSK_SUCCEED = 'DELETE_KIOSK_SUCCEED'
/* ------------ EMAIL ------------ */
/* ========================================================================================== */
export const CONFIRM_EMAIL = 'CONFIRM_EMAIL'
export const CONFIRM_EMAIL_SUCCEED = 'CONFIRM_EMAIL_SUCCEED'
export const SEND_EMAIL = 'SEND_EMAIL'
/* ------------ IMPORTS ------------ */
/* ========================================================================================== */
export const IMPORT_USERS_IN_GROUP = 'IMPORT_USERS_IN_GROUP'
export const IMPORT_GROUPS = 'IMPORT_GROUPS'
