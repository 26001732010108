import {
  SET_BOOKING_LOADING,
  GET_BOOKINGS_SUCCEED,
  POST_BOOKING_SUCCEED,
  DELETE_BOOKING_SUCCEED, GET_USER_BOOKING_HISTORY_SUCCEED,
} from '../actions/booking'
import { sortListByHourRevert } from '../utils/sort'

const initialState = {
  loading: false,
  history: [],
  user: {},
  count: null,
  is_over: null,
  office_title: '',
  floor_title: '',
  room_title: '',
  table_title: '',
}
export const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOOKING_LOADING:
      return { ...state, loading: true }

    case GET_BOOKINGS_SUCCEED:
      return {
        ...state,
        history: sortListByHourRevert(action.payload, 'date_from'),
      }
    // все ок, не исправляем
    case POST_BOOKING_SUCCEED: {
      const newList = [...state.history, action.newBooking]
      return { ...state, history: sortListByHourRevert(newList, 'date_from') }
    }

    case DELETE_BOOKING_SUCCEED: {
      const newList = state.history.filter(b => b.id !== action.bookId)
      return { ...state, history: sortListByHourRevert(newList, 'date_from') }
    }

    case GET_USER_BOOKING_HISTORY_SUCCEED: {
      const newList = (action.page === 1)
        ? action.booking.results
        : [...state.booking.history, action.booking.results]
      return {
        ...state,
        loading: false,
        history: newList,
        user: action.booking.user,
        count: action.booking.count,
      }
    }

    default:
      return state
  }
}

export default bookingReducer
