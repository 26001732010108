import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import UserInGroupItem from './UserInGroupItem'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import Button from '../../UI/Button'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import ExpandingSearch from '../../UI/ExpandingSearch'
import LargeSpin from '../../UI/Spin/LargeSpin'
import { usersAC } from '../../../actions/actionCreator/usersAC'
import ListLoadingSpin from '../../UI/Spin/ListLoadingSpin'
import UploadButton from '../../UI/UploadButton'
import { groupAC } from '../../../actions/actionCreator/groups'

const UsersInGroupList = React.memo(({
  users,
  onButtonClick,
  shouldShowBtn,
  isKiosk,
  searchEnabled,
  groupId,
  loading,
  onListScroll,
  paginationLoading,
}) => {
  const [searchString, setSearchString] = useState('')
  const dispatch = useDispatch()
  const getUsers = ({ searchString }) => dispatch(usersAC.getUsers({
    group: groupId,
    searchString,
    page: 1,
  }))
  const renderUsersList = () => {
    if (!users?.length) return <EmptyPlaceholder text='В группе нет пользователей' />
    return (
      <>
        {users.map((u, index) => (
          <UserInGroupItem isKiosk={isKiosk} key={u.id} user={u} index={index} />))}
      </>
    )
  }
  const packFile = useCallback(file => {
    const data = new FormData()
    data.append('file', file)
    data.append('group', groupId)
    return data
  }, [])
  const handleCsvImport = useCallback(({ target }) => {
    const file = target.files[0]
    if (!file) return
    const data = packFile(file)
    dispatch(groupAC.importUsersInGroup({ file: data, groupId }))
  }, [])
  return (
    <div className='container__right_side'>
      {searchEnabled && (
        <div style={{ margin: '0 17px 20px 0' }}>
          <ExpandingSearch
            setSearchString={setSearchString}
            searchString={searchString}
            getItems={getUsers}
            placeholder='Начните искать пользователя'
            defaultExpanded
            large
          />
        </div>
      )}
      <ScrollTransition
        loading={!loading || !paginationLoading}
        style={{ marginBottom: '10px' }}
        onScroll={onListScroll && onListScroll}
      >
        <div className='card_list full_height' style={{ flex: 1 }}>
          {loading
            ? <LargeSpin styles={{ flex: 1 }} /> : renderUsersList()}
        </div>
        {paginationLoading && <ListLoadingSpin />}
      </ScrollTransition>
      {shouldShowBtn && (
        <div className='button_container__edit_card'>
          <UploadButton
            acceptFiles='.csv'
            btnIcon='icon__download_2'
            btnClassName='button__import_users_csv'
            btnTitle='Импортировать из .csv'
            handleChange={handleCsvImport}
          />
          <Button
            icon='icon__edit icon__wrapper'
            styles='bordered_btn'
            title='Редактировать'
            onClick={onButtonClick}
          />
        </div>
      )}
    </div>
  )
})

export default UsersInGroupList
