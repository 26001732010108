import React, { useState } from 'react'

import ExpandIcon from '../../../UI/icons/Expand'

const GroupListItem = React.memo(({ group }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div style={{ paddingRight: '10px' }}>
      <div
        className='user-groups-list__group-wrapper'
        onClick={() => setIsExpanded(val => !val)}
        style={{ border: isExpanded && '1px solid #0079C1' }}
      >
        <span className='user-groups-list__group-title'>{group.title}</span>
        <span className='user-groups-list__group-description'>{group.description}</span>
        <ExpandIcon isExpanded={isExpanded} color='#0079C1' />
      </div>
      {isExpanded && (
        <div className=''>
          {group?.offices?.map((office,index) => (
            <>
              <div className='user-groups-list__office-wrapper' key={index}>
                <span>{office.title}</span>
                <span>{office.description}</span>
              </div>
              <div className='user-groups-list__zones-wrapper'>
                {office?.zones?.map((zone, index) => (
                  <div className='user-groups-list__zone-wrapper'>
                    <span className='user-groups-list__zone-index'>{`${index + 1}.`}</span>
                    <span className='user-groups-list__zone-title'>{zone.title}</span>
                  </div>
                ))}
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  )
})

export default GroupListItem
