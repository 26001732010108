import React, { useCallback, useEffect, useState } from 'react'
import { withFormik } from 'formik'

import Icon from './Icon'
import SimpleInput from './input/SimpleInput'

const InnerForm = React.memo(({
  values,
  setFieldValue,
  getItems,
  setIsExpanded = () => {},
  isExpanded,
  resetForm,
  setSearchString,
  searchString,
  onClose,
  defaultExpanded,
  autofocus,
  placeholder,
  style,
  children,
  autocomplete,
  onFocus,
  idSuffix,
  containerRef,
  disabled,
}) => {
  const [debounce, setDebounce] = useState(null)
  const [isFocused, setIsFocused] = useState(false)

  const getStyle = () => {
    const basicStyle = 'search_field__large'
    const isAcrive = isExpanded || defaultExpanded
    return `${basicStyle}${isAcrive ? '__active' : ''} ${disabled ? 'disabled' : ''}`
  }

  const getBorderStyle = () => isFocused ? { border: '1px solid #0079C1' } : {}

  const handleSearch = str => {
    setSearchString(str)
    if (str.length <= 2 && values.name.length <= str.length) return null
    if (debounce) clearTimeout(debounce)
    setDebounce(setTimeout(() => getItems({ page: 1, searchString: str }), 600))
  }

  const handleChange = async (name, val) => {
    await handleSearch(val)
    await setFieldValue(name, val)
  }

  const closeSearchField = async () => {
    await setIsExpanded(false)
    await resetForm()
    await onClose()
  }

  const handleClose = () => (searchString === '' && values.name === '')
    ? setIsExpanded(false)
    : closeSearchField()

  const handleKeyDown = useCallback(e => e.keyCode === 27 && handleClose(), [setIsExpanded])

  const handleFocus = () => {
    setIsFocused(true)
    onFocus && onFocus()
  }

  useEffect(() => {
    if (!defaultExpanded) document.addEventListener('keydown', handleKeyDown, false)
    return () => document.removeEventListener('keydown', handleKeyDown, false)
  }, [handleKeyDown, defaultExpanded])

  return (
    <div
      className={getStyle()}
      ref={containerRef}
      onKeyDown={() => !defaultExpanded && handleKeyDown}
      style={{ ...style, ...getBorderStyle() }}
    >
      <div className='icon__search' onClick={() => setIsExpanded(!isExpanded)} />
      {isExpanded || defaultExpanded
        ? (
          <div className='flex_container full_width'>
            <SimpleInput
              id={`search-input${idSuffix || ''}`}
              name='name'
              onChange={handleChange}
              onFocus={handleFocus}
              placeholder={placeholder}
              setFieldTouched={() => setIsFocused(false)}
              style={{ width: '100%' }}
              value={searchString}
              autofocus={autofocus}
              autocomplete={autocomplete}
              disabled={disabled}
            />
            {!defaultExpanded && <Icon name='icon__close' onClick={handleClose} />}
          </div>
        )
        : <div className='search_field__label' onClick={() => setIsExpanded(true)}>Поиск</div>}
      {children}
    </div>
  )
})

const ExpandingSearch = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ name: '' }),
})(InnerForm)

export default ExpandingSearch
