import React from 'react'

const PlusRoundedThickIcon = props => {
  const { style } = props

  return (
    <svg
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path d='M13.5 7a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z' stroke='#fff' />
      <path fillRule='evenodd' clipRule='evenodd' d='M7 3a1 1 0 00-1 1v2H4a1 1 0 100 2h2v2a1 1 0 102 0V8h2a1 1 0 100-2H8V4a1 1 0 00-1-1z' fill='#fff' />
    </svg>
  )
}

export default PlusRoundedThickIcon
