const OWNER_TITLE = 'Владелец'
const ADMINISTRATOR_TITLE = 'Администратор'
const EMPLOYEE_TITLE = 'Сотрудник'
const VISITOR_TITLE = 'Посетитель'

const OWNER_ACCESS_CODE = 1
const ADMINISTRATOR_ACCESS_CODE = 2
const EMPLOYEE_ACCESS_CODE = 3
const VISITOR_ACCESS_CODE = 4

export const convertGroupAccessCodeToTitle = accessCode => {
  if (accessCode === OWNER_ACCESS_CODE) return OWNER_TITLE
  if (accessCode === ADMINISTRATOR_ACCESS_CODE) return ADMINISTRATOR_TITLE
  if (accessCode === EMPLOYEE_ACCESS_CODE) return EMPLOYEE_TITLE
  if (accessCode === VISITOR_ACCESS_CODE) return VISITOR_TITLE
}

export const convertGroupAccessTitleToAccessCode = accessTitle => {
  if (accessTitle === ADMINISTRATOR_TITLE) return ADMINISTRATOR_ACCESS_CODE
  if (accessTitle === VISITOR_TITLE) return VISITOR_ACCESS_CODE
}
