import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Redirect, Switch, useLocation } from 'react-router-dom'

import Auth from './components/Auth'
import ErrorPage from './components/ErrorsPage/index'
import ERRORS from './utils/data/errors'
import GroupEditCard from './components/UserGroups/EditCard'
import Header from './components/Header/Header'
import MainPage from './components/MainPage'
import OfficesRouter from './components/Office/OfficesRouter'
import UserCard from './components/Users/index'
import UsersGroupsRouter from './components/UserGroups/Router'
import { GET_ACCOUNT } from './actions/account'
import UserBooking from './components/Reservation/userScreen'
import Notifications from './components/Notifications'
import Statistics from './components/Statistics/Statistics'

const App = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { pathname } = location
  useEffect(() => {
    pathname !== '/auth' && dispatch({ type: GET_ACCOUNT })
  }, [])

  return (
    <div className='app_BG'>
      {pathname !== '/auth' && (
        <Header />
      )}
      <div className='screen__no_grid'>
        <Switch>
          <Route path='/' exact component={MainPage}>
            <Redirect to='/offices' />
          </Route>
          <Route
            exact
            path='/groups/new'
            render={props => <GroupEditCard {...props} isNew />}
          />
          <Route exact path='/auth' component={Auth} />
          <Route
            exact
            path='/404'
            render={props => <ErrorPage {...props} error={ERRORS['404']} />}
          />
          <Route
            exact
            path='/500'
            render={props => <ErrorPage {...props} error={ERRORS['500']} />}
          />
          <Route exact path='/users' render={() => <UserCard />} />
          <Route path='/users/:id/booking' render={() => <UserBooking />} />
          <Route exact path='/notifications' render={() => <Notifications />} />
          <Route path='/groups' render={() => <UsersGroupsRouter />} />
          <Route path='/offices' render={() => <OfficesRouter />} />
          <Route exact path='/statistics' render={() => <Statistics />} />
          <Redirect from='*' to='/404' />
        </Switch>
      </div>
    </div>

  )
}
export default App
