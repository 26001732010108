import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Checkbox from 'antd/lib/checkbox'
import MainCard from '../../../MainCard'
import Button from '../../../UI/Button'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'
import TableHeader from '../../../UI/TableHeader'
import RoomListItem from './RoomListItem'
import ScrollTransition from '../../../UI/animations/transition/ScrollTransition'
import RoomFormModal from './Modal/RoomFormModal'
import pluralizeString from '../../../../utils/pluralizeString'
import IconPrint from '../../../UI/icons/Print'
import QRPrintModal from './Modal/QRPrintModal/QRPrintModal'
import TableFormModal from './Modal/TableFormModal/TableFormModal'
import ConfirmationModal from '../../../UI/modals/ConfirmationModal'
import FilterInput from './FilterInput'

import { officesAC } from '../../../../actions/actionCreator/offices'

const COLUMNS_FLEX_VALUES = [0, 0.1, 1, 1, 1, 0.3, 0.3, 0.3, 0]
const COLUMNS_EXTRA_STYLE = [{ marginRight: '20px', minWidth: '16px', paddingTop: '2px' }]

const getTableHeaderColumns = checkboxProps =>
  [<Checkbox {...checkboxProps} />, '#', 'Название помещения', 'Зона', 'Тип помещения', 'Этаж', 'Бронь', 'Фото']

const DetailInfo = React.memo(() => {
  const params = useParams()
  const { current: officeId } = useRef(params.id)

  const [roomFormState, setRoomFormState] = useState({ visible: false })
  const [isQRPrintModalVisible, setIsQRPrintModalVisible] = useState(false)
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false)
  const [tableFormState, setTableFormState] = useState({ targetRoomId: '', visible: false })

  const isOfficeLoading = useSelector(({ app }) => app.isOfficeLoading)
  const isFloorsLoading = useSelector(({ app }) => app.isFloorsLoading)
  const isRoomsLoading = useSelector(({ app }) => app.isRoomsLoading)
  const isZonesLoading = useSelector(({ app }) => app.isZonesLoading)
  const isTagsLoading = useSelector(({ app }) => app.isTagsLoading)
  const isRoomTypesLoading = useSelector(({ app }) => app.isRoomTypesLoading)

  const officeTitle = useSelector(({ offices }) => offices.title)
  const floors = useSelector(({ offices }) => offices.floors)
  const rooms = useSelector(({ offices }) => offices.rooms)
  const zones = useSelector(({ offices }) => offices.zones)
  const tagsList = useSelector(({ offices }) => offices.tags)
  const roomTypes = useSelector(({ offices }) => offices.roomTypes)

  const selectedRoomsCount = useSelector(({ offices }) => offices.selectedRoomsCount)
  const selectedTablesCount = useSelector(({ offices }) => offices.selectedTablesCount)

  const dispatch = useDispatch()

  const isAllRoomsSelected = rooms.length > 0 && selectedRoomsCount === rooms.length

  const isAllDataLoading = isOfficeLoading
    || isFloorsLoading
    || isRoomsLoading
    || isZonesLoading
    || isTagsLoading
    || isRoomTypesLoading

  useEffect(() => {
    dispatch(officesAC.getOffice({ officeId }))
    dispatch(officesAC.getFloors({ officeId }))
    dispatch(officesAC.getRooms({ officeId, isDownloadingWithTables: true }))
    dispatch(officesAC.getZones({ officeId }))
    dispatch(officesAC.getTags({ officeId }))
    dispatch(officesAC.getRoomTypes({ officeId }))

    return () => dispatch(officesAC.clearOffice())
  }, [])

  const handleAddTable = roomId =>
    setTableFormState({ visible: !tableFormState.visible, targetRoomId: roomId })

  const closeTableFormModal = () =>
    setTableFormState({ ...tableFormState, visible: false })

  const toggleRoomForm = () =>
    setRoomFormState({ visible: !roomFormState.visible })

  const handleEditRoom = room =>
    setRoomFormState({ room, visible: !roomFormState.visible })

  const generateHandleRoomCheck = roomId => () => dispatch(officesAC.checkRoom({ roomId }))

  const getRoomsForQRPrintModal = () =>
    rooms.reduce((acc, cur) => {
      const { checked: isRoomChecked, tables: tablesInRoom } = cur
      if (isRoomChecked || tablesInRoom?.some(table => table.checked)) {
        const newRoom = {
          ...cur,
          tables: tablesInRoom.filter(table => table.checked),
        }
        return [...acc, newRoom]
      }
      return acc
    }, [])

  const recalculateSelectedCounts = () => dispatch(officesAC.recalculateSelectedCounts())

  const handleSelectAllCheckbox = ({ target }) => dispatch(officesAC.checkAllRooms({
    checked: target.checked,
  }))

  const generateHandleDeleteRoom = roomId => () => dispatch(officesAC.deleteRoom({
    roomId,
    recalculateSelectedCounts,
  }))

  const indeterminate = selectedRoomsCount > 0 && selectedRoomsCount < rooms.length

  const selectAllCheckboxProps = {
    checked: isAllRoomsSelected,
    onChange: handleSelectAllCheckbox,
    indeterminate,
  }

  const renderRoomsList = () => (
    <ScrollTransition loading>
      <div className='rooms-list'>
        {rooms?.map((room, index) => (
          <RoomListItem
            tags={tagsList}
            handleDeleteRoom={generateHandleDeleteRoom(room.id)}
            handleEditRoom={handleEditRoom}
            officeId={officeId}
            room={room}
            index={index}
            key={room.id}
            handleAddTable={handleAddTable}
            onCheck={generateHandleRoomCheck(room.id)}
            flexValues={COLUMNS_FLEX_VALUES}
          />
        ))}
      </div>
    </ScrollTransition>
  )

  const getTotalSelectedRoomsStr = () =>
    `${selectedRoomsCount} ${pluralizeString(selectedRoomsCount, ['помещение', 'помещения', 'помещений'])}`

  const getTotalSelectedTablesStr = () =>
    `${selectedTablesCount} ${pluralizeString(selectedTablesCount, ['стол', 'стола', 'столов'])}`

  const getTotalSelectedStr = () =>
    `Выбрано: ${getTotalSelectedRoomsStr()} и ${getTotalSelectedTablesStr()}`

  const calculateSelectedCount = items => items
    .reduce((acc, item) => item.checked ? [...acc, item.id] : acc, [])

  const calculateSelectedTables = rooms => {
    const selectedTableIds = rooms.reduce((acc, room) => {
      if (!room.checked && room.tables.some(table => table.checked)) {
        return [...acc, ...calculateSelectedCount(room.tables)]
      }

      return acc
    }, [])

    return selectedTableIds
  }

  const handleDeleteSelected = () => {
    const roomIds = calculateSelectedCount(rooms)
    const tableIds = calculateSelectedTables(rooms)

    dispatch(officesAC.deleteSelectedRoomsAndTables({
      roomIds, tableIds, setIsDeletionModalVisible, recalculateSelectedCounts,
    }))
  }

  const handleGetFilteredRooms = filters => dispatch(officesAC.getFilteredRooms({
    filters,
    officeId,
    recalculateSelectedCounts,
  }))

  const renderActivityHeader = () => (
    <div className='office-details-screen__summary' style={{ paddingBottom: '20px' }}>
      <span className='office-details-screen__summary-info'>
        {getTotalSelectedStr()}
      </span>
      <IconPrint onClick={() => setIsQRPrintModalVisible(val => !val)} />
      <div
        onClick={() =>
          (selectedRoomsCount || selectedTablesCount) && setIsDeletionModalVisible(true)}
        className='icon__trash'
      />
    </div>
  )

  const CardSubtitle = (() => (
    <>
      <FilterInput
        officeId={officeId}
        onSearch={handleGetFilteredRooms}
        tags={tagsList}
        floors={floors}
        zones={zones}
        roomTypes={roomTypes}
        placeholder='Введите название помещения, этаж или зону'
      />
      <div className='button_container__content_right'>
        <Button
          confirm
          icon='icon__add__in_circle'
          styles='bordered_btn'
          title='Добавить помещение'
          onClick={toggleRoomForm}
        />
      </div>
    </>
  ))()

  return (
    <>
      <MainCard
        icon='icon__briefcase'
        title={officeTitle || 'БИЗНЕС-ЦЕНТР'}
        subtitleClassName='main_card__subtitle main_card__subtitle__office-detail'
        loading={isAllDataLoading}
        isAllDataLoading
        filter={CardSubtitle}
        innerContainerClassName='office-details-screen'
        isOffice='true'
      >
        {renderActivityHeader()}
        <TableHeader
          columns={getTableHeaderColumns(selectAllCheckboxProps)}
          flexValues={COLUMNS_FLEX_VALUES}
          style={COLUMNS_EXTRA_STYLE}
          rightPaddingWidth={48}
        />
        {rooms?.length
          ? renderRoomsList()
          : <EmptyPlaceholder text='Здесь пока нет кабинетов' />}
      </MainCard>

      {roomFormState.visible && (
        <RoomFormModal
          isNew={!roomFormState.room}
          start={roomFormState.visible}
          onClose={toggleRoomForm}
          floors={floors}
          zones={zones}
          room={roomFormState.room}
          roomTypes={roomTypes}
        />
      )}

      <QRPrintModal
        rooms={getRoomsForQRPrintModal()}
        start={isQRPrintModalVisible}
        onClose={() => setIsQRPrintModalVisible(false)}
      />

      {tableFormState.visible && (
        <TableFormModal
          isNew
          officeId={officeId}
          start={tableFormState.visible}
          onClose={closeTableFormModal}
          roomId={tableFormState.targetRoomId}
          tags={tagsList}
        />
      )}
      <ConfirmationModal
        start={isDeletionModalVisible}
        text={`Удалить ${getTotalSelectedRoomsStr()} и ${getTotalSelectedTablesStr()}?`}
        onConfirm={handleDeleteSelected}
        onClose={() => setIsDeletionModalVisible(false)}
      />
    </>
  )
})

export default DetailInfo
