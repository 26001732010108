import React from 'react'
import { Field as FormikField } from 'formik'
import Form from 'antd/lib/form'
import InputNumber from 'antd/lib/input-number'

const SimpleInputNumber = React.memo(({
  children,
  label,
  max,
  min,
  name,
  onChange,
  placeholder,
  setFieldTouched,
  style,
  value,
  error,
  disabled,
  className,
}) => (
  <Form.Item className={className} label={label}>
    <div className='validation_block'>
      <FormikField
        render={() => (
          <InputNumber
            type='number'
            disabled={disabled}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={value => onChange(name, value)}
            onBlur={() => setFieldTouched(name, true)}
            min={min}
            max={max}
            style={{ ...style, border: error && '1px solid #CD4141' }}
          />
        )}
      />
      <div className='validation_block margin_top__small'>{children}</div>
    </div>
  </Form.Item>
))

export default SimpleInputNumber
