import {
  CLEAN_UP_FILTERED_USERS_SUCCEED,
  CLEAR_GROUP_SUCCEED,
  CREATE_NEW_GROUP,
  DELETE_GROUP,
  DELETE_USERS_FROM_NEW_GROUP_SUCCEED,
  GET_ACCESS_GROUP,
  GET_ACCESS_GROUPS,
  GET_ACCESS_GROUPS_SUCCEED,
  GET_GROUP_ACCESS,
  IMPORT_GROUPS,
  IMPORT_USERS_IN_GROUP,
  SET_IS_NEW_FLAG_SUCCEED,
  SET_USERS_TO_CREATE_GROUP_SUCCEED,
  UPDATE_GROUP,
  UPDATE_GROUP_ADD_SUCCEED,
  UPDATE_GROUP_DELETE_SUCCEED,
} from '../users'

export const groupAC = {
  getGroupsList: () => ({ type: GET_ACCESS_GROUPS }),
  deleteGroup: ({ id, callback }) => ({
    type: DELETE_GROUP,
    payload: { callback, id },
  }),
  importUsersInGroup: ({ file, groupId }) =>
    ({ type: IMPORT_USERS_IN_GROUP, payload: { file, groupId } }),
  importGroups: ({ file, path, callback }) => ({
    type: IMPORT_GROUPS,
    payload: {
      file,
      path,
      callback,
    },
  }),
  getGroup: ({ id }) => ({ type: GET_ACCESS_GROUP, payload: { id } }),
  createNewGroup: ({ group, succeedCallback, failedCallback }) => ({
    type: CREATE_NEW_GROUP,
    payload: {
      group,
      succeedCallback,
      failedCallback,
    },
  }),
  getGroupAccess: ({ id }) => ({ type: GET_GROUP_ACCESS, id }),
  // only BLL
  addUserToNewGroup: ({ user }) => ({ type: SET_USERS_TO_CREATE_GROUP_SUCCEED, payload: { user } }),
  deleteUserFromNewGroup: ({ user }) =>
    ({ type: DELETE_USERS_FROM_NEW_GROUP_SUCCEED, payload: { user } }),
  setNewRoute: route => ({ type: SET_IS_NEW_FLAG_SUCCEED, payload: route }),
  clearGroup: () => ({ type: CLEAR_GROUP_SUCCEED }),
  setForDelete: ({ id }) => ({ type: UPDATE_GROUP_DELETE_SUCCEED, payload: { id } }),
  setForAdd: ({ id }) => ({ type: UPDATE_GROUP_ADD_SUCCEED, payload: { id } }),
  updateGroup: ({ id, mainInfo }, succeedCallback, failedCallback) =>
    ({ type: UPDATE_GROUP, id, mainInfo, succeedCallback, failedCallback }),
  setGroups: ({ groups }) => ({ type: GET_ACCESS_GROUPS_SUCCEED, payload: { groups } }),
  clearFilteredGroups: () => ({ type: CLEAN_UP_FILTERED_USERS_SUCCEED }),
}
