import message from 'antd/lib/message'
import { MAX_IMAGE_SIZE_IN_BITS, MAX_IMAGE_SIZE_IN_PIXELS } from '../../../utils/data/constants'

export const formFormData = file => {
  const data = new FormData()
  data.append('file', file)
  data.append('title', file.name)
  return data
}

const getImageSize = img => {
  const imgWidth = img.naturalWidth
  const imgHeight = img.naturalHeight

  if (imgWidth < MAX_IMAGE_SIZE_IN_PIXELS || imgHeight < MAX_IMAGE_SIZE_IN_PIXELS) {
    message.error(`Минимальный размер карты ${MAX_IMAGE_SIZE_IN_PIXELS}px x ${MAX_IMAGE_SIZE_IN_PIXELS}px`)
    return null
  }
  return { width: String(imgWidth), height: String(imgHeight) }
}

const onUploadChange = (e, activeFloor, loadMap, activeFloorMapId) => {
  const file = e.target.files[0]
  if (file.size > MAX_IMAGE_SIZE_IN_BITS) {
    message.error('Размер файла не более 10Мб')
    return
  }

  const img = new Image()
  img.src = URL.createObjectURL(file)
  img.onload = () => {
    const size = getImageSize(img)
    size && loadMap(activeFloor, formFormData(file), size, activeFloorMapId)
  }
}

export default onUploadChange
