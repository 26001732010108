import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from './Form'
import { getFloors, getOfficesList } from '../../../../reducers/offices-selectors'
import { kioskAC } from '../../../../actions/actionCreator/usersAC'
import DisabledInputBlock from '../../../UI/dataBlock/DisabledInputBlock'

const KioskForm = React.memo(({ isEditMode, kiosk, onCancel }) => {
  const offices = useSelector(getOfficesList)
  const floors = useSelector(getFloors)
  const dispatch = useDispatch()
  const onEditSave = values => dispatch(kioskAC.putKiosk(kiosk.id, values, onCancel))
  const onCreate = (values, callback) => dispatch(kioskAC.createKiosk(values, callback))
  const renderCode = () => (
    <DisabledInputBlock
      title='Код доступа'
      subtitle={kiosk.access_code}
      style={{ margin: '3px 0 16px', padding: 0, userSelect: 'text' }}
    />
  )
  const getFormProps = () => isEditMode
    ? { onSave: onEditSave, className: 'kiosk_form__edit', code: renderCode(), initialValues: kiosk }
    : { onSave: onCreate, className: 'kiosk_form__new' }
  const formProps = getFormProps()
  return (
    <div className={formProps.className}>
      <Form
        kiosk={kiosk}
        isEditMode={isEditMode}
        offices={offices}
        floors={floors}
        onSave={formProps.onSave}
        accessCode={formProps.code}
        initialValues={formProps.initialValues}
      />
    </div>
  )
})
export default KioskForm
