/* eslint-disable curly */
import { call, put } from 'redux-saga/effects'
import message from 'antd/lib/message'
import notification from 'antd/lib/notification'

import HOST from '../utils/data/connectionData'
import notificationBasicSetups from '../utils/data/notificationConfig'
import { authHeader, contentHeader } from '../utils/data/requestHeaders'
import { POST } from '../utils/methods'
import { removeTokenFromStorage } from '../utils/manageTokens'
import { SET_TOKEN, SET_TOKEN_LOADING } from '../actions/account'

const request = (path, payload) =>
  call(fetch, `${HOST}${path}`, payload)

function* getNewToken() {
  const refreshToken = localStorage.getItem('refresh_token')
  if (!refreshToken) return null
  const response = yield request('/refresh', {
    method: POST,
    headers: {
      'X-Workspace': process.env.REACT_APP_WORKSPACE,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ refresh: refreshToken }),
  })
  const res = yield response.json()

  if (
    res.message === 'Token has expired' || res.detail === 'Refresh token is invalid or expired' || res.detail === 'Refresh parametr is required'
  ) return null

  return yield res
}

const getHeader = header => header === 'auth' ? authHeader() : contentHeader()

function* getResponse({ method, path, headers, body, isFile, mode, isPush }) {
  const payload = {
    method,
    mode,
    headers: typeof headers === 'object' ? headers : getHeader(headers),
    body: isFile ? body : JSON.stringify(body),
  }

  const response = yield request(path, payload, isPush)
  const res = yield response.statusText.toUpperCase() === 'NO CONTENT' ? {} : response.json()

  if (res && (res.msg || res.message || res.detail)) {
    switch (res.msg || res.message || res.detail) {
      case 'Given token not valid for any token type': {
        yield put({ type: SET_TOKEN_LOADING })
        const newToken = yield getNewToken()

        if (newToken === null) {
          removeTokenFromStorage()
          window.location.pathname = '/auth'
          return null
        }

        if (newToken.access_token && newToken.refresh_token) yield put({
          type: SET_TOKEN,
          token: newToken,
        })

        return yield getResponse({ method, path, headers, body, isFile })
      }

      case 'No data':
        return res

      case 'Not enough segments':
      case 'Token has been revoked':
        return yield getResponse({
          method: method,
          path: path,
          headers: headers,
          body: body,
          isFile: isFile,
        })

      case 'Invalid Credentials':
      case 'No such user': {
        message.error('Неверный логин или пароль')
        return null
      }

      case 'License error': {
        notification.error({
          ...notificationBasicSetups,
          message: 'Не удалось забронировать',
          description: 'Ошибка лицензии. Обратитесь к администратору БЦ.',
        })
        return null
      }

      case 'No floors':
        return []

      case 'No offices':
        return []

      case 'No rooms':
        return []

      case 'No tables':
        return []

      case 'Account not found': {
        window.location.pathname = '/auth'
        return null
      }

      case 'Type already exists': {
        message.error('Такой тип помещения уже создан')
        return res
      }

      case 'Office not fount': {
        message.error('Офис не найден')
        return res
      }

      case 'Room not fount': {
        message.error('Помещение не найдено')
        return res
      }

      case 'Table not fount': {
        message.error('Стол не найден')
        return res
      }

      case 'Zone not fount': {
        message.error('Зона офиса не найдена')
        return res
      }

      case 'Floor not fount': {
        message.error('Этаж не найден')
        return res
      }

      case 'Signature verification failed': {
        window.location.pathname = '/auth'
        return null
      }

      case 'Date range is being overflowed by existing booking':
        return res

      case 'User not found': {
        message.error(response.statusText === 'FORBIDDEN'
          ? 'Неверный логин или пароль'
          : 'Пользователь не найден')
        return null
      }

      case 'User has no email specified': {
        message.error('У пользователя не указан адрес электронной почты')
        return null
      }

      case 'Email is not valid': {
        message.error('Некорректный адрес электронной почты')
        return null
      }

      case 'No suitable tables found': {
        notification.error({
          ...notificationBasicSetups,
          message: 'Ошибка бронирования',
          description: 'Не найдено свободных мест, отвечающих требованиям.',
        })
        return null
      }

      case 'This type of room can have no tables': {
        message.error('Комната такого типа не может иметь рабочих мест')
        return res
      }

      case 'Phone number is occupied': {
        message.error('Этот номер телефона уже используется другим пользователем')
        return res
      }

      case 'Meeting room must have no more than single table': {
        message.error('В переговорную комнату невозможно добавить рабочие места')
        return res
      }

      case 'Can\'t create table without right license': {
        notification.error({
          ...notificationBasicSetups,
          message: 'Невозможно создать стол',
          description: 'Ошибка лицензии. Обратитесь к администратору БЦ.',
        })
        return null
      }

      case 'Can\'t create meeting room without right license': {
        notification.error({
          ...notificationBasicSetups,
          message: 'Невозможно создать переговорную',
          description: 'Ошибка лицензии. Обратитесь к администратору БЦ.',
        })
        return null
      }

      case 'Old password does not match': {
        message.error('Старый пароль неверен')
        return res
      }

      case 'Group not found': {
        message.error('Группа не найдена. Убедитесь что группа существует.')
        return res
      }

      case 'You can\'t lower number of floors if some floors are named as strings. You can manually delete some if you want': {
        message.error('Невозможно автоматически создать этажи. Используйте ручное добавление.')
        return res
      }

      case 'No room type or office found': {
        return []
      }

      case 'Demoted':
      case 'Promoted':
      case 'OK': {
        return res
      }

      case 'Access denied': {
        message.error('Ошибка доступа')
        return res
      }

      case 'Try later': {
        message.error('Ошибка при выполнении запроса. Попробуйте повторить действие позже.')
        return res
      }

      case 'Can\'t add to this group': {
        message.error('Вы не можете добавить пользователя в эту группу')
        return res
      }

      case 'Office not found': {
        message.error('Бизнес центр не найден')
        return res
      }

      case 'User already has a booking for this date.': {
        message.error('У пользователя уже есть бронирование на выбранное время')
        return res
      }

      case 'User already exist': {
        message.error('Такой пользователь уже был создан. Вы можете его отредактировать')
        return res
      }

      case 'Not found': {
        return res
      }
      case 'Incorrect email or password': {
        message.error('Неправильный логин или пароль')
        return res
      }
      case 'wrong password': {
        message.error('Старый пароль указан неверно')
        return res
      }
      default: {
        message.error('Внутренняя ошибка сервера')
        return res
      }
    }
  }

  return res
}

export default getResponse
