/* eslint-disable curly */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-use-before-define */

// constants
export const SET_VIEWPORT_DIMENSIONS = 'SET_VIEWPORT_DIMENSIONS'
export const SET_MARKER_ON_HOVER_ATTR = 'SET_MARKER_ON_HOVER_ATTR'
export const SET_MARKER_ON_CLICK_ATTR = 'SET_MARKER_ON_CLICK_ATTR'
export const SET_ROOM_ON_HOVER_ATTR = 'SET_ROOM_ON_HOVER_ATTR'
export const SET_MAP_STATE = 'SET_MAP_STATE'
export const SET_DEFAULT_MAP_SCALE = 'SET_DEFAULT_MAP_SCALE'
export const ON_CLICK_MARKER = 'ON_CLICK_MARKER'
export const SET_STATE_WITHOUT_REDUX = 'SET_STATE_WITHOUT_REDUX'
export const SET_MARKERS = 'SET_MARKERS'
export const SET_IS_EXPANDED = 'SET_IS_EXPANDED'
export const SET_IS_VISIBLE_BUCKET = 'SET_IS_VISIBLE_BUCKET'
export const TOOGLE_IS_EXPANDED = 'TOOGLE_IS_EXPANDED'
export const SET_IS_EXPANDED_FLOORLIST = 'SET_IS_EXPANDED_FLOORLIST'
export const EDIT_ROOM = 'EDIT_ROOM'
export const SET_IS_BUCKET_HOVER = 'SET_IS_BUCKET_HOVER'
export const DELETING_MARKER = 'DELETING_MARKER'
export const SET_DELETING_MARKER_ROOM_ID = 'SET_DELETING_MARKER_ROOM_ID'
export const SET_BUCKET_POSITION = 'SET_BUCKET_POSITION'
export const SET_OPEN_ROOM = 'SET_OPEN_ROOM'
export const SET_ROOM_MARKER_ON_MAP = 'SET_ROOM_MARKER_ON_MAP'
export const CLEAR_OPEN_ROOM = 'CLEAR_OPEN_ROOM'
export const SET_IS_VISIBLE_ALERT_ON_MAP = 'SET_IS_VISIBLE_ALERT_ON_MAP'

export const DELETE_ROOM = 'DELETE_ROOM'

export const ADD_NEW_TABLE_MARKER = 'ADD_NEW_TABLE_MARKER'
export const PUT_TABLE_MARKER_ON_MAP = 'PUT_TABLE_MARKER_ON_MAP'
export const SHOW_TABLE_MARKERS = 'SHOW_TABLE_MARKERS'
export const DELETE_TABLE_MARKER = 'DELETE_TABLE_MARKER'
export const DELETE_ALL_TABLE_MARKERS = 'DELETE_ALL_TABLE_MARKERS'
export const SET_TABLE_MARKERS = 'SET_TABLE_MARKERS'

export const SET_CURSOR = 'SET_CURSOR'

const getMapStateScaleToOpenRoom = ({ state, room }) => {
  const { mapState } = state
  const newMapState = state.mapState
  const { x } = state.markers.find(marker => marker.roomId === room.id)
  const { y } = state.markers.find(marker => marker.roomId === room.id)
  const { viewportDimensions } = state
  const mapWidth = mapState.image.width
  const mapHeight = mapState.image.height
  const { scale, maxScale } = mapState
  const bigestScale = DEFAULT_MAP_STATE.scale * 2 > maxScale ? maxScale : DEFAULT_MAP_STATE.scale * 2
  const newScale = state.needExpandMap ? bigestScale : scale
  const mapOffsetX = mapWidth * newScale * x / 100
  const mapOffsetY = mapHeight * newScale * y / 100

  newMapState.x = -mapOffsetX + viewportDimensions.width / 2
  newMapState.y = -mapOffsetY + viewportDimensions.height / 2
  newMapState.scale = newScale
  return newMapState
}

const getTableMarkerCorrectFormat = table => {
  const { marker } = table
  marker.isDragging = false
  marker.title = table.title

  return marker
}

// initialState
export const DEFAULT_MARKER_ON_HOVER_ATTR = {
  hover: false,
  isCursorMove: false,
  id: null,
  x: null,
  y: null,
  title: '',
  zoneTitle: '',
  description: '',
  isTableMarker: false,
}

export const DEFAULT_MARKER_ON_CLICK_ATTR = {
  isActive: false,
  id: null,
  x: null,
  y: null,
}

export const DEFAULT_ROOM_ON_HOVER_ATTR = {
  isHover: false,
  id: null,
  src: null,
  Svg: null,
}

export const DEFAULT_MAP_STATE = {
  scale: 1,
  x: 0,
  y: 0,
  image: null,
  minScale: 1,
  maxScale: 1,
  loading: true,
  isDragging: false,
}

export const DEFAULT_OPEN_ROOM = { id: null, marker: null, type: null }

export const INITIAL_STATE_WITHOUT_REDUX = {
  mode: 'read',
  activeFloor: null,
  activeRoom: undefined, // TODO рефактор. activeRoom - id комнаты для hover и click по маркеру на карте
  openRoom: DEFAULT_OPEN_ROOM, // TODO рефактор. openRoom - объект комнаты, которая сейчас открыта в списке (RoomsList)
  loaded: false,
  isRoomModalVisible: false,
  editableRoom: null,
  isAddRoomModalVisible: false,
  isActiveAddMarkerMode: null,
  isBucketHover: false,
  bucketPosition: 0,
  isVisibleBucket: false,
  isDeletingMarker: false,
  deletingMarkerRoomId: null,
  markers: [], // TODO все markers должны стать roomMarkers
  tableMarkers: [],
  viewportDimensions: { width: 0, height: 0 },
  markerOnHoverAttr: DEFAULT_MARKER_ON_HOVER_ATTR,
  markerOnClickAttr: DEFAULT_MARKER_ON_CLICK_ATTR,
  roomOnHoverAttr: DEFAULT_ROOM_ON_HOVER_ATTR,
  mapState: DEFAULT_MAP_STATE,
  defaultMapScale: null,
  needExpandMap: true,
  floorsList: {
    isExpanded: false,
  },
  cursor: null,
  modalOnMap: { isVisible: false, title: null },
}

export const reducer = (state, action) => {
  const { payload } = action
  switch (action.type) {
    case SET_VIEWPORT_DIMENSIONS:
      return { ...state, viewportDimensions: { ...state.viewportDimensions, ...payload } }
    case SET_MARKER_ON_HOVER_ATTR:
      return { ...state, markerOnHoverAttr: { ...state.markerOnHoverAttr, ...payload } }
    case SET_ROOM_ON_HOVER_ATTR:
      return { ...state, roomOnHoverAttr: { ...state.roomOnHoverAttr, ...payload } }
    case SET_MARKER_ON_CLICK_ATTR:
      return { ...state, markerOnClickAttr: { ...state.markerOnClickAttr, ...payload } }
    case SET_MAP_STATE:
      return { ...state, mapState: { ...state.mapState, ...payload } }
    case SET_DEFAULT_MAP_SCALE:
      return { ...state, defaultMapScale: payload }
    case SET_IS_EXPANDED:
      return { ...state, floorsList: { isExpanded: payload } }
    case SET_IS_BUCKET_HOVER:
      return { ...state, isBucketHover: payload }
    case SET_BUCKET_POSITION:
      return { ...state, bucketPosition: payload.top }
    case SET_IS_VISIBLE_BUCKET:
      return { ...state, isVisibleBucket: payload }
    case TOOGLE_IS_EXPANDED:
      return { ...state, floorsList: { ...state.floorsList, isExpanded: !state.floorsList.isExpanded } }
    case SET_IS_EXPANDED_FLOORLIST:
      return { ...state, floorsList: { ...state.floorsList, isExpanded: payload } }
    case DELETING_MARKER:
      return { ...state, isDeletingMarker: payload }
    case SET_DELETING_MARKER_ROOM_ID:
      return { ...state, deletingMarkerRoomId: payload }
    case SET_MARKERS:
      const markersNewFormat = payload?.map(p => ({
        ...p,
        isDragging: p.isDragging,
        isVisible: !!p.x,
        x: Number(p.x),
        y: Number(p.y),
      }))

      return { ...state, markers: [...markersNewFormat] }
    case EDIT_ROOM:
      return { ...state, markerOnClickAttr: { ...state.markerOnClickAttr, ...payload } }
    case DELETE_ROOM:
      const { roomId } = payload
      const newOpenRoom = roomId === state.openRoom.id ? DEFAULT_OPEN_ROOM : state.openRoom
      return { ...state, openRoom: newOpenRoom }
    case SET_STATE_WITHOUT_REDUX:
      return { ...state, ...payload }
    case ON_CLICK_MARKER:
      const newIsActive = payload.id === state.markerOnClickAttr.id ? !state.markerOnClickAttr.isActive : true
      const newState = {
        ...state,
        markerOnClickAttr: {
          ...state.markerOnClickAttr,
          ...payload,
          isActive: newIsActive,
        },
      }
      return { ...newState }
    case SET_OPEN_ROOM: {
      const prevOpenRoom = state.openRoom
      const { room } = payload

      if (prevOpenRoom.id === room.id) {
        return {
          ...state,
          openRoom: { id: null, marker: null, type: null },
          mapState: { ...state.mapState, scale: state.defaultMapScale, x: 0, y: 0 },
          needExpandMap: true,
          tableMarkers: [],
          markerOnClickAttr: {
            ...state.markerOnClickAttr,
            isActive: false,
          },
        }
      }
      if (!room.marker) {
        return {
          ...state,
          openRoom: { id: room.id, marker: null, type: room.type },
          needExpandMap: true,
          markerOnClickAttr: {
            ...state.markerOnClickAttr,
            isActive: false,
          },
        }
      }

      const newMapState = getMapStateScaleToOpenRoom({ state, room })

      return {
        ...state,
        openRoom: { id: room.id, marker: room.marker, type: room.type },
        mapState: newMapState,
        needExpandMap: false,
        markerOnClickAttr: {
          ...state.markerOnClickAttr,
          isActive: false,
        },
      }
    }

    case SET_ROOM_MARKER_ON_MAP: {
      const { room } = payload
      const newMapState = getMapStateScaleToOpenRoom({ state, room })

      return {
        ...state,
        openRoom: { id: room.id, marker: room.marker, type: room.type },
        mapState: newMapState,
        needExpandMap: false,
      }
    }

    case CLEAR_OPEN_ROOM: {
      return {
        ...state,
        openRoom: { id: null, marker: null, type: null },
        needExpandMap: true,
        tableMarkers: [],
      }
    }
    case ADD_NEW_TABLE_MARKER: {
      const { newTableMarker } = action.payload

      return {
        ...state, tableMarkers: [newTableMarker, ...state.tableMarkers],
      }
    }

    case PUT_TABLE_MARKER_ON_MAP: {
      const newTableMarker = action.payload
      const formattedTableMarker = { ...newTableMarker, isDragging: false }
      const newMarkers = state.tableMarkers
        .map(tableMarker => tableMarker.id === formattedTableMarker.id ? formattedTableMarker : tableMarker)

      return {
        ...state, tableMarkers: newMarkers,
      }
    }

    case SHOW_TABLE_MARKERS: {
      const { payload } = action
      const { room } = payload
      const tablesWithMarker = room.tables.filter(table => table.marker)
      const tableMarkersInRoom = tablesWithMarker.map(getTableMarkerCorrectFormat)
      return {
        ...state, tableMarkers: tableMarkersInRoom,
      }
    }

    case DELETE_TABLE_MARKER: {
      const { tableMarker } = action.payload
      const newTableMarkers = state.tableMarkers.filter(marker => marker.id !== tableMarker.id)

      return { ...state, tableMarkers: newTableMarkers }
    }

    case DELETE_ALL_TABLE_MARKERS: {
      return { ...state, tableMarkers: [] }
    }

    case SET_TABLE_MARKERS: {
      return { ...state, tableMarkers: action.payload }
    }

    case SET_CURSOR: {
      return { ...state, cursor: payload }
    }

    // mapModal
    case SET_IS_VISIBLE_ALERT_ON_MAP: {
      const { payload } = action
      const { isVisible, title } = payload
      return { ...state, modalOnMap: { isVisible, title } }
    }

    default: return state
  }
}
