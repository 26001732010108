import React from 'react'
import { useSelector } from 'react-redux'

import Modal from '../../../UI/modals/Modal'
import GroupListItem from './GroupListItem'
import ScrollTransition from '../../../UI/animations/transition/ScrollTransition'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'

import './groupsInfoModal.scss'
import { getUserGroup } from '../../../../reducers/users-selectors'
import ListLoadingSpin from '../../../UI/Spin/ListLoadingSpin'
import { getIsGroupsLoading } from '../../../../reducers/app-selectors'

const GroupsInfoModal = React.memo(({ start, onClose }) => {
  const accessUserGroupsList = useSelector(getUserGroup)
  const loadingGroup = useSelector(getIsGroupsLoading)

  return (
    <Modal start={start} onClose={onClose} extraClassName='user-groups-list__modal'>
      <h1 className='text-modal__title' style={{ marginRight: '10px' }}>ГРУППЫ И ДОСТУПЫ</h1>
      {loadingGroup
        ? <ListLoadingSpin />
        : <RenderGroups accessUserGroupsList={accessUserGroupsList} />}
    </Modal>
  )
})
const RenderGroups = React.memo(({ accessUserGroupsList }) => {
  if (!accessUserGroupsList.length) {
    return <EmptyPlaceholder text='Пользователь не добавлен ни в одну группу' />
  }
  return (
    <ScrollTransition loading>
      {accessUserGroupsList.map((group, index) => <GroupListItem key={index} group={group} />)}
    </ScrollTransition>
  )
})

export default GroupsInfoModal
