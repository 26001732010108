import React from 'react'
import * as Yup from 'yup'
import { Form as FormikForm, withFormik } from 'formik'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import Logo from './helpers/Logo'
import SimpleInput from './UI/input/SimpleInput'
import Button from './UI/Button'
import PasswordInput from './UI/input/PasswordInput'
import { ErrorMsg } from './UI/ValidationMsg'
import { logInUser } from '../actions/actionCreator/actionCreator'
import { emailRegExp } from '../utils/data/regExp'

const ValidationSchema = Yup.object().shape({
  username: Yup.string()
    .matches(emailRegExp, 'Введите адрес электронной почты')
    .required('Обязательное поле'),
  password: Yup.string()
    .required('Обязательное поле'),
})

class InnerForm extends React.Component {
  logIn = () => {
    const { values, setTouched, logInUser, history } = this.props
    setTouched({ username: true, password: true })

    if (!ValidationSchema.isValidSync(values)) return null
    logInUser(values, () => history.push('/offices'))
  }

  getBasicInputProps = name => {
    const { setFieldValue, setFieldTouched, errors, touched, values } = this.props
    return ({
      name,
      value: values[name],
      onChange: setFieldValue,
      setFieldTouched,
      onEnter: this.logIn,
      error: errors[name] && touched[name],
    })
  }

  render() {
    // Если есть токен, нельзя перейти на AUTH
    if (localStorage.access_token) {
      return <Redirect to='/offices' />
    }
    return (
      <div className='app_BG'>
        <div className='flex_container__center'>
          <div className='card card__auth'>
            <Logo style={{ height: '35%', width: '80%', margin: '24px auto 27px' }} />
            <div className='flex_container full_height'>
              <FormikForm>
                <SimpleInput
                  label='Логин'
                  style={{ width: '100%' }}
                  placeholder='Введите логин'
                  {...this.getBasicInputProps('username')}
                >
                  <ErrorMsg field='username' />
                </SimpleInput>
                <PasswordInput
                  label='Пароль'
                  style={{ width: '100%', marginBottom: '2px' }}
                  placeholder='Введите пароль'
                  {...this.getBasicInputProps('password')}
                >
                  <ErrorMsg field='password' />
                </PasswordInput>
                <div className='auth__btn'>
                  <Button styles='btn_solid margin_top' title='Войти' onClick={this.logIn} />
                </div>
              </FormikForm>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const Auth = withFormik({
  mapPropsToValues: () => ({ username: '', password: '' }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapDispatchToProps = ({ logInUser })

export default withRouter(connect(null, mapDispatchToProps)(Auth))
