import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { groupAC } from '../../../actions/actionCreator/groups'
import MainCard from '../../MainCard'
import UsersInGroupList from './UsersInGroupList'
import GroupInfo from './GroupInfo'
import FilterItem from '../../helpers/FilterItem'
import OfficesAndZonesList from './OfficesAndZonesList'
import { usersAC } from '../../../actions/actionCreator/usersAC'
import { LOADER_TYPE_GROUP } from '../../../sagas/usersSagas'
import FullScreenLoading from '../../UI/FullScreenLoading'

const TABS = [{ title: 'Пользователи', id: 'users' }, { title: 'БЦ', id: 'offices' }]

const GroupCard = React.memo(({
  group,
  loading,
  usersInGroup,
  usersLoading,
  count,
  paginationLoading,
  importLoading,
  zonesLoading,
}) => {
  const isKiosk = group.pre_defined && group.title === 'Информационная панель'

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState('Пользователи')
  const { current: groupId } = useRef(params.id)

  const onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 3)
    const isAllUsers = count <= usersInGroup.length
    if (paginationLoading || isAllUsers) return null
    if (isScrollAtTheBottom) {
      setPage(page + 1)
      dispatch(usersAC.getUsers({
        page: page + 1,
        group: groupId,
        pagination: LOADER_TYPE_GROUP,
      }))
    }
  }
  const setFilterTabStyle = radioItem => {
    const defaultClass = 'filter__tab'
    return (radioItem === filter) ? `${defaultClass}__active` : defaultClass
  }
  const redirectToGroupEditPage = () => history.push(`/groups/${groupId}/edit`)

  useEffect(() => {
    dispatch(groupAC.getGroup({ id: groupId }))
    dispatch(usersAC.getUsers({ group: groupId, page }))
  }, [])
  return (
    <MainCard icon='icon__groups' title='Группа доступа' loading={!paginationLoading && loading}>
      <div className='flex_container full_height'>
        <GroupInfo group={group} />
        <div className='container__right_side'>
          <div className='filter-tabs__group-mode'>
            {TABS.map(({ title, id }) => (
              <FilterItem
                key={`account-type-switch-${id}`}
                className={setFilterTabStyle(title)}
                htmlFor={id}
                name='mode'
                value={title}
                id={id}
                onChange={e => setFilter(e.target.value)}
              >
                {title}
              </FilterItem>
            ))}
          </div>
          {filter === 'Пользователи'
            ? (
              <UsersInGroupList
                searchEnabled={!isKiosk}
                groupId={groupId}
                isKiosk={isKiosk}
                users={usersInGroup}
                onButtonClick={redirectToGroupEditPage}
                shouldShowBtn={!isKiosk}
                loading={usersLoading}
                paginationLoading={paginationLoading}
                count={count}
                onListScroll={onListScroll}
              />
            )
            : (
              <OfficesAndZonesList
                onButtonClick={redirectToGroupEditPage}
                shouldShowBtn={isKiosk}
                loading={zonesLoading}
              />
            )}
        </div>
      </div>
      {importLoading && <FullScreenLoading />}
    </MainCard>
  )
})

const mapStateToProps = ({ users, app }) => ({
  group: users.group,
  loading: app.isGroupLoading,
  usersInGroup: users.inGroup,
  usersLoading: app.isUsersLoading,
  count: users.countInGroup,
  paginationLoading: app.paginationInGroup,
  importLoading: app.importLoading,
  zonesLoading: app.isZonesLoading,
})

export default connect(mapStateToProps, {})(GroupCard)
