import * as _ from 'lodash'

const translateString = ({
  toSnakeCase(str) {
    const lowerCaseSplittedArray = str.split(/(?=[A-Z])/).map(word => word.toLowerCase())
    return lowerCaseSplittedArray.join('_')
  },
  toCamelCase(str) {
    const splittedArr = str.split('_')
    const firstLetterUpperCased = splittedArr.map((word, index) => index !== 0
      ? word.charAt(0).toUpperCase() + word.slice(1)
      : word)
    return firstLetterUpperCased.join('')
  },
})

export const formatObjectFieldsToCamelCase = object => {
  const formattedObj = {}

  _.forIn(object, (value, key) => {
    formattedObj[translateString.toCamelCase(key)] = value
  })
  return formattedObj
}

export default translateString
