import {
  GET_NOTIFICATION_GROUPS_SUCCEED,
  GET_NOTIFICATION_ITEMS,
  GET_NOTIFICATION_USERS_SUCCEED,
} from '../notifications'

export const notificationsAC = {
  getNotificationItems: callback => ({ type: GET_NOTIFICATION_ITEMS, payload: { callback } }),
  getUsersForNotification: users => ({ type: GET_NOTIFICATION_USERS_SUCCEED, payload: users }),
  getGroupsForNotification: groups => ({ type: GET_NOTIFICATION_GROUPS_SUCCEED, payload: groups }),
}
