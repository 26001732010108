import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import LargeSpin from '../../UI/Spin/LargeSpin'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import Icon from '../../UI/Icon'
import Button from '../../UI/Button'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import './officesAndZonesList.scss'
import { groupAC } from '../../../actions/actionCreator/groups'
import { getAccessGroupInfo } from '../../../reducers/users-selectors'

const OfficesAndZonesList = React.memo(({ onButtonClick, shouldShowBtn, loading }) => {
  const accessGroupInfoList = useSelector(getAccessGroupInfo)
  const dispatch = useDispatch()

  const { id } = useParams()

  const renderOfficesList = () => {
    if (!accessGroupInfoList?.length) return <EmptyPlaceholder text='Группа не входит в состав бизнес-центров' />
    return (accessGroupInfoList.map(office => (
      <div className='groups-screen__office-card' key={office.id}>
        <div className='office-card__header'>
          <span className='office-card__office-title'>{office.title}</span>
          <span className='office-card__office-description'>
            <Icon name='icon__location' />
            {office.description || <span className='secondary_text__inactive'>Не указано</span>}
          </span>
        </div>
        <div className='office-card__zones-list'>
          {office.zones.map((zone, index) => (
            <div className='office-card__zone' key={zone.id}>
              <span className='office-card__zone-id'>{`${index + 1}.`}</span>
              <span className='office-card__zone-title'>{zone.title}</span>
            </div>
          ))}
        </div>
      </div>
    )))
  }

  useEffect(() => {
    dispatch(groupAC.getGroupAccess({ id }))
  }, [dispatch])
  return (
    <div className='container__right_side'>
      <ScrollTransition loading style={{ marginBottom: '10px' }}>
        <div className='card_list full_height' style={{ flex: 1 }}>
          {loading ? <LargeSpin styles={{ flex: 1 }} /> : renderOfficesList()}
        </div>
      </ScrollTransition>
      {shouldShowBtn && (
        <div className='button_container__edit_card'>
          <Button
            icon='icon__edit icon__wrapper'
            styles='bordered_btn'
            title='Редактировать'
            onClick={onButtonClick}
          />
        </div>
      )}
    </div>
  )
})

export default OfficesAndZonesList
