import React, { useRef } from 'react'
import { connect } from 'react-redux'
import Tooltip from 'antd/lib/tooltip'

import handleUpload from './helpers/loadMap'
import OutsideClicker from './helpers/OutsideClicker'
import Button from '../UI/Button'
import IconExpand from '../UI/icons/Expand'
import FloorsList from './lists/FloorsList'
import {
  CLEAR_OPEN_ROOM,
  SET_IS_EXPANDED_FLOORLIST,
  SET_MARKER_ON_CLICK_ATTR,
  TOOGLE_IS_EXPANDED,
} from './helpers/useReducerForMapComponent'

import { officesAC } from '../../actions/actionCreator/offices'

const ControlPanel = React.memo(({
  floors,
  activeFloor,
  setActiveFloor,
  mapImage,
  deleteMap,
  postMap,
  deleteMarkersOnFloor,
  dispatchWithoutRedux,
  stateWithoutRedux,
  setConfirmModal,
  floorMapId,
  updateMap,
  currentFloor,
}) => {
  const { floorsList } = stateWithoutRedux
  const { isExpanded } = floorsList
  const input = useRef()
  const getFloorTitle = () => floors?.find(floor => floor.id === activeFloor)?.title

  const getDeleteBtnStyle = () => `bordered_btn__red${mapImage ? '' : '__inactive'}`

  const removeAllMarkers = activeFloor => {
    const callback = () => dispatchWithoutRedux({ type: CLEAR_OPEN_ROOM })
    deleteMarkersOnFloor(activeFloor, callback)
  }

  const onClickFloorSelect = () => {
    dispatchWithoutRedux({ type: TOOGLE_IS_EXPANDED })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  }
  return (
    <div className='flex_container'>
      <OutsideClicker
        className={`container__floor__select${isExpanded ? '__active' : ''}`}
        onClick={onClickFloorSelect}
        callback={() => dispatchWithoutRedux({ type: SET_IS_EXPANDED_FLOORLIST, payload: false })}
      >
        <span className='title__floor__select'>{`ЭТАЖ ${getFloorTitle()?.toUpperCase()}`}</span>
        <IconExpand isExpanded={isExpanded} />
        {isExpanded
        && <FloorsList floors={floors} condition={isExpanded} setActiveFloor={setActiveFloor} />}
      </OutsideClicker>
      <div className='button_container__content_right' style={{ alignItems: 'center' }}>
        <div className='border_right' style={{ marginRight: '20px' }}>
          <Button
            styles='simple-btn__red'
            title='Удалить все маркеры'
            onClick={() => {
              dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
              setConfirmModal({ isVisible: true, type: 'removeMarkers', callback: () => removeAllMarkers(activeFloor) })
            }}
          />
        </div>
        <Tooltip title='Удалить карту этажа'>
          <div>
            <Button
              delay={400}
              icon='icon__trash'
              style={{ marginRight: '16px' }}
              styles={getDeleteBtnStyle(activeFloor)}
              disable={!mapImage}
              onClick={() => {
                dispatchWithoutRedux(
                  { type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } },
                )
                setConfirmModal({
                  isVisible: true,
                  type: 'removeMap',
                  callback: () => deleteMap({ floorMapId, activeFloor }),
                })
              }}
            />
          </div>
        </Tooltip>
        <input
          type='file'
          ref={input}
          className='input__file_uploader'
          accept='.png, .jpg, .jpeg .svg'
          onClick={e => e.target.value = null}
          onChange={e => handleUpload(e, activeFloor,
            currentFloor.floor_map ? updateMap : postMap, currentFloor.floor_map?.id)}
        />
        <Button
          confirm
          styles='rounded_btn__green'
          title={currentFloor.floor_map ? 'Изменить карту' : 'Загрузить карту'}
          onClick={() => input.current.click()}
        />
      </div>
    </div>
  )
})

const mapStateToProps = ({ offices = {} }, { activeFloor }) => {
  const floorMapId = offices.floors.find(el => el.id === activeFloor)?.floor_map?.id
  return ({
    floors: offices.floors,
    mapImage: floorMapId,
    floorMapId,
  })
}

const mapDispatchToProps = dispatch => ({
  postMap: (floorId, map, sizes) => dispatch(
    officesAC.postMapToFloor({ floorId, map, sizes }),
  ),
  updateMap: (floorId, map, sizes, activeFloorMapId) => dispatch(
    officesAC.updateMapToFloor({ floorId, map, sizes, isUpdate: true, activeFloorMapId }),
  ),
  deleteMap: ({ floorMapId, activeFloor }) => dispatch(
    officesAC.deleteMap({ floorMapId, activeFloor }),
  ),
  deleteMarkersOnFloor: (floor, callback) => dispatch(
    officesAC.deleteAllMarkersOnFloor({ floorId: floor, callback }),
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel)
