import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import Button from '../../UI/Button'
import NewUserModal from '../../Users/modals/NewUserModal'
import UsersList from './UsersList'
import UploadButton from '../../UI/UploadButton'
import { usersAC } from '../../../actions/actionCreator/usersAC'
import { groupAC } from '../../../actions/actionCreator/groups'
import { LOADER_TYPE_GROUP, LOADER_TYPE_NOT_GROUP } from '../../../sagas/usersSagas'
import FullScreenLoading from '../../UI/FullScreenLoading'

const UsersPickContainer = React.memo(({
  usersList,
  usersLoading,
  onSave,
  usersInGroup,
  countNotInGroup,
  countInGroup,
  paginationInGroup,
  paginationNotInGroup,
  importLoading,
  deleteFromGroup,
  addToGroup,
}) => {
  const params = useParams()
  const dispatch = useDispatch()
  const { current: groupId } = useRef(params.id)

  const [searchStrInUnassigned, setSearchStrInUnassigned] = useState('')
  const [searchStrInGroup, setSearchStrInGroup] = useState('')
  const [isNewUserModalVisible, setIsNewUserModalVisible] = useState(false)
  const [page, setPage] = useState(1)
  const [pageGroup, setPageGroup] = useState(1)

  const loadWithOutScrollNotInGroup = useCallback(() => {
    const isAllUsers = countNotInGroup <= (usersList.length + addToGroup.length)
    if (usersLoading || isAllUsers) return null
    dispatch(usersAC.getUsers({
      page: page + 1,
      excludeGroupId: groupId,
      pagination: LOADER_TYPE_NOT_GROUP,
      throttle: true,
    }))
    setPage(page + 1)
  }, [usersList.length])

  const loadWithOutScrollInGroup = useCallback(() => {
    const isAllUsersInGroup = countInGroup <= (usersInGroup.length + deleteFromGroup.length)
    if (usersLoading || isAllUsersInGroup) return null
    dispatch(usersAC.getUsers({
      page: pageGroup + 1,
      group: groupId,
      pagination: LOADER_TYPE_GROUP,
      throttle: true,
    }))
    setPageGroup(pageGroup + 1)
  }, [usersInGroup.length])

  const onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 3)
    const isAllUsers = countNotInGroup <= (usersList.length + addToGroup.length)
    if (paginationNotInGroup || isAllUsers) return null
    if (isScrollAtTheBottom) {
      dispatch(usersAC.getUsers({
        page: page + 1,
        excludeGroupId: groupId,
        pagination: LOADER_TYPE_NOT_GROUP,
      }))
      setPage(page + 1)
    }
  }

  const onListScrollInGroup = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 3)
    const isAllUsersInGroup = countInGroup <= (usersInGroup.length + deleteFromGroup.length)
    if (paginationInGroup || isAllUsersInGroup) return null
    if (isScrollAtTheBottom) {
      setPageGroup(pageGroup + 1)
      dispatch(usersAC.getUsers({
        page: pageGroup + 1,
        group: groupId,
        pagination: LOADER_TYPE_GROUP,
      }))
    }
  }

  const setToDeleteBox = useCallback(id => dispatch(groupAC.setForDelete({ id })), [usersInGroup])
  const setToAddBox = useCallback(id => dispatch(groupAC.setForAdd({ id })), [usersList])

  const addUserToGroup = useCallback(user =>
    dispatch(groupAC.addUserToNewGroup({ user })), [dispatch])
  const deleteUserFromGroup = useCallback(user =>
    dispatch(groupAC.deleteUserFromNewGroup({ user })), [dispatch])
  const addNewUser = useCallback(user => dispatch(usersAC.createUser({
    user,
    callback: () => setIsNewUserModalVisible(false),
    inGroup: true,
  })), [dispatch])

  const searchInGroup = useCallback(({ searchString }) =>
    // ограничиваем поиск в несозданной группе
    params.id && dispatch(usersAC.getUsers({
      searchString,
      group: groupId,
      page: pageGroup,
      pagination: LOADER_TYPE_GROUP,
    })), [dispatch])

  const searchAmongAll = useCallback(({ searchString }) =>
    dispatch(usersAC.getUsers({
      searchString,
      excludeGroupId: groupId,
      page: 1,
      pagination: LOADER_TYPE_NOT_GROUP,
    })), [dispatch, !!usersList.length])

  const onSearchClose = useCallback(() => {
    setSearchStrInUnassigned('')
  }, [searchStrInGroup])

  const setSearchString = useCallback(val =>
    setSearchStrInUnassigned(val), [searchStrInUnassigned])

  const toggleNewUserModalVisibility = useCallback(val =>
    setIsNewUserModalVisible(val), [isNewUserModalVisible])

  const packFile = useCallback(file => {
    const data = new FormData()
    data.append('file', file)
    data.append('group', groupId)
    return data
  }, [])

  const handleCsvImport = useCallback(({ target }) => {
    const file = target.files[0]
    if (!file) return
    const data = packFile(file)
    dispatch(groupAC.importUsersInGroup({ file: data, groupId }))
  }, [])

  useEffect(() => {
    dispatch(usersAC.getUsers({ page: page, excludeGroupId: groupId }))
    // при релоаде страницы
    groupId && dispatch(groupAC.getGroup({ id: groupId }))
    // если новая группа то запрос не делаем
    groupId && dispatch(usersAC.getUsers({ group: groupId, page: pageGroup }))
    return () => {
      dispatch(groupAC.clearGroup())
      dispatch(usersAC.cleanUsers())
      dispatch(groupAC.clearFilteredGroups())
    }
  }, [])

  return (
    <>
      <div className='container__right_side' style={{ paddingLeft: '22px' }}>
        <div className='flex_container full_height'>
          <UsersList
            search
            name='selected'
            list={usersInGroup}
            loading={usersLoading}
            title='Пользователи в группе'
            searchString={searchStrInGroup}
            setSearchString={setSearchStrInGroup}
            getItems={searchInGroup}
            addDeleteHandler={setToDeleteBox}
            replaceUser={deleteUserFromGroup}
            onScroll={onListScrollInGroup}
            countInStore={countInGroup}
            paginationLoading={paginationInGroup}
            loadWithoutScroll={loadWithOutScrollInGroup}
            length={usersInGroup.length}

          />
          <UsersList
            search
            name='remain'
            list={usersList}
            loading={usersLoading}
            title='Пользователи не в группе'
            searchString={searchStrInUnassigned}
            setSearchString={setSearchString}
            onSearchClose={onSearchClose}
            getItems={searchAmongAll}
            addDeleteHandler={setToAddBox}
            replaceUser={addUserToGroup}
            onScroll={onListScroll}
            countInStore={countNotInGroup}
            paginationLoading={paginationNotInGroup}
            length={usersList.length}
            loadWithoutScroll={loadWithOutScrollNotInGroup}
          />
        </div>
        <div className='button_container__edit_card' style={{ marginBottom: '5px' }}>
          {/* Если группа не создана, не можем импортировать, нет логики не беке */}
          {groupId
          && (
            <UploadButton
              acceptFiles='.csv'
              btnIcon='icon__download_2'
              btnClassName='button__import_users_csv'
              btnTitle='Импортировать из .csv'
              handleChange={handleCsvImport}
            />
          )}
          {/* Функционал создания пользователя триггерит ошибку на бэке и пока не нужен. */}
          {/* <Button
            styles='button__new_user_in_group'
            title='Новый пользователь'
            onClick={() => toggleNewUserModalVisibility(true)}
          /> */}
          <button
            type='submit'
            className='rounded-btn_blue'
            onClick={onSave}
          >
            Сохранить
          </button>
        </div>
      </div>
      {/* Функционал создания пользователя триггерит ошибку на бэке и пока не нужен. */}
      {/* <NewUserModal
        start={isNewUserModalVisible}
        handleClose={() => toggleNewUserModalVisibility(false)}
        onSave={addNewUser}
      /> */}
      {importLoading && <FullScreenLoading />}
    </>
  )
})

const mapStateToProps = ({ users, app }) => ({
  deleteFromGroup: users.deleteFromGroup,
  addToGroup: users.addToGroup,
  usersInGroup: users.inGroup,
  usersList: users.usersList,
  usersLoading: app.isUsersLoading,
  importLoading: app.importLoading,
  countNotInGroup: users.countNotInGroup,
  countInGroup: users.countInGroup,
  paginationNotInGroup: app.paginationNotInGroup,
  paginationInGroup: app.paginationInGroup,
})

export default connect(mapStateToProps, {})(UsersPickContainer)
