import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Checkbox from 'antd/lib/checkbox'
import TableHeader from '../../../UI/TableHeader'
import TableListItem from './TableListItem'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'
import QRPrintModal from './Modal/QRPrintModal/QRPrintModal'
import TableFormModal from './Modal/TableFormModal/TableFormModal'

import { officesAC } from '../../../../actions/actionCreator/offices'

const flexValues = [0.1, 1.5, 1.3, 0.3, 0.7, 0.6]
const COLUMNS_EXTRA_STYLE = [{ marginRight: '14px', minWidth: '16px', marginLeft: '25px', paddingTop: '2px' }, {}, {}, {}, {}, { marginRight: '22px' }]

const getTableHeaderColumns = selectAllCheckboxProps =>
  [<Checkbox {...selectAllCheckboxProps} />, 'Номер стола', 'Теги', 'Рейтинг', 'Бронь', 'Фото']

const TableList = React.memo(props => {
  const {
    tables = [],
    room,
    officeId,
    tags,
  } = props

  const [qrModalState, setQRModalState] = useState({ visible: false, rooms: [] })
  const [tableFormModalState, setTableFormModalState] = useState({ visible: false, table: {} })
  const dispatch = useDispatch()

  const selectedTablesInRoomCount = tables.filter(table => table.checked).length

  const isAllTablesSelected = tables.length
    && selectedTablesInRoomCount === tables.length

  const indeterminate = selectedTablesInRoomCount > 0
    && selectedTablesInRoomCount < tables.length

  const handlePrint = table =>
    setQRModalState({ visible: true, rooms: [{ ...room, tables: [table] }] })

  const toggleQRModal = () =>
    setQRModalState({ ...qrModalState, visible: !qrModalState.visible })

  const toggleTableFormModal = () =>
    setTableFormModalState({ ...tableFormModalState, visible: !tableFormModalState.visible })

  const generateHandleRoomCheck = (tableId, roomId) => () => dispatch(officesAC.checkTable({
    tableId,
    roomId,
  }))

  const recalculateSelectedCounts = () => dispatch(officesAC.recalculateSelectedCounts())

  const handleEditTable = table => setTableFormModalState({ visible: true, table })

  const handleDeleteTable = tableId => dispatch(officesAC.deleteTable({
    tableId,
    recalculateSelectedCounts,
  }))

  const handleSelectAllCheckbox = ({ target }) => dispatch(officesAC.checkAllTables({
    roomId: room.id,
    checked: target.checked,
  }))

  const selectAllCheckboxProps = {
    checked: isAllTablesSelected,
    indeterminate,
    onChange: handleSelectAllCheckbox,
  }

  const renderTableList = () => {
    if (tables.length) {
      return tables.map(table => (
        <TableListItem
          handleDeleteTable={handleDeleteTable}
          handleEditTable={handleEditTable}
          handleTableCheck={generateHandleRoomCheck(table.id, room.id)}
          handlePrint={handlePrint}
          key={table.id}
          table={{ ...table, photos: [], room: room.id }}
          flexValues={flexValues}
        />
      ))
    }
    return <EmptyPlaceholder text='Список столов пуст' style={{ textAlign: 'center' }} />
  }

  return (
    <>
      <div className='table-list__wrapper'>
        <TableHeader
          style={COLUMNS_EXTRA_STYLE}
          rightPaddingWidth={68}
          flexValues={flexValues}
          columns={getTableHeaderColumns(selectAllCheckboxProps)}
        />
        {renderTableList()}
      </div>
      {qrModalState.visible && (
        <QRPrintModal
          start={qrModalState.visible}
          onClose={toggleQRModal}
          rooms={qrModalState.rooms}
        />
      )}
      {tableFormModalState.visible && (
        <TableFormModal
          tags={tags}
          roomId={room.id}
          isRoomUnified={room.room_type_unified}
          officeId={officeId}
          start={tableFormModalState.visible}
          onClose={toggleTableFormModal}
          table={tableFormModalState.table}
        />
      )}
    </>
  )
})

export default TableList
