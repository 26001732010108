import React from 'react'
import Spin from 'antd/lib/spin'

const ListLoadingSpin = () => (
  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }}>
    <Spin />
  </div>
)

export default ListLoadingSpin
