import * as _ from 'lodash'
import {
  CLEAN_UP_FILTERED_USERS_SUCCEED,
  CLEAN_USERS_SUCCEED,
  CLEAR_GROUP_SUCCEED,
  CONFIRM_EMAIL_SUCCEED,
  CREATE_KIOSK_SUCCEED,
  CREATE_OPERATOR_SUCCEED,
  CREATE_USER_SUCCEED,
  DELETE_GROUP_SUCCEED,
  DELETE_KIOSK_SUCCEED,
  DELETE_USER_SUCCEED,
  DELETE_USERS_FROM_NEW_GROUP_SUCCEED,
  GET_ACCESS_GROUPS_SUCCEED,
  GET_GROUP_ACCESS_SUCCEED,
  GET_GROUP_SUCCEED,
  GET_KIOSKS_SUCCEED,
  GET_USER_ACCESS_GROUPS_SUCCEED_ADMIN,
  GET_USERS_SUCCEED,
  PROMOTE_OPERATOR_SUCCEED,
  PUT_KIOSK_SUCCEED,
  PUT_USER_SUCCEED,
  SET_IS_NEW_FLAG_SUCCEED,
  SET_USERS_TO_CREATE_GROUP_SUCCEED,
  UPDATE_GROUP_ADD_SUCCEED,
  UPDATE_GROUP_DELETE_SUCCEED,
} from '../actions/users'

const initialState = {
  kiosksList: [],
  usersList: [],
  deleteFromGroup: [],
  addToGroup: [],
  inGroup: [],
  groupsList: [],
  group: {},
  newGroup: '',
  countNotInGroup: null,
  countInGroup: null,
  accessGroupInfo: [],
  accessUserGroupsInfo: [],
}
export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_NEW_FLAG_SUCCEED: {
      return {
        ...state,
        newGroup: action.payload,
      }
    }
    /* ------------ USERS ------------ */
    /* ========================================================================================== */
    case GET_USERS_SUCCEED: {
      const { users, page, list, count } = action.payload
      const shouldRewrite = !(state[list] && page !== 1)
      const newUsers = shouldRewrite
        ? users.results
        : _.uniqBy([...state[list], ...users.results], 'id')
      return {
        ...state,
        [list]: newUsers,
        [count]: users.count,
      }
    }

    case SET_USERS_TO_CREATE_GROUP_SUCCEED: {
      const { user } = action.payload
      const filteredList = state.usersList.filter(e => e.id !== user.id)
      return {
        ...state,
        inGroup: [...state.inGroup, user],
        usersList: filteredList,
      }
    }

    case CONFIRM_EMAIL_SUCCEED:
    case PUT_USER_SUCCEED: {
      const { user } = action
      const userIndex = state.usersList.findIndex(u => u.id === user.id)
      const newList = state.usersList
      newList[userIndex] = user
      return { ...state, usersList: [...newList] }
    }
    case CREATE_USER_SUCCEED:
    case CREATE_OPERATOR_SUCCEED: {
      const { user, inGroup } = action
      if (inGroup) return { ...state, inGroup: [user, ...state.inGroup] }
      const newList = state.usersList
        ? [user, ...state.usersList]
        : [user]
      return { ...state, usersList: newList }
    }
    case DELETE_USER_SUCCEED: {
      const newList = state.usersList.filter(u => u.id !== action.userId)
      return { ...state, usersList: newList }
    }
    case DELETE_USERS_FROM_NEW_GROUP_SUCCEED: {
      const { user } = action.payload
      const filteredList = state.inGroup.filter(e => e.id !== user.id)
      return {
        ...state,
        inGroup: filteredList,
        usersList: [...state.usersList, user],
      }
    }
    case CLEAN_USERS_SUCCEED:
      return initialState
    case CLEAN_UP_FILTERED_USERS_SUCCEED: {
      return {
        ...state,
        addToGroup: [],
        deleteFromGroup: [],
      }
    }
    case PROMOTE_OPERATOR_SUCCEED: {
      const { userId } = action
      const userIndex = state.usersList.findIndex(u => u.id === userId)
      const newUser = state.usersList[userIndex]
      newUser.has_cp_access = !newUser.has_cp_access
      const newList = state.usersList

      newList[userIndex] = newUser
      return { ...state, usersList: [...newList] }
    }
    /* ------------ GROUPS ------------ */
    /* ========================================================================================== */
    case GET_GROUP_SUCCEED: {
      return {
        ...state,
        group: action.payload,
      }
    }
    case GET_ACCESS_GROUPS_SUCCEED: {
      const { groups } = action.payload
      return {
        ...state,
        groupsList: groups,
      }
    }
    case GET_USER_ACCESS_GROUPS_SUCCEED_ADMIN: {
      const { groups } = action
      return {
        ...state,
        accessUserGroupsInfo: groups,
      }
    }
    case GET_GROUP_ACCESS_SUCCEED: {
      return {
        ...state,
        accessGroupInfo: action.payload,
      }
    }
    case UPDATE_GROUP_DELETE_SUCCEED: {
      const { id } = action.payload
      return {
        ...state,
        deleteFromGroup: [...state.deleteFromGroup, id],
      }
    }
    case UPDATE_GROUP_ADD_SUCCEED: {
      const { id } = action.payload
      return {
        ...state,
        addToGroup: [...state.addToGroup, id],
      }
    }
    case CLEAR_GROUP_SUCCEED: {
      return { ...state, group: {} }
    }
    case DELETE_GROUP_SUCCEED: {
      const filteredGroupList = state.groupsList?.filter(group => group.id !== action.id)
      return {
        ...state,
        groupsList: filteredGroupList,
      }
    }
    /* ------------ KIOSK ------------ */
    /* ========================================================================================== */
    case GET_KIOSKS_SUCCEED: {
      const { filteredKiosks } = action
      return {
        ...state,
        kiosksList: filteredKiosks,
      }
    }
    case CREATE_KIOSK_SUCCEED: {
      return { ...state, kiosksList: [...state.kiosksList, action.kiosk] }
    }
    case DELETE_KIOSK_SUCCEED: {
      const newList = state.kiosksList.filter(k => k.id !== action.userId)
      return { ...state, kiosksList: newList }
    }
    case PUT_KIOSK_SUCCEED: {
      const { kiosk } = action
      const kioskIndex = state.kiosksList.findIndex(k => k.id === kiosk.id)
      const newList = state.kiosksList
      newList[kioskIndex] = kiosk
      return { ...state, kiosksList: [...newList] }
    }
    default:
      return state
  }
}

export default usersReducer
