import React from 'react'
import { connect } from 'react-redux'

import BookingCardListItem from './BookingCardListItem'
import Button from '../../UI/Button'
import CardListItem from '../../helpers/lists/CardListItem'
import ConfirmationModal from '../../UI/modals/ConfirmationModal'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import FadeTransition from '../../UI/animations/transition/FadeTransition'
import MainCard from '../../MainCard'
import NewBookingForm from './NewBookingForm'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import TableInfo from './TableInfo'
import UnderlinedTitle from '../../UI/UnderlinedTitle'

import { usersAC } from '../../../actions/actionCreator/usersAC'
import { bookingAC } from '../../../actions/actionCreator/bookingAC'
import { officesAC } from '../../../actions/actionCreator/offices'
import ListLoadingSpin from '../../UI/Spin/ListLoadingSpin'

class TableReservationScreen extends React.Component {
  state = {
    isFormShown: false,
    isConfirmShow: false,
    deletedTable: null,
  }

  componentDidMount() {
    const { getBookings, match, getTableInfo } = this.props
    getBookings(match.params.id)
    getTableInfo(match.params.id)
  }

  toggleIsFormShown = isShown =>
    (isShown !== this.state.isFormShown) && this.setState({ isFormShown: isShown })

  onBookDeleteClick = id => this.setState({ isConfirmShow: true, deletedTable: id })

  topButton = () => (
    <div className='button_container__content_right'>
      <Button
        title='Добавить новую бронь'
        styles='bordered_btn'
        style={{ marginRight: '10px' }}
        icon='icon__add__in_circle'
        onClick={() => this.toggleIsFormShown(true)}
      />
    </div>
  )

  render() {
    const {
      floor,
      room,
      rating,
      table,
      loading,
      bookHistory,
      match,
      tags,
      description,
      deleteBooking,
      appLoading,
    } = this.props
    const { isFormShown, deletedTable, isConfirmShow } = this.state
    return (
      <>
        <MainCard
          title='БРОНИРОВАНИЕ'
          icon='icon__clock'
          loading={appLoading}
          separatedBtn={this.topButton()}
          innerContainerStyle={{ overflow: 'hidden' }}
        >
          <div className='basic_form full_height'>
            <div className='container_form__left' style={{ flex: 1, paddingRight: '1%', minWidth: 'auto' }}>
              <ScrollTransition loading>
                <div className='flex_container__column' style={{ marginRight: '22px', flex: 1 }}>
                  <UnderlinedTitle title='РАБОЧЕЕ МЕСТО' />
                  <TableInfo
                    floor={floor}
                    room={room}
                    rating={rating}
                    table={table}
                    description={description}
                    tags={tags}
                  />
                  <div className='flex_container__column full_height margin_top flex_container__flex_1'>
                    <FadeTransition loading={isFormShown}>
                      <div className='flex_container__new_booking'>
                        <UnderlinedTitle title='+ НОВАЯ БРОНЬ' />
                        <NewBookingForm
                          meetingRoom
                          isFormShown={isFormShown}
                          table={match?.params.id}
                          onCancel={() => this.toggleIsFormShown(false)}
                        />
                      </div>
                    </FadeTransition>
                  </div>
                </div>
              </ScrollTransition>
            </div>

            <div
              className='container_form__right'
              style={{ flex: '1.2', maxWidth: 'unset' }}
            >
              <UnderlinedTitle title='ИСТОРИЯ БРОНИ' />
              {/* Чтобы правильно отрабатывать лоадер */}
              <ScrollTransition loading={!!'true'}>
                {loading && <ListLoadingSpin />}
                <div className=' card_list'>
                  {bookHistory?.length > 0
                    ? bookHistory.map(b => (
                      <CardListItem
                        key={b.id}
                        confirm
                        onEdit={null}
                        extraClassName=' card_list__item__no_hover'
                        onCardClick={() => null}
                        confirmDeletion={() => this.onBookDeleteClick(b.id)}
                      >
                        <BookingCardListItem booking={b} />
                      </CardListItem>
                    ))
                    : <EmptyPlaceholder text=' Список бронирований пуст' />}
                </div>
              </ScrollTransition>
            </div>
          </div>
        </MainCard>
        <ConfirmationModal
          id={deletedTable}
          start={isConfirmShow}
          text=' Завершить бронирование?'
          onClose={() => this.setState({ isConfirmShow: false, deletedTable: null })}
          onConfirm={() => {
            deleteBooking(deletedTable)
            this.setState({ isConfirmShow: false, deletedTable: null })
          }}
        />
      </>
    )
  }
}

const mapStateToProps = ({ booking, app, offices }) => {
  const { history, table } = booking
  return {
    bookHistory: history || [],
    description: table?.description || '',
    floor: offices.tables.floor_title || 0,
    loading: app.isBookingLoading,
    appLoading: app.isTableLoading,
    room: offices.tables.room_title || 0,
    table: offices.tables.title || 0,
    tags: table?.tags || [],
    rating: table?.rating || 'нет',
  }
}

const mapDispatchToProps = dispatch => ({
  getBookings: (tableId, callback) => dispatch(bookingAC.getBookings({ tableId, callback })),
  getUsers: ({ page, searchString }) => dispatch(usersAC.getUsers({ page, searchString })),
  deleteBooking: bookId => dispatch(bookingAC.deleteBooking(bookId)),
  getTableInfo: tableId => dispatch(officesAC.getTables({ tableId })),
})

export default connect(mapStateToProps, mapDispatchToProps)(TableReservationScreen)
