export const getUsers = state => state.users.usersList
export const getFilteredKiosks = state => state.users.kiosksList
export const getUsersCount = state => state.users.countNotInGroup
export const getUserGroup = state => state.users.accessUserGroupsInfo
// TODO прикрутить реселект
export const getDeleteFromGroup = state => state.users.deleteFromGroup
  .filter((item, index, arr) => arr.indexOf(item) === index)
export const getAddToGroup = state => state.users.addToGroup
  .filter((item, index, arr) => arr.indexOf(item) === index)
export const getInGroup = state => state.users.inGroup
export const getInGroupId = state => state.users.inGroup.map(e => e.id)
export const getAccessGroupInfo = state => state.users.accessGroupInfo
export const getGroupsList = state => state.users.groupsList
