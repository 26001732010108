import React, { useCallback, useEffect } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import MainCard from '../../MainCard'
import GroupInfoForm from './GroupInfoForm'
import UsersPickContainer from './UsersPickContainer'

import { groupAC } from '../../../actions/actionCreator/groups'
import {
  convertGroupAccessCodeToTitle,
  convertGroupAccessTitleToAccessCode,
} from '../../../utils/data/accessCodesConversion'

const NEW_GROUP_ROUTE = '/groups/new'
const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Название должно содержать минимум 2 символа')
    .max(50, 'Максимальная длина 50 символов')
    .required('Обязательное поле'),
})

const translateRightsToAccess = group => convertGroupAccessCodeToTitle(group.access)

const InnerForm = React.memo(props => {
  const {
    setFieldValue,
    setFieldTouched,
    group,
    values,
    setTouched,
    setSubmitting,
    isSubmitting,
    touched,
    errors,
    setValues,
  } = props
  const radioGroupData = ['Посетитель', 'Администратор']
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const match = useRouteMatch()
  const resetDetails = () => {
    setValues({
      ...values,
      title: '',
      description: '',
      access: 'Посетитель',
    })
  }
  // id приходит с саги
  const succeedCallback = useCallback(id => {
    history.push(`/groups/${id}`)
  }, [])
  const failedCallback = () => setSubmitting(false)
  const getBasicGroupInfo = () => {
    const { access: accessTitle, ...valuesWithoutAccessField } = values

    const valuesWithAccessCode = {
      ...valuesWithoutAccessField,
      access: convertGroupAccessTitleToAccessCode(accessTitle),

    }

    return group?.pre_defined ? valuesWithoutAccessField : valuesWithAccessCode
  }
  // собирает данные в маининфо
  const packGroup = () => ({
    id: params.id,
    mainInfo: { ...getBasicGroupInfo() },
  })
  const saveGroup = () => {
    if (isSubmitting) return
    setSubmitting(true)
    setTouched({ title: true, description: true })
    if (!ValidationSchema.isValidSync(values)) {
      setSubmitting(false)
      return
    }
    if (params.id) {
      dispatch(groupAC.updateGroup(packGroup(), succeedCallback, failedCallback))
    } else {
      dispatch(groupAC.createNewGroup({
        group: { ...getBasicGroupInfo() },
        succeedCallback,
        failedCallback,
      }))
    }
  }
  const getErrorCondition = useCallback(field => touched[field] && errors[field], [touched, errors])

  const isKiosk = group?.title === 'Информационная панель'
  group?.pre_defined && isKiosk && history.push(`/groups/${group.id}`)

  useEffect(() => {
    // зачищаем поля при редактировании
    // сетаем значение для  правильного отображения полей форм
    if (match.path === NEW_GROUP_ROUTE) {
      resetDetails()
      dispatch(groupAC.setNewRoute(NEW_GROUP_ROUTE))
    }
    // очистка стейта для повторного вхождения
    return () => {
      dispatch(groupAC.setNewRoute(''))
      dispatch(groupAC.clearGroup())
    }
  }, [])

  return (
    <>
      <MainCard
        title='Группа доступа'
        icon='icon__user'
        style={{ width: '100%' }}
      >
        <div className='flex_container full_height'>
          <GroupInfoForm
            values={values}
            preDefined={group?.pre_defined}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            radioOptions={radioGroupData}
            getErrorCondition={getErrorCondition}
          />
          <UsersPickContainer onSave={saveGroup} />
        </div>
      </MainCard>
    </>
  )
})

const GroupEditCard = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ group, isNewGroup }) => isNewGroup
    ? { title: '', description: '', access: 'Посетитель' }
    : {
      title: group?.title,
      description: group?.description || '',
      access: translateRightsToAccess(group),
    },
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ users, app }) => ({
  group: users?.group,
  loading: app.isAppLoading,
  newGroup: users?.newGroup === NEW_GROUP_ROUTE,
})
export default connect(mapStateToProps, {})(GroupEditCard)
