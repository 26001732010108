import React from 'react'
import { Route, Switch } from 'react-router-dom'

import OfficesList from './OfficesList/OfficesList'
import OfficeDetailInfo from './OfficeEdit/DetailInfo/index'
import TableReservationScreen from '../Reservation/bookTableScreen'
import ErrorPage from '../ErrorsPage'
import ERRORS from '../../utils/data/errors'
import OfficeMap from '../Map'
import OfficeMainInfoEdit from './OfficeEdit/MainInfo'


const OfficesRouter = () => (
  <Switch>
    <Route exact path='/offices' render={() => <OfficesList />} />
    <Route exact path='/offices/new' render={props => <OfficeMainInfoEdit {...props} isNew />} />
    <Route exact path='/offices/:id/edit-main-info' render={() => <OfficeMainInfoEdit />} />
    <Route exact path='/offices/:id/edit-detail-info' render={() => <OfficeDetailInfo />} />
    <Route path='/offices/:id/map' render={() => <OfficeMap />} />
    <Route exact path='/offices/reservations/table/:id' component={TableReservationScreen} />
    <Route
      from='*'
      render={props => <ErrorPage {...props} error={ERRORS['404']} />}
    />
  </Switch>
)

export default OfficesRouter
