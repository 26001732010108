import {
  CLEAN_USERS_SUCCEED,
  CREATE_KIOSK,
  CREATE_USER,
  GET_KIOSKS,
  GET_USER_ACCESS_GROUPS,
  GET_USERS,
  GET_USERS_THROTTLE,
  PUT_KIOSK,
} from '../users'

export const usersAC = {
  getUsers: ({
    group, page, excludeGroupId,
    searchString, callback, pagination, throttle,
  }) => ({
    type: throttle ? GET_USERS_THROTTLE : GET_USERS,
    payload: {
      group,
      page,
      excludeGroupId,
      searchString,
      callback,
      pagination,
    },
  }),
  cleanUsers: () => ({ type: CLEAN_USERS_SUCCEED }),
  createUser: ({ user, callback, inGroup }) =>
    ({ type: CREATE_USER, payload: { user, callback, inGroup } }),
  getUserAccessGroups: ({ id }) => ({ type: GET_USER_ACCESS_GROUPS, id }),
}

export const kioskAC = {
  putKiosk: (kioskId, kiosk, callback) => ({ type: PUT_KIOSK, kioskId, kiosk, callback }),
  createKiosk: (kiosk, callback) => ({ type: CREATE_KIOSK, kiosk, callback }),
  getKiosks: ({ page, searchString }) => ({ type: GET_KIOSKS, page, searchString }),
}
