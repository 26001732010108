import React from 'react'
import { CSSTransition } from 'react-transition-group'
import { Scrollbar } from 'react-scrollbars-custom'

import ListLoadingSpin from '../../Spin/ListLoadingSpin'

const ScrollTransition = React.memo(({
  onScroll,
  children,
  loading,
  onLiveScroll,
  style,
  className,
  paginationLoading,
  paginationOnTop,
}) => (
  <Scrollbar onScrollStop={onScroll} style={style} className={className} onScroll={onLiveScroll}>
    <CSSTransition classNames='card_list' in={loading} timeout={400} appear mountOnEnter>
      <>
        {paginationOnTop && <ListLoadingSpin />}
        {children}
        {paginationLoading && <ListLoadingSpin />}
      </>
    </CSSTransition>
  </Scrollbar>
))

export default ScrollTransition
