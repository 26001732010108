import React from 'react'
import { Field as FormikField } from 'formik'
import Form from 'antd/lib/form'
import Checkbox from 'antd/lib/checkbox'

const CheckBox = React.memo(({
  name,
  value,
  style,
  title,
  onChange,
  setFieldTouched,
  disabled,
}) => (
  <Form.Item>
    <FormikField
      render={() => (
        <Checkbox
          name={name}
          style={style}
          checked={value}
          onBlur={() => setFieldTouched(name, true)}
          onChange={({ target }) => onChange(name, target.checked)}
          disabled={disabled}
        >
          {title}
        </Checkbox>
      )}
    />
  </Form.Item>
))

export default CheckBox
