import {
  COPY_PHOTO_TO_CURRENT_FILES,
  CLEAR_FILES,
  CHANGE_USER_PHOTO_SUCCEED,
  DELETE_PHOTO,
  POST_PHOTO_SUCCEED,
  SET_PHOTO_LOADING,
  SET_STOP_PHOTO_LOADING,
  DELETE_PHOTO_FROM_NEW_OFFICE,
  DELETE_PHOTO_FROM_NEW_ROOM,
} from '../actions/files'
import HOST from '../utils/data/connectionData'

export const filesReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_PHOTO_LOADING: return { ...state, loading: true }
    case SET_STOP_PHOTO_LOADING: return { ...state, loading: false }
    case POST_PHOTO_SUCCEED:
      return { ...state, photo: { id: action.photo.id, path: action.photo.path }, loading: false }

    case COPY_PHOTO_TO_CURRENT_FILES: {
      return {
        ...state,
        currentFiles: {
          id: action?.photo?.id,
          path: action?.photo?.id ? action?.photo?.path : `${HOST}${action?.photo?.path}`,
        },
      }
    }

    case DELETE_PHOTO: {
      const { isNew } = action
      if (isNew) return { ...state, photo: null }

      return { ...state, currentFiles: null }
    }
    // Добавляет файл в массив
    case CHANGE_USER_PHOTO_SUCCEED: {
      const { photo, userId } = action
      const newItem = { ...photo, userId }

      const filteredList = state.currentFilesList
        ? state.currentFilesList.filter(file => file.userId !== userId)
        : []

      const newList = filteredList.length > 0
        ? [...filteredList, newItem]
        : [newItem]

      return { ...state, currentFilesList: newList }
    }

    case CLEAR_FILES:
      return { ...state, currentFiles: null, photo: null, currentFilesList: null }

    case DELETE_PHOTO_FROM_NEW_OFFICE:
      return { ...state, currentFiles: { ...state.currentFiles, office: undefined } }

    case DELETE_PHOTO_FROM_NEW_ROOM:
      return { ...state, currentFiles: { ...state.currentFiles, room: undefined } }

    default: return state
  }
}

export default filesReducer
