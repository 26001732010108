import React from 'react'

import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import UserCard from './UserCard'

import '../../../styles/animations/userForm.scss'

const UserList = React.memo(({ list, onDelete, onEmailConfirm }) => {
  if (!list.length) {
    return <EmptyPlaceholder text='Список пользователей пуст' />
  }
  return (
    <div className='container__list '>
      {list.map((u, index) =>
        (
          <UserCard
            key={u.id}
            user={u}
            onDelete={onDelete}
            index={index}
            onEmailConfirm={onEmailConfirm}
          />
        ))}
    </div>
  )
})

export default UserList
