import React, { useMemo } from 'react'

import DisabledInputBlock from '../../UI/dataBlock/DisabledInputBlock'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import { convertGroupAccessCodeToTitle } from '../../../utils/data/accessCodesConversion'

const GroupInfo = React.memo(({ group = {} }) => {
  const { title, description, access } = group

  const memoizedConvertGroupAccessCodeToTitle = useMemo(
    () => convertGroupAccessCodeToTitle(access),
    [access],
  )

  return (
    <div className='container__left_side__user_group_form'>
      <ScrollTransition loading>
        <div style={{ paddingRight: '22px' }}>
          <DisabledInputBlock title='Название' subtitle={title || 'Не указано'} />
          <DisabledInputBlock title='Описание' subtitle={description || 'Не указано'} />
          <DisabledInputBlock title='Права доступа' subtitle={memoizedConvertGroupAccessCodeToTitle || 'Не указано'} />
        </div>
      </ScrollTransition>
    </div>
  )
})

export default GroupInfo
