/* ------------ Offices ------------ */
/* =========================================================================================== */
export const GET_OFFICES = 'GET_OFFICES'
export const GET_OFFICES_THROTTLE = 'GET_OFFICES_THROTTLE'
export const GET_OFFICE = 'GET_OFFICE'
export const GET_OFFICE_SUCCEED = 'GET_OFFICE_SUCCEED'
export const GET_OFFICE_FAILED = 'GET_OFFICE_FAILED'
export const GET_OFFICES_SUCCEED = 'GET_OFFICES_SUCCEED'
export const GET_OFFICES_FAILED = 'GET_OFFICES_FAILED'
export const POST_OFFICE = 'POST_OFFICE'
export const POST_OFFICE_SUCCEED = 'POST_OFFICE_SUCCEED'
export const PUT_OFFICE = 'PUT_OFFICE'
export const PUT_OFFICE_SUCCEED = 'PUT_OFFICE_SUCCEED'
export const DELETE_OFFICE = 'DELETE_OFFICE'
export const DELETE_OFFICE_SUCCEED = 'DELETE_OFFICE_SUCCEED'
export const CLEAR_OFFICE = 'CLEAR_OFFICE'
export const POST_OFFICE_IMAGES = 'POST_OFFICE_IMAGES'
export const POST_OFFICE_IMAGES_SUCCEED = 'POST_OFFICE_IMAGES_SUCCEED'
/* =========================================================================================== */

/* ------------ Licenses ------------ */
/* =========================================================================================== */
export const GET_OFFICES_LICENSES = 'GET_OFFICES_LICENSES'
export const GET_OFFICES_LICENSES_SUCCEED = 'GET_OFFICES_LICENSES_SUCCEED'
/* =========================================================================================== */

/* ------------ Floors ------------ */
/* =========================================================================================== */
export const GET_FLOORS = 'GET_FLOORS'
export const GET_FLOORS_SUCCEED = 'GET_FLOORS_SUCCEED'
export const POST_FLOORS = 'POST_FLOORS'
export const POST_FLOORS_SUCCEED = 'POST_FLOORS_SUCCEED'
export const PUT_FLOOR = 'PUT_FLOOR'
export const PUT_FLOOR_SUCCEED = 'PUT_FLOOR_SUCCEED'
export const DELETE_FLOOR = 'DELETE_FLOOR'
export const DELETE_FLOOR_SUCCEED = 'DELETE_FLOOR_SUCCEED'
export const CLEAR_FLOORS_SUCCEED = 'CLEAR_FLOORS_SUCCEED'
/* =========================================================================================== */

/* ------------ Zones ------------ */
/* =========================================================================================== */
export const GET_ZONES = 'GET_ZONES'
export const GET_ZONES_SUCCEED = 'GET_ZONES_SUCCEED'
export const POST_ZONES = 'POST_ZONES'
export const POST_ZONES_SUCCEED = 'POST_ZONES_SUCCEED'
export const PUT_ZONE = 'PUT_ZONE'
export const PUT_ZONE_SUCCEED = 'PUT_ZONE_SUCCEED'
export const DELETE_ZONE = 'DELETE_ZONE'
export const DELETE_ZONE_SUCCEED = 'DELETE_ZONE_SUCCEED'
/* =========================================================================================== */

/* ------------ Tags ------------ */
/* =========================================================================================== */
export const GET_OFFICE_TAGS = 'GET_OFFICE_TAGS'
export const GET_OFFICE_TAGS_SUCCEED = 'GET_OFFICE_TAGS_SUCCEED'
export const GET_OFFICE_TAGS_FAILED = 'GET_OFFICE_TAGS_FAILED'
export const POST_OFFICE_TAGS = 'POST_OFFICE_TAGS'
export const POST_OFFICE_TAGS_SUCCEED = 'POST_OFFICE_TAGS_SUCCEED'
export const PUT_OFFICE_TAG = 'PUT_OFFICE_TAG'
export const PUT_OFFICE_TAG_SUCCEED = 'PUT_OFFICE_TAG_SUCCEED'
export const DELETE_OFFICE_TAG = 'DELETE_OFFICE_TAG'
export const DELETE_OFFICE_TAG_SUCCEED = 'DELETE_OFFICE_TAG_SUCCEED'
export const POST_TAG_ICON = 'POST_TAG_ICON'
export const POST_TAG_ICON_SUCCEED = 'POST_TAG_ICON_SUCCEED'
/* =========================================================================================== */

/* ------------ RoomTypes ------------ */
/* =========================================================================================== */
export const GET_ROOM_TYPES = 'GET_ROOM_TYPES'
export const GET_ROOM_TYPES_SUCCEED = 'GET_ROOM_TYPES_SUCCEED'
export const GET_ROOM_TYPES_FAILED = 'GET_ROOM_TYPES_FAILED'
export const POST_ROOM_TYPES = 'POST_ROOM_TYPES'
export const POST_ROOM_TYPES_SUCCEED = 'POST_ROOM_TYPES_SUCCEED'
export const DELETE_ROOM_TYPE = 'DELETE_ROOM_TYPE'
export const DELETE_ROOM_TYPE_SUCCEED = 'DELETE_ROOM_TYPE_SUCCEED'
export const PUT_ROOM_TYPE = 'PUT_ROOM_TYPE'
export const PUT_ROOM_TYPE_SUCCEED = 'PUT_ROOM_TYPE_SUCCEED'
export const POST_ROOM_TYPE_ICON = 'POST_ROOM_TYPE_ICON'
export const POST_ROOM_TYPE_ICON_SUCCEED = 'POST_ROOM_TYPE_ICON_SUCCEED'
/* =========================================================================================== */

/* ------------ Rooms ------------ */
/* =========================================================================================== */
export const GET_ROOMS = 'GET_ROOMS'
export const GET_ROOMS_SUCCEED = 'GET_ROOMS_SUCCEED'
export const GET_FILTERED_ROOMS = 'GET_FILTERED_ROOMS'
export const POST_ROOM = 'POST_ROOM'
export const POST_ROOM_SUCCEED = 'POST_ROOM_SUCCEED'
export const PUT_ROOM = 'PUT_ROOM'
export const PUT_ROOM_SUCCEED = 'PUT_ROOM_SUCCEED'
export const DELETE_ROOM = 'DELETE_ROOM'
export const DELETE_ROOM_SUCCEED = 'DELETE_ROOM_SUCCEED'
export const DELETE_ROOMS_LIST_SUCCEED = 'DELETE_ROOMS_LIST_SUCCEED'
export const CLEAR_ROOMS_SUCCEED = 'CLEAR_ROOMS_SUCCEED'
/* =========================================================================================== */

/* ------------ Tables ------------ */
/* =========================================================================================== */
export const GET_TABLES = 'GET_TABLES'
export const GET_TABLES_SUCCEED = 'GET_TABLES_SUCCEED'
export const POST_TABLE = 'POST_TABLE'
export const POST_TABLE_SUCCEED = 'POST_TABLE_SUCCEED'
export const PUT_TABLE = 'PUT_TABLE'
export const PUT_TABLE_SUCCEED = 'PUT_TABLE_SUCCEED'
export const DELETE_TABLE = 'DELETE_TABLE'
export const DELETE_TABLE_SUCCEED = 'DELETE_TABLE_SUCCEED'
export const DELETE_TABLES_LIST_SUCCEED = 'DELETE_TABLES_LIST_SUCCEED'
export const CLEAR_TABLES_SUCCEED = 'CLEAR_TABLES_SUCCEED'

/* =========================================================================================== */

/* ------------ Rooms and Tables ------------ */
/* =========================================================================================== */
export const DELETE_SELECTED_ROOMS_AND_TABLES = 'DELETE_SELECTED_ROOMS_AND_TABLES'
/* =========================================================================================== */

/* ------------ Maps & Markers ------------ */
/* =========================================================================================== */
export const POST_MAP_TO_FLOOR = 'POST_MAP_TO_FLOOR'
export const UPDATE_MAP_TO_FLOOR = 'UPDATE_MAP_TO_FLOOR'
export const POST_MAP_TO_FLOOR_SUCCEED = 'POST_MAP_TO_FLOOR_SUCCEED'
export const DELETE_MAP = 'DELETE_MAP'
export const DELETE_MAP_SUCCEED = 'DELETE_MAP_SUCCEED'

export const POST_ROOM_MARKER = 'POST_ROOM_MARKER'
export const POST_ROOM_MARKER_SUCCEED = 'POST_ROOM_MARKER_SUCCEED'
export const PUT_ROOM_MARKER = 'PUT_ROOM_MARKER'
export const PUT_ROOM_MARKER_SUCCEED = 'PUT_ROOM_MARKER_SUCCEED'
export const DELETE_ROOM_MARKER = 'DELETE_ROOM_MARKER'
export const DELETE_ROOM_MARKER_SUCCEED = 'DELETE_ROOM_MARKER_SUCCEED'

export const POST_TABLE_MARKER = 'POST_TABLE_MARKER'
export const POST_TABLE_MARKER_SUCCEED = 'POST_TABLE_MARKER_SUCCEED'
export const PUT_TABLE_MARKER = 'PUT_TABLE_MARKER'
export const PUT_TABLE_MARKER_SUCCEED = 'PUT_TABLE_MARKER_SUCCEED'
export const DELETE_TABLE_MARKER = 'DELETE_TABLE_MARKER'
export const DELETE_TABLE_MARKER_SUCCEED = 'DELETE_TABLE_MARKER_SUCCEED'

export const DELETE_ALL_MARKERS_ON_FLOOR = 'DELETE_ALL_MARKERS_ON_FLOOR'
export const DELETE_ALL_MARKERS_ON_FLOOR_SUCCEED = 'DELETE_ALL_MARKERS_ON_FLOOR_SUCCEED'
/* =========================================================================================== */

/* ------------ Для чекбоксов в DetailInfo ------------ */
/* =========================================================================================== */
export const CHECK_ROOM = 'CHECK_ROOM'
export const CHECK_TABLE = 'CHECK_TABLE'
export const CHECK_ALL_ROOMS = 'CHECK_ALL_ROOMS'
export const CHECK_ALL_TABLES = 'CHECK_ALL_TABLES'
export const RECALCULATE_SELECTED_COUNTS = 'RECALCULATE_SELECTED_COUNTS'
/* =========================================================================================== */
