import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import Button from '../../../../../UI/Button'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import FloorListItem from './FloorListItem'
import handleUpload from '../../../../../Map/helpers/loadMap'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import LargeSpin from '../../../../../UI/Spin/LargeSpin'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import TableHeader from '../../../../../UI/TableHeader'
import TextModal from '../../../../../UI/modals/TextModal'
import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'

import { officesAC } from '../../../../../../actions/actionCreator/offices'

const FLOORS_LIST_COLUMNS_NAMES = ['Название этажа', 'Карта']
const COLUMNS_FLEX_VALUES = [1, 1]

const FloorsInfo = React.memo(() => {
  const history = useHistory()
  const params = useParams()
  const [isAddSingleFloorInputVisible, setIsAddSingleFloorInputVisible] = useState(false)
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isFloorsLoading = useSelector(({ app }) => app.isFloorsLoading)
  const floors = useSelector(({ offices }) => offices.floors)

  const dispatch = useDispatch()

  const { current: office } = useRef(params.id)

  const handleFloorPost = floor => dispatch(officesAC.postFloors({
    titles: [floor.title],
    office,
    callback: () => setIsAddSingleFloorInputVisible(false),
  }))

  const handleAddSingleFloor = () => setIsAddSingleFloorInputVisible(val => !val)

  const handleAddMultipleFloors = () => setIsModalOpen(true)

  const handlePackageUpload = (titles, callback) => dispatch(officesAC.postFloors({
    titles,
    office,
    callback,
  }))

  const generateHandleFloorSave = floor => (updatedFloor, callback) => dispatch(
    officesAC.putFloor({
      floor: { id: floor.id, title: updatedFloor.title },
      office,
      callback,
    }),
  )

  const generateHandleDeleteFloor = floorId => () => dispatch(
    officesAC.deleteFloor({ floorId }),
  )

  const handlePostMap = (floorId, map, sizes) => dispatch(
    officesAC.postMapToFloor({ floorId, map, sizes }),
  )

  const renderFloorsList = () => floors.length || isAddSingleFloorInputVisible
    ? (
      <ScrollTransition loading>
        <div className='floors-list'>
          {isAddSingleFloorInputVisible && (
            <FloorListItem
              onCancel={handleAddSingleFloor}
              onSave={handleFloorPost}
              isNew
            />
          )}
          {floors.map(floor => (
            <FloorListItem
              floor={floor}
              key={floor.id}
              onDelete={generateHandleDeleteFloor(floor.id)}
              onMapUpload={e => handleUpload(e, floor.id, handlePostMap)}
              onSave={generateHandleFloorSave(floor)}
              redirectToMap={floorID => history.push(`/offices/${params.id}/map/${floorID}`)}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список этажей пуст' />

  if (isFloorsLoading) {
    return <LargeSpin />
  }

  return (
    <>
      <div className='flex_container__column' style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ЭТАЖИ' />
        <SubtitleWithActions
          subtitle={`Всего этажей: ${floors?.length || 0}`}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={handleAddSingleFloor}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleFloorInputVisible ? 'Отменить добавление этажа' : 'Добавить 1 этаж'}
          />
          <Button
            onClick={handleAddMultipleFloors}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>

      <TableHeader
        columns={FLOORS_LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={64}
      />
      {renderFloorsList()}
      <TextModal
        tags={floors}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ЭТАЖЕЙ'
        subtitle='Введите названия этажей через запятую или числовой диапазон'
        hint='Пример: Цокольный этаж, 3..4, 6'
        submitTitle='Добавить этажи'
      />
      <ImportFromCsvModal
        form='floors'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />
    </>
  )
})

export default FloorsInfo
