import React from 'react'
import DatePicker from 'antd/lib/date-picker'
import locale from 'antd/es/date-picker/locale/ru_RU'
import isDateBeforeToday from '../../../utils/dates/isDateBeforeToday'

const RangePicker = React.memo(({ style, value, onChange, setFieldTouched, name, children, id, error }) => (
  <div className='validation_block'>
    <DatePicker.RangePicker
      id={id}
      name={name}
      value={value}
      locale={locale}
      format='DD MMM HH:00'
      showTime={{ format: 'HH' }}
      disabledDate={isDateBeforeToday}
      setFieldTouched={setFieldTouched}
      onChange={val => onChange(name, val)}
      style={{ ...style, border: error && '1px solid #CD4141' }}
    />
    <div className='validation_block margin_top__small'>{children}</div>
  </div>
))

export default RangePicker
