import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import message from 'antd/lib/message'
import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import Button from '../../../../../UI/Button'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import TextModal from '../../../../../UI/modals/TextModal'
import TableHeader from '../../../../../UI/TableHeader'
import RoomTypesListItem from './RoomTypesListItem'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import LargeSpin from '../../../../../UI/Spin/LargeSpin'

import { officesAC } from '../../../../../../actions/actionCreator/offices'
import { getRoomTypes } from '../../../../../../reducers/offices-selectors'

const LIST_COLUMNS_NAMES = [
  'Название типа помещения',
  'Иконка',
  'Цвет',
  'Бронь',
  'Часовой инт-л',
  'Дневной инт-л',
]

const COLUMNS_FLEX_VALUES = [10, 3, 2, 3, 6, 6]

const roomTypeDefault = {
  title: '',
  color: null,
  bookable: false,
  icon: null,
  work_interval_days: 0,
  work_interval_hours: 0,
}

const RoomTypes = React.memo(() => {
  const params = useParams()
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isTextModalOpen, setIsTextModalOpen] = useState(false)
  const [isAddSingleRoomTypeInputVisible, setIsAddSingleRoomTypeInputVisible] = useState(false)

  const dispatch = useDispatch()

  const { current: officeId } = useRef(params.id)

  const isRoomTypesLoading = useSelector(({ app }) => app.isRoomTypesLoading)
  const roomTypesList = useSelector(getRoomTypes)

  const isRoomTypeTitleUnique = (title, roomTypes) => !roomTypes
    .some(roomType => roomType.title.toLowerCase() === title.toLowerCase())

  const validateRoomTypePut = (roomTypeId, title, roomTypesList) => {
    const { title: currentTitle } = roomTypesList.find(({ id }) => id === roomTypeId)

    if (title === currentTitle) return true

    if (!isRoomTypeTitleUnique(title, roomTypesList)) {
      message.error('Тип помещений с таким названием уже существует')

      return false
    }

    return true
  }

  const validateRoomTypePost = (title, roomTypesList) => {
    if (!isRoomTypeTitleUnique(title, roomTypesList)) {
      message.error('Тип помещений с таким названием уже существует')

      return false
    }

    return true
  }

  const validatePackageUpload = (titles, roomTypesList) => {
    const isAllTitlesUnique = !titles
      .some(title => isRoomTypeTitleUnique(title, roomTypesList) === false)

    if (!isAllTitlesUnique) {
      message.error('Типы помещений с некоторыми из этих названий уже существуют')

      return
    }

    return true
  }

  const handleAddMultipleRoomTypes = () => setIsTextModalOpen(true)

  const toggleAddSingleRoomType = () => setIsAddSingleRoomTypeInputVisible(val => !val)

  const handleRoomTypePut = (roomTypeId, roomTypeData, callback) => {
    const { title } = roomTypeData

    validateRoomTypePut(roomTypeId, title, roomTypesList)
    && dispatch(officesAC.putRoomType({
      roomTypeId, roomTypeData, callback,
    }))
  }

  const handleRoomTypePost = roomTypeData => {
    const {
      bookable,
      title,
      unified,
      work_interval_days,
      work_interval_hours,
    } = roomTypeData

    const roomTypeSettings = {
      bookable,
      unified,
      work_interval_days,
      work_interval_hours,
    }

    return validateRoomTypePost(title, roomTypesList) && dispatch(officesAC.postRoomTypes({
      titles: [title],
      officeId,
      roomTypeSettings,
      callback: toggleAddSingleRoomType,
    }))
  }

  const handlePackageUpload = (titles, callback) => validatePackageUpload(titles, roomTypesList)
    && dispatch(officesAC.postRoomTypes({
      titles,
      officeId,
      callback,
    }))

  const handleRoomTypeDelete = roomTypeId => dispatch(officesAC.deleteRoomType({
    roomTypeId,
  }))

  const handleUploadRoomTypeIcon = (roomTypeData, icon) => dispatch(
    officesAC.uploadRoomTypeIcon({
      roomTypeData, icon,
    }),
  )

  const renderRoomTypesList = () => roomTypesList.length || isAddSingleRoomTypeInputVisible
    ? (
      <ScrollTransition loading>
        <div className='room-types-list'>
          {isAddSingleRoomTypeInputVisible && (
            <RoomTypesListItem
              roomType={roomTypeDefault}
              onSave={handleRoomTypePost}
              onCancel={toggleAddSingleRoomType}
              isNew
            />
          )}
          {roomTypesList.map(roomType => (
            <RoomTypesListItem
              onDelete={handleRoomTypeDelete}
              onSave={(payload, callback) => handleRoomTypePut(roomType.id, payload, callback)}
              roomType={roomType}
              key={roomType.id}
              onIconUpload={handleUploadRoomTypeIcon}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Здесь пока нет типов помещений' />

  if (isRoomTypesLoading) {
    return <LargeSpin />
  }

  return (
    <>
      <div style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ТИПЫ ПОМЕЩЕНИЙ' />
        <SubtitleWithActions
          subtitle={`Всего типов помещений: ${roomTypesList.length}`}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={toggleAddSingleRoomType}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleRoomTypeInputVisible ? 'Отменить добавление' : 'Добавить 1 тип'}
          />
          <Button
            onClick={handleAddMultipleRoomTypes}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>
      <TableHeader
        columns={LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={50}
        style={[{ marginRight: '5px' }]}
      />
      {renderRoomTypesList()}

      {/* MODALS */}
      <ImportFromCsvModal
        form='room-types'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />
      <TextModal
        tags={roomTypesList}
        isOpen={isTextModalOpen}
        setIsOpen={setIsTextModalOpen}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ТИПОВ ПОМЕЩЕНИЙ'
        subtitle='Введите названия типов помещений через запятую'
        hint='Пример: Рабочее место, Туалет, Кофе-пойнт'
        submitTitle='Добавить типы помещений'
      />
    </>
  )
})

export default RoomTypes
