import {
  DELETE_BOOKING, GET_BOOKINGS,
  GET_USER_BOOKING_HISTORY, POST_BOOKING,
  POST_FAST_BOOKING,
} from '../booking'

export const bookingAC = {
  postFastBooking: ({ bookings, addToHistory, onCancel }) => ({
    type: POST_FAST_BOOKING, payload: { bookings, addToHistory, onCancel },
  }),
  postBooking: ({ bookings, onCancel }) =>
    ({ type: POST_BOOKING, payload: { bookings, onCancel } }),
  deleteBooking: bookId => ({ type: DELETE_BOOKING, bookId }),
  getUserBookingHistory: ({ userId, page, callback }) =>
    ({ type: GET_USER_BOOKING_HISTORY, userId, page, callback }),
  getBookings: ({ tableId, callback }) => ({ type: GET_BOOKINGS, payload: { tableId, callback } }),
}
