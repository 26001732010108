/* eslint-disable max-len */
import React from 'react'
import Tooltip from 'antd/lib/tooltip'
import MarkerOnMap from '../../UI/icons/MarkerOnMap'
import markerIsNotOnMap from '../../../assets/images/icons/marker-is-not-on-map.svg'

const MarkerIcon = props => {
  const {
    hasMarkerOnMap,
    hasTableMarkerOnMap,
    room,
    dispatchWithoutRedux,
    deleteMarker,
    deleteTableMarker,
  } = props

  const onClickHandler = e => {
    e.stopPropagation()

    hasMarkerOnMap && deleteMarker({ markerId: room.marker.id, roomId: room.id, dispatchWithoutRedux })
    hasTableMarkerOnMap && deleteTableMarker()
  }

  const toolTipTitle = hasMarkerOnMap || hasTableMarkerOnMap
    ? 'Убрать метку с карты'
    : 'Перенесите на карту'

  return (
    <Tooltip title={toolTipTitle} mouseEnterDelay={1}>
      <div
        className={`table-item__marker-icons-wrap ${hasMarkerOnMap || hasTableMarkerOnMap ? '' : 'grab'}`}
        onClick={onClickHandler}
      >
        {hasMarkerOnMap || hasTableMarkerOnMap
          ? <div className='table-item__marker-icon-wrap'><MarkerOnMap /></div>
          : (
            <div className='table-item__marker-icon-wrap'>
              <img src={markerIsNotOnMap} alt='Иконка метки, не нанесённой на карту' />
            </div>
          )}
      </div>
    </Tooltip>
  )
}

export default MarkerIcon
