import React, { useCallback } from 'react'
import * as Yup from 'yup'
import { Form as FormikForm, withFormik } from 'formik'
import Select from 'antd/lib/select'
import { useDispatch } from 'react-redux'
import SimpleInput from '../../../UI/input/SimpleInput'
import { ErrorMsg } from '../../../UI/ValidationMsg'
import SimpleSelect from '../../../UI/input/SimpleSelect'
import Button from '../../../UI/Button'
import getBasicInputProps from '../../../../utils/getBasicInputProps'
import { officesAC } from '../../../../actions/actionCreator/offices'
import UnderlinedTitle from '../../../UI/UnderlinedTitle'

const { Option } = Select

const ValidationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(4, 'Минимум 4 символа')
    .max(120, 'Максимум 120 символов')
    .required('Обязательное поле'),
  office: Yup.string()
    .required('Обязательное поле'),
  floor: Yup.string()
    .required('Обязательное поле'),
})

const InnerForm = React.memo(({
  values,
  setFieldTouched,
  errors,
  touched,
  setFieldValue,
  setValues,
  onSave,
  setTouched,
  resetForm,
  accessCode,
  isEditMode,
  offices,
  floors,
  kiosk,
}) => {
  const dispatch = useDispatch()
  const getInputProps = getBasicInputProps({
    values,
    errors,
    touched,
    setFieldTouched,
    onChange: setFieldValue,
  })
  const handleOfficeChange = (_, val) => {
    setValues({
      office: val,
      floor: undefined,
      firstname: values.firstname,
    })
    dispatch(officesAC.getFloors({ roomType: null, officeId: val }))
  }
  const renderOfficeOptions = () => offices?.length > 0
    && offices.map(({ id, title }) => <Option value={id} key={id}>{title}</Option>)
  const renderFloorsOptions = useCallback(() => floors?.map(({ id, title }) =>
    <Option value={id} key={id}>{title} </Option>), [floors.length])
  const onFormSubmit = () => {
    setTouched({ firstname: true, office: true, floor: true })
    ValidationSchema.isValidSync(values) && onSave(values, resetForm)
  }
  return (
    <FormikForm>
      {!accessCode
      && <UnderlinedTitle title='СОЗДАНИЕ КИОСКА' style={{ width: 'calc(100% - 4px)', marginTop: '20px' }} />}
      <div className='container_form__left' style={{ flex: isEditMode && 1 }}>
        <SimpleInput
          label='Название киоска*'
          placeholder='Название'
          {...getInputProps('firstname')}
        >
          <ErrorMsg field='firstname' />
        </SimpleInput>
        {accessCode}
      </div>
      <div className='container_form__right flex_container__column' style={{ flex: 1 }}>
        <SimpleSelect
          name='office'
          label='Бизнес-центр*'
          placeholder='Укажите бизнес-центр'
          value={values.office}
          onChange={handleOfficeChange}
          setFieldTouched={setFieldTouched}
          error={errors.office && touched.office}
          errorComponent={() => <ErrorMsg field='office' />}
        >
          {renderOfficeOptions()}
        </SimpleSelect>

        <SimpleSelect
          label='Этаж*'
          placeholder='Выберите этаж'
          errorComponent={() => <ErrorMsg field='floor' />}
          {...getInputProps('floor')}
        >
          {floors.length
            ? renderFloorsOptions()
            : (
              <Option
                value={kiosk?.floor.id}
                key={kiosk?.floor.id}
              >
                {kiosk?.floor.title}
              </Option>
            )}
        </SimpleSelect>
        <div className='button_container__content_right' style={{ flex: !isEditMode && 1 }}>
          <Button
            styles='btn_solid'
            title={`${isEditMode ? 'Сохранить' : 'Создать'} киоск`}
            onClick={onFormSubmit}
            delay={300}
          />
        </div>
      </div>
    </FormikForm>
  )
})
const Form = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ initialValues }) => ({
    firstname: initialValues?.first_name || undefined,
    office: initialValues?.office?.id || undefined,
    floor: initialValues?.floor?.id || undefined,
  }),
  validationSchema: ValidationSchema,
})(InnerForm)
export default Form
