import React from 'react'

const DisabledInputBlock = React.memo(
  ({ title, subtitle, innerText, children, style, onClick }) => (
    <div className='info__block' style={style}>
      <span className='label margin_bottom'>{title}</span>
      <span className='main_text__white' onClick={onClick}>{subtitle}</span>
      <span className='secondary_text__white'>{innerText}</span>
      {children}
    </div>
  ),
)

export default DisabledInputBlock
