import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Button from '../UI/Button'
import ConfirmationModal from '../UI/modals/ConfirmationModal'
import List from './List'
import MainCard from '../MainCard'
import { groupAC } from '../../actions/actionCreator/groups'
import ImportGroupsFromCsvModal from './ImportGroupsFromCsvModal'
import FullScreenLoading from '../UI/FullScreenLoading'
import SearchFilter from './SearchFilter'
import { usersAC } from '../../actions/actionCreator/usersAC'
import LargeSpin from '../UI/Spin/LargeSpin'

const getFilteredGroupsByTitle = (groups, filterValue) => groups?.filter(({ title }) => {
  const normalizedTitle = title?.toLowerCase()
  const normalizedFilterValue = filterValue?.toLowerCase().trim()
  return normalizedTitle?.includes(normalizedFilterValue)
})

const UsersGroupList = React.memo(({
  groups,
  loading,
  importLoading,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isConfirmShow, setIsConfirmShow] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState({ id: null, title: '' })
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [filterValue, setFilterValue] = useState('')

  const filteredGroups = getFilteredGroupsByTitle(groups, filterValue)

  const onClickHandler = useCallback(() => {
    history.push('groups/new')
    dispatch(usersAC.cleanUsers())
  }, [])
  const onCloseHandler = useCallback(() => setIsConfirmShow(false), [isConfirmShow])
  const onCloseHandlerImport = useCallback(() =>
    setIsImportFromCsvModalVisible(false), [isImportFromCsvModalVisible])
  const onDeleteIconClick = useCallback(({ id, title }) => {
    setIsConfirmShow(true)
    setSelectedGroup({ id, title })
  }, [isConfirmShow])
  const onConfirm = useCallback(() => {
    dispatch(groupAC.deleteGroup({
      id: selectedGroup.id,
    }))
    onCloseHandler()
  }, [isConfirmShow])

  const createButtonBlock = () => (
    <>
      <SearchFilter
        style={{ maxWidth: '50%' }}
        placeholder='Название группы пользователей'
        value={filterValue}
        onChange={setFilterValue}
      />
      <div className='button_container__content_right'>
        <Button
          icon='icon__download_2'
          styles='button__import_users_csv'
          title='Импортировать из .csv'
          onClick={() => setIsImportFromCsvModalVisible(true)}
        />
        <Button
          styles='bordered_btn__flex__end'
          icon='icon__add__in_circle'
          title='Добавить группу'
          onClick={onClickHandler}
        />
      </div>
    </>
  )

  const groupListRender = () => {
    if (loading) return <LargeSpin />
    return (
      <List
        groups={filteredGroups}
        onDelete={onDeleteIconClick}
        style={{ padding: '0 10px 0 2px' }}
      />
    )
  }

  useEffect(() => {
    dispatch(groupAC.getGroupsList())
    return dispatch(groupAC.clearGroup())
  }, [dispatch])
  return (
    <>
      <MainCard
        icon='icon__groups'
        title='Доступы пользователей'
        subtitleClassName='main_card__subtitle'
        btn={createButtonBlock()}
      >
        {groupListRender()}
      </MainCard>
      {isConfirmShow && (
        <ConfirmationModal
          start={isConfirmShow}
          text={`Удалить группу "${selectedGroup.title}"?`}
          onConfirm={onConfirm}
          onClose={onCloseHandler}
          disable={loading}
        />
      )}
      <ImportGroupsFromCsvModal
        start={isImportFromCsvModalVisible}
        onClose={onCloseHandlerImport}
      />
      {importLoading && <FullScreenLoading />}
    </>
  )
})

const mapStateToProps = ({ users, app }) => ({
  groups: users.groupsList,
  loading: app.isAppLoading,
  importLoading: app.importLoading,
})

export default connect(mapStateToProps, {})(UsersGroupList)
