import React, { useEffect, useState } from 'react'
import { withFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as _ from 'lodash'
import * as Yup from 'yup'
import Checkbox from 'antd/lib/checkbox'
import Button from 'antd/lib/button'
import message from 'antd/lib/message'
import MainCard from '../MainCard'
import SimpleInput from '../UI/input/SimpleInput'
import SimpleTextarea from '../UI/input/SimpleTextArea'
import { POST_PUSH_NOTIFICATION } from '../../actions/notifications'
import CardListItem from '../helpers/lists/CardListItem'
import SendModal from './SendModal'
import { getSelectedIds, getUpdatedList, isAllItemsChecked, isSomeItemsChecked, setCheckedToValue } from './utils'
import { notificationsAC } from '../../actions/actionCreator/notificationsAC'
import CheckbleListNoRequest from './CheckbleListNoRequest'
import { getIsGroupsLoading } from '../../reducers/app-selectors'

const TITLE_MAX_LENGTH = 40
const MESSAGE_MAX_LENGTH = 150

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Обязательное поле')
    .max(TITLE_MAX_LENGTH, `Тема не более ${TITLE_MAX_LENGTH} символов`),
  message: Yup.string()
    .required('Обязательное поле')
    .max(MESSAGE_MAX_LENGTH, `Максимальная длина - ${MESSAGE_MAX_LENGTH} символов`),
})

const Index = React.memo(({
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  resetForm,
}) => {
  const dispatch = useDispatch()
  const loading = useSelector(getIsGroupsLoading)
  const [state, setState] = useState({ groups: [], users: [] })
  const [isSendModalVisible, setIsSendModalVisible] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [disableCheckboxForAllGroups, setDisableCheckboxForAllGroups] = useState(false)
  const [disableCheckboxForAllUsers, setDisableCheckboxForAllUsers] = useState(false)

  const isAllGroupsChecked = isAllItemsChecked(state.groups)

  const isAllUsersChecked = isAllItemsChecked(state.users)

  const isSomeUsersChecked = isSomeItemsChecked(state.users)

  const isSomeGroupsChecked = isSomeItemsChecked(state.groups)

  const selectedUsersIds = getSelectedIds(state.users)

  const isSomeUsersInGroupChecked = ({ users = [] }) =>
    users.some(user => selectedUsersIds.includes(user))
    && users.some(user => !selectedUsersIds.includes(user))

  const getInputValueLenghtIndicator = (field = 'title') => {
    const maxLength = field === 'title' ? TITLE_MAX_LENGTH : MESSAGE_MAX_LENGTH
    return `${values[field].length}/${maxLength}`
  }

  const toggleAllGroups = () => {
    const isNextValueTrue = !isAllGroupsChecked
    const allUniqueUsersInGroups = _.uniq(state.groups
      .reduce((acc, { users = [] }) => [...acc, ...users], []))
    const newSelectedUsers = state.users
      .map(user => ({
        ...user,
        checked: isNextValueTrue ? allUniqueUsersInGroups.includes(user.id) : false,
      }))

    const newSelectedGroups = state.groups.map(group =>
      ({ ...group, checked: isNextValueTrue }))

    setState({ groups: newSelectedGroups, users: newSelectedUsers })
  }

  const toggleGroup = checkedGroup => {
    const updatedGroups = getUpdatedList(state.groups, checkedGroup)

    const allUniqueUsersInGroups = _.uniq(updatedGroups
      .filter(group => group.checked)
      .reduce((acc, { users = [] }) => [...acc, ...users], []))

    const newSelectedUsers = state.users.map(user =>
      ({ ...user, checked: allUniqueUsersInGroups.includes(user.id) }))

    setState({ groups: updatedGroups, users: newSelectedUsers })
  }

  const toggleAllUsers = () => {
    const newValue = !isAllUsersChecked
    const newUsers = setCheckedToValue(state.users, newValue)
    const newGroups = setCheckedToValue(state.groups, newValue)

    setState({ groups: newGroups, users: newUsers })
  }

  const toggleUser = checkedUser => {
    const updatedUsers = getUpdatedList(state.users, checkedUser)
    const newGroups = state.groups.map(g => ({
      ...g,
      checked: g.users.every(u => updatedUsers.some(user => user.id === u && user.checked)),
    }))
    setState({ groups: newGroups, users: updatedUsers })
  }

  const getGroupsWithUsers = (groups, allUsers) => {
    const notEmptyGroups = groups
      .filter(group => group.users?.length && group.title !== 'Информационный киоск')

    const formattedGroups = notEmptyGroups.map(group => ({
      ...group,
      users: group.users.filter(user => allUsers.some(u => u.id === user)),
    }))
    return formattedGroups.filter(group => group.users?.length)
  }

  const handleModalClose = () => {
    const { groups, users } = state
    const newGroups = setCheckedToValue(groups, false)
    const newUsers = setCheckedToValue(users, false)

    const newStateValue = { users: newUsers, groups: newGroups }

    setState(newStateValue)
    setIsSendModalVisible(false)
    resetForm()
  }

  const formPushPayload = () => ({
    accounts: getSelectedIds(state.users),
    expo: {
      title: values.title,
      body: values.message,
    },
  })

  const handleSend = () => {
    setIsSending(true)
    const payload = formPushPayload()

    dispatch({
      type: POST_PUSH_NOTIFICATION,
      payload,
      callback: () => {
        setIsSending(false)
        handleModalClose()
      },
      errorCallback: () => setIsSending(false),
    })
  }

  const handleSendBtnClick = () => {
    getSelectedIds(state.users).length
      ? setIsSendModalVisible(true)
      : message.error('Укажите хотя бы одного получателя')
  }

  const renderGroupListItem = (group, key, style) => (
    <div
      key={key}
      style={{ ...style, padding: '0 10px 0 2px' }}
      onClick={() => toggleGroup(group)}
    >
      <CardListItem>
        <Checkbox
          checked={group.checked}
          indeterminate={isSomeUsersInGroupChecked(group)}
        />
        <span className='groups-list__item-title'>{group.title}</span>
        <span className='groups-list__item-count'>{group.users?.length}</span>
      </CardListItem>
    </div>
  )

  const renderUserListItem = (user, key, style) => (
    <div
      key={key}
      style={{ ...style, padding: '0 10px 0 2px' }}
      onClick={() => toggleUser(user)}
    >
      <CardListItem small>
        <Checkbox checked={user.checked} />
        {user.phone_number || <span className='secondary_text__inactive'>Не указано</span>}
      </CardListItem>
    </div>
  )

  const setInitValues = ({ groups, users }) => {
    setState({ users, groups: getGroupsWithUsers(groups, users) })
  }
  useEffect(() => {
    dispatch(notificationsAC.getNotificationItems(
      ({ groups, users }) => setInitValues({ groups, users })))
    return () => {
    }
  }, [])
  return (
    <MainCard
      icon='icon__notification'
      title='Отправка PUSH-уведомлений'
      innerContainerStyle={{ overflow: 'visible' }}
    >
      <div className='notifications__container'>
        <div className='notifications__form-wrapper'>
          <h3>Напишите тему и содержание сообщения</h3>
          <SimpleInput
            name='title'
            label='Тема'
            value={values.title}
            placeholder='Введите тему'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={errors.title && touched.title}
          />
          <span style={{ color: errors.title && touched.title && '#CD4141' }}>
            {getInputValueLenghtIndicator('title')}
          </span>
          <SimpleTextarea
            name='message'
            rows={4}
            label='Содержание'
            value={values.message}
            placeholder='Введите содержание уведомления'
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={errors.message && touched.message}
          />
          <span style={{ color: errors.message && touched.message && '#CD4141' }}>
            {getInputValueLenghtIndicator('message')}
          </span>
        </div>
        {/* TODO Ждем логику на беке на поиск людей и групп*/}
        {/*логика не дописана*/}
        {/*<CheckableList*/}
        {/*  title='Список групп'*/}
        {/*  searchPlaceholder='Поиск по группам'*/}
        {/*  list={state.groups}*/}
        {/*  renderListItem={renderGroupListItem}*/}
        {/*  rowHeight={74}*/}
        {/*  actionTypeOnSearch={GET_ACCESS_GROUP_NO_REDUX}*/}
        {/*  fieldToShowInSearch='title'*/}
        {/*  onCheck={toggleGroup}*/}
        {/*  searchFieldIdSuffix='-groups'*/}
        {/*  renderListHeader={(*/}
        {/*    <Checkbox*/}
        {/*      checked={isAllGroupsChecked}*/}
        {/*      indeterminate={isSomeGroupsChecked}*/}
        {/*      onChange={toggleAllGroups}*/}
        {/*    >*/}
        {/*      Выбрать все группы*/}
        {/*    </Checkbox>*/}
        {/*  )}*/}
        {/*/>*/}
        {/*<CheckableList*/}
        {/*  title='Список пользователей'*/}
        {/*  searchPlaceholder='Поиск по получателям'*/}
        {/*  list={state.users}*/}
        {/*  // loading={usersLoading}*/}
        {/*  renderListItem={renderUserListItem}*/}
        {/*  actionTypeOnSearch={GET_USERS_FOR_NOTIFICATIONS_SEARCH}*/}
        {/*  onCheck={toggleUser}*/}
        {/*  fieldToShowInSearch='phone_number'*/}
        {/*  searchFieldIdSuffix='-users'*/}
        {/*  renderListHeader={(*/}
        {/*    <Checkbox*/}
        {/*      indeterminate={isSomeUsersChecked}*/}
        {/*      checked={isAllUsersChecked}*/}
        {/*      onChange={toggleAllUsers}*/}
        {/*    >*/}
        {/*      {isSomeUsersChecked || isAllUsersChecked*/}
        {/*        ? `Выбрано ${state.users.filter(user => user.checked).length} пользователей`*/}
        {/*        : 'Выбрать всех пользователей'}*/}
        {/*    </Checkbox>*/}
        {/*  )}*/}
        <CheckbleListNoRequest
          title='Список групп'
          loading={loading}
          searchPlaceholder='Поиск по группам'
          list={state.groups}
          renderListItem={renderGroupListItem}
          rowHeight={74}
          setDisable={setDisableCheckboxForAllGroups}
          renderListHeader={(
            <Checkbox
              checked={isAllGroupsChecked}
              indeterminate={isSomeGroupsChecked}
              onChange={toggleAllGroups}
              disabled={loading || disableCheckboxForAllGroups}
            >
              Выбрать все группы
            </Checkbox>
          )}
        />
        <CheckbleListNoRequest
          title='Список пользователей'
          searchPlaceholder='Поиск по получателям'
          list={state.users}
          loading={loading}
          type='number'
          renderListItem={renderUserListItem}
          setDisable={setDisableCheckboxForAllUsers}
          renderListHeader={(
            <Checkbox
              indeterminate={isSomeUsersChecked}
              checked={isAllUsersChecked}
              onChange={toggleAllUsers}
              disabled={loading || disableCheckboxForAllUsers}
            >
              {isSomeUsersChecked || isAllUsersChecked
                ? `Выбрано ${state.users.filter(user => user.checked).length} пользователей`
                : 'Выбрать всех пользователей'}
            </Checkbox>
          )}
        />
      </div>

      <div className='notifications__button-wrapper'>
        <Button
          type='primary'
          disabled={!ValidationSchema.isValidSync(values)}
          onClick={handleSendBtnClick}
        >
          Отправить уведомления
        </Button>
      </div>

      {isSendModalVisible && (
        <SendModal
          pushTitle={values.title}
          pushMessage={values.message}
          onSend={handleSend}
          start={isSendModalVisible}
          recipients={state.users.filter(user => user.checked)}
          onClose={() => setIsSendModalVisible(false)}
          loading={isSending}
        />
      )}
    </MainCard>
  )
})

const Notifications = withFormik({
  validationSchema: ValidationSchema,
  mapPropsToValues: () => ({ title: '', message: '' }),
})(Index)

export default Notifications
