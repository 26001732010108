import React, { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import message from 'antd/lib/message'

import Button from '../../../../../UI/Button'
import EmptyPlaceholder from '../../../../../UI/EmptyPlaceholder'
import ImportFromCsvModal from '../../helpers/ImportFromCsvModal'
import ScrollTransition from '../../../../../UI/animations/transition/ScrollTransition'
import SubtitleWithActions from '../../../../../UI/SubtitleWithActions'
import TableHeader from '../../../../../UI/TableHeader'
import TagListItem from './TagListItem'
import TextModal from '../../../../../UI/modals/TextModal'
import UnderlinedTitle from '../../../../../UI/UnderlinedTitle'
import LargeSpin from '../../../../../UI/Spin/LargeSpin'

import { officesAC } from '../../../../../../actions/actionCreator/offices'

const TAGS_LIST_COLUMNS_NAMES = ['Название', 'Иконка']
const COLUMNS_FLEX_VALUES = [1, 1]

const TagsInfo = React.memo(() => {
  const [isAddSingleTagInputVisible, setIsSingleTagInputVisible] = useState(false)
  const [isImportFromCsvModalVisible, setIsImportFromCsvModalVisible] = useState(false)
  const [isMultipleInputModalVisible, setIsMultipleInputModalVisible] = useState(false)

  const isTagsLoading = useSelector(({ app }) => app.isTagsLoading)
  const tags = useSelector(({ offices }) => offices.tags)

  const params = useParams()
  const { current: officeId } = useRef(params.id)

  const dispatch = useDispatch()

  const toggleAddMultipleModal = () => setIsMultipleInputModalVisible(val => !val)

  const toggleSingleInputVisible = () => setIsSingleTagInputVisible(val => !val)

  const handleTagPost = tag => {
    if (tags.some(({ title }) => tag.title === title)) {
      message.error('Тег с таким названием уже существует')
      return
    }

    dispatch(officesAC.postOfficeTags({
      titles: [tag.title],
      officeId,
      callback: () => setIsSingleTagInputVisible(false),
    }))
  }

  const handlePutOfficeTag = (tagId, tagData, callback) => {
    if (tags.some(({ id, title }) => tagId !== id && tagData.title === title)) {
      message.error('Тег с таким названием уже существует')

      return
    }

    dispatch(officesAC.putOfficeTag({ tagId, tagData, callback }))
  }

  const handleDeleteTag = tagId => dispatch(officesAC.deleteOfficeTag({ tagId }))

  const handlePostTagIcon = (tagId, icon) => dispatch(officesAC.postTagIcon({ tagId, icon }))

  const isTitlesNotValid = titles => titles.some(title => title.length < 2 || title.length > 140)

  const handlePackageUpload = (titlesArray, callback) => {
    if (!isTitlesNotValid(titlesArray)) {
      dispatch(officesAC.postOfficeTags({
        titles: titlesArray,
        officeId,
        callback,
      }))

      return
    }
    message.error('Некорректные значения. Длина тега – от 2 до 140 символов')
  }

  const renderTagsList = () => tags.length || isAddSingleTagInputVisible
    ? (
      <ScrollTransition loading>
        <div className='tags-list'>
          {isAddSingleTagInputVisible && (
            <TagListItem
              isNew
              tag={{ title: '', icon: {} }}
              onSave={handleTagPost}
              onCancel={toggleSingleInputVisible}
              flexValues={COLUMNS_FLEX_VALUES}
            />
          )}
          {tags.map(tag => (
            <TagListItem
              key={tag.id}
              tag={tag}
              flexValues={COLUMNS_FLEX_VALUES}
              onSave={(updatedTag, callback) => handlePutOfficeTag(tag.id, updatedTag, callback)}
              postIcon={handlePostTagIcon}
              onDelete={handleDeleteTag}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список тегов пуст' />

  if (isTagsLoading) {
    return <LargeSpin />
  }

  return (
    <div className='flex_container__column full_height'>
      <div className='flex_container__column' style={{ paddingRight: '10px' }}>
        <UnderlinedTitle title='ТЕГИ' />
        <SubtitleWithActions
          subtitle={`Всего тегов: ${tags.length || 0}`}
        />
        <div className='btn-group' style={{ margin: '12px 0 20px' }}>
          <Button
            onClick={toggleSingleInputVisible}
            styles='btn_solid__light-blue btn-group__item'
            title={isAddSingleTagInputVisible ? 'Отменить добавление тега' : 'Добавить 1 тег'}
          />
          <Button
            onClick={toggleAddMultipleModal}
            styles='btn_solid__light-blue btn-group__item'
            title='Добавить несколько'
          />
        </div>
      </div>
      <TableHeader
        columns={TAGS_LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={47}
      />
      {renderTagsList()}

      <ImportFromCsvModal
        form='tags'
        onClose={() => setIsImportFromCsvModalVisible(false)}
        start={isImportFromCsvModalVisible}
      />
      <TextModal
        tags={tags}
        isOpen={isMultipleInputModalVisible}
        setIsOpen={setIsMultipleInputModalVisible}
        onSubmit={handlePackageUpload}
        title='ДОБАВИТЬ НЕСКОЛЬКО ТЕГОВ'
        subtitle='Введите названия тегов через запятую (минимум 2 символа)'
        hint='Пример: с ПК, У окна, С монитором'
        submitTitle='Добавить теги'
      />
    </div>
  )
})

export default TagsInfo
