/* eslint-disable import/no-cycle */
import React, { useState } from 'react'
import Menu from 'antd/lib/menu'
import Tooltip from 'antd/lib/tooltip'
import Popover from 'antd/lib/popover'
import { useDrag, DragPreviewImage } from 'react-dnd'
import { connect } from 'react-redux'
import MenuContent from './MenuContent'
import IconPlus from '../../UI/icons/PlusRoundedThinner'
import MarkerIcon from './MarkerIcon'
import { dndPreview } from '../../UI/icons/DnDPreview'
import LargeSpin from '../../UI/Spin/LargeSpin'
import renderTables from './renderTables'

const { SubMenu } = Menu

export const getPopOverContent = props => {
  const {
    room,
    hasMarkerOnMap = {},
    hasTableMarkerOnMap = {},
    setIsVisibleMenu,
    defaultProps,
    table,
    deleteTableMarker,
  } = props
  return (
    <MenuContent
      room={room}
      hasMarkerOnMap={hasMarkerOnMap}
      hasTableMarkerOnMap={hasTableMarkerOnMap}
      setIsVisibleMenu={setIsVisibleMenu}
      defaultProps={defaultProps}
      table={table}
      deleteTableMarker={deleteTableMarker}
    />
  )
}

const Room = React.memo(props => {
  const {
    room,
    defaultProps,
    tablesLoader,
    ...others
  } = props
  const { room_type_unified: isUnified, is_bookable: isBookable } = room
  const {
    dispatchWithoutRedux,
    deleteMarker,
    handleAddTable,
  } = defaultProps
  const [isVisibleMenu, setIsVisibleMenu] = useState(false)

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: { type: 'room', roomId: room.id, room },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const renderRoomTitle = () => {
    const isAnotherRoomType = room.type !== 'Рабочее место' && room.type !== 'Переговорная'

    const onClickAddTable = e => {
      e.stopPropagation()
      handleAddTable(room.id)
    }

    const hasMarkerOnMap = room.marker

    return (
      <>
        <DragPreviewImage connect={preview} src={dndPreview} />
        <div
          className='rooms-list__room-title-wrap'
          ref={hasMarkerOnMap ? null : dragRef}
          style={{ cursor: isDragging ? 'none' : 'pointer' }}
        >
          <div className='rooms-list__info-and-icon-wrap'>
            <div
              className='room-item__info'
              style={{ maxWidth: isBookable && !isUnified ? '82.5%' : '100%' }}
            >
              <Tooltip title={room.title} mouseEnterDelay={1.6}>
                <span className='room-item__room-title'>{room.title}</span>
              </Tooltip>
              {isAnotherRoomType
                ? (
                  <Tooltip
                    className='room-item__another-info'
                    title={room.type + ', ' + room.zone_title}
                    mouseEnterDelay={1.6}
                  >
                    <span className='room-item__room-type'>{room.type}</span>
                    <div className='square' />
                    <span className='room-item__room-zone'>{room.zone_title || 'без зоны'}</span>
                  </Tooltip>
                )
                : (
                  <Tooltip title={room.zone_title || 'без зоны'} mouseEnterDelay={1.6}>
                    <span className='room-item__room-zone'>{room.zone_title || 'без зоны'}</span>
                  </Tooltip>
                )}
            </div>
            {isBookable && !isUnified && (
              <Tooltip title='Добавить место' mouseEnterDelay={1}>
                <div className='room-item__add-icon-wrap' onClick={onClickAddTable}>
                  <IconPlus color='#FFF' width='35' height='35' />
                </div>
              </Tooltip>
            )}
          </div>
          <MarkerIcon
            hasMarkerOnMap={hasMarkerOnMap}
            room={room}
            deleteMarker={deleteMarker}
            dispatchWithoutRedux={dispatchWithoutRedux}
          />
          <div className='table-item__menu-icon-wrap' onClick={e => e.stopPropagation()}>
            <Popover
              content={getPopOverContent({ room, hasMarkerOnMap, setIsVisibleMenu, defaultProps })}
              trigger='click'
              visible={isVisibleMenu}
              onVisibleChange={val => setIsVisibleMenu(val)}
            >
              <div className='icon__more' onClick={() => setIsVisibleMenu(prev => !prev)} />
            </Popover>
          </div>
        </div>
      </>
    )
  }

  if (tablesLoader?.isLoading && tablesLoader?.roomId === room.id) {
    return (
      <div className='room-item__spin-wrap'>
        <LargeSpin />
      </div>
    )
  }

  return (
    <>
      <SubMenu key={`roomId_${room.id}`} title={renderRoomTitle()} {...others}>
        {!isUnified && renderTables({ room, defaultProps })}
      </SubMenu>
    </>
  )
})

const mapStateToProps = ({ offices = {} }) => ({
  tablesLoader: offices.tablesLoader,
})

export default connect(mapStateToProps, {})(Room)
