import React from 'react'

const MarkerOnMap = ({ className, width = 36, height = 36, color = '#FFF' }) => (
  <svg className={className} width={width} height={height} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect y='1' width={width - 1} height={height - 1} rx='17.5' fill='#0079C1' />
    <circle cx='29.5' cy='6.5' r='6' fill='#7A7D82' stroke='#252B35' />
    <path fillRule='evenodd' clipRule='evenodd' d='M27.1614 8.83856C26.9462 8.6233 26.9462 8.2743 27.1614 8.05904L28.7205 6.49997L27.1614 4.9409C26.9462 4.72564 26.9462 4.37664 27.1614 4.16138C27.3767 3.94613 27.7257 3.94612 27.941 4.16138L29.5 5.72045L31.059 4.16144C31.2743 3.94618 31.6233 3.94619 31.8386 4.16144C32.0538 4.3767 32.0538 4.7257 31.8386 4.94096L30.2795 6.49997L31.8386 8.05898C32.0538 8.27424 32.0538 8.62324 31.8386 8.8385C31.6233 9.05375 31.2743 9.05375 31.059 8.8385L29.5 7.27949L27.941 8.83856C27.7257 9.05381 27.3767 9.05381 27.1614 8.83856Z' fill='white' />
    <g clipPath='url(#clip0)'>
      <path d='M17.0469 25.6974C12.7901 19.5263 12 18.893 12 16.625C12 13.5184 14.5184 11 17.625 11C20.7316 11 23.25 13.5184 23.25 16.625C23.25 18.893 22.4599 19.5263 18.2031 25.6974C17.9237 26.1009 17.3262 26.1009 17.0469 25.6974ZM17.625 18.9688C18.9194 18.9688 19.9688 17.9194 19.9688 16.625C19.9688 15.3306 18.9194 14.2812 17.625 14.2812C16.3306 14.2812 15.2812 15.3306 15.2812 16.625C15.2812 17.9194 16.3306 18.9688 17.625 18.9688Z' fill='white' />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='11.25' height='15' fill='white' transform='translate(12 11)' />
      </clipPath>
    </defs>
  </svg>
)

export default MarkerOnMap
