import React, { useState, useRef } from 'react'
import { withFormik } from 'formik'
import Select from 'antd/lib/select'
import Button from 'antd/lib/button'
import { isEqual, pickBy } from 'lodash'
import * as Yup from 'yup'
import SimpleInput from '../../../UI/input/SimpleInput'
import SimpleSelect from '../../../UI/input/SimpleSelect'
import SimpleInputNumber from '../../../UI/input/SimpleInputNumber'
import SimpleRadioGroup from '../../../UI/input/RadioGroup'
import IconFilter from '../../../UI/icons/IconFilter'
import IconCross from '../../../UI/icons/CrossRounded'
import useOutsideClick from '../../../../utils/hooks/useOutsideClick'

const radioValues = [
  {
    title: 'С фото',
    value: 1,
  },
  {
    title: 'Без фото',
    value: 0,
  },
  {
    title: 'Забронировано',
    value: 1,
  },
  {
    title: 'Свободно',
    value: 0,
  },
]

const ValidationSchema = Yup.object().shape({
  search: Yup.string().required(),
  type: Yup.array().of(Yup.string()).required(),
  zone: Yup.array().of(Yup.string()).required(),
  floor: Yup.array().of(Yup.string()).required(),
  tags: Yup.array().of(Yup.string()).required(),
  images:
    Yup.number()
      .min(0)
      .max(1)
      .integer()
      .required(),
  booked:
    Yup.number()
      .min(0)
      .max(1)
      .integer()
      .required(),
  range_from: Yup.number()
    .integer()
    .moreThan(-1),
  range_to: Yup.number()
    .integer()
    .moreThan(-1),
  office: Yup.string().required(),
})

const isFieldValid = (value, key) =>
  Yup.reach(ValidationSchema, key).isValidSync(value)

const InnerForm = React.memo(({
  officeId,
  onSearch,
  placeholder,
  values,
  setFieldValue,
  setFieldTouched,
  roomTypes,
  zones,
  tags,
  floors,
  resetForm,
  initialValues,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const filtersBlockRef = useRef(null)
  const filterBtnRef = useRef(null)
  const [filtersVisible, setFiltersVisible] = useOutsideClick(filtersBlockRef, filterBtnRef)
  const debounceTimer = useRef()

  const filtersSelected = !isEqual(initialValues, values)

  const getStyle = () => isFocused
    ? { border: '1px solid #0079C1' }
    : { border: '1px solid #7A7D82' }

  const toggleFilters = () => setFiltersVisible(!filtersVisible)

  // отдельная функция из-за того что иначе
  // в resetForm передается event и сетится в стейт формы
  const handleReset = () => {
    resetForm()
    onSearch()
  }

  const handleFilters = (search = '') => {
    const filters = { ...values }

    // для дебаунса
    filters.search = search

    // если не указано ни одного этажа, необходимо указать id БЦ
    if (!filters.floor?.length) filters.office = officeId

    // Костыль чтобы заменить поля photo и booked на нужные 0 и 1
    filters.images = radioValues.find(({ title }) => title === filters.images)?.value
    filters.booked = radioValues.find(({ title }) => title === filters.booked)?.value

    return pickBy(filters, isFieldValid)
  }

  const handleSearch = search => {
    const filters = handleFilters(search)

    onSearch(filters)
  }

  const handleSearchChange = (key, value) => {
    setFieldValue(key, value)

    debounceTimer.current && clearTimeout(debounceTimer.current)
    debounceTimer.current = setTimeout(() => handleSearch(value), 500)
  }

  const onFiltersSubmit = () => {
    toggleFilters()
    handleSearch()
  }

  const handleChangeInputNumber = (key, val) => {
    const formattedValue = val && Math.floor(val)
    setFieldValue(key, formattedValue)
  }

  return (
    <>
      <div style={getStyle()} className='filter-input-wrapper'>
        <div className='icon__search' />
        <SimpleInput
          setFieldTouched={setFieldTouched}
          name='search'
          placeholder={placeholder}
          value={values.search}
          onChange={handleSearchChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          style={{ marginBottom: 0 }}
        />
        <IconFilter color={filtersSelected ? '#0079C1' : '#FBFBFB'} ref={filterBtnRef} />
        {filtersVisible && (
          <div
            ref={filtersBlockRef}
            className='filters-form-card'
            id='filters-form-card'
          >
            <SimpleSelect
              setFieldTouched={setFieldTouched}
              mode='multiple'
              name='type'
              label='Тип помещения'
              value={values.type}
              placeholder='Выберите тип помещения'
              onChange={setFieldValue}
              className='ant-form-item-row'
              parentNodeId='filters-form-card'
            >
              {roomTypes?.map(roomType => (
                <Select.Option
                  key={roomType.id}
                  value={roomType.title}
                >
                  {roomType.title}
                </Select.Option>
              ))}
            </SimpleSelect>
            <SimpleSelect
              setFieldTouched={setFieldTouched}
              mode='multiple'
              name='zone'
              label='Зоны'
              value={values.zone}
              placeholder='Выберите зону'
              onChange={setFieldValue}
              className='ant-form-item-row'
              parentNodeId='filters-form-card'
            >
              {zones?.map(zone => (
                <Select.Option
                  key={zone.id}
                  value={zone.id}
                >
                  {zone.title}
                </Select.Option>
              ))}
            </SimpleSelect>
            <SimpleSelect
              setFieldTouched={setFieldTouched}
              mode='multiple'
              name='floor'
              label='Этажи'
              value={values.floor}
              placeholder='Выберите этажи'
              onChange={setFieldValue}
              className='ant-form-item-row'
              parentNodeId='filters-form-card'
            >
              {floors?.map(floor => (
                <Select.Option
                  key={floor.id}
                  value={floor.id}
                >
                  {floor.title}
                </Select.Option>
              ))}
            </SimpleSelect>
            <div className='filter-input__seats-amount-wrapper'>
              <SimpleInputNumber
                setFieldTouched={setFieldTouched}
                className='ant-form-item-row filter-input__seats-amount-input'
                label='Количество мест'
                name='range_from'
                value={values.range_from}
                onChange={handleChangeInputNumber}
                min={1}
                placeholder='От'
              />
              <span className='filter-input__seats-amount-divider'>—</span>
              <SimpleInputNumber
                setFieldTouched={setFieldTouched}
                className='ant-form-item-row'
                name='range_to'
                value={values.range_to}
                onChange={handleChangeInputNumber}
                placeholder='До'
                min={values.range_from || 1}
              />
            </div>
            <SimpleSelect
              mode='multiple'
              name='tags'
              label='Теги'
              setFieldTouched={setFieldTouched}
              value={values.tags}
              placeholder='Выберите теги'
              onChange={setFieldValue}
              className='ant-form-item-row'
              parentNodeId='filters-form-card'
            >
              {tags?.map(tag => (
                <Select.Option
                  key={tag.id}
                  value={tag.title}
                >
                  {tag.title}
                </Select.Option>
              ))}
            </SimpleSelect>
            <SimpleRadioGroup
              className='ant-form-item-row ant-radiogroup'
              name='images'
              label='Фото'
              setFieldTouched={setFieldTouched}
              value={values.images}
              onChange={setFieldValue}
              options={['Не выбрано', 'С фото', 'Без фото']}
            />
            <SimpleRadioGroup
              className='ant-form-item-row ant-radiogroup'
              name='booked'
              setFieldTouched={setFieldTouched}
              label='Бронирование'
              value={values.booked}
              onChange={setFieldValue}
              options={['Не выбрано', 'Забронировано', 'Свободно']}
            />

            <div
              style={{ marginTop: '20px' }}
              className='button_container__content_right'
            >
              <Button type='text' onClick={toggleFilters}>Отмена</Button>
              <Button
                type='primary'
                onClick={onFiltersSubmit}
              >
                Поиск
              </Button>
            </div>

          </div>
        )}
      </div>
      {filtersSelected && (
        <div className='filter-input__reset' onClick={handleReset}>
          <IconCross
            width='18'
            height='18'
          />
          <span>Сбросить</span>
        </div>
      )}
    </>
  )
})

const FilterInput = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    images: 'Не выбрано',
    booked: 'Не выбрано',
    type: [],
    zone: [],
    floor: [],
    tags: [],
    range_from: null,
    range_to: null,
    search: '',
  }),
})(InnerForm)

export default FilterInput
