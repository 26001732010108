import React from 'react'

import BigCardTitle from './UI/BigCardTitle'
import LargeSpin from './UI/Spin/LargeSpin'
import FadeTransition from './UI/animations/transition/FadeTransition'

const MainCard = React.memo(({
  style,
  loading,
  title,
  subtitle,
  icon,
  children,
  filter,
  btn,
  separatedBtn,
  tagsFilter,
  innerRef,
  innerContainerStyle,
  subtitleClassName,
  innerContainerClassName,
  addClass = '',
  isOffice,
}) => (
  <div className={`main ${addClass}`} id='main' style={style}>
    <FadeTransition loading>
      <div>
        <div className='title_block'>
          <BigCardTitle icon={icon} title={title} subtitle={subtitle} isOffice={isOffice} />
          {(filter || btn) && (
            <div className={subtitleClassName || 'title_block__subtitle'}>
              {filter}
              {btn}
            </div>
          )}
          <div style={{ marginRight: '17px' }}>{separatedBtn}</div>
        </div>
        {tagsFilter}
      </div>
    </FadeTransition>
    {loading
      ? <LargeSpin />
      : (
        <div
          className={`event_form ${innerContainerClassName || ''}`}
          style={innerContainerStyle}
          ref={innerRef}
        >
          {children}
        </div>
      )}
  </div>
))

export default MainCard
