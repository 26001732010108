import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'

import SyncIcon from '../../UI/icons/SyncIcon'
import PlusRoundedThickIcon from '../../UI/icons/PlusRoundedThickIcon'
import Button from './Button/Button'
import EmptyPlaceholder from '../../UI/EmptyPlaceholder'
import ExpandingSearch from '../../UI/ExpandingSearch'
import LargeSpin from '../../UI/Spin/LargeSpin'
import MainCard from '../../MainCard'
import OfficeListItem from './OfficeListItem/OfficeListItem'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import TableHeader from '../../UI/TableHeader'

import { getOfficesList } from '../../../reducers/offices-selectors'
import { officesAC } from '../../../actions/actionCreator/offices'
import { getAppLoading, getDisableLoading, getIsLicensesLoading } from '../../../reducers/app-selectors'

const OFFICES_LIST_COLUMNS_NAMES = [
  '#',
  'Название бизнес-центра',
  'Адрес',
  'Кол-во этажей',
  'Всего мест',
  'Свободно',
]

const COLUMNS_FLEX_VALUES = [0.3, 2.8, 2.8, 0.9, 0.9, 0.9]

const AddNewOfficeButton = React.memo(() => (
  <Button
    additionalClasses='rounded-btn_blue'
    text='Добавить БЦ'
    renderIcon={() => (
      <PlusRoundedThickIcon
        style={{
          width: '14px',
          height: '14px',
          marginRight: '10px',
        }}
      />
    )}
  />
))

const ReloadOfficesButton = React.memo(props => {
  const { handleGetOffices, isLoading } = props

  return (
    <Button
      disabled={isLoading}
      additionalClasses='bordered_btn'
      style={{ marginLeft: 'auto' }}
      text='Обновить данные'
      onClick={handleGetOffices}
      renderIcon={() => (
        <SyncIcon
          style={{
            width: '14px',
            height: '14px',
            marginRight: '8px',
          }}
          fill='#fff'
        />
      )}
    />
  )
})

const OfficesList = React.memo(() => {
  const dispatch = useDispatch()

  const offices = useSelector(getOfficesList)
  const disable = useSelector(getDisableLoading)
  const loading = useSelector(getAppLoading)

  const [searchString, setSearchString] = useState('')
  const [reload, setReload] = useState(false)

  const searchingOffices = useCallback(val => {
    dispatch(officesAC.getOffices({ searchString: val.searchString }))
  }, [searchString])

  const handleGetOffices = () => {
    setReload(true)
    dispatch(officesAC.getOffices({ delaySec: 2000 }))
  }

  const CardSubtitle = (() => (
    <div style={{ display: 'flex', width: '100%' }}>
      <ExpandingSearch
        setSearchString={setSearchString}
        searchString={searchString}
        getItems={searchingOffices}
        placeholder='Название бизнес-центра'
        style={{ maxWidth: '50%', marginRight: '20px' }}
        defaultExpanded
        isExpanded
        large
      />
      <Link to='/offices/new'>
        <AddNewOfficeButton />
      </Link>
      <ReloadOfficesButton
        handleGetOffices={handleGetOffices}
        isLoading={disable}
      />
    </div>
  ))()

  const renderOfficesList = () => offices.length
    ? (
      <ScrollTransition
        loading
        paginationOnTop={reload && loading}
      >
        <div style={{ padding: '0 10px 0 2px' }}>
          {offices.map((office, index) => (
            <OfficeListItem
              key={office.id}
              office={office}
              index={index}
              flexValues={COLUMNS_FLEX_VALUES}
            />
          ))}
        </div>
      </ScrollTransition>
    )
    : <EmptyPlaceholder text='Список бизнес-центров пуст' />

  useEffect(() => {
    dispatch(officesAC.getOffices({}))
    return setReload(false)
  }, [])

  return (
    <MainCard
      icon='icon__briefcase'
      title='БИЗНЕС-ЦЕНТРЫ'
      filter={CardSubtitle}
      subtitleClassName='main_card__subtitle'
    >
      <TableHeader
        columns={OFFICES_LIST_COLUMNS_NAMES}
        flexValues={COLUMNS_FLEX_VALUES}
        rightPaddingWidth={50}
      />
      {!reload && loading ? <LargeSpin /> : renderOfficesList()}
    </MainCard>
  )
})

export default OfficesList
