import React from 'react'
import cn from 'classnames'

import buttonStyles from './buttonStyles.module.scss'

const Button = props => {
  const {
    additionalClasses,
    style,
    onClick = () => {},
    disabled,
    renderIcon,
    text,
  } = props

  const classes = cn(buttonStyles.button, {
    [additionalClasses]: additionalClasses,
  })

  return (
    <button
      type='button'
      className={classes}
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      {renderIcon && renderIcon()}
      {text}
    </button>
  )
}

export default Button
