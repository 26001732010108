import React from 'react'
import { Field as FormikField } from 'formik'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'

const SimpleSelect = React.memo(({
  name,
  label,
  value,
  onChange,
  setFieldTouched,
  style,
  wrapperStyle,
  disabled,
  children,
  error,
  errorComponent,
  placeholder,
  className,
  parentNodeId,
  mode,
  errorStyle,
}) => {
  const getPopupContainer = () => parentNodeId
    ? document.getElementById(parentNodeId)
    : document.body
  return (
    <Form.Item className={className} label={label} style={wrapperStyle}>
      <div className='validation_block' style={errorStyle}>
        <FormikField
          render={() => (
            <Select
              mode={mode}
              name={name}
              value={value}
              disabled={disabled}
              placeholder={value || placeholder}
              notFoundContent='Не найдено'
              onChange={val => onChange(name, val)}
              onBlur={() => setFieldTouched(name, true)}
              style={{
                ...style,
                border: error && '1px solid #CD4141',
                width: '100%',
              }}
              defaultActiveFirstOption
              getPopupContainer={getPopupContainer}
            >
              {children}
            </Select>
          )}
        />
        {errorComponent
        && <div className='validation_block margin_top__small'>{errorComponent()}</div>}
      </div>
    </Form.Item>
  )
})

export default SimpleSelect
