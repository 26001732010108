import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from 'antd/lib/checkbox'
import LeftSidePanel from './LeftSidePanel'
import EmailConfirmationModal from './modals/EmailConfirmationModal'
import ExpandingSearch from '../UI/ExpandingSearch'
import FadeTransition from '../UI/animations/transition/FadeTransition'
import KioskList from './list/KioskList'
import LargeSpin from '../UI/Spin/LargeSpin'
import ListLoadingSpin from '../UI/Spin/ListLoadingSpin'
import MainCard from '../MainCard'
import ScrollTransition from '../UI/animations/transition/ScrollTransition'
import UserList from './list/UserList'
import { CONFIRM_EMAIL } from '../../actions/users'
import { getFilteredKiosks, getUsers, getUsersCount } from '../../reducers/users-selectors'
import { kioskAC, usersAC } from '../../actions/actionCreator/usersAC'
import { getLoadingNotInGroup, getUsersLoading } from '../../reducers/app-selectors'
import { LOADER_TYPE_NOT_GROUP } from '../../sagas/usersSagas'

export const DEFAULT_USER = { id: null, name: '' }
export const DEFAULT_USER_EMAIL = { ...DEFAULT_USER, email: '' }

const RenderActiveUserCheckbox = ({ isInactiveUsersVisible, toggleInactiveUsersVisibility }) => (
  <div className='flex_container__align_items__center flex_container__flex_1'>
    <Checkbox
      checked={isInactiveUsersVisible}
      onChange={toggleInactiveUsersVisibility}
    >
      Неактивные аккаунты
    </Checkbox>
  </div>
)

const UserCard = React.memo(() => {
  const dispatch = useDispatch()
  const users = useSelector(getUsers)
  const kiosks = useSelector(getFilteredKiosks)
  const isUserLoading = useSelector(getLoadingNotInGroup)
  const isAppLoading = useSelector(getUsersLoading)
  const count = useSelector(getUsersCount)

  const [isConfirmEmailShow, setIsConfirmEmailShow] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState({ id: null, name: '', email: '' })
  const [isInactiveUsersVisible, setIsInactiveUsersVisible] = useState(false)
  const [isSearchFieldExpanded, setIsSearchFieldExpanded] = useState(false)
  const [accountType, setAccountType] = useState('Пользователь')
  const [page, setPage] = useState(1)
  const [searchString, setSearchString] = useState('')
  const isUserFilterEnabled = accountType === 'Пользователь'

  const getUserList = useCallback(({ page, pagination, searchString }) =>
    dispatch(usersAC.getUsers({ page, searchString, pagination })), [page])
  const getKiosks = useCallback(({ page, searchString }) =>
    dispatch(kioskAC.getKiosks({ page, searchString })), [page])
  useEffect(() => {
    accountType === 'Пользователь'
      ? getUserList({ page: 1 })
      : getKiosks({})
  }, [accountType])

  const onEmailConfirm = useCallback((user, callback) =>
    dispatch({ type: CONFIRM_EMAIL, user, callback }), [])
  const setCurrentAccountType = useCallback(val => {
    setAccountType(val)
    setPage(1)
    setSearchString('')
  }, [accountType])

  const confirmEmail = () => {
    const { id } = selectedEmail
    onEmailConfirm({ account: id },
      () => {
        setIsConfirmEmailShow(false)
        setSelectedEmail({ id: null, name: '', email: '' })
      })
  }

  const loadFilteredAccounts = useCallback(() => {
    accountType === 'Пользователь'
      ? getUserList({ page: page + 1, searchString, pagination: LOADER_TYPE_NOT_GROUP })
      : getKiosks({ searchString })
  }, [accountType, page])

  const onListScroll = ({ contentScrollHeight, clientHeight, scrollTop }) => {
    const isAllFetched = count <= users.length
    if (isUserLoading || isAllFetched) return null
    const isScrollAtTheBottom = (contentScrollHeight - (clientHeight + scrollTop) <= 3)
    if (isScrollAtTheBottom) {
      setPage(prev => prev + 1)
      loadFilteredAccounts()
    }
  }
  const getSearchPlaceHolder = useCallback(() => `Начните вводить ${accountType === 'Пользователь'
    ? 'имя пользователя'
    : 'название киоска'}`, [accountType])
  const onCloseHandler = useCallback(() => {
    setIsConfirmEmailShow(false)
    setSelectedEmail({ id: null, name: '', email: '' })
  }, [])

  const setSearchStringHandler = val => {
    setSearchString(val)
    setPage(1)
  }
  const getFilterStringForResponse = () => isInactiveUsersVisible ? 'true' : false
  const setIsExpanded = useCallback(value =>
    setIsSearchFieldExpanded(value), [isSearchFieldExpanded])
  const onEmailConfirmIconClick = useCallback(({ id, email, name }) => {
    setIsConfirmEmailShow(true)
    setSelectedEmail({ id, name, email })
  }, [])

  const toggleInactiveUsersVisibility = () => {
    setIsInactiveUsersVisible(prevState => !prevState)
    setPage(1)
    loadFilteredAccounts()
  }

  return (
    <>
      <MainCard title='ВСЕ ПОЛЬЗОВАТЕЛИ' icon='icon__user'>
        <div className='flex_container full_height'>
          <LeftSidePanel
            accountType={accountType}
            setAccountType={setCurrentAccountType}
          />
          <div className='container__right_side'>
            <FadeTransition loading style={{ marginBottom: '12px' }}>
              <div className='flex_container flex_container__space_between'>
                <ExpandingSearch
                  setIsExpanded={setIsExpanded}
                  setSearchString={setSearchString}
                  searchString={searchString}
                  isExpanded={isSearchFieldExpanded}
                  placeholder={getSearchPlaceHolder()}
                  getItems={isUserFilterEnabled ? getUserList : getKiosks}
                  style={{ flex: 2 }}
                  defaultExpanded
                  large
                  autofocus
                />
                {/* {isUserFilterEnabled && this.renderActiveUserCheckbox()} */}
              </div>
            </FadeTransition>
            {isAppLoading
              ? <LargeSpin />
              : (
                <ScrollTransition
                  // Отключения пагинации для киосков
                  onScroll={isUserFilterEnabled ? onListScroll : null}
                  loading={!isAppLoading}
                >
                  <div className='flex_container__column flex_container__center'>
                    {isUserFilterEnabled
                      ? (
                        <UserList
                          list={users}
                          onEmailConfirm={onEmailConfirmIconClick}
                        />
                      )
                      : <KioskList list={kiosks} />}
                    {isUserLoading && <ListLoadingSpin />}
                  </div>
                </ScrollTransition>
              )}
          </div>
        </div>
      </MainCard>
      <EmailConfirmationModal
        {...selectedEmail}
        start={isConfirmEmailShow}
        onConfirm={confirmEmail}
        onClose={onCloseHandler}
      />
    </>
  )
})
export default UserCard
