import {
  CHECK_ALL_ROOMS,
  CHECK_ALL_TABLES,
  CHECK_ROOM,
  CHECK_TABLE,
  CLEAR_FLOORS_SUCCEED,
  CLEAR_OFFICE,
  CLEAR_ROOMS_SUCCEED,
  CLEAR_TABLES_SUCCEED,
  DELETE_FLOOR_SUCCEED,
  DELETE_MAP_SUCCEED,
  DELETE_OFFICE_SUCCEED,
  DELETE_OFFICE_TAG_SUCCEED,
  DELETE_ROOM_SUCCEED,
  DELETE_ROOM_TYPE_SUCCEED,
  DELETE_ROOMS_LIST_SUCCEED,
  DELETE_TABLE_SUCCEED,
  DELETE_TABLES_LIST_SUCCEED,
  DELETE_ZONE_SUCCEED,
  GET_FLOORS_SUCCEED,
  GET_OFFICE_FAILED,
  GET_OFFICE_SUCCEED,
  GET_OFFICE_TAGS_FAILED,
  GET_OFFICE_TAGS_SUCCEED,
  GET_OFFICES_FAILED,
  GET_OFFICES_LICENSES_SUCCEED,
  GET_OFFICES_SUCCEED,
  GET_ROOM_TYPES_FAILED,
  GET_ROOM_TYPES_SUCCEED,
  GET_ROOMS_SUCCEED,
  GET_TABLES_SUCCEED,
  GET_ZONES_SUCCEED,
  POST_FLOORS_SUCCEED,
  POST_MAP_TO_FLOOR_SUCCEED,
  POST_OFFICE_IMAGES_SUCCEED,
  POST_OFFICE_SUCCEED,
  POST_OFFICE_TAGS_SUCCEED,
  POST_ROOM_SUCCEED,
  POST_ROOM_TYPE_ICON_SUCCEED,
  POST_ROOM_TYPES_SUCCEED,
  POST_TABLE_SUCCEED,
  POST_TAG_ICON_SUCCEED,
  POST_ZONES_SUCCEED,
  PUT_FLOOR_SUCCEED,
  PUT_OFFICE_SUCCEED,
  PUT_OFFICE_TAG_SUCCEED,
  PUT_ROOM_SUCCEED,
  PUT_ROOM_TYPE_SUCCEED,
  PUT_TABLE_SUCCEED,
  POST_ROOM_MARKER_SUCCEED,
  PUT_ROOM_MARKER_SUCCEED,
  DELETE_ROOM_MARKER_SUCCEED,
  POST_TABLE_MARKER_SUCCEED,
  PUT_TABLE_MARKER_SUCCEED,
  DELETE_TABLE_MARKER_SUCCEED,
  DELETE_ALL_MARKERS_ON_FLOOR_SUCCEED,
  RECALCULATE_SELECTED_COUNTS,
  PUT_ZONE_SUCCEED,
} from '../actions/offices'

const initialState = {
  count: 0,
  created_at: '',
  description: '',
  id: '',
  images: [],
  license: {},
  service_email: '',
  title: '',
  working_hours: '',
  advancedSettings: {
    timeBeforeBook: 60,
    timeAfterBook: 60,
  },

  licenses: [],
  list: [], // массив бизнес центров
  floors: [],
  rooms: [],
  tables: [],
  zones: [],
  tags: [],
  roomTypes: [],

  // для чекбоксов в DetailInfo
  selectedRoomsCount: 0,
  selectedTablesCount: 0,
}

// для чекбоксов в DetailInfo
const getSelectedRoomsCount = rooms => rooms.filter(room => room.checked).length
const getSelectedTablesCount = rooms => rooms
  .reduce((acc, cur) => [...acc, ...cur.tables], [])
  .filter(table => table.checked).length

const officesReducer = (state = initialState, action) => {
  switch (action.type) {
    /* ------------ Offices ------------ */
    /* ========================================================================================== */
    case GET_OFFICE_SUCCEED: {
      const { office } = action.payload

      return {
        ...state,
        ...office,
      }
    }

    case GET_OFFICE_FAILED: {
      return { ...initialState }
    }

    case POST_OFFICE_SUCCEED: {
      const { newOffice } = action.payload

      return {
        ...state,
        ...newOffice,
      }
    }

    case PUT_OFFICE_SUCCEED: {
      const { updatedOffice } = action.payload
      return {
        ...state,
        ...updatedOffice,
      }
    }

    case DELETE_OFFICE_SUCCEED: {
      const { officeId } = action.payload
      const updatedOfficesList = state.list.filter(office => office.id !== officeId)
      return {
        ...state,
        list: updatedOfficesList,
      }
    }

    case CLEAR_OFFICE: {
      // сбрасываем все поля кроме списка офисов
      return { ...initialState, list: state.list }
    }

    case POST_OFFICE_IMAGES_SUCCEED: {
      const { postedImages: newList } = action.payload
      const { images: prevList } = state
      const newImagesList = prevList?.length ? [...newList, ...prevList] : newList

      return {
        ...state,
        images: newImagesList,
      }
    }
    case GET_OFFICES_SUCCEED: {
      return {
        ...state,
        list: action.list,
        count: action.count,
      }
    }
    case GET_OFFICES_FAILED: {
      return {
        ...state,
        list: [],
      }
    }
    /* ========================================================================================== */

    /* ------------ Licenses ------------ */
    /* ========================================================================================== */
    case GET_OFFICES_LICENSES_SUCCEED: {
      const { licenses } = action.payload

      return {
        ...state,
        licenses,
      }
    }
    /* ========================================================================================== */

    /* ------------ Floors ------------ */
    /* ========================================================================================== */
    case GET_FLOORS_SUCCEED: {
      return {
        ...state,
        floors: action.payload,
      }
    }

    case POST_FLOORS_SUCCEED: {
      const { floors } = action.payload

      const updatedFloors = [...floors, ...state.floors]

      return {
        ...state,
        floors: updatedFloors,
      }
    }

    case PUT_FLOOR_SUCCEED: {
      const { newFloor } = action.payload

      const updatedFloors = state.floors.map(floor => {
        if (floor.id === newFloor.id) {
          return newFloor
        }

        return floor
      })

      return {
        ...state,
        floors: updatedFloors,
      }
    }

    case DELETE_FLOOR_SUCCEED: {
      const { floorId } = action.payload

      const updatedFloors = state.floors.filter(({ id }) => id !== floorId)
      const updatedRooms = state.rooms.filter(({ floor_id }) => floor_id !== floorId)

      return {
        ...state,
        floors: updatedFloors,
        rooms: updatedRooms,
      }
    }
    case CLEAR_FLOORS_SUCCEED: {
      return {
        ...state,
        floors: [],
      }
    }
    /* ========================================================================================== */

    /* ------------ Zones ------------ */
    /* ========================================================================================== */
    case GET_ZONES_SUCCEED: {
      const { zones } = action.payload

      return {
        ...state,
        zones,
      }
    }

    case POST_ZONES_SUCCEED: {
      const { zones } = action.payload

      const updatedZones = [...zones, ...state.zones]

      return {
        ...state,
        zones: updatedZones,
      }
    }

    case PUT_ZONE_SUCCEED: {
      const { updatedZone } = action.payload

      const updatedZones = state.zones.map(zone => {
        if (zone.id === updatedZone.id) {
          return updatedZone
        }

        return zone
      })

      return {
        ...state,
        zones: updatedZones,
      }
    }

    case DELETE_ZONE_SUCCEED: {
      const { zoneId } = action.payload

      const updatedZones = state.zones.filter(({ id }) => id !== zoneId)

      return {
        ...state,
        zones: updatedZones,
      }
    }
    /* ========================================================================================== */

    /* ------------ Tags ------------ */
    /* ========================================================================================== */
    case GET_OFFICE_TAGS_SUCCEED: {
      const { tags } = action.payload

      return {
        ...state,
        tags,
      }
    }

    case GET_OFFICE_TAGS_FAILED: {
      return {
        ...state,
        tags: [],
      }
    }

    case POST_OFFICE_TAGS_SUCCEED: {
      const { tags } = action.payload
      const updatedTags = [...state.tags, ...tags]

      return {
        ...state,
        tags: updatedTags,
      }
    }

    case POST_TAG_ICON_SUCCEED:
    case PUT_OFFICE_TAG_SUCCEED: {
      const { updatedTag } = action.payload

      const updatedTags = state.tags.map(tag => {
        if (tag.id === updatedTag.id) {
          return updatedTag
        }

        return tag
      })

      return {
        ...state,
        tags: updatedTags,
      }
    }

    case DELETE_OFFICE_TAG_SUCCEED: {
      const { tagId } = action.payload
      const updatedTags = state.tags.filter(({ id }) => id !== tagId)

      return {
        ...state,
        tags: updatedTags,
      }
    }
    /* ========================================================================================== */

    /* ------------ RoomTypes ------------ */
    /* ========================================================================================== */
    case GET_ROOM_TYPES_SUCCEED: {
      const { roomTypes } = action.payload

      return {
        ...state,
        roomTypes: roomTypes,
      }
    }

    case GET_ROOM_TYPES_FAILED: {
      return {
        ...state,
        roomTypes: [],
      }
    }

    case POST_ROOM_TYPES_SUCCEED: {
      const { roomTypes } = action.payload
      const updatedRoomTypes = [...state.roomTypes, ...roomTypes]

      return {
        ...state,
        roomTypes: updatedRoomTypes,
      }
    }

    case POST_ROOM_TYPE_ICON_SUCCEED:
    case PUT_ROOM_TYPE_SUCCEED: {
      const { updatedRoomType } = action.payload

      const updatedRoomTypes = state.roomTypes.map(roomType => {
        if (roomType.id === updatedRoomType.id) {
          return updatedRoomType
        }

        return roomType
      })

      return {
        ...state,
        roomTypes: updatedRoomTypes,
      }
    }

    case DELETE_ROOM_TYPE_SUCCEED: {
      const { roomTypeId } = action.payload
      const updatedRoomTypes = state.roomTypes.filter(({ id }) => id !== roomTypeId)

      return {
        ...state,
        roomTypes: updatedRoomTypes,
      }
    }
    /* ========================================================================================== */

    /* ------------ Rooms ------------ */
    /* ========================================================================================== */
    case GET_ROOMS_SUCCEED: {
      const { rooms } = action.payload

      return {
        ...state,
        rooms,
      }
    }

    case POST_ROOM_SUCCEED: {
      const { room } = action.payload

      const updatedRooms = [...state.rooms, { ...room }]

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case PUT_ROOM_SUCCEED: {
      const { room: updatedRoom } = action.payload

      const updatedRooms = state.rooms.map(room => {
        if (room.id === updatedRoom.id) {
          // Сделано для кейса, когда мы меняем roomType в руме.
          // Если это изменение затрагивает столы, то мы получаем с бэка новый рум,
          // с уже созданным дефолтным столом, либо с пустым полем tables.
          // Иначе мы получаем с бэка новый рум без столов
          // и записываем в него его текущие столы из стора.
          const updatedRoomWithCorrectTables = Object.hasOwnProperty.call(updatedRoom, 'tables')
            ? { ...updatedRoom }
            : { ...updatedRoom, tables: room.tables }

          return updatedRoomWithCorrectTables
        }

        return room
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case DELETE_ROOM_SUCCEED: {
      const { roomId } = action.payload

      const updatedRooms = state.rooms.filter(room => room.id !== roomId)

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case DELETE_ROOMS_LIST_SUCCEED: {
      const { roomIds } = action.payload

      const updatedRooms = state.rooms
        .filter(({ id }) => !roomIds.includes(id))

      return {
        ...state,
        rooms: updatedRooms,
      }
    }
    case CLEAR_ROOMS_SUCCEED: {
      return {
        ...state,
        rooms: [],
      }
    }

    /* ========================================================================================== */

    /* ------------ Tables ------------ */
    /* ========================================================================================== */
    case GET_TABLES_SUCCEED: {
      const { tables } = action.payload
      return {
        ...state,
        tables: tables,
      }
    }

    case POST_TABLE_SUCCEED: {
      const { newTable } = action.payload

      const updatedRooms = [...state.rooms]
      const currentRoomIndex = updatedRooms.findIndex(room => room.id === newTable.room)
      const updatedCurrentRoom = { ...updatedRooms[currentRoomIndex] }

      // checked для чекбоксов в DetailInfo
      if (updatedCurrentRoom.checked) updatedCurrentRoom.checked = false

      updatedCurrentRoom.tables.push(newTable)
      updatedRooms[currentRoomIndex] = updatedCurrentRoom

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case PUT_TABLE_SUCCEED: {
      const { newTable, oldRoomId } = action.payload
      const { room: newRoomId } = newTable

      const updatedRooms = [...state.rooms]
      const currentRoomIndex = updatedRooms.findIndex(room => room.id === newRoomId)
      const updatedCurrentRoom = { ...updatedRooms[currentRoomIndex] }
      const updatedCurrentTables = [...updatedCurrentRoom.tables]

      // checked для чекбоксов в DetailInfo
      if (updatedCurrentRoom.checked) updatedCurrentRoom.checked = false

      if (oldRoomId === newRoomId) {
        const currentTableIndex = updatedCurrentTables.findIndex(({ id }) => id === newTable.id)

        updatedCurrentTables[currentTableIndex] = newTable
      } else {
        const oldRoomIndex = updatedRooms.findIndex(room => room.id === oldRoomId)
        const updatedOldRoom = { ...updatedRooms[oldRoomIndex] }
        const updatedOldTables = updatedOldRoom.tables.filter(({ id }) => id !== newTable.id)

        updatedCurrentTables.push(newTable)
        updatedOldRoom.tables = updatedOldTables
        updatedRooms[oldRoomIndex] = updatedOldRoom
      }

      updatedCurrentRoom.tables = updatedCurrentTables
      updatedRooms[currentRoomIndex] = updatedCurrentRoom

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case DELETE_TABLE_SUCCEED: {
      const { tableId } = action.payload

      const updatedRooms = state.rooms.map(room => {
        const updatedTables = room.tables.filter(({ id }) => id !== tableId)

        return {
          ...room,
          tables: updatedTables,
        }
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case DELETE_TABLES_LIST_SUCCEED: {
      const { tableIds } = action.payload

      const updatedRooms = state.rooms.map(room => {
        const updatedTables = room.tables.filter(({ id }) => !tableIds.includes(id))

        return { ...room, tables: updatedTables }
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }
    case CLEAR_TABLES_SUCCEED: {
      return {
        ...state,
        tables: [],
      }
    }
    /* ========================================================================================== */

    /* ------------ Maps & Markers ------------ */
    /* ========================================================================================== */
    case DELETE_MAP_SUCCEED:
    case POST_MAP_TO_FLOOR_SUCCEED: {
      const { floorId, floorMap } = action.payload
      const updatedFloors = state.floors.map(floor => floor.id === floorId ? {
        ...floor,
        floor_map: floorMap,
      } : floor)
      return {
        ...state,
        floors: updatedFloors,
      }
    }

    case POST_ROOM_MARKER_SUCCEED:
    case PUT_ROOM_MARKER_SUCCEED: {
      const { newMarker } = action.payload

      const updatedRooms = state.rooms.map(room => {
        if (room.id === newMarker.room) {
          return { ...room, marker: newMarker }
        }

        return room
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case DELETE_ROOM_MARKER_SUCCEED: {
      const { roomId } = action.payload

      const updatedRooms = state.rooms.map(room => {
        if (room.id === roomId) {
          const updatedCurrentRoom = { ...room }
          updatedCurrentRoom.marker = null
          updatedCurrentRoom.tables = updatedCurrentRoom.tables
            .map(table => ({ ...table, marker: null }))

          return updatedCurrentRoom
        }

        return room
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case POST_TABLE_MARKER_SUCCEED:
    case PUT_TABLE_MARKER_SUCCEED: {
      const { newTableMarker } = action.payload

      const updatedRooms = state.rooms.map(room => {
        if (room.id === newTableMarker.room) {
          const indexOfCurrentTable = room.tables
            .findIndex(table => table.id === newTableMarker.table)

          if (indexOfCurrentTable > -1) {
            const updatedRoom = { ...room }
            updatedRoom.tables[indexOfCurrentTable].marker = newTableMarker

            return updatedRoom
          }
        }

        return room
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case DELETE_TABLE_MARKER_SUCCEED: {
      const { tableId, roomId } = action.payload

      const updatedRooms = state.rooms.map(room => {
        if (room.id === roomId) {
          const indexOfCurrentTable = room.tables
            .findIndex(table => table.id === tableId)

          if (indexOfCurrentTable > -1) {
            const updatedRoom = { ...room }
            updatedRoom.tables[indexOfCurrentTable].marker = null

            return updatedRoom
          }
        }

        return room
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }

    case DELETE_ALL_MARKERS_ON_FLOOR_SUCCEED: {
      const { floorId } = action.payload

      const updatedRooms = state.rooms.map(room => {
        if (room.floor_id === floorId) {
          const updatedCurrentRoom = { ...room }
          updatedCurrentRoom.marker = null
          updatedCurrentRoom.tables = updatedCurrentRoom.tables
            .map(table => ({ ...table, marker: null }))

          return updatedCurrentRoom
        }

        return room
      })

      return {
        ...state,
        rooms: updatedRooms,
      }
    }
    /* ========================================================================================== */

    /* ------------ для чекбоксов в DetailInfo ------------ */
    /* ========================================================================================== */
    case CHECK_ROOM: {
      const { roomId } = action.payload

      const updatedRooms = state.rooms.map(room => {
        if (room.id !== roomId) {
          return room
        }

        const newRoom = { ...room }

        newRoom.checked = !newRoom.checked
        newRoom.tables = newRoom.tables.map(table => ({
          ...table,
          checked: newRoom.checked,
        }))

        return newRoom
      })

      const newSelectedRoomsCount = getSelectedRoomsCount(updatedRooms)
      const newSelectedTablesCount = getSelectedTablesCount(updatedRooms)

      return {
        ...state,
        rooms: updatedRooms,
        selectedRoomsCount: newSelectedRoomsCount,
        selectedTablesCount: newSelectedTablesCount,
      }
    }

    case CHECK_ALL_ROOMS: {
      const { checked } = action.payload

      const updatedRooms = state.rooms.map(room => {
        const newRoom = { ...room }

        newRoom.checked = checked
        newRoom.tables = newRoom.tables.map(table => ({
          ...table,
          checked,
        }))

        return newRoom
      })

      const newSelectedRoomsCount = getSelectedRoomsCount(updatedRooms)
      const newSelectedTablesCount = getSelectedTablesCount(updatedRooms)

      return {
        ...state,
        rooms: updatedRooms,
        selectedRoomsCount: newSelectedRoomsCount,
        selectedTablesCount: newSelectedTablesCount,
      }
    }

    case CHECK_TABLE: {
      const { tableId, roomId } = action.payload

      const updatedRooms = [...state.rooms]
      const targetRoomIndex = updatedRooms.findIndex(room => room.id === roomId)
      const newTargetRoom = { ...updatedRooms[targetRoomIndex] }

      newTargetRoom.tables = newTargetRoom.tables.map(table => {
        const newCheckedValue = table.id === tableId
          ? !table.checked
          : table.checked

        return {
          ...table,
          checked: newCheckedValue,
        }
      })

      updatedRooms[targetRoomIndex] = newTargetRoom

      const newSelectedTablesCount = getSelectedTablesCount(updatedRooms)

      return {
        ...state,
        rooms: updatedRooms,
        selectedTablesCount: newSelectedTablesCount,
      }
    }

    case CHECK_ALL_TABLES: {
      const { roomId, checked } = action.payload
      const updatedRooms = [...state.rooms]

      const targetRoomIndex = updatedRooms.findIndex(({ id }) => id === roomId)
      const newTargetRoom = { ...updatedRooms[targetRoomIndex] }
      newTargetRoom.tables = newTargetRoom.tables.map(table => ({
        ...table,
        checked,
      }))

      updatedRooms[targetRoomIndex] = newTargetRoom

      const newSelectedTablesCount = getSelectedTablesCount(updatedRooms)

      return {
        ...state,
        rooms: updatedRooms,
        selectedTablesCount: newSelectedTablesCount,
      }
    }

    case RECALCULATE_SELECTED_COUNTS: {
      const { rooms } = state

      const newSelectedRoomsCount = getSelectedRoomsCount(rooms)
      const newSelectedTablesCount = getSelectedTablesCount(rooms)

      return {
        ...state,
        selectedTablesCount: newSelectedTablesCount,
        selectedRoomsCount: newSelectedRoomsCount,
      }
    }
    /* ========================================================================================== */

    default:
      return state
  }
}
export default officesReducer
