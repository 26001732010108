import React from 'react'
import { useSelector } from 'react-redux'
import Icon from './Icon'
import { getDisableLoading } from '../../reducers/app-selectors'

export const Button = React.memo(props => {
  const {
    icon,
    title,
    styles,
    onClick,
    style,
    renderIcon,
    disable,
  } = props
  const localDisable = useSelector(getDisableLoading)
  return (
    <div
      type='button'
      className={styles}
      style={style}
      onClick={disable || localDisable ? null : onClick}
    >
      {renderIcon ? renderIcon() : icon && <Icon name={icon} />}
      <span className='large_btn__title'>{title}</span>
    </div>
  )
})

export default Button
