import React, { useEffect, useState } from 'react'
import InputNumber from 'antd/lib/input-number'
import Input from 'antd/lib/input'
import Switch from 'antd/lib/switch'
import Checkbox from 'antd/lib/checkbox'
import Tooltip from 'antd/lib/tooltip'
import message from 'antd/lib/message'
import IconSave from '../../../../../UI/icons/Save'
import IconInfo from '../../../../../UI/icons/InfoBlue'
import IconEdit from '../../../../../UI/icons/Edit'
import IconDelete from '../../../../../UI/icons/Delete'
import ColorpickerIcon from '../../../../../UI/icons/Colorpicker'
import CardListItem from '../../../../../helpers/lists/CardListItem'
import AddImageBtn from '../../helpers/AddImageBtn'
import pluralizeString from '../../../../../../utils/pluralizeString'
import ConfirmationModal from '../../../../../UI/modals/ConfirmationModal'
import { formFormData } from '../../../../../Map/helpers/loadMap'
import Button from '../../../../../UI/Button'
import ColorPickModal from '../../../../../UI/modals/ColorPickModal'

const INTERVAL_PLURALIZE_STRINGS = {
  hours: ['часа', 'часов', 'часов'],
  days: ['дня', 'дней', 'дней'],
}

const INTERVAL_PLURALIZE_STRINGS_STYLES = {
  hours: { flex: 1, display: 'inline-block' },
  days: { flex: 1 },
}

export default ({
  isNew,
  roomType,
  onSave,
  onDelete,
  onIconUpload,
  onCancel,
}) => {
  const [isEdit, setIsEdit] = useState(isNew)
  const [values, setValues] = useState({})
  const [error, setError] = useState(false)
  const [isHourBookingAvailable, setIsHourBookingAvailable] = useState(false)
  const [isDailyBookingAvailable, setIsDailyBookingAvailable] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isColorPickerModalOpen, setIsColorPickerModalOpen] = useState(false)

  const handleChangeInterval = (name, value) => setValues(prev => ({
    ...prev,
    [`work_interval_${name}`]: value,
  }))

  const handleSwitch = () => setValues(prev => ({ ...prev, bookable: !prev.bookable }))

  const handleChangeTitle = ({ target }) => {
    const inputValue = target.value
    setValues(prev => ({ ...prev, title: inputValue }))
    setError(!inputValue)
  }

  const handleChangeColor = color => {
    setValues(prev => ({ ...prev, color }))
    setIsColorPickerModalOpen(false)
  }

  const isRoomTypeTitleValid = (title = '') => {
    const titleLength = title.length
    if (titleLength >= 3 && titleLength <= 140) return true

    message.error(`Введите название типа помещения (от 3 до 140 символов). Введено: ${titleLength} символов`)
    return false
  }

  const handleSave = (values, callback) => {
    const formattedTitle = values.title?.trim()

    if (isRoomTypeTitleValid(formattedTitle)) {
      onSave({ ...values, title: formattedTitle, icon: values.icon?.id || null }, callback)
      return
    }

    !error && setError(true)
  }

  const handleIconUpload = ({ target }) => {
    const file = target.files[0]
    const formData = formFormData(file)

    onIconUpload(values, formData)
  }

  const renderInterval = intervalType => {
    const styles = INTERVAL_PLURALIZE_STRINGS_STYLES[intervalType]
    const interval = roomType[`work_interval_${intervalType}`]
    const strings = INTERVAL_PLURALIZE_STRINGS[intervalType]

    return (
      <span className='room-types-list__item-interval' style={styles}>
        {interval
          ? `До ${interval} ${pluralizeString(interval, strings)}`
          : (
            <span className='secondary_text__inactive'>
              Не задан
            </span>
          )}
      </span>
    )
  }

  const renderDeleteIcon = () => (
    <Button
      onClick={() => setValues(prev => ({ ...prev, icon: null }))}
      styles='btn-remove-image'
      renderIcon={() =>
        <IconDelete height={16} width={16} className='btn-remove-image__icon_red' />}
    />
  )

  const getIconClassName = (() =>
    `room-types-list__item-action${roomType.pre_defined ? '_disabled' : ''}`)()

  const clickOutsideNewItemHandler = ({ target }) => {
    if (!values?.title) {
      const isClickOnBtn = target.closest('.btn-group__item')
      const isClickOnSidePanel = target.closest('.side_panel')
      if (isClickOnBtn || isClickOnSidePanel) return

      const isClickOutsideItem = !target.closest('.room-types-list__item')
      if (target.nodeName === 'LI') return
      if (isClickOutsideItem) onCancel()
    }
  }

  const generateHandleSaveColor = () => isEdit
    ? handleChangeColor
    : color => onSave(
      { ...values, color, icon: values.icon?.id || null }, () => setIsColorPickerModalOpen(false),
    )

  useEffect(() => {
    if (isNew) return

    isEdit ? setValues({ ...values, icon: roomType.icon }) : setValues(roomType)
  }, [roomType])

  useEffect(() => {
    if (isNew) {
      document.addEventListener('mousedown', clickOutsideNewItemHandler)
      return () => document.removeEventListener('mousedown', clickOutsideNewItemHandler)
    }
  }, [values?.title])

  return (
    <>
      <CardListItem
        style={{ height: isEdit && '120px', alignItems: isEdit && 'flex-start' }}
        extraClassName='room-types-list__item'
      >
        {/* TITLE INPUT */}
        <div style={{ width: '30%' }}>
          {isEdit
            ? (
              <Input
                autoFocus
                name='title'
                value={values.title}
                placeholder='Введите название'
                className='room-types-list__item-input'
                onChange={handleChangeTitle}
                disabled={roomType.pre_defined}
                style={{ border: error && '1px solid #CD4141' }}
              />
            )
            : <span className='room-types-list__item-title'>{values.title}</span>}
        </div>

        {/* IMAGE BTN */}
        <div style={{ width: '10%' }}>
          {isNew || roomType.pre_defined
            ? <span className='secondary_text__inactive'>–</span>
            : (
              <AddImageBtn
                image={values.icon?.path}
                onUpload={handleIconUpload}
                style={{ display: 'flex', marginRight: 0 }}
                extraIcon={isEdit && renderDeleteIcon()}
              />
            )}
        </div>

        {/* COLORPICKER BTN */}
        <div style={{ width: '6%' }}>
          {isNew || roomType.pre_defined
            ? <span className='secondary_text__inactive'>–</span>
            : (
              <ColorpickerIcon
                color={values.color}
                onClick={() => setIsColorPickerModalOpen(true)}
              />
            )}
        </div>

        {/* SWITCH  */}
        <div className='room-types-list__item-switch-group'>
          <Switch disabled={!isEdit} checked={values.bookable} onChange={handleSwitch} />
        </div>

        {/* INTERVALS VALUES, CHECKBOXES & INPUTS */}
        <div
          style={{
            flex: 1,
            display: !isEdit && 'flex',
            flexDirection: !isEdit && 'row',
            flexWrap: !isEdit && 'nowrap',
          }}
        >
          {isEdit
            ? (
              <>
                {/* HOURS */}
                <div className='room-types-list__item-checkbox-group'>
                  <Checkbox
                    checked={isHourBookingAvailable}
                    disabled={!values.bookable}
                    onChange={() => setIsHourBookingAvailable(val => !val)}
                  >
                    Часовой инт-л
                  </Checkbox>
                  <InputNumber
                    name='hours'
                    min={0}
                    max={24}
                    style={{ height: '30px' }}
                    value={values.work_interval_hours || 0}
                    onChange={value => handleChangeInterval('hours', value)}
                    disabled={!isHourBookingAvailable || !values.bookable}
                  />
                  <Tooltip
                    overlayClassName='room-types-list__item-tooltip'
                    title='Укажите максимальный период бронирования в часах'
                  >
                    <IconInfo className='icon-info' />
                  </Tooltip>
                </div>
                {/* DAYS */}
                <div className='room-types-list__item-checkbox-group'>
                  <Checkbox
                    checked={isDailyBookingAvailable}
                    disabled={!values.bookable}
                    onChange={() => setIsDailyBookingAvailable(val => !val)}
                  >
                    Дневной инт-л
                  </Checkbox>
                  <InputNumber
                    name='days'
                    min={0}
                    max={90}
                    style={{ height: '30px' }}
                    value={values.work_interval_days || 0}
                    onChange={value => handleChangeInterval('days', value)}
                    disabled={!isDailyBookingAvailable || !values.bookable}
                  />
                  <Tooltip
                    overlayClassName='room-types-list__item-tooltip'
                    title='Укажите максимальный период бронирования в днях'
                  >
                    <IconInfo className='icon-info' />
                  </Tooltip>
                </div>
                <Checkbox
                  checked={values.unified}
                  disabled={!values.bookable || !isEdit}
                  onChange={() => setValues(values => ({ ...values, unified: !values.unified }))}
                >
                  Помещение целиком
                </Checkbox>
              </>
            )
            : (
              <>
                {/* INTERVAL VALUES */}
                {renderInterval('hours')}
                {renderInterval('days')}
              </>
            )}
        </div>

        {/* ACTIONS */}
        <div
          style={{ alignSelf: isEdit && 'flex-start' }}
          className='room-types-list__item-action-group'
        >
          {isEdit
            ? (
              <IconSave
                className='room-types-list__icon-wrapper'
                onClick={() => handleSave(values, () => setIsEdit(false))}
                color='#0079C1'
              />
            )
            : (
              <>
                <IconDelete
                  onClick={() => !roomType.pre_defined && setIsDeleteModalVisible(true)}
                  className={getIconClassName}
                />
                <IconEdit
                  width='18'
                  height='18'
                  className={getIconClassName}
                  onClick={() => !roomType.pre_defined && setIsEdit(!isEdit)}
                />
              </>
            )}
        </div>
      </CardListItem>
      <ConfirmationModal
        start={isDeleteModalVisible}
        text={`Удалить тип помещения '${roomType.title}'?`}
        onConfirm={() => onDelete(roomType.id)}
        onClose={() => setIsDeleteModalVisible(false)}
      />
      {isColorPickerModalOpen && (
        <ColorPickModal
          icon={roomType.icon?.path || null}
          color={values.color}
          onClose={() => setIsColorPickerModalOpen(false)}
          start={isColorPickerModalOpen}
          onSave={generateHandleSaveColor()}
        />
      )}
    </>
  )
}
