import React, { useState } from 'react'
import Input from 'antd/lib/input'

export default React.memo(props => {
  const {
    placeholder,
    style,
    value,
    onChange,
    type,
    disable,
  } = props

  const [isFocused, setIsFocused] = useState(false)

  const getBorderStyle = () => isFocused ? { border: '1px solid #0079C1' } : {}
  const handleToggleIsFocused = () => setIsFocused(!isFocused)

  return (
    <div
      className='search_field__large search_field__large__active'
      style={{ ...style, ...getBorderStyle() }}
    >
      <div className='icon__search' />
      <Input
        value={value}
        placeholder={placeholder}
        onFocus={handleToggleIsFocused}
        onChange={({ target }) => onChange(target.value)}
        onBlur={handleToggleIsFocused}
        type={type}
        disabled={disable}
      />
    </div>
  )
})
