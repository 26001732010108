import { combineReducers } from 'redux'
import accountReducer from './accountReducer'
import filesReducer from './filesReducer'
import usersReducer from './usersReducer'
import bookingReducer from './bookingReducer'
import officesReducer from './officesReducer'
import statisticsReducer from './statisticsReducer'
import appReducer from './appReducer'
import notificationReducer from './notificationReducer'

const rootReducer = combineReducers({
  files: filesReducer,
  users: usersReducer,
  account: accountReducer,
  booking: bookingReducer,
  offices: officesReducer,
  statistics: statisticsReducer,
  app: appReducer,
  notification: notificationReducer,
})

export default rootReducer
