import { createSelector } from 'reselect'
import getFullName from '../utils/getFullName'

export const getUserEmail = state => state.account.user.email
export const getUserDescription = state => state.account.user.description
export const getUserPhoneNumber = state => state.account.user.phoneNumber
export const getUserPhoto = state => state.account.user.photo
export const getUserName = createSelector(
  state => state.account.user,
  user => getFullName(user),
)
