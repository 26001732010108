import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import FloorsList from './ControlPanel'
import LargeSpin from '../UI/Spin/LargeSpin'
import ConfirmationModal from '../UI/modals/ConfirmationModal'
import MainCard from '../MainCard'
import ResponsibleMapContainer from './ResponsibleMapContainer'
import NewRoomsList from './lists/NewRoomsList'
import RoomFormModal from '../Office/OfficeEdit/DetailInfo/Modal/RoomFormModal'
import TableFormModal from '../Office/OfficeEdit/DetailInfo/Modal/TableFormModal/TableFormModal'
import EditRoomModalContext from './editRoomModalContext'

import {
  CLEAR_OPEN_ROOM,
  DELETE_ALL_TABLE_MARKERS,
  DELETE_ROOM,
  INITIAL_STATE_WITHOUT_REDUX,
  reducer,
  SET_MAP_STATE,
  SET_MARKER_ON_CLICK_ATTR,
  SET_MARKERS,
  SET_STATE_WITHOUT_REDUX,
} from './helpers/useReducerForMapComponent'

import { officesAC } from '../../actions/actionCreator/offices'

import './styles.scss'

const DEFAULT_CONFIRM_MODAL = { isVisible: false, type: null, title: '', callback: () => { }, args: [] }

const OfficeMap = React.memo(props => {
  const {
    deleteRoomMarker,
    deleteTableMarker,
  } = props

  const params = useParams()
  const { location } = useHistory()

  const [prevActiveFloor, setPrevActiveFloor] = useState(null)
  const [confirmModal, setConfirmModal] = useState(DEFAULT_CONFIRM_MODAL)
  const [tableFormState, setTableFormState] = useState({ targetRoomId: '', visible: false, isNew: false, table: {} })
  const [stateWithoutRedux, dispatchWithoutRedux] = useReducer(reducer, INITIAL_STATE_WITHOUT_REDUX)
  const [currentFloor, setCurrentFloor] = useState({})

  const isOfficeLoading = useSelector(({ app }) => app.isOfficeLoading)
  const isFloorsLoading = useSelector(({ app }) => app.isFloorsLoading)
  const isRoomsLoading = useSelector(({ app }) => app.isRoomsLoading)
  const isZonesLoading = useSelector(({ app }) => app.isZonesLoading)
  const isTagsLoading = useSelector(({ app }) => app.isTagsLoading)
  const isRoomTypesLoading = useSelector(({ app }) => app.isRoomTypesLoading)
  const isFilesLoading = useSelector(({ files }) => files.loading)

  const officeTitle = useSelector(({ offices }) => offices.title)
  const floors = useSelector(({ offices }) => offices.floors)
  const rooms = useSelector(({ offices }) => offices.rooms)
  const zones = useSelector(({ offices }) => offices.zones)
  const tags = useSelector(({ offices }) => offices.tags)
  const roomTypes = useSelector(({ offices }) => offices.roomTypes)

  const dispatch = useDispatch()

  const officeId = params.id

  const isAllDataLoading = isOfficeLoading
    || isFloorsLoading
    || isRoomsLoading
    || isZonesLoading
    || isTagsLoading
    || isRoomTypesLoading

  const {
    activeFloor,
    activeRoom,
    mode,
    isRoomModalVisible,
    editableRoom,
    isAddRoomModalVisible,
  } = stateWithoutRedux
  // получение id этажа с url для  перехода на нужный этаж
  const urlFloorId = location.pathname.split('/map/')[1]
  useEffect(() => {
    const filteredFloor = () => floors.find(e => e.id === (activeFloor || urlFloorId))
    setCurrentFloor(filteredFloor)
  }, [activeFloor, floors, urlFloorId])

  useEffect(() => {
    dispatch(officesAC.getOffice({ officeId }))
    dispatch(officesAC.getFloors({ officeId }))
    dispatch(officesAC.getRooms({ officeId, isDownloadingWithTables: true }))
    dispatch(officesAC.getZones({ officeId }))
    dispatch(officesAC.getTags({ officeId }))
    dispatch(officesAC.getRoomTypes({ officeId }))
  }, [])
//TODO афектит на переход на конкретный рум
  // useEffect(() => {
  //   !prevActiveFloor && floors?.length && setPrevActiveFloor(floors[0].id)
  // }, [floors])

  useEffect(() => {
    // Заглушка медленной подгрузки rooms
    if (rooms) {
      const filteredRooms = rooms
        .filter(room => room.floor_id === (activeFloor || urlFloorId || floors[0]?.id)) || []

      const markers = filteredRooms?.map(({
        marker,
        id,
        floor_id,
        title,
        type,
        room_type_color,
        room_type_icon,
        zone_title,
        description,
        deleted,
        images,
        is_bookable,
      }) =>
        ({
          ...marker,
          roomId: id,
          floorId: floor_id,
          title,
          deleted,
          type,
          room_type_color,
          description,
          images,
          zoneTitle: zone_title,
          isBookable: is_bookable,
          room_type_icon: room_type_icon?.thumb || room_type_icon?.path,
        })) || []
      dispatchWithoutRedux({ type: SET_MARKERS, payload: markers })
    }
  }, [activeFloor, officeTitle, floors, rooms, zones, tags, roomTypes])

  const deleteRoom = ({ roomId, dispatchWithoutRedux }) => {
    dispatch(officesAC.deleteRoom({ roomId }))

    // TODO сделать это через колбек?
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    dispatchWithoutRedux({ type: DELETE_ALL_TABLE_MARKERS })
    dispatchWithoutRedux({ type: DELETE_ROOM, payload: { roomId } })
  }

  const deleteTable = (tableId, deleteTableMarkerFromMap) => {
    dispatch(officesAC.deleteTable({ tableId, deleteTableMarkerFromMap }))
  }

  const roomModalManipulating = () => ({
    set: roomId => {
      const room = rooms.find(room => room.id === roomId)
      dispatchWithoutRedux({
        type: SET_STATE_WITHOUT_REDUX, payload: { editableRoom: room, isRoomModalVisible: true },
      })
    },
    remove: () => {
      dispatchWithoutRedux({
        type: SET_STATE_WITHOUT_REDUX, payload: { editableRoom: null, isRoomModalVisible: false },
      })
    },
  })

  const setActiveFloor = newActiveFloorId => {
    if (newActiveFloorId === prevActiveFloor) return null
    dispatchWithoutRedux({
      type: SET_STATE_WITHOUT_REDUX, payload: { activeFloor: newActiveFloorId },
    })
    dispatchWithoutRedux({ type: SET_MAP_STATE, payload: { loading: true } })
    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    dispatchWithoutRedux({ type: CLEAR_OPEN_ROOM })
    setPrevActiveFloor(newActiveFloorId)
  }

  const handleMapClick = () => {
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { mode: 'read', activeRoom: undefined } })
  }

  const toogleModalAddNewRoom = useCallback(
    () => {
      const newVisibleValue = { isAddRoomModalVisible: !isAddRoomModalVisible }
      dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: newVisibleValue })
      dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
    },
    [isAddRoomModalVisible],
  )

  const getConfirmModalTitle = (type, title) => {
    switch (type) {
      case 'removeTable':
        return `Удалить место для бронирования: ${title}?`
      case 'removeRoom':
        return `Удалить помещение ${title}?`
      case 'removeMap':
        return 'Удалить карту?'
      case 'removeMarkers':
        return 'Удалить все маркеры?'
      default:
        return ''
    }
  }
  const onCloseModal = () => {
    dispatchWithoutRedux({ type: SET_STATE_WITHOUT_REDUX, payload: { isRoomModalVisible: false } })
  }

  const memoDispatchWithoutRedux = useCallback(
    dispatchWithoutRedux,
    [],
  )

  const memoSetConfirmModal = useCallback(
    setConfirmModal,
    [confirmModal],
  )

  const memoHandleAddTable = useCallback(
    roomId => setTableFormState({
      visible: !tableFormState.visible, targetRoomId: roomId, isNew: true, table: {},
    }),
    [],
  )

  const memoHandleEditTable = useCallback(
    (table, roomId) => setTableFormState({
      visible: !tableFormState.visible, targetRoomId: roomId, isNew: false, table,
    }),
    [tableFormState],
  )

  const closeTableFormModal = () => {
    setTableFormState({ ...tableFormState, visible: false })
  }

  if (!floors?.length) return <LargeSpin />

  return (
    <MainCard
      icon='icon__briefcase'
      loading={isAllDataLoading}
      addClass='grabbing'
      title={`Карта ${officeTitle}`}
      innerContainerStyle={{ overflow: 'hidden', flexDirection: 'row' }}
    >
      <NewRoomsList
        activeFloor={activeFloor || urlFloorId || floors[0].id}
        deleteMarker={deleteRoomMarker}
        deleteRoom={deleteRoom}
        deleteTableMarker={deleteTableMarker}
        addNewRoomHandler={toogleModalAddNewRoom}
        handleAddTable={memoHandleAddTable}
        editTable={memoHandleEditTable}
        deleteTable={deleteTable}
        setConfirmModal={memoSetConfirmModal}
        dispatchWithoutRedux={memoDispatchWithoutRedux}
      />
      <div className='container__rooms_and_map'>
        <FloorsList
          activeFloor={activeFloor || urlFloorId || floors[0].id}
          setActiveFloor={setActiveFloor}
          stateWithoutRedux={stateWithoutRedux}
          dispatchWithoutRedux={dispatchWithoutRedux}
          setConfirmModal={setConfirmModal}
          currentFloor={currentFloor || urlFloorId || floors[0]}
        />
        <div id='map-container' className='container_map'>
          {isFilesLoading
            ? <LargeSpin />
            : (
              <EditRoomModalContext.Provider value={roomModalManipulating}>
                <ResponsibleMapContainer
                  mode={mode}
                  activeRoom={activeRoom}
                  activeFloor={activeFloor || urlFloorId || floors[0].id}
                  onMapClickCallback={handleMapClick}
                  stateWithoutRedux={stateWithoutRedux}
                  setConfirmModal={setConfirmModal}
                  dispatchWithoutRedux={dispatchWithoutRedux}
                  deleteMarker={deleteRoomMarker}
                  deleteRoom={deleteRoom}
                />
              </EditRoomModalContext.Provider>
            )}
        </div>
      </div>
      <ConfirmationModal
        start={confirmModal.isVisible}
        text={getConfirmModalTitle(confirmModal.type, confirmModal.title)}
        onClose={() => {
          setConfirmModal(DEFAULT_CONFIRM_MODAL)
        }}
        onConfirm={() => {
          confirmModal.callback()
          setConfirmModal(DEFAULT_CONFIRM_MODAL)
        }}
      />
      {isRoomModalVisible && (
        <RoomFormModal
          mapMode
          room={editableRoom}
          zones={zones}
          floors={floors}
          roomTypes={roomTypes}
          start={isRoomModalVisible}
          onClose={onCloseModal}
          dispatchWithoutRedux={dispatchWithoutRedux}
        />
      )}
      {isAddRoomModalVisible && (
        <RoomFormModal
          isNew
          start={isAddRoomModalVisible}
          onClose={toogleModalAddNewRoom}
          floors={floors}
          zones={zones}
          roomTypes={roomTypes}
          activeFloor={activeFloor || floors[0].id}
          dispatchWithoutRedux={dispatchWithoutRedux}
        />
      )}
      {tableFormState.visible && (
        <TableFormModal
          isNew={tableFormState.isNew}
          officeId={officeId}
          start={tableFormState.visible}
          onClose={closeTableFormModal}
          roomId={tableFormState.targetRoomId}
          tags={tags}
          table={tableFormState.table}
        />
      )}
    </MainCard>
  )
})

const mapDispatchToProps = dispatch => ({
  deleteRoomMarker: ({ markerId, roomId, dispatchWithoutRedux }) => {
    const deleteAllTableMarkers = () => dispatchWithoutRedux({ type: DELETE_ALL_TABLE_MARKERS })

    dispatch(officesAC.deleteRoomMarker({
      markerId,
      roomId,
      deleteAllTableMarkers,
    }))

    dispatchWithoutRedux({ type: SET_MARKER_ON_CLICK_ATTR, payload: { isActive: false } })
  },
  deleteTableMarker: ({ tableMarker, deleteTableMarkerFromMap }) => {
    dispatch(officesAC.deleteTableMarker({
      tableMarker,
      deleteTableMarkerFromMap,
    }))
  },
})

export default connect(null, mapDispatchToProps)(OfficeMap)
