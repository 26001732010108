/* eslint-disable no-console */
import { all, call, delay, put, takeEvery, takeLatest, throttle } from 'redux-saga/effects'
import message from 'antd/lib/message'

import getResponse from './getResponse'
import isNoMessage from '../utils/isNoMessage'
import { auth, content } from '../utils/data/requestHeaders'
import { DELETE, GET, POST, PUT } from '../utils/methods'
import { SET_PHOTO_LOADING, SET_STOP_PHOTO_LOADING } from '../actions/files'
import {
  DELETE_ALL_MARKERS_ON_FLOOR,
  DELETE_ALL_MARKERS_ON_FLOOR_SUCCEED,
  DELETE_FLOOR,
  DELETE_FLOOR_SUCCEED,
  DELETE_MAP,
  DELETE_MAP_SUCCEED,
  DELETE_OFFICE,
  DELETE_OFFICE_SUCCEED,
  DELETE_OFFICE_TAG,
  DELETE_OFFICE_TAG_SUCCEED,
  DELETE_ROOM,
  DELETE_ROOM_MARKER,
  DELETE_ROOM_MARKER_SUCCEED,
  DELETE_ROOM_SUCCEED,
  DELETE_ROOM_TYPE,
  DELETE_ROOM_TYPE_SUCCEED,
  DELETE_ROOMS_LIST_SUCCEED,
  DELETE_SELECTED_ROOMS_AND_TABLES,
  DELETE_TABLE,
  DELETE_TABLE_MARKER,
  DELETE_TABLE_MARKER_SUCCEED,
  DELETE_TABLE_SUCCEED,
  DELETE_TABLES_LIST_SUCCEED,
  DELETE_ZONE,
  DELETE_ZONE_SUCCEED,
  GET_FILTERED_ROOMS,
  GET_FLOORS,
  GET_FLOORS_SUCCEED,
  GET_OFFICE,
  GET_OFFICE_FAILED,
  GET_OFFICE_SUCCEED,
  GET_OFFICE_TAGS,
  GET_OFFICE_TAGS_FAILED,
  GET_OFFICE_TAGS_SUCCEED,
  GET_OFFICES,
  GET_OFFICES_FAILED,
  GET_OFFICES_LICENSES,
  GET_OFFICES_LICENSES_SUCCEED,
  GET_OFFICES_SUCCEED,
  GET_OFFICES_THROTTLE,
  GET_ROOM_TYPES,
  GET_ROOM_TYPES_FAILED,
  GET_ROOM_TYPES_SUCCEED,
  GET_ROOMS,
  GET_ROOMS_SUCCEED,
  GET_TABLES,
  GET_TABLES_SUCCEED,
  GET_ZONES,
  GET_ZONES_SUCCEED,
  POST_FLOORS,
  POST_FLOORS_SUCCEED,
  POST_MAP_TO_FLOOR,
  POST_MAP_TO_FLOOR_SUCCEED,
  POST_OFFICE,
  POST_OFFICE_IMAGES,
  POST_OFFICE_IMAGES_SUCCEED,
  POST_OFFICE_SUCCEED,
  POST_OFFICE_TAGS,
  POST_OFFICE_TAGS_SUCCEED,
  POST_ROOM,
  POST_ROOM_MARKER,
  POST_ROOM_MARKER_SUCCEED,
  POST_ROOM_SUCCEED,
  POST_ROOM_TYPE_ICON,
  POST_ROOM_TYPE_ICON_SUCCEED,
  POST_ROOM_TYPES,
  POST_ROOM_TYPES_SUCCEED,
  POST_TABLE,
  POST_TABLE_MARKER,
  POST_TABLE_MARKER_SUCCEED,
  POST_TABLE_SUCCEED,
  POST_TAG_ICON,
  POST_TAG_ICON_SUCCEED,
  POST_ZONES,
  POST_ZONES_SUCCEED,
  PUT_FLOOR,
  PUT_FLOOR_SUCCEED,
  PUT_OFFICE,
  PUT_OFFICE_SUCCEED,
  PUT_OFFICE_TAG,
  PUT_OFFICE_TAG_SUCCEED,
  PUT_ROOM,
  PUT_ROOM_MARKER,
  PUT_ROOM_MARKER_SUCCEED,
  PUT_ROOM_SUCCEED,
  PUT_ROOM_TYPE,
  PUT_ROOM_TYPE_SUCCEED,
  PUT_TABLE,
  PUT_TABLE_MARKER,
  PUT_TABLE_MARKER_SUCCEED,
  PUT_TABLE_SUCCEED,
  PUT_ZONE,
  PUT_ZONE_SUCCEED,
  UPDATE_MAP_TO_FLOOR,
} from '../actions/offices'
import { appAC } from '../actions/actionCreator/appAC'
import formPath, { createPathWithFilters } from '../utils/formPath'
import { formatObjectFieldsToCamelCase } from '../utils/translateString'

/* ------------ Offices ------------ */

/* =========================================================================================== */
function* getOfficesListSaga({ payload }) {
  const {
    filter,
    searchString,
    delaySec,
  } = payload
  try {
    yield put(appAC.setDisable(true))
    yield put(appAC.setAppIsLoading(true))
    yield put(appAC.setLicensesIsLoading(true))

    const path = formPath('/admin/office', [
      { search: searchString },
      { tags: filter?.length ? filter.join('&tags=') : '' },
    ])

    const response = yield getResponse({ method: GET, path, headers: auth })

    if (isNoMessage(response)) {
      yield put({
        type: GET_OFFICES_SUCCEED,
        list: response.results,
        count: response.count,
      })
    }
  } catch (e) {
    yield put({ type: GET_OFFICES_FAILED })
    console.log(e.message)
  } finally {
    // устанавливает задержку для подгрузки
    yield put(appAC.setLicensesIsLoading(false))
    yield put(appAC.setAppIsLoading(false))

    yield delaySec && delay(delaySec)
    yield put(appAC.setDisable(false))
  }
}

function* getOfficeSaga({ payload }) {
  const { officeId } = payload

  try {
    yield put(appAC.setOfficeIsLoading(true))

    const response = yield getResponse({
      method: GET,
      path: `/admin/office/${officeId}`,
      headers: auth,
    })

    if (!isNoMessage(response)) throw Error('Office loading error')

    // Берем из ответа только те поля, которые нам нужны
    const {
      created_at,
      description,
      id,
      images,
      license,
      service_email,
      title,
      working_hours,
    } = response

    const office = {
      created_at,
      description,
      id,
      images,
      license,
      service_email,
      title,
      working_hours,
    }

    // TODO-OL исправить, когда подключать к API буду
    office.advancedSettings = {
      time_before_book: 60,
      time_after_book: 60,
    }
    const advancedSettingsCamelCase = {
      timeBeforeBook: office.advancedSettings.time_before_book,
      timeAfterBook: office.advancedSettings.time_after_book,
    }
    office.advancedSettings = advancedSettingsCamelCase

    yield put({ type: GET_OFFICE_SUCCEED, payload: { office } })
  } catch (e) {
    console.log(e.message)
    message.error('Не удается загрузить данные о бизнес-центре')
    yield put({ type: GET_OFFICE_FAILED })
  } finally {
    yield put(appAC.setOfficeIsLoading(false))
  }
}

function* postOfficeSaga({ payload }) {
  const { officeData, callback } = payload

  yield put(appAC.setOfficeIsLoading(true))

  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/office',
      headers: content,
      body: officeData,
    })

    if (!isNoMessage(response)) {
      throw new Error('Post office failed')
    }

    const newOffice = formatObjectFieldsToCamelCase(response)

    yield put({ type: POST_OFFICE_SUCCEED, payload: { newOffice } })
    yield callback && callback(newOffice.id)

    message.success('Бизнес-центр сохранен')
  } catch (e) {
    console.log(e.message)
    message.error('Не удалось создать бизнес центр')
  } finally {
    yield put(appAC.setOfficeIsLoading(false))
  }
}

function* putOfficeSaga({ payload }) {
  const { officeData, officeId } = payload

  yield put(appAC.setOfficeIsLoading(true))

  try {
    const response = yield getResponse({
      method: PUT,
      path: `/admin/office/${officeId}`,
      headers: content,
      body: officeData,
    })

    if (!isNoMessage(response)) return

    // Берем из ответа только те поля, которые нам нужны
    const {
      created_at,
      description,
      id,
      images,
      license,
      service_email,
      title,
      working_hours,
    } = response

    const updatedOffice = {
      created_at,
      description,
      id,
      images,
      license,
      service_email,
      title,
      working_hours,
    }

    message.success('Изменения сохранены')
    yield put({ type: PUT_OFFICE_SUCCEED, payload: { updatedOffice } })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setOfficeIsLoading(false))
  }
}

function* deleteOfficeSaga({ payload }) {
  const { officeId, title, callback } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/office/${officeId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_OFFICE_SUCCEED, payload: { officeId } })

      message.success(`Вы удалили БЦ "${title}"`)
      yield callback && callback()
    }
  } catch (e) {
    console.log(e.message)
    message.error(`Не удалось удалить "${title}"`)
  } finally {
    yield put(appAC.setDisable(false))
  }
}

function* postOfficeImagesSaga({ payload }) {
  const { images, callback } = payload

  try {
    const postedImages = yield all(images.map(image => getResponse({
      method: POST,
      path: '/admin/file',
      headers: auth,
      body: image,
      isFile: true,
    })))

    if (postedImages.some(response => !isNoMessage(response))) return

    yield put({ type: POST_OFFICE_IMAGES_SUCCEED, payload: { postedImages } })
    yield callback && callback(postedImages)
  } catch (e) { console.log(e.message) }
}

/* =========================================================================================== */

/* ------------ Licenses ------------ */

/* =========================================================================================== */
function* getLicensesSaga() {
  try {
    yield put(appAC.setLicensesIsLoading(true))

    const response = yield getResponse({
      method: GET,
      path: '/admin/license?free=true',
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({
        type: GET_OFFICES_LICENSES_SUCCEED, payload: { licenses: response.results },
      })
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setLicensesIsLoading(false))
  }
}

/* =========================================================================================== */

/* ------------ Floors ------------ */

/* =========================================================================================== */
function* getFloorsSaga({ payload }) {
  const { roomType, officeId } = payload

  const path = formPath('/admin/floor', [
    { room_type: roomType },
    { office: officeId },
  ])

  try {
    yield put(appAC.setFloorsIsLoading(true))

    const floors = yield getResponse({
      method: GET,
      path: path,
      headers: auth,
    })

    yield put({ type: GET_FLOORS_SUCCEED, payload: floors.results })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setFloorsIsLoading(false))
  }
}

function* postFloorsSaga({ payload }) {
  const { titles, office, callback } = payload

  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/floor',
      headers: content,
      body: {
        titles,
        office,
      },
    })

    if (!isNoMessage(response)) return

    const floors = response.results

    yield put({ type: POST_FLOORS_SUCCEED, payload: { floors } })

    const successMessage = floors.length > 1
      ? `Этажи ${floors.map(floor => floor.title).join(', ')} добавлены`
      : `Этаж ${floors[0].title} добавлен`

    message.success(successMessage)

    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putFloorSaga({ payload }) {
  const { floor, office, callback } = payload

  try {
    const newFloor = yield getResponse({
      method: PUT,
      path: `/admin/floor/${floor.id}`,
      headers: content,
      body: {
        title: floor.title,
        office,
      },
    })

    if (!isNoMessage(newFloor)) return

    yield put({ type: PUT_FLOOR_SUCCEED, payload: { newFloor } })

    message.success('Изменения сохранены')
    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* deleteFloorSaga({ payload }) {
  const { floorId, callback } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/floor/${floorId}`,
      headers: auth,
    })

    if (!isNoMessage(response)) return

    yield put({ type: DELETE_FLOOR_SUCCEED, payload: { floorId } })
    yield callback && callback()
    message.success('Этаж удален')
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setDisable(false))
  }
}

/* =========================================================================================== */

/* ------------ Zones ------------ */

/* =========================================================================================== */
function* getZonesSaga({ payload }) {
  const { officeId } = payload

  try {
    yield put(appAC.setZonesIsLoading(true))

    const response = yield getResponse({
      method: GET,
      path: `/admin/office_zone?office=${officeId}`,
      headers: auth,
    })

    if (!isNoMessage(response)) return

    yield put({ type: GET_ZONES_SUCCEED, payload: { zones: response.results } })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setZonesIsLoading(false))
  }
}

function* postZonesSaga({ payload }) {
  const {
    titles,
    officeId,
    groupWhitelist,
    callback,
  } = payload

  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/office_zone',
      headers: content,
      body: {
        titles,
        office: officeId,
        group_whitelist_visit: groupWhitelist,
      },
    })

    if (!isNoMessage(response)) return

    const zones = response.result

    yield put({ type: POST_ZONES_SUCCEED, payload: { zones } })

    const successMessage = zones.length > 1
      ? `Зоны ${zones.map(zone => zone.title).join(', ')} добавлены`
      : `Зона ${zones[0].title} добавлена`

    message.success(successMessage)

    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putZoneSaga({ payload }) {
  const {
    zoneId,
    zone,
    office,
    callback,
  } = payload

  try {
    const updatedZone = yield getResponse({
      method: PUT,
      path: `/admin/office_zone/${zoneId}`,
      headers: content,
      body: {
        title: zone.title,
        group_whitelist_visit: zone.groupWhitelist,
        office,
      },
    })

    if (!isNoMessage(updatedZone)) return

    yield put({ type: PUT_ZONE_SUCCEED, payload: { updatedZone } })
    message.success('Изменения сохранены')
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* deleteZoneSaga({ payload }) {
  const { zoneId } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/office_zone/${zoneId}`,
      headers: content,
    })

    if (!isNoMessage(response)) return

    yield put({ type: DELETE_ZONE_SUCCEED, payload: { zoneId } })
    message.success('Зона удалена')
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setDisable(false))
  }
}

/* =========================================================================================== */

/* ------------ Tags ------------ */

/* =========================================================================================== */
function* getOfficeTagsSaga({ payload }) {
  const { officeId } = payload

  try {
    yield put(appAC.setTagsIsLoading(true))

    const response = yield getResponse({
      method: GET,
      path: `/admin/table/tag?office=${officeId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: GET_OFFICE_TAGS_SUCCEED, payload: { tags: response.results } })
    }
  } catch (e) {
    console.log(e.message)
    yield put({ type: GET_OFFICE_TAGS_FAILED })
  } finally {
    yield put(appAC.setTagsIsLoading(false))
  }
}

function* postOfficeTagsSaga({ payload }) {
  const { titles, officeId, callback } = payload

  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/table/tag',
      body: {
        titles,
        office: officeId,
      },
      headers: content,
    })

    if (!isNoMessage(response)) return

    const tags = response.results

    yield put({ type: POST_OFFICE_TAGS_SUCCEED, payload: { tags } })

    const successMessage = tags.length > 1
      ? `Теги ${tags.map(tag => tag.title).join(', ')} добавлены`
      : `Тег ${tags[0].title} добавлен`

    message.success(successMessage)
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* putOfficeTagSaga({ payload }) {
  const { tagId, tagData, callback } = payload

  try {
    const { title, icon } = tagData

    const updatedTag = yield getResponse({
      method: PUT,
      path: `/admin/table/tag/${tagId}`,
      body: {
        title,
        icon: icon === null ? '' : icon,
      },
      headers: content,
    })

    if (!isNoMessage(updatedTag)) return

    yield put({ type: PUT_OFFICE_TAG_SUCCEED, payload: { updatedTag } })

    message.success('Изменения сохранены')
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* deleteOfficeTagSaga({ payload }) {
  const { tagId } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/table/tag/${tagId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_OFFICE_TAG_SUCCEED, payload: { tagId } })
      message.success('Тег удален')
    }
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setDisable(false))
  }
}

function* postTagIconSaga({ payload }) {
  const { tagId, icon } = payload

  try {
    const postedImage = yield getResponse({
      method: POST,
      path: '/admin/file',
      headers: auth,
      body: icon,
      isFile: true,
    })

    if (!isNoMessage(postedImage)) return

    const tagWithIcon = yield { tag: tagId, icon: postedImage.id }

    const updatedTag = yield getResponse({
      method: PUT,
      path: `/table_tags/${tagId}`,
      headers: content,
      body: tagWithIcon,
    })

    if (!isNoMessage(updatedTag)) return

    yield put({ type: POST_TAG_ICON_SUCCEED, payload: { updatedTag } })
  } catch (e) { console.log(e.message) }
}

/* =========================================================================================== */

/* ------------ RoomTypes ------------ */

/* =========================================================================================== */
function* getRoomTypesSaga({ payload }) {
  const { officeId, isBookable } = payload

  const path = formPath('/admin/room_type', [
    { office: officeId },
    { bookable: isBookable },
  ])

  try {
    yield put(appAC.setRoomTypesIsLoading(true))

    const response = yield getResponse({
      method: GET,
      path,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: GET_ROOM_TYPES_SUCCEED, payload: { roomTypes: response.results } })
    }
  } catch (e) {
    yield put({ type: GET_ROOM_TYPES_FAILED })
    console.log(e.message)
  } finally {
    yield put(appAC.setRoomTypesIsLoading(false))
  }
}

function* postRoomTypesSaga({ payload }) {
  const {
    titles,
    officeId,
    roomTypeSettings,
    callback,
  } = payload

  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/room_type',
      body: {
        titles,
        office: officeId,
        ...roomTypeSettings,
      },
      headers: content,
    })

    if (isNoMessage(response)) {
      yield put({ type: POST_ROOM_TYPES_SUCCEED, payload: { roomTypes: response.result } })

      message.success('Сохранено')
    }

    yield callback && callback()
  } catch (e) { console.log(e.message) }
}

function* putRoomTypeSaga({ payload }) {
  const { roomTypeId, roomTypeData, callback } = payload

  try {
    const response = yield getResponse({
      method: PUT,
      path: `/admin/room_type/${roomTypeId}`,
      body: roomTypeData,
      headers: content,
    })

    if (!isNoMessage(response)) return

    yield put({ type: PUT_ROOM_TYPE_SUCCEED, payload: { updatedRoomType: response } })
    message.success('Изменения сохранены')
    yield callback()
  } catch (e) { console.log(e.message) }
}

function* deleteRoomTypeSaga({ payload }) {
  const { roomTypeId } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/room_type/${roomTypeId}`,
      headers: auth,
    })

    if (!isNoMessage(response)) return

    yield put({ type: DELETE_ROOM_TYPE_SUCCEED, payload: { roomTypeId } })
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setDisable(false))
  }
}

function* postRoomTypeIconSaga({ payload }) {
  const { roomTypeData, icon } = payload

  try {
    const postedImage = yield getResponse({
      method: POST,
      path: '/admin/file',
      headers: auth,
      body: icon,
      isFile: true,
    })

    if (!isNoMessage(postedImage)) return

    const updatedRoomType = yield getResponse({
      method: PUT,
      path: `/admin/room_type/${roomTypeData.id}`,
      headers: content,
      body: {
        ...roomTypeData,
        icon: postedImage.id,
      },
    })

    if (!isNoMessage(updatedRoomType)) return

    yield put({ type: POST_ROOM_TYPE_ICON_SUCCEED, payload: { updatedRoomType } })
  } catch (e) { console.log(e.message) }
}

/* =========================================================================================== */

/* ------------ Rooms ------------ */
/* =========================================================================================== */

// Убрать export?
export function* getRoomsSaga({ payload }) {
  const {
    officeId,
    floorId,
    roomType,
    unified,
    isDownloadingWithTables,
  } = payload

  try {
    yield put(appAC.setRoomsIsLoading(true))

    const path = formPath('/admin/room', [
      { office: officeId },
      { floor: floorId },
      { tables: isDownloadingWithTables },
      { room_type: roomType },
      { unified: unified },
    ])

    const response = yield getResponse({
      method: GET,
      path: path,
      headers: auth,
    })

    if (!isNoMessage(response)) throw Error('Rooms loading error')

    yield put({
      type: GET_ROOMS_SUCCEED,
      payload: { rooms: response.results },
    })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setRoomsIsLoading(false))
  }
}

function* getFilteredRoomsSaga({ payload }) {
  const { filters = {}, officeId, recalculateSelectedCounts } = payload

  // Преобразование значений поля images и booked для бэка.
  // Значения может быть 0 или 1.
  const normalizedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
    if (key === 'images') {
      return { ...acc, [key]: !!value }
    }

    if (key === 'booked' && value === 0) {
      return { ...acc, free: 1 }
    }

    return { ...acc, [key]: value }
    // дефолтные квери параметры
  }, { tables: true, office: officeId })

  try {
    yield put(appAC.setRoomsIsLoading(true))

    const filtersArr = Object.entries(normalizedFilters)
    const path = createPathWithFilters('/admin/room', filtersArr)

    const response = yield getResponse({
      method: GET,
      path: `${path}`,

      headers: auth,
    })

    if (!isNoMessage(response)) throw Error('Rooms loading error')

    yield put({ type: GET_ROOMS_SUCCEED, payload: { rooms: response.results } })

    yield recalculateSelectedCounts && recalculateSelectedCounts()
  } catch (e) {
    console.log(e.message)
    message.error('Произошла ошибка')
  } finally {
    yield put(appAC.setRoomsIsLoading(false))
  }
}

function* postRoomSaga({ payload }) {
  const { room, closeRoomModal } = payload

  try {
    const newRoom = yield getResponse({
      method: POST,
      path: '/admin/room',
      headers: content,
      body: room,
    })

    if (isNoMessage(newRoom)) {
      yield put({
        type: POST_ROOM_SUCCEED,
        payload: { room: newRoom },
      })

      message.success('Помещение добавлено')
      yield closeRoomModal && closeRoomModal(newRoom)
    } else message.error('Произошла ошибка')
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* putRoomSaga({ payload }) {
  const {
    room,
    roomId,
    closeRoomModal,
    recalculateSelectedCounts,
  } = payload

  try {
    const newRoom = yield getResponse({
      method: PUT,
      path: `/admin/room/${roomId}`,
      headers: content,
      body: room,
    })

    if (isNoMessage(newRoom)) {
      yield closeRoomModal && closeRoomModal()
      yield put({
        type: PUT_ROOM_SUCCEED,
        payload: { room: newRoom },
      })
      yield recalculateSelectedCounts && recalculateSelectedCounts()
      message.success('Изменения сохранены')
    } else message.error('Произошла ошибка')
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* deleteRoomSaga({ payload }) {
  const { roomId, recalculateSelectedCounts } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/room/${roomId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_ROOM_SUCCEED, payload: { roomId } })
      yield recalculateSelectedCounts && recalculateSelectedCounts()

      message.success('Помещение удалено')
    } else message.error('Произошла ошибка')
  } catch (e) {
    console.log(e.message)
    message.error('Произошла ошибка')
  } finally {
    yield put(appAC.setDisable(false))
  }
}

function* deleteRoomsListSaga({ payload }) {
  const { roomIds } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: '/admin/room/list_delete',
      headers: content,
      body: {
        rooms: roomIds,
      },
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_ROOMS_LIST_SUCCEED, payload: { roomIds } })
      message.success('Помещения удалены')
    } else message.error('Произошла ошибка')
  } catch (e) {
    console.log(e.message)
    message.error('Произошла ошибка')
  } finally {
    yield put(appAC.setDisable(false))
  }
}

/* =========================================================================================== */

/* ------------ Tables ------------ */
/* =========================================================================================== */

// Убрать export?
export function* getTablesSaga({ payload }) {
  const { roomId, dateFrom, dateTo, tableId } = payload
  try {
    yield put(appAC.setTableLoader(true))
    const pathWithRoom = formPath('/admin/table', [
      { room: roomId },
      { date_from: dateFrom },
      { date_to: dateTo },
    ])
    const pathWithTable = `/admin/table/${tableId}`
    const tables = yield getResponse({
      method: GET,
      path: tableId ? pathWithTable : pathWithRoom,
      headers: auth,
    })
    // Если приходит конкретный стол, кладем напрямую. results нет
    yield put({
      type: GET_TABLES_SUCCEED,
      payload: { tables: tables.results ? tables.results : tables },
    })
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setTableLoader(false))
  }
}

function* postTableSaga({ payload }) {
  const { table, closeTableModal, recalculateSelectedCounts } = payload

  try {
    const newTable = yield getResponse({
      method: POST,
      path: '/admin/table',
      headers: content,
      body: table,
    })

    if (isNoMessage(newTable)) {
      yield closeTableModal && closeTableModal()
      yield put({ type: POST_TABLE_SUCCEED, payload: { newTable } })
      yield recalculateSelectedCounts && recalculateSelectedCounts()

      message.success('Место для бронирования добавлено')
    } else message.error('Произошла ошибка')
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  }
}

function* putTableSaga({ payload }) {
  const {
    table,
    oldRoomId,
    closeTableModal,
    recalculateSelectedCounts,
  } = payload

  try {
    const newTable = yield getResponse({
      method: PUT,
      path: `/admin/table/${table.id}`,
      headers: content,
      body: table,
    })

    if (isNoMessage(newTable)) {
      yield closeTableModal && closeTableModal()
      yield put({ type: PUT_TABLE_SUCCEED, payload: { newTable, oldRoomId } })
      yield recalculateSelectedCounts && recalculateSelectedCounts()

      message.success('Изменения сохранены')
    } else message.error('Произошла ошибка')
  } catch (e) {
    message.success('Произошла ошибка')
    console.log(e.message)
  }
}

function* deleteTableSaga({ payload }) {
  const { tableId, recalculateSelectedCounts, deleteTableMarkerFromMap } = payload

  try {
    yield put(appAC.setDisable(true))

    const response = yield getResponse({
      method: DELETE,
      path: `/admin/table/${tableId}`,
      headers: auth,
    })
    if (isNoMessage(response)) {
      yield put({ type: DELETE_TABLE_SUCCEED, payload: { tableId } })
      yield recalculateSelectedCounts && recalculateSelectedCounts()
      yield deleteTableMarkerFromMap && deleteTableMarkerFromMap()

      message.success('Место для бронирования удалено')
    } else message.error('Произошла ошибка')
  } catch (e) {
    message.error('Произошла ошибка')
    console.log(e.message)
  } finally {
    yield put(appAC.setDisable(false))
  }
}

function* deleteTablesListSaga({ payload }) {
  const { tableIds } = payload
  yield put(appAC.setDisable(true))
  try {
    const response = yield getResponse({
      method: DELETE,
      path: '/admin/table/list_delete',
      headers: content,
      body: {
        tables: tableIds,
      },
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_TABLES_LIST_SUCCEED, payload: { tableIds } })
      message.success('Рабочие места удалены')
    } else message.error('Произошла ошибка')
  } catch (e) {
    console.log(e.message)
    message.error('Произошла ошибка')
  } finally {
    yield put(appAC.setDisable(false))
  }
}

/* =========================================================================================== */

/* ------------ Rooms and Tables ------------ */

/* =========================================================================================== */
function* deleteSelectedRoomsAndTablesSaga({ payload }) {
  const {
    roomIds,
    tableIds,
    setIsDeletionModalVisible,
    recalculateSelectedCounts,
  } = payload

  try {
    yield put(appAC.setDisable(true))
    yield roomIds.length && call(deleteRoomsListSaga, { payload: { roomIds } })
    yield tableIds.length && call(deleteTablesListSaga, { payload: { tableIds } })
  } catch (e) {
    console.log(e.message)
  } finally {
    recalculateSelectedCounts()
    setIsDeletionModalVisible(false)
    yield put(appAC.setDisable(false))
  }
}

/* =========================================================================================== */

/* ------------ Maps & Markers ------------ */

/* =========================================================================================== */
function* postMapToFloorSaga({ payload }) {
  const { floorId, map, sizes, isUpdate, activeFloorMapId } = payload

  try {
    // используется в filesReducer
    yield put({ type: SET_PHOTO_LOADING })

    const postedMap = yield getResponse({
      method: POST,
      path: '/admin/file',
      headers: auth,
      body: map,
      isFile: true,
    })

    if (!isNoMessage(postedMap)) return

    const floorWithMap = yield {
      floor: floorId,
      image: postedMap.id,
      ...sizes,
    }
    const updatedFloor = yield getResponse({
      method: isUpdate ? PUT : POST,
      path: isUpdate ? `/admin/floor_map/${activeFloorMapId}` : '/admin/floor_map',
      headers: content,
      body: floorWithMap,
    })

    if (isNoMessage(updatedFloor)) {
      yield put({
        type: POST_MAP_TO_FLOOR_SUCCEED,
        payload: { floorId, floorMap: updatedFloor },
      })
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    // используется в filesReducer
    yield put({ type: SET_STOP_PHOTO_LOADING })
  }
}

function* deleteMapSaga({ payload }) {
  const { floorMapId, floorId, callback } = payload

  try {
    yield put(appAC.setDisable(true))
    const updatedFloor = yield getResponse({
      method: DELETE,
      path: `/admin/floor_map/${floorMapId}`,
      headers: content,
    })
    if (isNoMessage(updatedFloor)) {
      yield put({
        type: DELETE_MAP_SUCCEED,
        payload: {
          floorId,
        },
      })
      yield callback && callback()
    }
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setDisable(false))
  }
}

function* postRoomMarkerSaga({ payload }) {
  const { marker, callback } = payload

  try {
    const markerForRequest = marker.icon ? marker : {
      ...marker,
      icon: 'null',
    }
    const newMarker = yield getResponse({
      method: POST,
      path: '/admin/room/marker',
      headers: content,
      body: markerForRequest,
    })

    if (isNoMessage(newMarker)) {
      yield put({ type: POST_ROOM_MARKER_SUCCEED, payload: { newMarker } })
      yield callback && callback(marker)
    }
  } catch (e) { console.log(e.message) }
}

function* putRoomMarkerSaga({ payload }) {
  const { marker } = payload

  try {
    const markerForRequest = marker.icon ? marker : {
      ...marker,
      icon: 'null',
    }
    const newMarker = yield getResponse({
      method: PUT,
      path: `/admin/room/marker/${marker.id}`,
      headers: content,
      body: markerForRequest,
    })

    if (isNoMessage(newMarker)) {
      yield put({ type: PUT_ROOM_MARKER_SUCCEED, payload: { newMarker } })
    }
  } catch (e) { console.log(e.message) }
}

function* deleteRoomMarkerSaga({ payload }) {
  const {
    markerId,
    roomId,
    deleteAllTableMarkers,
  } = payload

  try {
    yield put(appAC.setDisable(true))

    const response = yield getResponse({
      method: DELETE,
      path: `/admin/room/marker/${markerId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_ROOM_MARKER_SUCCEED, payload: { roomId } })
      yield deleteAllTableMarkers && deleteAllTableMarkers()
    } else {
      message.error('Не удалось удалить маркер')
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setDisable(false))
  }
}

function* postTableMarkerSaga({ payload }) {
  const { tableMarker, setTableMarkerOnMap } = payload

  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/table/marker',
      headers: content,
      body: tableMarker,
    })

    if (isNoMessage(response)) {
      yield put({
        type: POST_TABLE_MARKER_SUCCEED,
        payload: { newTableMarker: response },
      })
      yield setTableMarkerOnMap && setTableMarkerOnMap(response)
    } else {
      message.error('Не удалось добавить маркер')
    }
  } catch (e) { console.log(e.message) }
}

function* putTableMarkerSaga({ payload }) {
  const { tableMarker, setTableMarkerOnMap } = payload

  try {
    const response = yield getResponse({
      method: PUT,
      path: `/admin/table/marker/${tableMarker.id}`,
      headers: content,
      body: tableMarker,
    })
    if (isNoMessage(response)) {
      const newTableMarker = response

      yield put({
        type: PUT_TABLE_MARKER_SUCCEED,
        payload: { newTableMarker },
      })
      yield setTableMarkerOnMap && setTableMarkerOnMap(newTableMarker)
    }
  } catch (e) { console.log(e.message) }
}

function* deleteTableMarkerSaga({ payload }) {
  const { tableMarker, deleteTableMarkerFromMap } = payload

  try {
    yield put(appAC.setDisable(true))

    const response = yield getResponse({
      method: DELETE,
      path: `/admin/table/marker/${tableMarker.id}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({
        type: DELETE_TABLE_MARKER_SUCCEED,
        payload: { tableId: tableMarker.table, roomId: tableMarker.room },
      })
      deleteTableMarkerFromMap && deleteTableMarkerFromMap(tableMarker)
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setDisable(false))
  }
}

function* deleteAllMarkersOnFloor({ payload }) {
  const { floorId, callback } = payload

  try {
    yield put(appAC.setDisable(true))

    const response = yield getResponse({
      method: DELETE,
      path: '/admin/floor/clear',
      headers: content,
      body: { floor: floorId },
    })

    if (isNoMessage(response)) {
      yield put({
        type: DELETE_ALL_MARKERS_ON_FLOOR_SUCCEED,
        payload: { floorId },
      })
      yield callback && callback()
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setDisable(false))
  }
}

/* =========================================================================================== */

export default function* pollSagas() {
  /* ------------ Offices ------------ */
  yield takeLatest(GET_OFFICES, getOfficesListSaga)
  yield throttle(800, GET_OFFICES_THROTTLE, getOfficesListSaga)
  yield takeLatest(GET_OFFICE, getOfficeSaga)
  yield takeLatest(POST_OFFICE, postOfficeSaga)
  yield takeLatest(PUT_OFFICE, putOfficeSaga)
  yield takeLatest(DELETE_OFFICE, deleteOfficeSaga)
  yield takeLatest(POST_OFFICE_IMAGES, postOfficeImagesSaga)

  /* ------------ Licenses ------------ */
  yield takeEvery(GET_OFFICES_LICENSES, getLicensesSaga)

  /* ------------ Floors ------------ */
  yield takeLatest(GET_FLOORS, getFloorsSaga)
  yield takeLatest(POST_FLOORS, postFloorsSaga)
  yield takeLatest(PUT_FLOOR, putFloorSaga)
  yield takeLatest(DELETE_FLOOR, deleteFloorSaga)

  /* ------------ Zones ------------ */
  yield takeEvery(GET_ZONES, getZonesSaga)
  yield takeEvery(POST_ZONES, postZonesSaga)
  yield takeEvery(PUT_ZONE, putZoneSaga)
  yield takeEvery(DELETE_ZONE, deleteZoneSaga)

  /* ------------ Tags ------------ */
  yield takeEvery(GET_OFFICE_TAGS, getOfficeTagsSaga)
  yield takeEvery(POST_OFFICE_TAGS, postOfficeTagsSaga)
  yield takeEvery(PUT_OFFICE_TAG, putOfficeTagSaga)
  yield takeEvery(DELETE_OFFICE_TAG, deleteOfficeTagSaga)
  yield takeEvery(POST_TAG_ICON, postTagIconSaga)

  /* ------------ RoomTypes ------------ */
  yield takeEvery(GET_ROOM_TYPES, getRoomTypesSaga)
  yield takeEvery(POST_ROOM_TYPES, postRoomTypesSaga)
  yield takeEvery(PUT_ROOM_TYPE, putRoomTypeSaga)
  yield takeEvery(DELETE_ROOM_TYPE, deleteRoomTypeSaga)
  yield takeLatest(POST_ROOM_TYPE_ICON, postRoomTypeIconSaga)

  /* ------------ Rooms ------------ */
  yield takeLatest(GET_ROOMS, getRoomsSaga)
  yield takeLatest(GET_FILTERED_ROOMS, getFilteredRoomsSaga)
  yield takeLatest(POST_ROOM, postRoomSaga)
  yield takeLatest(PUT_ROOM, putRoomSaga)
  yield takeEvery(DELETE_ROOM, deleteRoomSaga)

  /* ------------ Tables ------------ */
  yield takeLatest(GET_TABLES, getTablesSaga)
  yield takeLatest(POST_TABLE, postTableSaga)
  yield takeLatest(PUT_TABLE, putTableSaga)
  yield takeLatest(DELETE_TABLE, deleteTableSaga)

  /* ------------ Rooms and Tables ------------ */
  yield takeEvery(DELETE_SELECTED_ROOMS_AND_TABLES, deleteSelectedRoomsAndTablesSaga)

  /* ------------ Maps & Markers ------------ */
  yield takeEvery(POST_MAP_TO_FLOOR, postMapToFloorSaga)
  yield takeEvery(UPDATE_MAP_TO_FLOOR, postMapToFloorSaga)
  yield takeEvery(DELETE_MAP, deleteMapSaga)

  yield takeLatest(POST_ROOM_MARKER, postRoomMarkerSaga)
  yield takeLatest(PUT_ROOM_MARKER, putRoomMarkerSaga)
  yield takeLatest(DELETE_ROOM_MARKER, deleteRoomMarkerSaga)

  yield takeLatest(POST_TABLE_MARKER, postTableMarkerSaga)
  yield takeLatest(PUT_TABLE_MARKER, putTableMarkerSaga)
  yield takeLatest(DELETE_TABLE_MARKER, deleteTableMarkerSaga)

  yield takeEvery(DELETE_ALL_MARKERS_ON_FLOOR, deleteAllMarkersOnFloor)
}
