/* eslint-disable no-console */
import { put } from 'redux-saga/effects'
import message from 'antd/lib/message'
import getResponse from '../../getResponse'
import { GET } from '../../../utils/methods'
import formPath from '../../../utils/formPath'
import { auth } from '../../../utils/data/requestHeaders'
import {
  STATISTICS_SET_LOADING,
  STATISTICS_GET_SUCCEED,
  STATISTICS_GET_FAILED,
} from '../../../actions/statistics'
import prepareFacts from './prepareFacts'

export default function* getStatistics(action) {
  const { officeId, dateFrom, dateTo } = action.payload

  yield put({ type: STATISTICS_SET_LOADING, payload: true })

  try {
    const path = formPath('/admin/booking/dashboard', [
      { office_id: officeId },
      { date_from: dateFrom },
      { date_to: dateTo },
    ])

    const response = yield getResponse({ method: GET, path: path, headers: auth })
    const facts = prepareFacts(response)

    yield put({ type: STATISTICS_GET_SUCCEED, payload: facts })
  } catch (error) {
    yield put({ type: STATISTICS_GET_FAILED })
    console.log(error.message)
    message.error('Не удается загрузить данные')
  } finally {
    yield put({ type: STATISTICS_SET_LOADING, payload: false })
  }
}
