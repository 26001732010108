import React, { useCallback, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Button from '../../UI/Button'
import UserBookingHistory from './history/UserBookingHistory'
import FadeTransition from '../../UI/animations/transition/FadeTransition'
import MainCard from '../../MainCard'
import NewBookingForm from './form/NewBookingForm'
import UnderlinedTitle from '../../UI/UnderlinedTitle'
import UserInfo from './UserInfo'
import { officesAC } from '../../../actions/actionCreator/offices'
import { bookingAC } from '../../../actions/actionCreator/bookingAC'
import { getBookingLoader } from '../../../reducers/booking-selectors'

const UserReservationScreen = React.memo(({
  user,
}) => {
  const dispatch = useDispatch()
  const params = useParams()
  const loading = useSelector(getBookingLoader)
  const [isFormShow, setIsFormShow] = useState(false)

  useEffect(() => {
    dispatch(bookingAC.getUserBookingHistory({ userId: params.id, page: 1 }))
    return undefined
  }, [dispatch, params.id])

  const onCancel = useCallback(() => setIsFormShow(false), [])

  const FormBlock = () => {
    const basicStyle = 'flex_container__column full_height flex_container__flex_1'
    const formBlockStyle = `${basicStyle} ${(user.description || user.email) ? 'margin_top' : ''}`
    return (
      <div className={formBlockStyle} style={{ flex: 1 }}>
        <FadeTransition
          loading={isFormShow}
          style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
        >
          <div className='full_height flex_container__flex_1'>
            <UnderlinedTitle title='+ НОВАЯ БРОНЬ' />
            <NewBookingForm
              meetingRoom
              userId={params.id}
              isFormShown={isFormShow}
              onCancel={onCancel}
            />
          </div>
        </FadeTransition>
      </div>
    )
  }

  const addNewBooking = useCallback(() => {
    setIsFormShow(true)
    dispatch(officesAC.getOffices({ delaySec: 2000 }))
  }, [])

  return (
    <MainCard
      title='ПОЛЬЗОВАТЕЛЬ'
      icon='icon__user'
      loading={loading}
      innerContainerStyle={{ overflow: 'hidden' }}
    >
      <div className='flex_container__column full_height'>
        <FadeTransition loading>
          <div className='flex_container'>
            <div
              className='card_h1 flex_container__flex_1'
              style={{ overflow: 'auto' }}
            >
              <h1 className='text__wrapper'>
                {user?.id ? user?.phone_number : 'Пользователь'}
              </h1>
              <div className='main_text'>{user.access}</div>
            </div>
            <Button
              title='Добавить новую бронь'
              styles='bordered_btn align_self__center'
              style={{ marginRight: '17px' }}
              icon='icon__add__in_circle'
              onClick={addNewBooking}
            />
          </div>
        </FadeTransition>

        <div className='flex_container full_height' style={{ marginRight: '10px' }}>
          <UserInfo user={user} FormBlock={FormBlock} isFormShow={isFormShow} />
          <UserBookingHistory />
        </div>
      </div>
    </MainCard>
  )
})
// перевести на хуки
const mapStateToProps = ({ booking }) => {
  const { loading, user, count } = booking
  return ({ loading, user: user || {}, count: count || null })
}

export default connect(mapStateToProps, {})(UserReservationScreen)
