import React from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import Form from 'antd/lib/form'
import message from 'antd/lib/message'
import { withFormik, Form as FormikForm, ErrorMessage } from 'formik'
import Button from '../../UI/Button'
import LargeSpin from '../../UI/Spin/LargeSpin'
import RangePicker from '../../UI/input/RangePicker'
import SelectWithSearch from '../../UI/input/SelectWithSearch'
import ValidationMsg from '../../UI/ValidationMsg'
import { formatDate } from '../../../utils/dates/formatDate'
import { usersAC } from '../../../actions/actionCreator/usersAC'
import { bookingAC } from '../../../actions/actionCreator/bookingAC'

const ValidationSchema = Yup.object().shape({
  date: Yup.array()
    .min(2, 'Выберите время')
    .required('Обязательное поле'),
  name: Yup.object().shape({
    id: Yup.string().nullable().required('Обязательное поле'),
  }),
})

class InnerForm extends React.Component {
  state = { loading: false }

  hardLock = false

  shouldComponentUpdate(nextProps) {
    return (nextProps.isFormShown !== false)
  }

  componentDidUpdate(prevProps) {
    const { isFormShown } = this.props
    if (isFormShown !== prevProps.isFormShown && isFormShown === true) this.clearValues()
  }

  clearValues = () => this.props.setValues({ date: [], name: { id: null, title: '' } })

  onDateChange = (name, value) => {
    const newVal = value.map(time => time.set({ minute: 0, second: 0, millisecond: 0 }))
    const isEndTimeBeforeStart = newVal[0] && newVal[1] && newVal[0].diff(newVal[1], 'hours') >= 0
    if (isEndTimeBeforeStart) newVal[1] = newVal[1].add(1, 'hours')

    this.props.setFieldValue(name, newVal)
  }

  postBooking = () => {
    const { table, values } = this.props
    const booking = {
      table: table,
      user_id: values.name.id,
      date_from: formatDate(values.date[0]),
      date_to: formatDate(values.date[1]),
    }
    this.props.postBooking(booking, this.props.onCancel)
    this.hardLock = false
  }

  onSaveClick = async () => {
    const { values, setTouched, onCancel } = this.props
    this.hardLock = true
    await setTouched({ date: true, name: true })
    if (!ValidationSchema.isValidSync(values)) {
      this.hardLock = false
      return null
    }
    if (values.date === []) {
      message.info('Выберите временной интервал')
      this.hardLock = false
      return null
    }

    if (values.name.id) {
      this.postBooking()
      onCancel()
      return null
    }
    message.info('Выберите пользователя')
    this.hardLock = false
  }

  render() {
    const {
      values,
      setFieldValue,
      setFieldTouched,
      users,
      getUsers,
      onCancel,
      errors,
      touched,
    } = this.props
    return (
      <>
        {this.state.loading
          ? <LargeSpin />
          : (
            <FormikForm className='date_pick'>
              <Form.Item label='Время*'>
                <RangePicker
                  name='date'
                  value={values.date}
                  onChange={this.onDateChange}
                  setFieldTouched={setFieldTouched}
                  style={{ width: '100%' }}
                  error={touched.date && errors.date}
                >
                  <ErrorMessage component={ValidationMsg} name='date' />
                </RangePicker>
              </Form.Item>
              <SelectWithSearch
                label='Kем*'
                name='name'
                targetField='title'
                idField='id'
                onChange={setFieldValue}
                onSearch={searchString => getUsers({
                  page: 1,
                  searchString: (searchString.length > 2 ? searchString : ''),
                })}
                placeholder='Начните вводить имя пользователя'
                setFieldTouched={setFieldTouched}
                source={users}
                style={{ width: '100%' }}
                // Test version
                toShownString={({ phone_number }) => phone_number}
                // toShownString={getFullName}
                value={values.name}
                error={touched.name && errors.name}
              >
                {touched.name && errors.name && <ValidationMsg>{errors.name.id}</ValidationMsg>}
              </SelectWithSearch>
              <div className='button_container__content_right'>
                <Button
                  styles='simple-btn'
                  title='Отмена'
                  onClick={onCancel}
                />
                <Button
                  delay={700}
                  styles='bordered_btn__save_lecture'
                  title='Сохранить бронирование'
                  onClick={this.onSaveClick}
                  hardLock={this.hardLock}
                />
              </div>
            </FormikForm>
          )}
      </>
    )
  }
}

const NewBookingForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({ date: [], name: { id: null, title: '' } }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ users, app }) => {
  const { usersList, count } = users
  return ({
    count,
    users: usersList || [],
    loading: app.isUsersLoading,
  })
}

const mapDispatchToProps = dispatch => ({
  getUsers: ({ page, searchString }) =>
    dispatch(usersAC.getUsers({ page, searchString})),
  postBooking: (bookings, callback) => dispatch(bookingAC.postBooking({
    bookings, onCancel: callback,
  })),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewBookingForm)
