import React from 'react'
import Modal from '../../../UI/modals/Modal'
import Button from '../../../UI/Button'
import OneLineInfoBlock from '../../../UI/dataBlock/OneLineInfoBlock'
import { dateToStringNoHour } from '../../../../utils/dates/formatDate'
import EmptyPlaceholder from '../../../UI/EmptyPlaceholder'

const LicenseInfoModal = ({
  officeName,
  license,
  start,
  onClose,
  licenseTextColor,
  supportTextColor,
  tablesTextColor,
}) => {
  const getTablesLimit = val => license.tables_infinite ? '–' : val || '0'

  return start && (
    <Modal
      start={start}
      onClose={onClose}
      style={{ minWidth: '500px' }}
      extraClassName='modal_confirmation'
    >
      <span className='large_btn__title__center border_bottom'>ИНФОРМАЦИЯ О ЛИЦЕНЗИИ</span>
      <OneLineInfoBlock label='Название' text={officeName} />
      {license
        ? (
          <>
            <div className='container__one_line_info__group'>
              <OneLineInfoBlock
                label='Лимит рабочих мест'
                text={getTablesLimit(license.tables_available)}
              />
              <OneLineInfoBlock
                label='Осталось'
                textColor={tablesTextColor}
                text={getTablesLimit(license.tables_remain)}
              />
            </div>
            <OneLineInfoBlock
              label='Дата окончания лицензии'
              textColor={licenseTextColor}
              text={license.forever ? 'Не установлено' : dateToStringNoHour(license.expires_at)}
            />
            {license.support_available && (
              <OneLineInfoBlock
                label='Дата окончания технической поддержки'
                text={dateToStringNoHour(license.support_expires_at)}
                textColor={supportTextColor}
              />
            )}
          </>
        )
        : <EmptyPlaceholder text='Нет данных о лицензии' style={{ marginTop: '20px' }} />}
      <div className='button_container__content_right margin_top'>
        <Button styles='rounded-btn_blue' title='Понятно' onClick={onClose} />
      </div>
    </Modal>
  )
}

export default LicenseInfoModal
