import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import { withFormik, Form as FormikForm, ErrorMessage } from 'formik'
import st from './NewBookingForm.module.scss'

import BookingDetailsBlock from './BookingDetailsBlock'
import BookingTypeBlock from './BookingTypeBlock'
import Button from '../../../UI/Button'
import FadeTransition from '../../../UI/animations/transition/FadeTransition'
import LargeSpin from '../../../UI/Spin/LargeSpin'
import RangePicker from '../../../UI/input/RangePicker'
import SimpleSelect from '../../../UI/input/SimpleSelect'
import ValidationMsg from '../../../UI/ValidationMsg'
import { formatDate, formatDateAdmin } from '../../../../utils/dates/formatDate'
import { getFloors, getOfficesList, getRooms, getRoomTypes, getTables } from '../../../../reducers/offices-selectors'
import { officesAC } from '../../../../actions/actionCreator/offices'
import { bookingAC } from '../../../../actions/actionCreator/bookingAC'
import { getUsersLoading } from '../../../../reducers/app-selectors'

const ValidationSchema = Yup.object()
  .shape({
    date: Yup.array()
      .min(2, 'Обязательное поле')
      .required('Обязательное поле'),
    office: Yup.string()
      .required('Обязательное поле'),
    type: Yup.string()
      .required('Обязательное поле'),
    floor: Yup.string()
      .when('isDetailBooking', {
        is: true,
        then: Yup.string()
          .required('Выберите'),
        otherwise: Yup.string()
          .nullable(),
      }),
    room: Yup.string()
      .when('isDetailBooking', {
        is: true,
        then: Yup.string()
          .required('Обязательное поле'),
        otherwise: Yup.string()
          .nullable(),
      }),
    table: Yup.string()
      .when(['isDetailBooking', 'isRoomUnified'], {
        is: true,
        then: Yup.string()
          .required('Обязательное поле'),
        otherwise: Yup.string()
          .nullable(),
      }),
  })

const InnerForm = React.memo(props => {
  const dispatch = useDispatch()
  const loading = useSelector(getUsersLoading)
  const offices = useSelector(getOfficesList)
  const floors = useSelector(getFloors)
  const roomTypes = useSelector(getRoomTypes)
  const rooms = useSelector(getRooms)
  const tables = useSelector(getTables)

  const {
    setValues,
    values,
    setFieldValue,
    onCancel,
    setTouched,
    userId,
    errors,
    touched,
    setFieldTouched,
    isFormShown,
  } = props
  const clearValues = () => setValues({
    date: [],
    office: undefined,
  })
  useEffect(() => {
    if (isFormShown) clearValues()
  }, [])
  const resetReduxFields = () => {
    dispatch(officesAC.clearFloors())
    dispatch(officesAC.clearRooms())
    dispatch(officesAC.clearTables())
  }
  const onDateChange = useCallback((name, value) => {
    const newVal = value.map(time => time.set({
      minute: 0,
      second: 0,
      millisecond: 0,
    }))
    const isEndTimeBeforeStart = newVal[0].diff(newVal[1], 'hours') >= 0 && newVal[0] && newVal[1]

    if (isEndTimeBeforeStart) newVal[1] = newVal[1].add(1, 'hours')
    setFieldValue(name, newVal)
  }, [])
  const resetDetails = () => {
    setValues({
      ...values,
      room: undefined,
      floor: undefined,
      table: undefined,
    })
  }
  const resetRoom = () => {
    setValues({
      ...values,
      room: undefined,
      table: undefined,
    })
  }
  const resetTable = () => setValues({
    ...values,
    table: undefined,
  })

  const onOfficeSelect = useCallback((name, value) => {
    resetDetails()
    resetReduxFields()
    dispatch(officesAC.getRoomTypes({ officeId: value, isBookable: true }))
    setFieldValue(name, value)
    setFieldValue('type', undefined)
  }, [values.date, values.office])

  const onFloorSelect = useCallback((name, value) => {
    if (values.room) {
      resetRoom()
      dispatch(officesAC.clearRooms())
    }
    dispatch(officesAC.getRooms({
      floorId: value,
      roomType: values.type,
    }))
    setFieldValue(name, value)
  }, [values.type, values.room])

  const onRoomSelect = useCallback((name, value) => {
    if (values.table) {
      resetTable()
      dispatch(officesAC.clearTables())
    }
    dispatch(officesAC.getTables({
      roomId: value,
      dateFrom: formatDateAdmin(values.date[0]),
      dateTo: formatDateAdmin(values.date[1]),
    }))
    setFieldValue(name, value)
  }, [values.floor, values.table])

  const onTypeChange = useCallback((name, value) => {
    resetDetails()
    dispatch(officesAC.getFloors({ roomType: value }))
    setFieldValue(name, value)
  }, [values.office])

  const getBookingDate = () => ({
    date_from: formatDate(values.date[0]),
    date_to: formatDate(values.date[1]),
  })

  const postDetailBooking = baseBooking => {
    const {
      table,
    } = values
    const bookings = {
      ...baseBooking,
      table,
    }
    dispatch(bookingAC.postBooking({ bookings, onCancel }))
  }

  const postRandomBooking = baseBooking => {
    const {
      type,
    } = values
    const bookings = {
      ...baseBooking,
      type,
    }
    dispatch(bookingAC.postFastBooking({ bookings, addToHistory: true, onCancel }))
  }

  const onSaveClick = useCallback(async () => {
    await setTouched({
      date: true,
      office: true,
      floor: true,
      room: true,
      table: true,
      type: true,
      roomType: true,
    })
    if (!ValidationSchema.isValidSync(values)) return null
    const baseBooking = { user_id: userId, ...getBookingDate() }
    values.isDetailBooking
      ? postDetailBooking(baseBooking)
      : postRandomBooking(baseBooking)
  }, [values])
  const getErrorCondition = useCallback(field => errors[field] && touched[field], [])

  const onCheckBoxClick = useCallback((name, value) => {
    setFieldValue(values.isDetailBooking, true)
    setFieldValue(name, value)
    setTouched({
      room: false,
      floor: false,
      table: false,
    })
  }, [])

  if (loading) {
    return <LargeSpin />
  }
  return (
    <FormikForm style={{
      flex: '1',
      padding: '0 2px',
      overflowX: 'hidden',
    }}
    >
      <div className={st.bookingForm}>
        <div className={st.bookingForm__rangePicker}>
          <Form.Item label='Время'>
            <RangePicker
              name='date'
              value={values.date}
              onChange={onDateChange}
              setFieldTouched={setFieldTouched}
              style={{
                width: '100%',
                marginRight: '20px',
              }}
              error={getErrorCondition('date')}
            >
              <ErrorMessage component={ValidationMsg} name='date' />
            </RangePicker>
          </Form.Item>
        </div>
        <div className={st.bookingForm__simpleSelectItems}>
          <SimpleSelect
            name='office'
            label='Бизнеc-центр'
            placeholder='Выберите бизнес-центр'
            onChange={onOfficeSelect}
            value={values.office}
            values={values}
            setFieldTouched={setFieldTouched}
            error={getErrorCondition('office')}
            disabled={!values.date?.length}
          >
            {offices.length && (offices.map(
              ({
                id,
                title,
              }) => <Select.Option value={id} key={id}>{title}</Select.Option>,
            ))}
          </SimpleSelect>
          {touched.office && errors.office
          && <ValidationMsg>{errors.office}</ValidationMsg>}
        </div>
      </div>
      <FadeTransition loading={!!(values.office)}>
        <div className='flex_container__column'>
          <BookingTypeBlock
            values={values}
            typesList={roomTypes}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            onCheckBoxClick={onCheckBoxClick}
            onTypeChange={onTypeChange}
            getErrorCondition={getErrorCondition}
            // If you don't choose room_type u can't use checkbox
            disabled={!floors[0]}
          />
          <BookingDetailsBlock
            tables={tables}
            rooms={rooms}
            types={roomTypes}
            floors={floors}
            values={values}
            onFloorSelect={onFloorSelect}
            onRoomSelect={onRoomSelect}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            getErrorCondition={getErrorCondition}
          />
        </div>
      </FadeTransition>
      <div className='button_container__user_booking__content_right'>
        <Button
          styles='simple-btn'
          title='Отмена'
          onClick={onCancel}
        />
        <Button
          styles='bordered_btn__save_lecture'
          title='Забронировать'
          onClick={onSaveClick}
        />
      </div>
    </FormikForm>

  )
})
const NewBookingForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: () => ({
    date: [],
    // undefined для плейсхолдеров в SimpleSelect
    office: undefined,
    type: undefined,
    floor: undefined,
    room: undefined,
    table: undefined,
    isDetailBooking: false,
  }),
  validationSchema: ValidationSchema,
})(InnerForm)
export default NewBookingForm
