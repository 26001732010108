import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ErrorMessage, Form as FormikForm, withFormik } from 'formik'
import * as Yup from 'yup'

import Modal from '../../UI/modals/Modal'
import SimpleInput from '../../UI/input/SimpleInput'
import ValidationMsg, { ErrorMsg } from '../../UI/ValidationMsg'
import Button from '../../UI/Button'

import SimpleTextArea from '../../UI/input/SimpleTextArea'
import { SEND_EMAIL } from '../../../actions/users'

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(4, 'Минимум 4 символа')
    .max(128, 'Максимум 128 символов')
    .required('Обязательное поле'),
  body: Yup.string()
    .max(500, 'Максимум 500 символов')
    .required('Обязательное поле'),
})

const InnerForm = React.memo(({
  values,
  start,
  user,
  onClose,
  setFieldValue,
  setFieldTouched,
  setTouched,
  resetForm,
  sendEmail,
  userEmail,
  touched,
  errors,
}) => {
  const handleClose = () => {
    resetForm()
    onClose()
  }

  const onSend = () => {
    setTouched({ title: true, body: true })
    if (ValidationSchema.isValidSync(values)) {
      const payload = { ...values, account: user.id }
      sendEmail(payload, handleClose)
    }
  }

  useEffect(() => {
    start && resetForm()
  }, [start])

  return (
    <Modal start={start} onClose={handleClose} extraClassName='modal_send_email'>
      <span className='large_btn__title__center border_bottom'>ОТПРАВИТЬ СООБЩЕНИЕ</span>
      <div className='flex_container margin_top'>
        <div className='flex_container__column'>
          <span className='label margin_right'>кому:</span>
          <span className='label margin_right'>email:</span>
        </div>
        <div className='flex_container__column'>
          <span className='main_text__white'>{user?.name}</span>
          <span className='secondary_text'>{userEmail}</span>
        </div>
      </div>

      <FormikForm>
        <div className='flex_container__column margin_top full_width'>
          <SimpleInput
            name='title'
            label='Заголовок письма'
            placeholder='Заголовок'
            value={values.title}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={errors.title && touched.title}
            autofocus
          >
            <ErrorMsg field='title' />
          </SimpleInput>

          <SimpleTextArea
            name='body'
            label='Текст'
            style={{ height: '150px' }}
            placeholder='Введите тело письма'
            value={values.body}
            onChange={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={errors.body && touched.body}
          >
            <ErrorMsg field='body' />
          </SimpleTextArea>
          <div className='button_container__content_right'>
            <Button styles='simple-btn' title='Отмена' onClick={handleClose} />
            <Button styles='btn_solid' title='Отправить' onClick={onSend} delay={300} />
          </div>
        </div>
      </FormikForm>
    </Modal>
  )
})

const SendEmailModal = withFormik({
  enableReinitialize: true,
  isInitialValid: false,
  mapPropsToValues: () => ({ title: '', body: '' }),
  validationSchema: ValidationSchema,
})(InnerForm)

const mapStateToProps = ({ users }, { user }) => ({
  userEmail: users?.list?.find(u => u.id === user.id)?.email,
})

const mapDispatchToProps = dispatch => ({
  sendEmail: (payload, callback) => dispatch({ type: SEND_EMAIL, payload, callback }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SendEmailModal)
