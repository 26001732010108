/* eslint-disable import/no-cycle */
/* eslint-disable max-len */
import React, { useState } from 'react'
import Menu from 'antd/lib/menu'
import Tooltip from 'antd/lib/tooltip'
import Popover from 'antd/lib/popover'
import { Scrollbar } from 'react-scrollbars-custom'
import { useDrag, DragPreviewImage } from 'react-dnd'

import MarkerIcon from './MarkerIcon'
import { getPopOverContent } from './Room'
import { dndPreview } from '../../UI/icons/DnDPreview'
import { DELETE_TABLE_MARKER } from './useReducerForMapComponent'

const Table = ({ table, room, defaultProps, ...others }) => {
  const [isVisibleMenu, setIsVisibleMenu] = useState(false)
  const { deleteTableMarker: deleteTableMarkerAPI, dispatchWithoutRedux } = defaultProps
  const hasTableMarkerOnMap = table.marker

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: { type: 'table', roomId: room.id, room, table },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const deleteTableMarker = () => {
    const deleteTableMarkerFromMap = tableMarker => {
      dispatchWithoutRedux({ type: DELETE_TABLE_MARKER, payload: { tableMarker } })
    }

    deleteTableMarkerAPI({ tableMarker: table.marker, deleteTableMarkerFromMap })
  }

  return (
    <Menu.Item key={`tableId=${table.id}`} {...others}>
      <DragPreviewImage connect={preview} src={dndPreview} />
      <div
        className='room-list-table-wrap'
        ref={hasTableMarkerOnMap ? null : dragRef}
        style={{ cursor: isDragging ? 'none' : 'pointer' }}
      >
        <div className='table-item__info-wrap'>
          <Tooltip title={table.title} mouseEnterDelay={0.5}>
            <span className='table-item__title'>{table.title}</span>
          </Tooltip>
          <Scrollbar>
            <div className='table-item__tags-wrap'>
              {table.tags?.map(tag => (
                <span key={tag.id} className='table-item__tag'>{tag.title?.toUpperCase()}</span>
              ))}
            </div>
          </Scrollbar>
        </div>
        <MarkerIcon
          hasTableMarkerOnMap={hasTableMarkerOnMap}
          deleteTableMarker={deleteTableMarker}
          dispatchWithoutRedux={dispatchWithoutRedux}
        />
        <Popover
          content={getPopOverContent({ room, hasTableMarkerOnMap, setIsVisibleMenu, defaultProps, table, deleteTableMarker })}
          trigger='click'
          visible={isVisibleMenu}
          onVisibleChange={val => setIsVisibleMenu(val)}
        >
          <div className='icon__more' onClick={() => setIsVisibleMenu(prev => !prev)} />
        </Popover>
      </div>
    </Menu.Item>
  )
}

export default Table
