import React, { useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import Logo from '../helpers/Logo'
import UserInfo from './UserInfo'
import { NAVIGATION } from '../../utils/data/navigation'

const Header = React.memo(() => {
  const history = useHistory()
  const renderNavigation = useMemo(() => (
    NAVIGATION.map(({ address, title }) => (
      <NavLink
        to={address}
        key={title}
        className='nav_link__inactive'
        activeClassName='nav_link__active'
      >
        {title}
      </NavLink>
    ))
  ), [])
  const onClickHandler = () => {
    history.push('/')
  }
  return (
    <div className='header'>
      <div className='logo_placeholder' onClick={onClickHandler}>
        <Logo />
      </div>
      <nav className='nav_block'>{renderNavigation}</nav>
      <UserInfo />
    </div>
  )
})

export default Header
