/* eslint-disable no-console */
import message from 'antd/lib/message'
import notification from 'antd/lib/notification'
import { all, call, put, select, takeEvery, takeLatest, throttle } from 'redux-saga/effects'

import getResponse from './getResponse'
import nextPaginationStart from '../utils/nextPaginationStart'
import isNoMessage from '../utils/isNoMessage'
import formPath from '../utils/formPath'
import DEFAULT_AVATAR from '../utils/data/defaultAvatarUrl'
import { auth, content } from '../utils/data/requestHeaders'
import { DELETE, GET, POST, PUT } from '../utils/methods'
import { ITEMS_ON_PAGE } from '../utils/data/constants'
import {
  CLEAN_UP_FILTERED_USERS_SUCCEED,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCEED,
  CREATE_KIOSK,
  CREATE_KIOSK_SUCCEED,
  CREATE_NEW_GROUP,
  CREATE_OPERATOR,
  CREATE_OPERATOR_SUCCEED,
  CREATE_USER,
  CREATE_USER_SUCCEED,
  DELETE_GROUP,
  DELETE_GROUP_SUCCEED,
  DELETE_KIOSK,
  DELETE_KIOSK_SUCCEED,
  DELETE_USER,
  DELETE_USER_SUCCEED,
  GET_ACCESS_GROUP,
  GET_ACCESS_GROUPS,
  GET_GROUP_ACCESS,
  GET_GROUP_ACCESS_SUCCEED,
  GET_GROUP_SUCCEED,
  GET_KIOSKS,
  GET_KIOSKS_SUCCEED,
  GET_USER_ACCESS_GROUPS,
  GET_USER_ACCESS_GROUPS_SUCCEED_ADMIN,
  GET_USERS,
  GET_USERS_SUCCEED,
  GET_USERS_THROTTLE,
  IMPORT_GROUPS,
  IMPORT_USERS_IN_GROUP,
  PROMOTE_OPERATOR,
  PROMOTE_OPERATOR_SUCCEED,
  PUT_KIOSK,
  PUT_KIOSK_SUCCEED,
  PUT_USER,
  PUT_USER_SUCCEED,
  RESET_PASSWORD,
  SEND_EMAIL,
  UPDATE_GROUP,
} from '../actions/users'
import notificationBasicSetups from '../utils/data/notificationConfig'
import { getAddToGroup, getDeleteFromGroup, getInGroupId } from '../reducers/users-selectors'
import { appAC } from '../actions/actionCreator/appAC'
import { groupAC } from '../actions/actionCreator/groups'
import { GET_NOTIFICATION_ITEMS } from '../actions/notifications'
import { notificationsAC } from '../actions/actionCreator/notificationsAC'

export const LOADER_TYPE_GROUP = 'GROUP'
export const LOADER_TYPE_NOT_GROUP = 'NOT_GROUP'
export const LOADER_IMPORT = 'LOADER_IMPORT'

/* ------------ USERS ------------ */

/* =========================================================================================== */

function* putUser(action) {
  try {
    const userWithPhotoId = {
      ...action.user,
      photo: action.photo ? action.photo.id : null,
    }
    const user = yield call(() => getResponse({
      method: PUT,
      path: `/admin/user/${action.userId}`,
      headers: content,
      body: userWithPhotoId,
    }))

    if (!isNoMessage(user)) return message.error('Ошибка при выполнении операции')

    message.success('Изменения сохранены')
    yield put({ type: PUT_USER_SUCCEED, user })
    yield action.callback && action.callback()
  } catch (e) { console.log(e.message) }
}

function* createOperator({ user, photo, callback }) {
  yield put(appAC.setAppUsersIsLoading(true))
  try {
    const userWithPhoto = photo ? { ...user, photo: photo.id } : user
    const account = yield call(() => getResponse({
      method: POST,
      path: '/admin/user',
      headers: content,
      body: {
        username: user.email,
        phone_number: user.phone_number,
        ...userWithPhoto,
        operator: true,
      },
    }))
    if (!isNoMessage(account)) return message.error('Ошибка при выполнении операции')
    message.success('Пользователь создан')
    account.photo = {
      id: photo ? photo.id : null,
      path: photo ? photo.path : DEFAULT_AVATAR,
    }
    yield put({ type: CREATE_OPERATOR_SUCCEED, user: { ...account } })
    yield callback && callback()
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setAppUsersIsLoading(false))
  }
}

function* createUser({ payload }) {
  const { user, callback, inGroup } = payload
  yield put(appAC.setAppUsersIsLoading(true))
  try {
    const newUser = yield call(() => getResponse({
      method: POST,
      path: '/admin/user',
      headers: content,
      body: user,
    }))

    if (isNoMessage(newUser)) {
      inGroup
        ? yield all([
          put({
            type: CREATE_USER_SUCCEED,
            user: newUser,
            inGroup,
          }),
          put(groupAC.setForAdd({ id: newUser.id }))])
        : yield put({ type: CREATE_USER_SUCCEED, user: newUser })
      yield callback && callback(newUser)
      message.success('Пользователь создан')
    } else {
      message.error('Ошибка при выполнении операции')
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setAppUsersIsLoading(false))
  }
}

function* getUsers({ payload }) {
  const {
    searchString, callback,
    group, page,
    excludeGroupId, pagination,
  } = payload
  // свитчкейс нужен для обработки работы 4 лоадеров , передаем из AC тот кейс который нужен
  switch (pagination) {
    case LOADER_TYPE_GROUP: {
      yield put(appAC.setPaginationInGroup(true))
      break
    }
    case LOADER_TYPE_NOT_GROUP: {
      yield put(appAC.setPaginationNotInGroup(true))
      break
    }
    case LOADER_IMPORT: {
      yield put(appAC.setImportLoader(true))
      break
    }
    default: {
      yield put(appAC.setAppUsersIsLoading(true))
    }
  }
  yield put(appAC.setAppIsLoading(true))
  try {
    const path = formPath('/admin/user', [
      { start: nextPaginationStart(page) },
      { limit: ITEMS_ON_PAGE },
      { search: searchString?.replace('+', '%2B') },
      { group: group },
      { group_exclude: excludeGroupId },
    ])
    const users = yield call(() => getResponse({ method: GET, path: path, headers: auth }))
    if (!isNoMessage(users)) return message.error('Ошибка при выполнении операции')
    group
      ? yield put({
        type: GET_USERS_SUCCEED,
        payload: { users, page, list: 'inGroup', count: 'countInGroup' },
      })
      : yield put({
        type: GET_USERS_SUCCEED,
        payload: { users, page, list: 'usersList', count: 'countNotInGroup' },
      })
    yield callback && callback(users.results)
  } catch (e) {
    console.log(e.message)
  } finally {
    yield all([
      put(appAC.setAppIsLoading(false)),
      put(appAC.setAppUsersIsLoading(false)),
      put(appAC.setPaginationInGroup(false)),
      put(appAC.setPaginationNotInGroup(false)),
      put(appAC.setImportLoader(false))])
  }
}

function* deleteUser({ userId, callback }) {
  try {
    yield put(appAC.setDisable(true))
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/user/${userId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_USER_SUCCEED, userId })
      yield callback && callback()
      message.success('Удалено')
    } else {
      message.error('Ошибка при выполнении операции')
    }
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setDisable(false))
  }
}

function* resetUserPassword({ userId, userEmail }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/admin/user/pass_reset',
      headers: content,
      body: userId,
    }))

    response.message === 'OK'
    && message.success(`Пользователь получит письмо с новым паролем на адрес ${userEmail}`)
  } catch (e) { console.log(e.message) }
}

function* promoteOperator({ userId, userEmail }) {
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: '/admin/user/promotion',
      headers: content,
      body: { account: userId },
    }))

    if (response.message === 'Promoted' || response.message === 'Demoted') {
      message.success(response.message === 'Promoted'
        ? `Пользователь получит письмо с паролем на адрес ${userEmail}`
        : 'Пользователь лишен доступа к панели оператора')
      yield put({ type: PROMOTE_OPERATOR_SUCCEED, userId })
    }
  } catch (e) { console.log(e.message) }
}

/* ------------ Groups ------------ */

/* =========================================================================================== */
function* getGroupsList({ callback }) {
  yield put(appAC.setAppIsLoading(true))
  try {
    const response = yield call(() => getResponse({
      method: GET,
      path: '/admin/group',
      headers: auth,
    }))
    if (isNoMessage(response)) {
      yield put(groupAC.setGroups({ groups: response.results }))
      yield callback && callback(response)
    } else {
      message.error('Ошибка с получением групп')
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setAppIsLoading(false))
  }
}

function* getGroup({ payload }) {
  const { id } = payload
  try {
    yield put(appAC.setGroupLoader(true))
    const group = yield call(() => getResponse({
      method: GET,
      path: `/admin/group/${id}`,
      headers: auth,
    }))
    if (!isNoMessage(group)) return message.error('Ошибка при выполнении операции')
    yield put({ type: GET_GROUP_SUCCEED, payload: group })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setGroupLoader(false))
  }
}

function* getGroupAccess({ id }) {
  yield put(appAC.setZonesIsLoading(true))
  try {
    const access = yield call(() => getResponse({
      method: GET,
      path: `/admin/group/access/${id}`,
      headers: auth,
    }))
    if (!isNoMessage(access)) return message.error('Ошибка при выполнении операции')
    yield put({ type: GET_GROUP_ACCESS_SUCCEED, payload: access })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setZonesIsLoading(false))
  }
}

function* getUserAccessGroups({ id }) {
  yield put(appAC.setGroupLoader(true))
  try {
    const groups = yield call(() => getResponse({
      method: GET,
      path: `/admin/group?user=${id}`,
      headers: auth,
    }))
    if (!isNoMessage(groups)) return message.error('Ошибка при выполнении операции')
    yield put({ type: GET_USER_ACCESS_GROUPS_SUCCEED_ADMIN, groups: groups.results })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setGroupLoader(false))
  }
}

function* createNewGroup({ payload }) {
  const { group, succeedCallback, failedCallback } = payload
  yield put(appAC.setAppIsLoading(true))
  try {
    const users = yield select(getInGroupId)
    const bodyPayload = { ...group, users }
    const response = yield call(() => getResponse({
      method: POST,
      path: '/admin/group',
      body: bodyPayload,
    }))
    if (!isNoMessage(response)) return message.error('Ошибка при выполнении операции')
    yield put({ type: GET_GROUP_SUCCEED, payload: group })
    yield succeedCallback(response.id)
  } catch (e) {
    failedCallback()
    console.log(e.message)
  } finally {
    yield put(appAC.setAppIsLoading(false))
  }
}

function* deleteGroup({ payload }) {
  const { id, callback } = payload
  yield put(appAC.setAppIsLoading(true))
  yield put(appAC.setDisable(true))
  try {
    const res = yield getResponse({
      method: DELETE,
      path: `/admin/group/${id}`,
      headers: auth,
    })
    if (isNoMessage(res)) {
      yield callback && callback()
      yield put({ type: DELETE_GROUP_SUCCEED, id })
      message.success('Группа доступа удалена')
    } else message.error('Ошибка удаления')
  } catch (e) {
    message.error('Проверьте интернет соединение')
    console.log(e.message)
  } finally {
    yield put(appAC.setAppIsLoading(false))
    yield put(appAC.setDisable(false))
  }
}

function* updateGroup({ id, mainInfo, succeedCallback }) {
  try {
    const forDelete = yield select(getDeleteFromGroup)
    const forAdd = yield select(getAddToGroup)
    const payload = { ...mainInfo, users_add: forAdd, users_remove: forDelete }
    const response = yield call(() => getResponse({
      method: PUT,
      path: `/admin/group/${id}`,
      headers: content,
      body: payload,
    }))
    if (response.code === 400) throw Error('Something went wrong')
    // для редиректа на группу
    if (isNoMessage(response) && message.success('Изменения сохранены')) return yield succeedCallback(response.id)
  } catch (e) {
    console.log(e.message)
    message.error('Что-то пошло не так')
  } finally {
    // зачищаем стейт
    yield put({ type: CLEAN_UP_FILTERED_USERS_SUCCEED })
  }
}

function* importUsersInGroup({ payload }) {
  const { file, groupId } = payload
  yield put(appAC.setImportLoader(true))
  try {
    const payload = { group: groupId, page: 1, pagination: LOADER_IMPORT }
    const response = yield call(() => getResponse({
      method: POST,
      path: '/admin/group/import_single',
      headers: auth,
      body: file,
      isFile: true,
    }))
    if (response.message !== 'OK') return null

    yield getUsers({ payload })
    notification.success({
      ...notificationBasicSetups,
      className: 'notification_antd___succeed',
      message: 'Пользователи добавлены',
      description: `Всего добавлено ${response.counters.accounts_added} пользователей. Создано ${response.counters.accounts_created} новых аккаунтов.`,
    })
  } catch (e) {
    message.error('Ошибка с добавлением  пользователей')
    console.log(e.message)
  } finally {
    yield put(appAC.setImportLoader(false))
  }
}

function* importGroups({ payload }) {
  const { file, path, callback } = payload
  yield put(appAC.setImportLoader(true))
  try {
    const response = yield call(() => getResponse({
      method: POST,
      path: `/admin/group/${path}`,
      headers: auth,
      body: file,
      isFile: true,
    }))
    if (response.message === 'OK') {
      yield put(groupAC.setGroups({ groups: response.result }))
      yield callback && callback(response)
    }
    yield callback()
    const { counters } = response
    const extraText = path === '/import_list'
      ? ` Добавлено ${counters.accounts_added} пользователей, из них новых аккаунтов: ${counters.accounts_created}.`
      : ''
    notification.success({
      ...notificationBasicSetups,
      className: 'notification_antd___succeed',
      message: 'Изменения сохранены',
      description: `Обработано ${counters.groups_processed} групп, из них новых: ${counters.groups_created}.${extraText}`,
    })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setImportLoader(false))
  }
}

/* ------------ Office panel KIOSK ------------ */

/* =========================================================================================== */
function* deleteKiosk({ userId, callback }) {
  try {
    yield put(appAC.setDisable(true))
    const response = yield getResponse({
      method: DELETE,
      path: `/admin/office_panel/${userId}`,
      headers: auth,
    })

    if (isNoMessage(response)) {
      yield put({ type: DELETE_KIOSK_SUCCEED, userId })
      yield callback && callback()
      message.success('Удалено')
    } else {
      message.error('Ошибка при выполнении операции')
    }
  } catch (e) { console.log(e.message) } finally {
    yield put(appAC.setDisable(false))
  }
}

function* postKiosk({ kiosk, callback }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/office_panel',
      headers: content,
      body: kiosk,
    })

    if (isNoMessage(response)) {
      yield put({ type: CREATE_KIOSK_SUCCEED, kiosk: response })
      yield callback && callback()
      message.success('Киоск создан')
    } else {
      message.error('Ошибка при выполнении операции')
    }
  } catch (e) { console.log(e.message) }
}

function* getKiosks({ page, searchString, callback }) {
  yield put(appAC.setAppUsersIsLoading(true))
  try {
    const path = formPath('/admin/office_panel', [
      { search: searchString },
    ])
    const kiosks = yield getResponse({ method: GET, path, headers: auth })
    if (isNoMessage(kiosks)) {
      yield put({ type: GET_KIOSKS_SUCCEED, filteredKiosks: kiosks.results, page })
      yield callback && callback()
    } else {
      message.error('Ошибка при выполнении операции')
    }
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setAppUsersIsLoading(false))
  }
}

function* putKiosk({ kioskId, kiosk, callback }) {
  try {
    const newKiosk = yield getResponse({
      method: PUT,
      path: `/admin/office_panel/${kioskId}`,
      headers: content,
      body: kiosk,
    })

    if (isNoMessage(newKiosk)) {
      yield put({ type: PUT_KIOSK_SUCCEED, kiosk: newKiosk })
      yield callback && callback()
      message.success('Изменения сохранены')
    } else {
      message.error('Ошибка при выполнении операции')
    }
  } catch (e) { console.log(e.message) }
}

/* ------------ PUSH ------------ */

/* =========================================================================================== */
function* getNotificationGroups() {
  try {
    const groups = yield call(() => getResponse({
      method: GET,
      path: '/admin/push/group',
      headers: auth,
    }))
    if (!isNoMessage(groups)) return message.error('Ошибка получения групп')
    yield put(notificationsAC.getGroupsForNotification(groups))
  } catch (e) {
    message.error('Ошибка получения групп')
    console.log(e.message)
  }
}

function* getNotificationUsers() {
  try {
    const users = yield call(() => getResponse({
      method: GET,
      path: '/admin/push/user',
      headers: auth,
    }))
    if (!isNoMessage(users)) return message.error('Ошибка получения пользователей')
    yield put(notificationsAC.getUsersForNotification(users))
  } catch (e) {
    message.error('Ошибка получения пользователей')
    console.log(e.message)
  }
}

function* getItemsForNotifications({ payload }) {
  const { callback } = payload
  try {
    yield put(appAC.setGroupLoader(true))
    yield all([
      yield call(getNotificationUsers),
      yield call(getNotificationGroups),
    ])
    const { groups, users } = yield select(({ notification }) => notification)
    callback && callback({ users, groups })
  } catch (e) {
    console.log(e.message)
  } finally {
    yield put(appAC.setGroupLoader(false))
  }
}

/* ------------ EMAIL ------------ */

/* =========================================================================================== */
function* sendEmail({ payload, callback }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/admin/user/service_email',
      headers: content,
      body: payload,
    })
    if ((response.message === 'OK') && callback) {
      yield callback && callback()
      message.success('Сообщение отправлено')
    }
  } catch (e) { console.log(e.message) }
}

// Логики на бэке нет, обсудить
function* confirmEmail({ user, callback }) {
  try {
    const response = yield getResponse({
      method: POST,
      path: '/account_confirm',
      headers: content,
      body: user,
    })

    if (isNoMessage(response)) {
      yield put({ type: CONFIRM_EMAIL_SUCCEED, user: response })
      yield callback && callback()
      message.success('Email подтвержден')
    }
  } catch (e) { console.log(e.message) }
}

export default function* filesSagas() {
  /* ------------ USERS ------------ */
  yield takeEvery(GET_USERS, getUsers)
  yield throttle(2500, GET_USERS_THROTTLE, getUsers)
  yield takeLatest(CREATE_USER, createUser)
  yield takeLatest(DELETE_USER, deleteUser)
  yield takeLatest(PUT_USER, putUser)
  yield takeLatest(CREATE_OPERATOR, createOperator)
  yield takeEvery(PROMOTE_OPERATOR, promoteOperator)
  yield takeEvery(RESET_PASSWORD, resetUserPassword)
  /* ------------ GROUPS ------------ */
  yield takeLatest(GET_GROUP_ACCESS, getGroupAccess)
  yield takeLatest(GET_ACCESS_GROUP, getGroup)
  yield takeLatest(GET_ACCESS_GROUPS, getGroupsList)
  yield takeLatest(CREATE_NEW_GROUP, createNewGroup)
  yield takeLatest(DELETE_GROUP, deleteGroup)
  yield takeLatest(UPDATE_GROUP, updateGroup)
  yield takeLatest(IMPORT_USERS_IN_GROUP, importUsersInGroup)
  yield takeLatest(IMPORT_GROUPS, importGroups)
  yield takeEvery(GET_USER_ACCESS_GROUPS, getUserAccessGroups)
  /* ------------ INFO-PANEL KIOSK ------------ */
  yield takeEvery(GET_KIOSKS, getKiosks)
  yield takeEvery(CREATE_KIOSK, postKiosk)
  yield takeLatest(DELETE_KIOSK, deleteKiosk)
  yield takeEvery(PUT_KIOSK, putKiosk)
  /* ------------ PUSH ------------ */
  yield takeEvery(GET_NOTIFICATION_ITEMS, getItemsForNotifications)
  /* ------------ EMAIL ------------ */
  yield takeEvery(SEND_EMAIL, sendEmail)
  yield takeEvery(CONFIRM_EMAIL, confirmEmail)
}
