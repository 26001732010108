/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
import React from 'react'
import { Layer, Text, Rect } from 'react-konva'

const ModalOnMap = React.memo(props => {
  const {
    openRoom,
    floorMap,
    mapState,
  } = props

  const { marker } = openRoom
  const { scale } = mapState

  const x = marker?.x
  const y = marker?.y

  const mapWidth = floorMap.width
  const mapHeight = floorMap.height

  const modalWidth = mapWidth * 0.1 / scale > mapWidth * 0.5 ? mapWidth * 0.5 : mapWidth * 0.1 / scale
  const modalHeight = mapWidth * 0.04 / scale > mapHeight * 0.5 ? mapHeight * 0.5 : mapWidth * 0.04 / scale

  const modalOffsetX = x / 100 * mapWidth - modalWidth / 2
  const modalOffsetY = y / 100 * mapHeight - modalHeight / 2

  return (
    <Layer>
      <Rect
        x={modalOffsetX}
        y={modalOffsetY}
        width={modalWidth}
        height={modalHeight}
        fill='#FFF'
        cornerRadius={modalWidth * 0.03}
        shadowBlur={10}
      />
      <Text
        x={modalOffsetX}
        y={modalOffsetY}
        width={modalWidth}
        height={modalHeight}
        fill='#333'
        text='Перенесите места для бронирования на карту'
        fontSize={mapWidth * 0.04 * 0.2 / scale > mapWidth * 0.5 ? mapWidth * 0.5 : mapWidth * 0.04 * 0.2 / scale}
        fontFamily='DIN Pro'
        align='center'
        verticalAlign='center'
        padding={modalHeight * 0.3}
      />
    </Layer>
  )
})

export default ModalOnMap
