import React from 'react'
import { dateToStringDayOfWeek } from '../../../utils/dates/formatDate'
import DoubleLineColonBlock from '../../UI/dataBlock/DoubleLineColonBlock'

const BookingCardListItem = React.memo(({ booking }) => {
  const { status } = booking

  const codeTextColor = booking.active ? 'secondary_text__green' : 'secondary_text__white'

  const getBookStatus = () => {
    const statusMap = {
      waiting: 'Ожидание',
      active: 'Активна',
      canceled: 'Отменена',
      over: 'Завершена',
      auto_canceled: 'Отменена автоматически',
      auto_over: 'Завершена автоматически',
    }
    return statusMap[status]
  }
  return (
    <div
      className='flex_container full_width'
      style={{ width: 'calc(100% - 20px)' }}
      title={booking?.user?.phone_number}
    >
      <DoubleLineColonBlock
        first={{ title: 'с', text: dateToStringDayOfWeek(booking.date_from) }}
        second={{ title: 'по', text: dateToStringDayOfWeek(booking.date_to) }}
        containerClass='card_list__item_duration__table_screen'
        containerStyle={{ width: '35%', minWidth: '35%', maxWidth: '35%' }}
      />
      <span
        className='card_list__item_name main_text__white'
        style={{ width: '40%', minWidth: '40%', paddingLeft: '3%', maxWidth: '40%' }}
      >
        {booking?.user?.phone_number || 'Телефон не указан'}
      </span>
      <span
        className={`card_list__item_small_description ${codeTextColor}`}
        style={{ borderRight: 0, width: '25%', minWidth: '25%', textAlign: 'center' }}
      >
        {getBookStatus()}
      </span>
    </div>
  )
})

export default BookingCardListItem
