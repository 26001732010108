/* eslint-disable import/prefer-default-export */
import {
  SET_APP_IMPORT_LOADER,
  SET_APP_IS_LOADING,
  SET_APP_PAGINATION_IN_GROUP,
  SET_APP_PAGINATION_NOT_IN_GROUP,
  SET_APP_USERS_IS_LOADING,
  SET_OFFICE_IS_LOADING,
  SET_LICENSES_IS_LOADING,
  SET_FLOORS_IS_LOADING,
  SET_ROOMS_IS_LOADING,
  SET_ZONES_IS_LOADING,
  SET_TAGS_IS_LOADING,
  SET_ROOM_TYPES_IS_LOADING,
  SET_BOOKING_IS_LOADING,
  SET_TABLE_IS_LOADING,
  SET_DISABLE_IS_LOADING,
  SET_GROUP_IS_LOADING,
} from '../appActions'

export const appAC = {
  setAppIsLoading: isLoading => ({
    type: SET_APP_IS_LOADING, payload: isLoading,
  }),
  setOfficeIsLoading: isLoading => ({
    type: SET_OFFICE_IS_LOADING, payload: isLoading,
  }),
  setLicensesIsLoading: isLoading => ({
    type: SET_LICENSES_IS_LOADING, payload: isLoading,
  }),
  setFloorsIsLoading: isLoading => ({
    type: SET_FLOORS_IS_LOADING, payload: isLoading,
  }),
  setRoomsIsLoading: isLoading => ({
    type: SET_ROOMS_IS_LOADING, payload: isLoading,
  }),
  setZonesIsLoading: isLoading => ({
    type: SET_ZONES_IS_LOADING, payload: isLoading,
  }),
  setTagsIsLoading: isLoading => ({
    type: SET_TAGS_IS_LOADING, payload: isLoading,
  }),
  setRoomTypesIsLoading: isLoading => ({
    type: SET_ROOM_TYPES_IS_LOADING, payload: isLoading,
  }),
  setAppUsersIsLoading: isLoading => ({
    type: SET_APP_USERS_IS_LOADING, payload: isLoading,
  }),
  setPaginationInGroup: isLoading => ({
    type: SET_APP_PAGINATION_IN_GROUP, payload: isLoading,
  }),
  setPaginationNotInGroup: isLoading => ({
    type: SET_APP_PAGINATION_NOT_IN_GROUP, payload: isLoading,
  }),
  setImportLoader: isLoading => ({
    type: SET_APP_IMPORT_LOADER, payload: isLoading,
  }),
  setBookingLoader: isLoading => ({
    type: SET_BOOKING_IS_LOADING, payload: isLoading,
  }),
  setTableLoader: isLoading => ({
    type: SET_TABLE_IS_LOADING, payload: isLoading,
  }),
  setDisable: isLoading => ({
    type: SET_DISABLE_IS_LOADING, payload: isLoading,
  }),
  setGroupLoader: isLoading => ({
    type: SET_GROUP_IS_LOADING, payload: isLoading,
  }),
}
