import moment from 'moment'

export const formatDateFromTwoFields = (date = moment(), time = moment()) =>
  `${date.format('YYYY-MM-DD')}T${time.format('HH:mm:ssZ')}`

export const formatDate = (date = moment()) => `${(date || moment()).format('YYYY-MM-DDTHH:mm:ssZ')}`
export const formatDateAdmin = (date = moment()) => `${(date || moment()).format('YYYY-MM-DDTHH:mm:ss')}`

export const dateToString = date => `${moment(date).format('DD MMM YYYY  HH:mm')}`
export const dateToShortString = date => `${moment(date).format('HH:mm DD MMM')}`
export const formatDateZeroMinutes = (date = moment()) => `${date.format('YYYY-MM-DDTHH:00:00Z')}`
export const formatDateHoursMinutes = (date = moment()) => date.format('HH:mm')

export const dateToStringNoHour = date => `${moment(date).format('DD MMM YYYY')}`

export const dateToStringDayOfWeek = date => `${moment(date).format('dd,DD MMM HH:mm')}`

export const TODAY = moment.utc()
