import React from 'react'
import Tooltip from 'antd/lib/tooltip'

import { useDispatch, useSelector } from 'react-redux'
import stopPropagation from '../../../utils/stopPropagation'
import { officesAC } from '../../../actions/actionCreator/offices'
import { getAppLoading } from '../../../reducers/app-selectors'

const KioskCardListItem = React.memo(({
  kiosk,
  index,
  onDelete,
  onItemClick,
  getItemActivityClass,
}) => {
  const { id, office, floor, first_name } = kiosk
  const dispatch = useDispatch()

  const onClickHandler = () => {
    dispatch(officesAC.getFloors({ officeId: office.id }))
  }
  return (
    <div
      className={getItemActivityClass()}
      onClick={e => stopPropagation(() => {
        onItemClick(kiosk)
        onClickHandler()
      }, e)}
    >
      <span className='card_list__item_id'>{`${index + 1}.`}</span>
      <span className='card_list__item_user_name'>
        {first_name === 'Название не указано'
          ? <div className='main_text__inactive text__wrapper'>{first_name}</div>
          : first_name}
      </span>
      <span className='card_list__item_colon_block__left_border' style={{ flex: 4 }}>
        <span className='secondary_text margin_right'>БЦ:</span>
        {office?.title
          ? <span className='secondary_text__white'>{office?.title}</span>
          : <div className='secondary_text__inactive text__wrapper'>Не указано</div>}
      </span>
      <span className='card_list__item_colon_block__left_border' style={{ flex: 1 }}>
        <span className='secondary_text margin_right'>Этаж:</span>
        <span className='secondary_text__white'>{floor?.title}</span>
      </span>
      {onDelete && (
        <Tooltip title='Удалить' mouseEnterDelay={0.6}>
          <div
            className='icon__wrapper__hover_enabled full_height'
            onClick={e => stopPropagation(() => onDelete({ id, name: first_name }), e)}
          >
            <div className='icon__delete' />
          </div>
        </Tooltip>
      )}
    </div>
  )
})

export default KioskCardListItem
