import React from 'react'
import { Route, Switch } from 'react-router-dom'

import UsersGroupList from './index'
import GroupCard from './InfoCard/index'
import GroupEditCard from './EditCard'

const Router = () => (
  <Switch>
    <Route exact path='/groups' render={() => <UsersGroupList />} />
    <Route exact path='/groups/:id' render={() => <GroupCard />} />
    <Route exact path='/groups/:id/edit' render={props => <GroupEditCard {...props} />} />
  </Switch>
)

export default Router
