import React from 'react'

const SyncIcon = ({ style, fill = '#1A7FC3' }) => (
  <svg
    style={style}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M16.119 12.159h-4.077a.9.9 0 100 1.8h2.16A7.2 7.2 0 011.8 9 .9.9 0 000 9a9 9 0 0015.192 6.507V17.1a.9.9 0 101.8 0v-4.05a.9.9 0 00-.873-.891zM9 0a9 9 0 00-6.192 2.493V.9a.9.9 0 10-1.8 0v4.05a.9.9 0 00.9.9h4.05a.9.9 0 000-1.8h-2.16A7.2 7.2 0 0116.2 9 .9.9 0 1018 9a9 9 0 00-9-9z'
      fill={fill}
    />
  </svg>
)

export default SyncIcon
