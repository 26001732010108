import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'antd/lib/select'
import cn from 'classnames'

import SyncIcon from '../../../UI/icons/SyncIcon'
import DatePicker from './DatePicker/DatePicker'
import { STATISTICS_GET_REQUEST } from '../../../../actions/statistics'
import { getCurrentMoment, getMomentMonthsBefore, formatMoment, getMomentEndOfDay } from '../utils'

import styles from './styles.module.scss'
import './customAntdStyles.scss'

const getBaseDisabledDateCondition = moment => moment > getMomentEndOfDay()

const renderReloadStatisticsButton = (isLoading, downloadStatistics) => (
  <button
    type='button'
    className={styles.reload__statistics__button}
    onClick={downloadStatistics}
    disabled={isLoading}
  >
    <SyncIcon
      style={{
        width: '18px',
        height: '18px',
        marginRight: '14px',
      }}
    />
    Обновить статистику
  </button>
)

const renderOfficesOption = ({ id, title }) => (
  <Select.Option value={id} key={id}>{title}</Select.Option>
)

const SelectorsInnerComponent = ({ offices }) => {
  const defaultOfficeId = offices[0].id
  const currentMoment = getCurrentMoment()
  const momentOneMonthBefore = getMomentMonthsBefore(currentMoment)

  const [officeId, setOfficeId] = useState(defaultOfficeId)
  const [dateFrom, setDateFrom] = useState(momentOneMonthBefore)
  const [dateTo, setDateTo] = useState(currentMoment)
  const isLoading = useSelector(({ statistics }) => statistics.isLoading)

  const dispatch = useDispatch()

  const payload = {
    officeId,
    dateFrom: formatMoment(dateFrom, 'YYYY-MM-DD'),
    dateTo: formatMoment(dateTo, 'YYYY-MM-DD'),
  }

  const downloadStatistics = () => dispatch({ type: STATISTICS_GET_REQUEST, payload })

  useEffect(() => {
    downloadStatistics(payload)
  }, [officeId, dateFrom, dateTo])

  const selectorClasses = cn('custom-selector',
    {
      [styles.selector]: true,
      [styles.disabled]: isLoading,
    })
  const datePickerClasses = cn({ [styles.disabled]: isLoading })

  return (
    <div className={styles.root}>
      <div className={styles.title__panel}>
        <div className={styles.title__container}>
          <div className='icon__graphic' />
          <span className={`large_btn__title ${styles.title}`}>cтатистика бронирования рабочих мест</span>
        </div>
        {renderReloadStatisticsButton(isLoading, downloadStatistics)}
      </div>
      <div className={styles.selectors__container}>
        <div className={styles.selector__wrapper}>
          <Select
            className={selectorClasses}
            value={officeId}
            onChange={setOfficeId}
          >
            {!!offices.length && offices.map(renderOfficesOption)}
          </Select>
        </div>
        <DatePicker
          className={datePickerClasses}
          name='start-date'
          value={dateFrom}
          setValue={setDateFrom}
          disabledDate={current => getBaseDisabledDateCondition(current) || current > dateTo}
        />
        <span className={styles.date__picker__divider} />
        <DatePicker
          className={datePickerClasses}
          name='end-date'
          value={dateTo}
          setValue={setDateTo}
          disabledDate={current => getBaseDisabledDateCondition(current) || current < dateFrom}
        />
      </div>
    </div>
  )
}

export default SelectorsInnerComponent
