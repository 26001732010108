import React from 'react'
import DatePicker from 'antd/lib/date-picker'
import ConfigProvider from 'antd/lib/config-provider'
import ruRU from 'antd/es/locale/ru_RU'
import cn from 'classnames'

import Icon from '../../../../UI/Icon'
import './customAntdStyles.scss'

export default props => {
  const { name, value, setValue, disabledDate, disabled, className } = props
  const iconClasses = 'icon__suitcase icon__suitcase_place_date-picker'

  const classes = cn('custom__picker',
    {
      [className]: className,
    })

  return (
    <ConfigProvider locale={ruRU}>
      <DatePicker
        className={classes}
        name={name}
        value={value}
        format='DD.MM.YYYY'
        disabledDate={disabledDate}
        suffixIcon={<Icon name={iconClasses} />}
        onChange={setValue}
        disabled={disabled}
      />
    </ConfigProvider>
  )
}
