import {
  SET_OFFICE_IS_LOADING,
  SET_LICENSES_IS_LOADING,
  SET_FLOORS_IS_LOADING,
  SET_ROOMS_IS_LOADING,
  SET_ZONES_IS_LOADING,
  SET_TAGS_IS_LOADING,
  SET_ROOM_TYPES_IS_LOADING,
  SET_APP_IMPORT_LOADER,
  SET_APP_IS_LOADING,
  SET_APP_PAGINATION_IN_GROUP,
  SET_APP_PAGINATION_NOT_IN_GROUP,
  SET_APP_USERS_IS_LOADING,
  SET_BOOKING_IS_LOADING,
  SET_TABLE_IS_LOADING,
  SET_DISABLE_IS_LOADING,
  SET_GROUP_IS_LOADING,
} from '../actions/appActions'

const initialState = {
  isAppLoading: false,
  isUsersLoading: false,
  paginationInGroup: false,
  paginationNotInGroup: false,
  importLoading: false,
  isOfficeLoading: false,
  isLicensesLoading: false,
  isFloorsLoading: false,
  isRoomsLoading: false,
  isZonesLoading: false,
  isTagsLoading: false,
  isRoomTypesLoading: false,
  isBookingLoading: false,
  isTableLoading: false,
  isDisableLoading: false,
  isGroupLoading: false,

}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APP_IS_LOADING: {
      return { ...state, isAppLoading: action.payload }
    }
    case SET_APP_USERS_IS_LOADING: {
      return { ...state, isUsersLoading: action.payload }
    }
    case SET_APP_PAGINATION_IN_GROUP: {
      return { ...state, paginationInGroup: action.payload }
    }
    case SET_APP_PAGINATION_NOT_IN_GROUP: {
      return { ...state, paginationNotInGroup: action.payload }
    }
    case SET_APP_IMPORT_LOADER: {
      return { ...state, importLoading: action.payload }
    }

    case SET_OFFICE_IS_LOADING: {
      return { ...state, isOfficeLoading: action.payload }
    }

    case SET_LICENSES_IS_LOADING: {
      return { ...state, isLicensesLoading: action.payload }
    }

    case SET_FLOORS_IS_LOADING: {
      return { ...state, isFloorsLoading: action.payload }
    }

    case SET_ROOMS_IS_LOADING: {
      return { ...state, isRoomsLoading: action.payload }
    }

    case SET_ZONES_IS_LOADING: {
      return { ...state, isZonesLoading: action.payload }
    }

    case SET_TAGS_IS_LOADING: {
      return { ...state, isTagsLoading: action.payload }
    }

    case SET_ROOM_TYPES_IS_LOADING: {
      return { ...state, isRoomTypesLoading: action.payload }
    }

    case SET_BOOKING_IS_LOADING: {
      return { ...state, isBookingLoading: action.payload }
    }
    case SET_TABLE_IS_LOADING: {
      return { ...state, isTableLoading: action.payload }
    }
    case SET_DISABLE_IS_LOADING: {
      return { ...state, isDisableLoading: action.payload }
    }
    case SET_GROUP_IS_LOADING: {
      return { ...state, isGroupLoading: action.payload }
    }
    default: {
      return state
    }
  }
}

export default appReducer
