import React from 'react'
import { connect } from 'react-redux'
import EmptyPlaceholder from '../UI/EmptyPlaceholder'
import IconPlus from '../UI/icons/Plus'
import handleUpload from './helpers/loadMap'
import MapKonva from './MapKonva'
import './tooltip.scss'
import './toolbar.scss'

import { officesAC } from '../../actions/actionCreator/offices'

const DEFAULT_POSITION_PARAMS = { scale: 0.4, translation: { x: 0, y: 0 } }

class ResponsibleMapContainer extends React.Component {
  state = {
    floor: null,
    ...DEFAULT_POSITION_PARAMS,
  }

  input = React.createRef()

  mapConfig = {
    minScale: 0.4,
    maxScale: 2,
    btnClass: 'map__button',
    showControls: true,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return (nextProps.activeFloor !== prevState.floor)
      ? ({ ...DEFAULT_POSITION_PARAMS, floor: nextProps.activeFloor })
      : null
  }

  getMarkerStyle = roomId => roomId === this.props.activeRoom && 'marker__active'

  render() {
    const { activeRoom, mapImage, activeFloor, postMap, floors } = this.props
    const activeFloorMap = floors.find(f => f.id === activeFloor)?.floor_map
    return (
      <>
        {mapImage
          ? (
            <MapKonva
              map={mapImage}
              floorMap={activeFloorMap}
              activeRoom={activeRoom}
              activeFloor={activeFloor}
              mapState={this.props.mapState}
              setConfirmModal={this.props.setConfirmModal}
              deleteMarker={this.props.deleteMarker}
              deleteRoom={this.props.deleteRoom}
              stateWithoutRedux={this.props.stateWithoutRedux}
              dispatchWithoutRedux={this.props.dispatchWithoutRedux}
            />
          )
          : (
            <div className='container__add_image'>
              <input
                type='file'
                ref={this.input}
                className='input__file_uploader'
                onChange={e => handleUpload(e, activeFloor, postMap)}
              />
              <div className='btn__add_map' onClick={() => this.input.current.click()}>
                <IconPlus className='icon__add_inactive' />
                <EmptyPlaceholder text='Добавить карту этажа' />
              </div>
            </div>
          )}
      </>
    )
  }
}

const mapStateToProps = ({ offices = {} }, { activeFloor }) => {
  const currentFloor = offices.floors?.find(floor => floor.id === activeFloor)
  const mapImage = currentFloor?.floor_map?.image?.path

  return { floors: offices.floors, mapImage }

}

const mapDispatchToProps = dispatch => ({
  postMap: (floorId, map, sizes) => dispatch(
    officesAC.postMapToFloor({ floorId, map, sizes }),
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResponsibleMapContainer)
