import React from 'react'
import { useSelector } from 'react-redux'
import LargeSpin from '../../UI/Spin/LargeSpin'
import ScrollTransition from '../../UI/animations/transition/ScrollTransition'
import styles from './styles.module.scss'
import { getFacts, getIsLoading } from '../../../reducers/statistics-selectors'

const renderFact = fact => {
  const { title, value, badgeValue } = fact

  return (
    <div className={styles.fact__wrapper} key={title}>
      <p className={styles.fact__title}>{title}</p>
      <div className={styles.fact__value__wrapper}>
        <span className={styles.fact__value}>{value}</span>
        {badgeValue && <span className={styles.fact__badge}>{badgeValue}</span>}
      </div>
    </div>
  )
}

const Facts = () => {
  const facts = useSelector(getFacts)
  const isLoading = useSelector(getIsLoading)
  if (isLoading || !facts.length) return <LargeSpin />
  return (
    <ScrollTransition loading paginationLoading={false}>
      <div className={styles.root}>
        <h2 className={styles.main__title}>Статистика бронирования рабочих мест</h2>
        <div className={styles.facts__list}>
          {facts?.map(renderFact)}
        </div>
      </div>
    </ScrollTransition>
  )
}
export default Facts
