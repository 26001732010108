import React from 'react'
import Spin from 'antd/lib/spin'

const LargeSpin = ({ extraStyle, styles }) => (
  <div style={styles} className={`spin_placeholder ${extraStyle}`}>
    <Spin size='large' />
  </div>
)

export default LargeSpin
