import React from 'react'

const InfoBlue = ({ ...props }) => (
  <span {...props}>
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM5.51504 9H6.49248V4.40642H5.51504V9ZM5.5 3.46229H6.5V2.5H5.5V3.46229Z'
        fill='#0079C1'
      />
    </svg>
  </span>
)

export default InfoBlue
